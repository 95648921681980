<script>
import { branchMenus as menus } from "@/index/Shared/branch-menus.js";
import OffCanvas from "@/components/OffCanvas.vue";
export default {
  name: "BranchPermissions",
  props: ["role"],
  components: { OffCanvas },
  data: () => ({
    newRole: { permissions: {} },
  }),
  created() {
    const permissions = {};
    menus.forEach((menu) => {
      permissions[menu.title] = {
        accessible: false,
      };
      if (menu.submenus) {
        for (let key in menu.submenus) {
          permissions[menu.title][key] = [];
        }
      } else {
        permissions[menu.title].access = [];
      }
    });
    if (this.role.branch_permissions) {
      this.newRole.permissions = this.mergeDeep(
        permissions,
        this.role.branch_permissions
      );
    } else {
      this.newRole.permissions = JSON.parse(JSON.stringify(permissions));
    }
  },
  methods: {
    isObject(item) {
      return item && typeof item === "object" && !Array.isArray(item);
    },
    mergeDeep(target, ...sources) {
      if (!sources.length) return target;
      const source = sources.shift();

      if (this.isObject(target) && this.isObject(source)) {
        for (const key in source) {
          if (this.isObject(source[key])) {
            if (!target[key]) Object.assign(target, { [key]: {} });
            this.mergeDeep(target[key], source[key]);
          } else {
            Object.assign(target, { [key]: source[key] });
          }
        }
      }
      return this.mergeDeep(target, ...sources);
    },
    grantFullAccess(e, item, menu) {
      if (e.target.checked) {
        this.newRole.permissions[menu][item] = ["C", "R", "U", "D"];
      } else {
        this.newRole.permissions[menu][item] = [];
      }
    },
    handleSave() {
      this.newRole.id = this.role.id;
      this.$http
        .post(
          "settings/users/branch-permissions/store",
          this.$helper.generateFormData(this.newRole)
        )
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "success",
              message: "Record saved successfully.",
            });
            this.$emit("saved", this.newRole.permissions);
          }
        });
    },
  },
};
</script>
<template>
  <off-canvas
    @close="$emit('closed')"
    style="display: block"
    :modalSize="'modal-lg'"
    position="end"
  >
    <span slot="head"
      >Manage Branch Permissions for <a href="#">{{ role.name }}</a></span
    >
    <div slot="body" v-if="Object.keys(newRole.permissions).length > 0">
      <div
        class="ember-view"
        v-for="(menu, i) in newRole.permissions"
        :key="'menu' + i"
      >
        <div class="card mb-5">
          <div class="card-header">
            <div class="card-title font-large mb-0 text-capitalize">
              <div class="form-check">
                <input
                  :id="i"
                  class="ember-checkbox ember-view form-check-input"
                  type="checkbox"
                  v-model="newRole.permissions[i].accessible"
                />
                <label class="form-check-label" for="a05c3054f">{{
                  $helper.inputTitle(i)
                }}</label>
              </div>
            </div>
          </div>
          <table
            class="table table-bordered role-table mb-0"
            v-if="Object.keys(menu).length > 1 && menu.accessible"
          >
            <colgroup>
              <col />
              <col style="background: #fcfcfc" />
            </colgroup>
            <thead>
              <tr>
                <th style="min-width: 150px"></th>
                <th class="permission">Full Access</th>
                <th class="permission text-capitalize">view</th>
                <th class="permission">Create</th>
                <th class="permission">Edit</th>
                <th class="permission">Delete</th>
                <!-- <th class="permission">Others</th> -->
              </tr>
            </thead>
            <tbody>
              <template v-for="j in Object.keys(menu)">
                <tr
                  v-if="j != 'accessible'"
                  :key="'menu' + i + '.' + 'item' + j"
                >
                  <td class="text-capitalize">
                    {{ $helper.inputTitle(j) }}
                  </td>
                  <td class="permission">
                    <div class="form-check">
                      <input
                        :id="j + '-full'"
                        class="ember-checkbox ember-view form-check-input position-static"
                        type="checkbox"
                        @change="grantFullAccess($event, j, i)"
                      />
                    </div>
                  </td>
                  <td class="permission">
                    <div class="form-check">
                      <input
                        :id="j + '-view'"
                        class="ember-checkbox ember-view form-check-input position-static"
                        type="checkbox"
                        value="R"
                        v-model="newRole.permissions[i][j]"
                      />
                    </div>
                  </td>
                  <td class="permission">
                    <div class="form-check">
                      <input
                        :id="j + '-create'"
                        class="ember-checkbox ember-view form-check-input position-static"
                        type="checkbox"
                        value="C"
                        v-model="newRole.permissions[i][j]"
                      />
                    </div>
                  </td>
                  <td class="permission">
                    <div class="form-check">
                      <input
                        :id="j + '-edit'"
                        class="ember-checkbox ember-view form-check-input position-static"
                        type="checkbox"
                        value="U"
                        v-model="newRole.permissions[i][j]"
                      />
                    </div>
                  </td>
                  <td class="permission">
                    <div class="form-check">
                      <input
                        :id="j + '-delete'"
                        class="ember-checkbox ember-view form-check-input position-static"
                        type="checkbox"
                        value="D"
                        v-model="newRole.permissions[i][j]"
                      />
                    </div>
                  </td>
                  <!-- <td class="permission">
                    <div class="d-grid" style="grid-template-columns: 1fr 1fr">
                      <div
                        class="form-check"
                        v-for="(item, z) in newRole.permissions[i][j].others"
                        :key="j + 'extras.' + item"
                      >
                        <input
                          class="ember-checkbox ember-view form-check-input"
                          type="checkbox"
                          v-model="newRole.permissions[i][j].others[z]"
                        />
                        <label class="form-check-label text-uppercase">{{
                          z
                        }}</label>
                      </div>
                    </div>
                  </td> -->
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="stick-action w-100">
        <div class="border shadow text-right px-4 py-2">
          <wolf-button
            classes="btn btn-primary"
            @clicked="handleSave"
            :disabler="false"
            activator="saving_records"
          >
            <span slot="caption"
              ><em class="icon ni ni-save"></em
              ><span>Confirm and Save</span></span
            >
          </wolf-button>
        </div>
      </div>
    </div>
  </off-canvas>
</template>
<style scoped>
label {
  margin-bottom: 0.5rem;
}
label {
  display: inline-block;
}
input {
  overflow: visible;
}
*,
::after,
::before {
  box-sizing: border-box;
}
a {
  color: var(--base-color);
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: var(--base-color);
  text-decoration: underline;
}
table {
  border-collapse: collapse;
}
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
@media print {
  tr {
    page-break-inside: avoid;
  }
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  thead {
    display: table-header-group;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td {
    background-color: #fff !important;
  }
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
}
label {
  font-weight: 400;
}
.table {
  width: 100%;
  margin-bottom: 10px;
  color: #212529;
}
.table td {
  padding: 8px;
  vertical-align: top;
  border-top: 1px solid #eee;
}
.table-bordered,
.table-bordered td {
  border: 1px solid #eee;
}
.table-bordered thead td {
  border-bottom-width: 2px;
}
.card > .table-bordered {
  border: 0;
}
.card > .table-bordered > tbody > tr > td:first-of-type,
.card > .table-bordered > thead > tr > td:first-of-type {
  border-left: 0;
}
.card > .table-bordered > tbody > tr > td:last-of-type,
.card > .table-bordered > thead > tr > td:last-of-type {
  border-right: 0;
}
.card > .table-bordered > tbody > tr:first-of-type > td,
.card > .table-bordered > tbody > tr:last-of-type > td,
.card > .table-bordered > thead > tr:first-of-type > td {
  border-bottom: 0;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #777;
}
.form-check-label {
  margin-bottom: 0;
}
input::-ms-clear {
  display: none;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-header {
  margin-bottom: 0;
}
.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.position-static {
  position: static !important;
}
.mb-5 {
  margin-bottom: 20px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-large {
  font-size: 16px;
}
.form-check-label {
  cursor: pointer;
}
.form-check-input:disabled ~ .form-check-label,
input[type="checkbox"]:disabled {
  cursor: not-allowed;
}
.popovercontainer {
  position: relative;
}
.popovercontainer:focus {
  outline: 0;
}
:focus::-webkit-input-placeholder {
  opacity: 0.5;
}
:focus:-moz-placeholder {
  opacity: 0.5;
}
:focus::-moz-placeholder {
  opacity: 0.5;
}
:focus:-ms-input-placeholder {
  opacity: 0.5;
}
.role-table td.permission {
  min-width: 70px;
  text-align: center;
}
.stick-action {
  position: sticky;
  bottom: 0;
  z-index: 90;
  background: #fff;
}

.collapse:not(.show) {
  display: none;
}
svg {
  vertical-align: baseline;
}
.alert {
  position: relative;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 2px;
}
.alert-warning {
  background-color: #fff4e7 !important;
  border: 0;
  color: #222;
}
.align-middle {
  vertical-align: middle !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
svg.icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
}
svg.icon.icon-xs {
  height: 12px;
  width: 12px;
}
svg.icon.fill-draft {
  fill: #999;
}
.cursor-pointer {
  cursor: pointer;
}
.font-large {
  font-size: 16px;
}
.reports-permission .reports-row .information {
  visibility: hidden;
}
.reports-permission .cell-border-right {
  border-right: 1px solid #eee;
}
.reports-permission .active,
.reports-permission .reports-row:hover {
  background-color: #fafafa;
}
.reports-permission .cell {
  padding: 0.9rem;
}
.reports-permission .cell-border-top {
  border-top: 1px solid #eee;
}
.reports-permission .active-border {
  margin-left: -15px;
  padding-left: 13px;
  border-left: 2px solid #54b4fa;
}
.reports-permission .reports-row:hover .information {
  visibility: visible;
}
.reports-row.active .reports-arrow-down {
  transform: rotate(90deg);
}
.reports-permission .fix-header {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  z-index: 1;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid #eee;
}
.text-brown {
  color: #8a6d3b;
}
.reports-row .select-cell {
  display: none;
}
.reports-row.active .select-cell {
  display: block;
}
</style>
