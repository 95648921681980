<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-lg mx-auto">
          <div
            class="invoice"
            id="invoice-container"
            v-if="!$helper.empty(order)"
          >
            <div class="invoice-action">
              <a
                class="btn btn-icon btn-white btn-dim btn-outline-primary"
                href="javascript:void(0)"
                @click.prevent="$helper.handlePrint('.invoice-wrap')"
                ><em class="icon ni ni-printer-fill"></em
              ></a>
            </div>
            <div class="invoice-wrap">
              <print-header>
                <div slot="title" class="mb-1">
                  <h3 class="title mb-0 text-center">Purchase Order</h3>
                  <hr />
                  <div class="d-flex justify-content-between w-100">
                    <div class="invoice-contact">
                      <span class="overline-title">Invoice To</span>
                      <div class="invoice-contact-info">
                        <h5 class="title mb-1">{{ order.vendor.name }}</h5>
                        <ul class="list-plain">
                          <li class="p-1">
                            <em class="icon ni ni-map-pin-fill"></em
                            ><span>{{ order.vendor.address }}</span>
                          </li>
                          <li class="p-1">
                            <em class="icon ni ni-call-fill"></em
                            ><span>{{ order.vendor.phone }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="invoice-desc">
                      <ul class="list-plain">
                        <li class="invoice-id">
                          <span>Order Code</span>#<span>{{
                            $helper.generateVoucherNo(order.id)
                          }}</span>
                        </li>
                        <li class="invoice-date">
                          <span>Date</span>:<span>{{
                            $helper.formatDate(order.purchase_date)
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </print-header>
              <div class="invoice-head d-print-none">
                <div class="invoice-contact">
                  <span class="overline-title">Invoice To</span>
                  <div class="invoice-contact-info">
                    <h5 class="title">{{ order.vendor.name }}</h5>
                    <ul class="list-plain">
                      <li>
                        <em class="icon ni ni-map-pin-fill"></em
                        ><span>{{ order.vendor.address }}</span>
                      </li>
                      <li>
                        <em class="icon ni ni-call-fill"></em
                        ><span>{{ order.vendor.phone }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="invoice-desc">
                  <h6 class="title mb-0">Purchase Order</h6>
                  <ul class="list-plain">
                    <li class="invoice-id">
                      <span>Order Code</span>#<span>{{
                        $helper.generateVoucherNo(order.id)
                      }}</span>
                    </li>
                    <li class="invoice-date">
                      <span>Date</span>:<span>{{
                        $helper.formatDate(order.purchase_date)
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="invoice-bills">
                <div class="table-responsive">
                  <table class="table table-striped-">
                    <thead>
                      <tr>
                        <th class="">$/N</th>
                        <th class="">Item Name</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in items" :key="'item' + i">
                        <td>{{ i+1 }}</td>
                        <td>{{ item.product.name }}</td>
                        <td>
                          {{ formatMoney(item.price) }}
                        </td>
                        <td>{{ $helper.formatNumber(item.quantity) }}</td>
                        <td>
                          <span class="tb-sub tb-amount">{{
                            formatMoney(item.total_amount)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="3"></td>
                        <td colspan="">Subtotal</td>
                        <td>
                          {{ formatMoney(order.total_amount) }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td colspan="">Shipping fee</td>
                        <td>{{ appSettings.currency }}0.00</td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td colspan="">TAX</td>
                        <td>{{ appSettings.currency }}0.00</td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td colspan="">Grand Total</td>
                        <td>
                          {{ formatMoney(order.total_amount) }}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div class="nk-notes ff-italic fs-12px text-soft">
                    <!-- Invoice was created on a computer and is valid without
                        the signature and seal. -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "OrderInvoice",
  components: { PrintHeader },
  data: () => ({
    items: [],
    order: {},
  }),
  computed: {},
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$http.get(`/orders/report/${reference}/details`).then((response) => {
        this.items = response.data.items;
        this.order = response.data.order;
      });
    }
  },
  methods: {},
};
</script>
