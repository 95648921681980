<script>
import { dashboardServices } from "@/_helpers/services";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
export default {
  name: "PhysicalCountReport",
  mixins: [dashboardServices],
  data: () => ({
    selectedBranch: null,
    scrolled: false,
  }),
  components: {
    Multiselect,
  },
  computed: {
    ...mapState({
      branches: (state) => state.branches,
    }),
  },
  created() {
    window.addEventListener("scroll", () => {
      this.scrolled = window.scrollY > 170;
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchUrl = "reports/physical-count-report";
      if (!this.$helper.empty(this.loggedUser.branch)) {
        this.newFilter.branch_id = this.loggedUser.branch.id;
        this.handlePaginationFilter();
      }
    });
  },
  methods: {
    handleBranchFilter(record) {
      this.selectedBranch = record;
      if (record) {
        this.newFilter.branch_id = record.id;
        this.handlePaginationFilter();
      }
    },
  },
};
</script>
<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title mb-0">
                      Sales Analysis Report
                    </h3>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            style="width: 180px"
                            v-if="$helper.empty(loggedUser.branch)"
                          >
                            <multiselect
                              v-model="selectedBranch"
                              :options="branches"
                              placeholder="Selected branch"
                              label="name"
                              track-by="name"
                              @select="handleBranchFilter"
                            ></multiselect>
                          </li>
                          <li style="width: 180px">
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="search"
                                class="form-control"
                                placeholder="Search by name"
                                v-model="filterKey"
                                @keyup="handlePaginationSearch"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div
                    class="nk-tb-item nk-tb-head"
                    :class="{ 'sticky-table': scrolled }"
                  >
                    <div class="nk-tb-col" style="width: 18%">
                      <span class="sub-text">Item Name</span>
                    </div>
                    <div class="nk-tb-col" style="width: 7%">
                      <span class="sub-text">OPEN.</span>
                    </div>
                    <div class="nk-tb-col" style="width: 7%">
                      <span class="sub-text">REC. </span>
                    </div>
                    <div class="nk-tb-col p-0">
                      <span class="sub-text">SALES</span>
                      <table class="table table-bordered table-sm">
                        <thead>
                          <tr>
                            <th style="width: 40%">Used In</th>
                            <th style="width: 20%">Qty</th>
                            <th style="width: 20%">Portion</th>
                            <th style="width: 20%">Used</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div class="nk-tb-col" style="width: 7%">
                      <span class="sub-text">CLOSE.</span>
                    </div>
                    <div class="nk-tb-col" style="width: 7%">
                      <span class="sub-text">COUNT</span>
                    </div>
                    <div class="nk-tb-col" style="width: 7%">
                      <span class="sub-text">BAL.</span>
                    </div>
                    <!-- <div class="nk-tb-col">
                      <span class="sub-text">SP&amp;DM</span>
                    </div> -->
                    <div class="nk-tb-col" style="width: 15%">
                      <span class="sub-text">Notes</span>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'item' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{ item.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.open)} ${item.unit}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.receivedItems)} ${
                          item.unit
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col p-0">
                      <table
                        class="table table-striped table-sm"
                        v-if="item.recipes && item.recipes.length"
                      >
                        <tbody>
                          <tr
                            v-for="(recipe, j) in item.recipes"
                            :key="
                              $helper.generateRandomText(5) + recipe.item_id
                            "
                          >
                            <td style="width: 40%">{{ recipe.name }}</td>
                            <td style="width: 20%">
                              {{ `${$helper.formatNumber(recipe.parent_qty)}` }}
                            </td>
                            <td style="width: 20%">
                              {{ `${$helper.formatNumber(recipe.quantity)}` }}
                            </td>
                            <td
                              style="width: 20%"
                              :rowspan="item.recipes.length"
                              v-if="j == 0"
                            >
                              <span class="tb-amount">{{
                                `${$helper.formatNumber(item.transferredItems)}`
                              }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.close)} ${item.unit}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col" style="width: 150px">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.counted)} ${item.unit}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.close - item.counted)} ${
                          item.unit
                        }`
                      }}</span>
                    </div>
                    <!-- <div class="nk-tb-col">
                      <span
                        class="tb-amount"
                        :class="{ 'text-danger': rows[i].spoiledItems > 0 }"
                        >{{
                          `${$helper.formatNumber(rows[i].spoiledItems)} ${
                            item.unit
                          }`
                        }}</span
                      >
                    </div> -->
                    <div class="nk-tb-col">
                      <template
                        v-if="
                          item.comments &&
                          Object.prototype.toString.call(item.comments) ===
                            '[object Array]'
                        "
                      >
                        <p
                          class="sub-text"
                          v-for="(row, k) in item.comments"
                          :key="k"
                        >
                          {{ row.reason }}:{{ row.qty }} {{ row.notes }}
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
table td,
table th {
  padding-left: 2px !important;
  vertical-align: middle !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
