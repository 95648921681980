<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-lg mx-auto">
          <div
            class="invoice break-inside"
            id="report-container"
            v-if="!$helper.empty(record)"
          >
            <div class="invoice-action">
              <a
                class="btn btn-icon btn-white btn-dim btn-outline-primary"
                href="javascript:void(0)"
                @click.prevent="$helper.handlePrint('.invoice-wrap')"
                ><em class="icon ni ni-printer-fill"></em
              ></a>
            </div>
            <div class="invoice-wrap">
              <print-header>
                <div slot="title" class="text-center mb-1">
                  <h4 class="text-center">
                    RECEIPT :#{{ $helper.generateVoucherNo(record.id) }} /
                    <small style="font-size: 14px"
                      >Client: {{ record?.client?.name || "Walk-In Client" }} -
                      Date:
                      {{ $helper.formatDate(record.committed_date) }}</small
                    >
                  </h4>
                </div>
              </print-header>
              <div class="invoice-head d-print-none">
                <div class="invoice-contact">
                  <span class="overline-title">CLIENT</span>
                  <div class="invoice-contact-info">
                    <h4 class="title">{{ record?.client?.name || "Walk-In Client" }}</h4>
                    <ul class="list-plain">
                      <li class="my-0">
                        <em class="icon ni ni-call-fill"></em
                        ><span>{{ record?.client?.phone }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="invoice-desc" style="width: 180px">
                  <h3 class="title">RECEIPT</h3>
                  <ul class="list-plain">
                    <li class="invoice-id">
                      <span>Voucher No:</span
                      ><span>#{{ $helper.generateVoucherNo(record.id) }}</span>
                    </li>
                    <li class="invoice-date">
                      <span>Date</span>:<span>{{
                        $helper.formatDate(record.committed_date)
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="invoice-bills">
                <div class="table-responsive">
                  <table class="table table-striped-">
                    <thead>
                      <tr>
                        <th>$/N</th>
                        <th>Item Name</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Sub. Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in items" :key="'item' + i">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item.product.name }}</td>
                        <td>
                          <span class="tb-sub tb-amount">
                            {{ formatMoney(item.price) }}
                          </span>
                        </td>
                        <td>
                          {{
                            `${$helper.formatNumber(item.quantity)} ${
                              item.product.unit || ""
                            }`
                          }}
                        </td>
                        <td>
                          <span class="tb-sub tb-amount">{{
                            formatMoney(item.amount)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2"></td>
                        <td colspan="2">Subtotal</td>
                        <td>
                          {{ formatMoney(record.total_amount) }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                        <td colspan="2">Discount</td>
                        <td>{{ record.discount_perc }}%</td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                        <td colspan="2">Grand Total</td>
                        <td>
                          {{ formatMoney(record.discounted_total) }}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div class="nk-notes ff-italic fs-12px text-soft">
                    <!-- Invoice was created on a computer and is valid without
                        the signature and seal. -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "SaleVoucher",
  components: { PrintHeader },
  data: () => ({
    items: [],
    record: {},
  }),
  computed: {},
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$http.get(`sales/items/${reference}`).then((response) => {
        this.items = response.data.items;
        this.record = response.data.record;
      });
    }
  },
  methods: {},
};
</script>
<style></style>
