<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Record Spoiled Items
                    </h3>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} products</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li class="w-100">
                            <div class="form-control-wrap w-100">
                              <type-head
                                :url="
                                  isProductionItems
                                    ? 'production/items'
                                    : 'stock/products/search'
                                "
                                label="name"
                                tracker="name"
                                placeholder="Select item"
                                @changed="handleSelectedItem"
                              ></type-head>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Qty. In</span></div>
                    <div class="nk-tb-col"><span>Spoiled Qty.</span></div>
                    <div class="nk-tb-col"><span>Reason</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">
                          {{ formatMoney(product.price) }}</span
                        ></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span>{{
                        `${$helper.formatNumber(product.current_qty)} ${
                          product.unit
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="
                            'required|max_value:' + items[i].current_qty
                          "
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(product)"
                        />
                      </div>
                      <span
                        class="text-danger"
                        v-if="errors.has('Quantity' + i)"
                        >Quantity is required and <br />
                        must be less or equal to
                        {{ items[i].current_qty }}
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <textarea
                        :name="'Reason' + i"
                        v-model="items[i].comment"
                        rows="2"
                        class="form-control"
                        style="min-height: 12px"
                        placeholder="Write brief comments"
                      ></textarea>
                      <span class="text-danger" v-if="errors.has('Reason' + i)"
                        >Reason is required
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6
                      class="d-flex mb-3 align-items-center justify-content-end"
                    >
                      Grand Total:
                      <span class="ml-auto">{{ formatMoney(grandTotal) }}</span>
                    </h6>
                  </div>
                  <div class="w-100">
                    <div
                      class="d-flex justify-content-end align-items-center actions w-100"
                    >
                      <div class="form-group col-2 mb-0 px-1">
                        <input
                          type="date"
                          v-model="committed_date"
                          class="form-control"
                          placeholder="Delivery Date"
                        />
                      </div>
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @clicked="handleSubmit"
                        :disabler="submitDisabled"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Save</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added spoiled items</h6>
                              <p>
                                No items found in Spoiled list. Search for
                                product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TypeHead from "@/components/TypeHead.vue";
export default {
  name: "Spoiled",
  components: { TypeHead },
  data: () => ({
    items: [],
    selectedItemIds: [],
    committed_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
    record: {},
    items_type: null,
    branch_id: null,
  }),
  computed: {
    submitDisabled() {
      return !this.items.length || this.items.some((item) => !item.current_qty);
    },
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    isProductionItems() {
      return this.$route.name == "ProductionSpoiled";
    },
    branches() {
      return this.$store.state.branches || [];
    },
  },
  created() {
    localStorage.removeItem("spoiledItems");
  },
  beforeDestroy() {
    if (this.items.length) {
      localStorage.setItem(
        "spoiledItems",
        JSON.stringify({
          items: this.items,
          selectedItemIds: this.selectedItemIds,
          isProduction: this.isProductionItems,
        })
      );
    }
  },
  watch: {
    items() {
      if (!this.items.length) localStorage.removeItem("spoiledItems");
    },
    $route() {
      location.reload();
    },
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
      localStorage.setItem(
        "spoiledItems",
        JSON.stringify({
          items: this.items,
          selectedItemIds: this.selectedItemIds,
          isProduction: this.isProductionItems,
        })
      );
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        if (item.quantity == 0)
          this.$notifier({
            type: "warning",
            message: "Item Quantity is 0",
          });
        else {
          this.items.push({
            id: item.id,
            name: item.name,
            source: item.source,
            quantity: 1,
            total_amount: item.cost_price || item.cost,
            price: item.cost || item.cost_price || 0,
            current_qty: item.quantity,
            unit: item.unit,
            comment: null,
          });
          this.selectedItemIds.push(item.id);
        }
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.isProductionItems) {
            this.items_type = "PRODUCTION";
          }
          this.$http
            .post(
              "stock/products/spoiled/create",
              this.$helper.generateFormData({
                items: this.items,
                committed_date: this.committed_date,
                items_type: this.items_type,
                branch_id: this.branch_id,
              })
            )
            .then((response) => {
              if (response.data.status) {
                this.items = [];
                localStorage.removeItem("spoiledItems");
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                if (this.isProductionItems)
                  this.$router.replace({
                    name: "SpoiledItemsReport",
                    query: { "items-category": "production" },
                  });
                else this.$router.replace({ name: "SpoiledItemsReport" });
              }
            })
            
        }
      });
    },
  },
};
</script>
