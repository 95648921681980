<script>
import BulkItemsSelect from "@/components/BulkItemsSelect.vue";
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "PurchaseCreator",
  components: { BulkItemsSelect, TypeHead, Multiselect },
  data: () => ({
    items: [],
    modalOpen: false,
    newReceive: {
      supplier: null,
      received_date: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
      has_invoice: 0,
      vat: "EXCLUSIVE",
      items_type: "BRANCH_ITEMS",
    },
    row: {},
    supplier: null,
  }),
  computed: {
    addedItems() {
      return this.items.map((item) => item.id);
    },
    totalAmount() {
      return this.items.reduce((a, b) => a + b.total_amount, 0);
    },
    isEditingMode() {
      return this.$route && this.$route.params.reference;
    },
    submitDisabled() {
      return (
        !this.newReceive.received_date ||
        !this.supplier ||
        !this.items.length ||
        !this.totalAmount ||
        !this.newReceive.vat ||
        this.hasUnavailableItems ||
        (this.$helper.empty(this.loggedUser.branch) &&
          !this.newReceive.branch_id)
      );
    },
    hasUnavailableItems() {
      return this.items.some((item) => !item.price);
    },
    branches() {
      return this.$store.state.branches || [];
    },
    itemsUrl() {
      return "stock/products/purchasable-products";
      /*
      if (!this.$helper.empty(this.loggedUser.branch)) {
        return "stock/products/purchasable-products";
      } else {
        if (this.newReceive.branch_id) {
          return `stock/products/purchasable-products?branch_id=${this.newReceive.branch_id.id}`;
        }
      }
      return false; */
    },
  },
  mounted() {
    if (this.isEditingMode) {
      this.$http
        .get(`/reports/receive/${this.$route.params.reference}/voucher`)
        .then((response) => {
          this.row = response.data.record;
          this.supplier = this.row.vendor;
          this.newReceive.supplier_id = this.supplier.id;
          this.newReceive.received_date = new Date(this.row.received_date)
            .toLocaleString("fr-CA", {})
            .slice(0, 10);
          this.newReceive.vat = this.row.vat;
          const items = response.data.items;
          items.forEach((item) => {
            this.items.push({
              id: item.product.id,
              name: item.product.name,
              unit: item.product.unit,
              quantity: item.quantity,
              total_amount: item.quantity * item.price,
              price: item.price,
            });
          });
        });
    }
  },
  methods: {
    setSupplier(supplier) {
      this.supplier = supplier;
    },
    handleItemRemove(i) {
      this.items.splice(i, 1);
    },
    getBulkAddedItems(rows) {
      this.toggleBulkModal();
      rows.forEach((row) => {
        if (!this.addedItems.includes(row.item.id)) {
          this.items.push({
            id: row.item.id,
            name: row.item.name,
            unit: row.item.unit,
            quantity: row.quantity,
            price: row.item.cost_price || row.item.cost,
            total_amount: row.quantity * (row.item.cost_price || row.item.cost),
          });
        }
      });
    },
    toggleBulkModal() {
      this.modalOpen = !this.modalOpen;
    },
    calculateItemAmount(i) {
      this.items[i].total_amount = this.items[i].price * this.items[i].quantity;
    },
    handleSubmit() {
      this.newReceive.amount = this.totalAmount;
      this.newReceive.items = this.items;
      this.newReceive.record_items = this.addedItems;
      this.newReceive.total_amount = this.totalAmount;
      this.newReceive.supplier = this.supplier.id;
      const currentBranch = this.newReceive.branch_id;
      if (!this.$helper.empty(this.row)) {
        this.newReceive.record = this.row.id;
      }
      if (currentBranch) {
        this.newReceive.branch_id = currentBranch.id;
      }
      const fd = this.$helper.generateFormData(this.newReceive);
      this.$http.post("stock/products/receive", fd).then((response) => {
        if (response.data.status) {
          this.newReceive = {
            supplier: null,
            received_date: new Date(TODAY)
              .toLocaleString("fr-CA", { timeZone: TIMEZONE })
              .slice(0, 10),
            has_invoice: 0,
            vat: "EXCLUSIVE",
            items_type: "BRANCH_ITEMS",
          };
          if (currentBranch) {
            this.newReceive.branch_id = currentBranch;
          }
          this.items = [];
          this.$store.commit("SET_FLASH_MESSAGE", {
            type: "success",
            message: "Record saved successfully",
          });
        }
      });
    },
  },
};
</script>
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h5 class="mb-0">Record purchased items from suppliers</h5>

          <div class="page-title-right">
            <span class="fw-bold">{{ items.length }} items added</span>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <div class="receive-items-container">
          <div class="row">
            <div class="col-xl-6 col-lg-8">
              <div class="form-container">
                <div class="mb-3 row" v-if="$helper.empty(loggedUser.branch)">
                  <label class="col-md-4 col-form-label">Branch</label>
                  <div class="col-md-8">
                    <multiselect
                      v-model="newReceive.branch_id"
                      :options="branches"
                      placeholder="Select Branch"
                      label="name"
                      track-by="name"
                    ></multiselect>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="col-md-4 col-form-label required"
                    >Purchase Date</label
                  >
                  <div class="col-md-8">
                    <input
                      class="form-control"
                      type="date"
                      v-model="newReceive.received_date"
                    />
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-md-4 col-form-label">Supplier</label>
                  <div class="col-md-8">
                    <type-head
                      url="shared/suppliers/search"
                      label="name"
                      tracker="name"
                      :loadDefaults="true"
                      placeholder="Select supplier"
                      @changed="setSupplier"
                    ></type-head>
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-md-4 col-form-label">Tax/Vat</label>
                  <div class="col-md-8">
                    <div
                      class="custom-control custom-control-sm custom-radio mb-2"
                    >
                      <input
                        class="custom-control-input"
                        type="radio"
                        name="formRadios"
                        v-model="newReceive.vat"
                        value="INCLUSIVE"
                        id="formRadios1"
                      />
                      <label class="custom-control-label" for="formRadios1">
                        Tax Inclusinve
                      </label>
                    </div>
                    <div
                      class="custom-control custom-control-sm custom-radio mb-2 ml-3"
                    >
                      <input
                        class="custom-control-input"
                        type="radio"
                        name="formRadios"
                        v-model="newReceive.vat"
                        value="EXCLUSIVE"
                        id="formRadios2"
                      />
                      <label class="custom-control-label" for="formRadios2">
                        Tax Exclusive
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="h-100 mt-4">
            <div
              class="page-title-box d-flex align-items-center justify-content-between"
            >
              <h6 class="mb-0">Items on purchase list</h6>

              <div class="page-title-right">
                <div class="w-100 my-3">
                  <button
                    type="button"
                    class="btn btn-md btn-primary"
                    @click="toggleBulkModal"
                    :disabled="!itemsUrl"
                  >
                    <i class="uil uil-plus"></i> Add Items
                  </button>
                </div>
              </div>
            </div>
            <div class="bulf-form-container">
              <table class="table table-form mb-0 sheet-view">
                <thead class="thead-sticky">
                  <tr>
                    <th class="text-right px-0" style="width: 60px">S/N</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th class="required">Quantity</th>
                    <th class="required">Purchase Price</th>
                    <th>Total Amount</th>
                    <th style="width: 30px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in items" :key="'item' + i">
                    <td class="text-center align-middle">
                      {{ i + 1 }}
                    </td>
                    <td class="align-middle">
                      <span class="fw-bolder">{{ item.name }}</span>
                    </td>
                    <td class="text-left align-middle">
                      <span class="fw-normal">{{ item.unit || "N/A" }}</span>
                    </td>
                    <td>
                      <div class="sheet-field px-1">
                        <div class="currency-with-amount">
                          <input
                            type="number"
                            v-model="items[i].quantity"
                            class="form-control"
                            placeholder="Qty"
                            :name="'Quantity' + i"
                            @keyup="calculateItemAmount(i)"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="sheet-field">
                        <div class="currency-with-amount">
                          <div class="currency-field">RWF</div>
                          <input
                            type="number"
                            v-model="items[i].price"
                            class="form-control"
                            placeholder="Purchase Price"
                            @keyup="calculateItemAmount(i)"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="sheet-field">
                        <div class="currency-with-amount">
                          <div class="currency-field">RWF</div>
                          <input
                            type="number"
                            readonly
                            v-model="items[i].total_amount"
                            class="form-control"
                            placeholder="Total Amount"
                          />
                        </div>
                      </div>
                    </td>
                    <td class="text-center align-middle">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="handleItemRemove(i)"
                      >
                        <i class="ni ni-trash text-danger fs-5"></i>
                      </a>
                    </td>
                  </tr>
                  <tr
                    v-if="!items.length"
                    style="border-color: transparent !important"
                  >
                    <td
                      colspan="6"
                      class="text-center"
                      style="padding: 1rem !important"
                    >
                      <span class="py-2">No Items Added</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right mt-2" v-if="totalAmount">
                <span class="h6"
                  >RWF {{ $helper.formatNumber(totalAmount) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div
      class="border-top shadow w-100 fixed-bottom p-2 main-content bg-white d-flex align-items-center"
      style="z-index: 999; left: 200px"
    >
      <wolf-button
        classes="btn btn-primary ml-2"
        @clicked="handleSubmit"
        :disabler="submitDisabled"
        activator="RECEIVING_ITEMS"
      >
        <span slot="caption"
          ><em class="icon ni ni-save"></em><span>Save</span></span
        >
      </wolf-button>

      <button class="btn btn-light ml-2" type="button">Cancel</button>
      <div class="text-danger ml-3" v-if="hasUnavailableItems">
        <span>Receiving is disabled. Some items has no const price.</span>
      </div>
    </div>
    <BulkItemsSelect
      @closed="toggleBulkModal"
      @confirmed="getBulkAddedItems"
      v-if="modalOpen"
      :showQuantity="false"
      :fetch-url="itemsUrl"
    />
  </div>
</template>
