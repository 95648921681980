<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "PhysicalCountReport",
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "reports/physical-count-report",
  }),
};
</script>
<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
