<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="components-preview wide-md mx-auto">
          <div class="nk-block-head nk-block-head-lg wide-sm">
            <div class="nk-block-head-content">
              <div class="nk-block-head-sub">
                <router-link class="back-to" :to="{ name: 'BranchItems' }"
                  ><em class="icon ni ni-arrow-left"></em
                  ><span>Sales Items</span></router-link
                >
              </div>
              <h4 class="nk-block-title fw-normal">Create Sales Item</h4>
            </div>
          </div>
          <div class="nk-block nk-block-lg">
            <div class="card card-bordered card-preview">
              <div class="card-inner">
                <div class="preview-block has-multselect">
                  <span class="preview-title-lg overline-title"
                    >Item information</span
                  >
                  <div class="row mb-3">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="form-label mr-2">Group</label>
                        <ul class="custom-control-group">
                          <li>
                            <div
                              class="
                                custom-control
                                custom-control-sm
                                custom-radio
                                custom-control-pro
                              "
                            >
                              <input
                                type="radio"
                                class="custom-control-input"
                                name="itemGroup"
                                id="itemGroup1"
                                v-model="newItem.status"
                                value="MENU"
                              /><label
                                class="custom-control-label"
                                for="itemGroup1"
                                >Goods</label
                              >
                            </div>
                          </li>
                          <li>
                            <div
                              class="
                                custom-control
                                custom-control-sm
                                custom-radio
                                custom-control-pro
                                checked
                              "
                            >
                              <input
                                type="radio"
                                class="custom-control-input"
                                name="itemGroup"
                                id="itemGroup2"
                                v-model="newItem.status"
                                value="SALES_GROUP"
                              /><label
                                class="custom-control-label"
                                for="itemGroup2"
                                >Services</label
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row gy-4">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="default-01"
                          >Item Name</label
                        >
                        <div class="form-control-wrap">
                          <input
                            type="text"
                            class="form-control"
                            id="default-01"
                            placeholder="Product name"
                            v-model="newItem.name"
                            name="Item Name"
                            v-validate="'required'"
                            :class="{ 'is-invalid': errors.has('Item Name') }"
                          />
                          <div class="invalid-feedback">
                            {{ errors.first("Item Name") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3" v-if="newItem.status == 'MENU'">
                      <div class="form-group">
                        <label class="form-label" for="default-05"
                          >Cost Price <em>(optional)</em></label
                        >
                        <div class="form-control-wrap">
                          <div class="form-text-hint">
                            <span class="overline-title">{{
                              appSettings.currency
                            }}</span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            id="default-05"
                            :placeholder="`In ${appSettings.currency}`"
                            v-model="newItem.cost"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label class="form-label" for="default-03"
                          >Sales Price</label
                        >
                        <div class="form-control-wrap">
                          <div class="form-text-hint">
                            <span class="overline-title">{{
                              appSettings.currency
                            }}</span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            id="default-03"
                            :placeholder="`In ${appSettings.currency} (optional)`"
                            v-model="newItem.price"
                            name="Sales Price"
                            v-validate="
                              `${newItem.status == 'MENU' ? 'required' : ''}`
                            "
                            :class="{ 'is-invalid': errors.has('Sales Price') }"
                          />
                          <div class="invalid-feedback">
                            {{ errors.first("Sales Price") }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-sm-6"
                      v-if="
                        categories.length && newItem.status != 'SALES_GROUP'
                      "
                    >
                      <div class="form-group">
                        <label class="form-label" for="default-96"
                          >Item Type</label
                        >
                        <multiselect
                          v-model="newItem.type"
                          :options="categories"
                          placeholder="Select one"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>
                      <div class="d-flex w-100">
                        <div class="form-group col-6 pl-0">
                          <label class="form-label" for="default-06"
                            >Item Unit</label
                          >
                          <div class="form-control-wrap">
                            <div class="form-control-select">
                              <select
                                class="form-control"
                                id="default-06"
                                v-model="newItem.unit_id"
                              >
                                <option value="null" hidden disabled>
                                  Select Unit
                                </option>
                                <option
                                  :value="item.id"
                                  v-for="(item, i) in units"
                                  :key="'unit' + i"
                                >
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-6">
                          <label class="form-label d-block" for=""
                            >Has Addons?</label
                          >
                          <ul class="custom-control-group">
                            <li>
                              <div
                                class="
                                  custom-control
                                  custom-control-sm
                                  custom-radio
                                  custom-control-pro
                                "
                              >
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="btnRadio"
                                  id="btnRadio1"
                                  v-model="newItem.has_addons"
                                  value="1"
                                /><label
                                  class="custom-control-label"
                                  for="btnRadio1"
                                  >YES</label
                                >
                              </div>
                            </li>
                            <li>
                              <div
                                class="
                                  custom-control
                                  custom-control-sm
                                  custom-radio
                                  custom-control-pro
                                  checked
                                "
                              >
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="btnRadio"
                                  id="btnRadio2"
                                  v-model="newItem.has_addons"
                                  value="0"
                                /><label
                                  class="custom-control-label"
                                  for="btnRadio2"
                                  >No</label
                                >
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="default-textarea"
                          >Item Description</label
                        >
                        <div class="form-control-wrap">
                          <textarea
                            class="form-control no-resize"
                            id="default-textarea"
                            v-model="newItem.description"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-3 float-right">
                    <wolf-button
                      classes="btn btn-primary"
                      caption="Save"
                      @clicked="handleSubmit"
                      :disabler="
                        (newItem.status == 'MENU' && !newItem.type) ||
                        !newItem.name
                      "
                      activator="saving_records"
                    ></wolf-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "ItemCreator",
  components: {
    Multiselect,
  },
  data: () => ({
    category: {},
    categories: {},
    units: [],
    isProduct: true,
    newItem: {
      name: null,
      description: null,
      cost: null,
      price: null,
      store: null,
      type_id: null,
      unit_id: null,
      quantity: 0,
      daily_count: 0,
      status: "MENU",
      category: null,
      supplier: null,
      store_min_qty: 0,
      store_purchase: 0,
      branch_min_qty: 0,
      branch_purchase: 0,
      source: null,
      has_addons: 0,
    },
    url: "stock/products/create",
    editingMode: false,
  }),
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$http.get(`stock/products/single/${reference}`).then((response) => {
        this.newItem = { ...this.newItem, ...response.data.product };
        this.url = "stock/products/update";
        this.editingMode = true;
      });
    }
  },
  mounted() {
    this.$http.get(`stock/assets`).then((response) => {
      if (response.data.status) {
        this.categories = response.data.types;
        this.units = response.data.units;
      }
    });
  },
  methods: {
    handleSubmit() {
      if (this.newItem.status == "MENU") {
        this.newItem.type_id = this.newItem.type.id;
      }
      this.$http
        .post(this.url, this.$helper.generateFormData(this.newItem))
        .then((response) => {
          if (response.data.status) {
            this.$helper.resetObjectValues(this.newItem);
            this.newItem = {
              name: null,
              description: null,
              cost: null,
              price: null,
              store: null,
              type_id: null,
              unit_id: null,
              quantity: 0,
              daily_count: 0,
              status: "MENU",
              category: null,
              supplier: null,
              store_min_qty: 0,
              store_purchase: 0,
              branch_min_qty: 0,
              branch_purchase: 0,
            };
          }
        })
        
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="css"></style>
