export const Cart = {
  data: () => ({
    cartItems: [],
  }),
  computed: {
    totalAmount() {
      console.log(this.cartItems);
      return this.cartItems.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    totalIngredients() {
      return this.cartItems.length;
    },
  },
  created() {
    localStorage.removeItem("cartItems");
  },
  methods: {
    addToCart(ingredient) {
      if (!this.cartItems.length) {
        ingredient.products = {};
        ingredient.products[ingredient.product.toString()] =
          ingredient.quantity;
        this.cartItems.push(ingredient);
      } else {
        var itemExist = this.cartItems.some((item) => {
          if (item.id == ingredient.id) {
            item.quantity += ingredient.quantity;
            item.total_amount += Number(ingredient.total_amount);
            let product = ingredient.product.toString();
            if (Object.keys(item.products).includes(product))
              item.products[product] += ingredient.quantity;
            else item.products[product] = ingredient.quantity;
            return true;
          }
        });
        if (!itemExist) {
          ingredient.products = {};
          ingredient.products[ingredient.product.toString()] =
            ingredient.quantity;
          this.cartItems.push(ingredient);
        }
      }
    },
    getCartItems() {
      let result = localStorage.getItem("cartItems");
      if (result) this.cartItems = JSON.parse(result);
      else this.cartItems = [];
    },
  },
};
