export const permissionsHandler = {
  computed: {
    loggedUser() {
      return this.$store.state.user || {};
    },
    isReady() {
      return this.loggedUser && Object.keys(this.loggedUser).length > 0;
    },
    permissions() {
      return this.isReady && this.loggedUser?.role?.branch_permissions;
    },
    routePathString() {
      return this.$store.state.routes[this.$route.name] || "";
    }
  },
  methods: {
    hasPermissionTo(permission) {
      const access = this.routePathString
        .split(".")
        .reduce((a, b) => (a && a[b]) || null, this.permissions);
      return access && access.includes(permission);
    },
  },
};
