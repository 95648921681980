<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h5 class="nk-block-title page-title mb-0">Dispatch Report</h5>
              <div class="nk-block-des text-soft">
                <a href="javascript:void(0)"
                  ><small
                    ><b>{{ reportLabel }}</b></small
                  ></a
                >
              </div>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <div class="toggle-expand-content" >
                  <ul class="nk-block-tools g-1 float-right">
                    <li style="width: 180px">
                      <multiselect
                        v-model="destination"
                        :options="filterBranches"
                        group-values="rows"
                        group-label="destination"
                        placeholder="Destination"
                        label="name"
                        track-by="name"
                        @select="filterByDestination"
                      ></multiselect>
                    </li>
                    <li>
                      <type-head
                        url="stock/products/search/DIRECT_USE"
                        label="name"
                        tracker="name"
                        placeholder="Search by item name"
                        @changed="handleItemFilter"
                        :load-defaults="true"
                      ></type-head>
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'ProductReceive',
                          query: {
                            action: 'confirm-dispatch',
                            items: encodeURIComponent(dispatchableItems),
                            start_date: newFilter.from,
                            end_date: newFilter.to,
                          },
                        }"
                        class="btn btn-icon btn-secondary"
                      >
                        <em class="icon ni ni-check-fill-c"></em>
                      </router-link>
                    </li>
                    <template v-if="rows.length">
                      <li>
                        <button
                          type="button"
                          class="btn btn-icon btn-white btn-dim btn-outline-light"
                          @click="$helper.handlePrint('.nk-block')"
                        >
                          <em class="icon ni ni-printer"></em>
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="btn btn-white btn-dim btn-outline-light"
                          @click="handleExcelExport"
                        >
                          <svg
                            height="18px"
                            viewBox="0 0 384 512"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                          >
                            <path
                              d="m369.9 97.9-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-204.1c-26.5.1-48 21.6-48 48.1v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-332.1c0-12.7-5.1-25-14.1-34zm-37.8 30.1h-76.1v-76.1zm-284.1 336v-416h160v104c0 13.3 10.7 24 24 24h104v288zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3h-28.9c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3h28.8c9.3 0 15-10 10.4-18l-46.4-78c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"
                            ></path>
                          </svg>
                        </button>
                      </li>
                    </template>
                    <li>
                      <div class="dropdown filter-dropdown">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><em class="icon ni ni-filter"></em
                        ></a>
                        <div class="dropdown-menu dropdown-menu-right" style="">
                          <div class="p-3" style="width: 400px">
                            <div class="row">
                              <div class="col-lg-12">
                                <span class="mb-2 font-weight-bolder"
                                  >Filter Results</span
                                >
                              </div>
                              <div class="col-lg-6">
                                <div class="form-group mb-2">
                                  <label for="">From:</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    v-model="newFilter.from"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form-group mb-2">
                                  <label for="">To:</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    v-model="newFilter.to"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-12">
                                <div class="form-group text-right">
                                  <button
                                    type="button"
                                    class="btn btn-primary d-md-inline-flex"
                                    :disabled="$helper.empty(newFilter)"
                                    @click="handleFilter"
                                  >
                                    <em class="icon ni ni-filter"></em
                                    ><span>Filter</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <print-header>
            <div slot="title" class="text-center mb-1">
              <h5 class="text-center">
                Dispatch Report /
                <small style="font-size: 14px">{{ reportLabel }}</small>
              </h5>
            </div>
          </print-header>
          <div class="card card-stretch pb-4">
            <div class="card-inner-group">
              <div class="card-inner p-0">
                <div class="nk-tb-list is-compact mb-3">
                  <div
                    class="nk-tb-item nk-tb-head"
                    :class="{ 'stick-table': scrolled }"
                  >
                    <div class="nk-tb-col">
                      <span class="sub-text">$/N</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item</span>
                    </div>
                    <div
                      class="nk-tb-col"
                      v-for="(destination, i) in destinations"
                      :key="'destination' + i"
                    >
                      <span class="sub-text">{{ destination.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Total</span>
                    </div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ item.item_name }}</span>
                    </div>
                    <div
                      class="nk-tb-col"
                      v-for="(data, j) in item.data"
                      :key="j + 'row-qty' + i"
                    >
                      <span class="tb-sub tb-amount"
                        >{{ $helper.formatNumber(data) }}
                        <span>{{ item.unit }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub tb-amount"
                        >{{
                          $helper.formatNumber(
                            item.data.reduce((a, b) => a + Number(b), 0)
                          )
                        }}
                        <span>{{ item.unit }}</span></span
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="!rows.length"
                  class="text-center w-100 cursor-pointer"
                >
                  <h6 class="mb-0 text-muted">No data to display</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
import PrintHeader from "@/components/PrintHeader.vue";
import { mapState } from "vuex";
export default {
  name: "Dispatch",
  components: { TypeHead, Multiselect, PrintHeader },
  data: () => ({
    destinations: [],
    destination: null,
    sources: [],
    rows: [],
    scrolled: false,
    newFilter: {
      from: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
      to: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
    },
  }),
  computed: {
    ...mapState({
      branches: (state) => state.branches,
    }),
    reportLabel() {
      let from = !this.newFilter.from
        ? new Date(TODAY)
            .toLocaleString("fr-CA", { timeZone: TIMEZONE })
            .slice(0, 10)
        : this.newFilter.from;
      if (!this.newFilter.to) return this.$helper.formatDate(from);
      else
        return `${this.$helper.formatDate(from)} - ${this.$helper.formatDate(
          this.newFilter.to
        )}`;
    },
    dispatchableItems() {
      return JSON.stringify(this.rows.map((row) => row.product_id));
    },
    isProductionItems() {
      const isProduction = this.$route?.query?.origin;
      return isProduction && isProduction == "production";
    },
    filterBranches() {
      const result = [{ destination: "Branches", rows: this.branches }];
      if (!this.isProductionTransfer) {
        result.push({ destination: "Productions", rows: this.sources });
      }
      return result;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.isProductionItems) {
      this.newFilter.is_production = 1;
    }
    this.$http
      .get(this.$helper.encodeQuery("reports/dispatch", this.newFilter))
      .then((response) => {
        this.destinations = response.data.destinations;
        this.rows = response.data.rows;
      });
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.isProductionItems) {
        this.$http.get("production/sources").then((response) => {
          this.sources = response.data.sources;
        });
      }
    });
  },
  methods: {
    filterByDestination(destination) {
      if (!this.$helper.empty(destination)) {
        this.newFilter.branch = null;
        this.newFilter.source = null;
        const keys = Object.keys(destination);
        if (keys.includes("phone")) this.newFilter.branch = destination.id;
        else this.newFilter.source = destination.id;
        this.handleFilter();
      }
    },
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    },
    handleItemFilter(item) {
      this.newFilter.item = item.id;
      this.handleFilter();
    },
    handleFilter() {
      this.$store.commit("SET_REQUEST_FLAG", "PAGINATION_SEARCH");
      this.$http
        .get(this.$helper.encodeQuery("reports/dispatch", this.newFilter))
        .then((response) => {
          this.destinations = response.data.destinations;
          this.rows = response.data.rows;
        });
    },
    handleExcelExport() {
      let container = document.querySelector(".nk-tb-list");
      let head = container.querySelector(".nk-tb-item.nk-tb-head");
      let headers = [
        ...head.querySelectorAll(
          ".nk-tb-col:not(.nk-tb-col-check):not(.nk-tb-col-tools)"
        ),
      ].map((item) => item.textContent);
      let dataset = [];
      [...container.querySelectorAll(".nk-tb-item:not(.nk-tb-head)")].forEach(
        (row) => {
          let arr = [
            ...row.querySelectorAll(
              ".nk-tb-col:not(.nk-tb-col-check):not(.nk-tb-col-tools)"
            ),
          ].map((item) => item.textContent);
          dataset.push(arr);
        }
      );
      /**
       * Creating a temporary form
       */
      let dataForm = document.createElement("form");
      dataForm.target = "_blank";
      dataForm.method = "POST";
      dataForm.action = "https://tameaps.com/export.php";
      let records = document.createElement("input");
      let file_name = document.createElement("input");
      let headings = document.createElement("input");
      records.type = "hidden";
      file_name.type = "hidden";
      headings.type = "hidden";
      records.name = "dataset";
      records.value = JSON.stringify(dataset);
      file_name.name = "filename";
      file_name.value = `Dispatch-Report`;
      headings.name = "columns";
      headings.value = JSON.stringify(headers);
      dataForm.appendChild(records);
      dataForm.appendChild(file_name);
      dataForm.appendChild(headings);
      dataForm.style.display = "none";
      document.body.appendChild(dataForm);
      dataForm.submit();
    },
  },
};
</script>
<style scoped>
.stick-table {
  position: sticky;
  top: 130px;
  background: var(--base-color);
  z-index: 90;
}
.nk-tb-head.stick-table .nk-tb-col,
.nk-tb-head.stick-table .sub-text {
  color: #fff;
  font-weight: bolder;
}
</style>
