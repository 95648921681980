var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container-fluids"},[_c('div',{staticClass:"nk-content-inner"},[_c('div',{staticClass:"nk-content-body"},[_c('div',{staticClass:"nk-block-head nk-block-head-sm"},[_c('div',{staticClass:"nk-block-between"},[_vm._m(0),_c('div',{staticClass:"nk-block-head-content"},[_c('div',{staticClass:"toggle-wrap nk-block-tools-toggle"},[_c('div',{staticClass:"toggle-expand-content"},[_c('ul',{staticClass:"nk-block-tools g-1 float-right"},[_c('li',[_c('router-link',{staticClass:"btn btn-primary d-md-inline-flex",attrs:{"to":{
                        name: 'RolesCreator',
                        params: { action: 'new' },
                      }}},[_c('em',{staticClass:"icon ni ni-plus"}),_c('span',[_vm._v("New")])])],1)])])])])])]),_c('div',{staticClass:"nk-block"},[_c('div',{staticClass:"nk-tb-list is-compact mb-3"},[_vm._m(1),_vm._l((_vm.rows),function(row,i){return _c('div',{key:'role' + i,staticClass:"nk-tb-item",attrs:{"loading":"lazy"}},[_c('div',{staticClass:"nk-tb-col"},[_c('span',[_vm._v(" "+_vm._s(i + 1)+" ")])]),_c('div',{staticClass:"nk-tb-col"},[_c('span',{staticClass:"tb-lead"},[_vm._v(" "+_vm._s(row.name)+" ")])]),_c('div',{staticClass:"nk-tb-col"},[_c('span',[_vm._v(" "+_vm._s(row.description)+" ")])]),_c('div',{staticClass:"nk-tb-col nk-tb-col-tools"},[_c('ul',{staticClass:"nk-tb-actions gx-1 my-n1"},[_c('li',{staticClass:"mr-n1"},[_c('div',{staticClass:"dropdown"},[_vm._m(2,true),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('ul',{staticClass:"link-list-opt no-bdr"},[_c('li',[_c('router-link',{attrs:{"to":{
                              name: 'RolesCreator',
                              params: { action: 'edit', id: row.id },
                            }}},[_c('em',{staticClass:"icon ni ni-edit"}),_c('span',[_vm._v("Edit")])])],1),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.openBranchPermissionsModal(row)}}},[_c('em',{staticClass:"icon ni ni-lock"}),_c('span',[_vm._v("Branch Permissions")])])]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();_vm.handleDeleteAlertModal({
                                tableName: 'permissions',
                                entityIdentifer: 'permissions',
                                entity: `${row.name || ''}`,
                                url: `settings/users/permissions/delete/${row.id}`,
                                callback: () => {
                                  _vm.rows.splice(i, 1);
                                },
                              })}}},[_c('em',{staticClass:"icon ni ni-trash"}),_c('span',[_vm._v("Delete")])])])])])])])])])])})],2)])])]),(_vm.modalOpen && _vm.selectedRole)?_c('BranchPermissions',{attrs:{"role":_vm.selectedRole},on:{"closed":function($event){_vm.toggleModal();
      _vm.selectedRole = null;},"saved":_vm.updateSavedRow}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nk-block-head-content"},[_c('h3',{staticClass:"nk-block-title page-title"},[_vm._v("User Roles")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nk-tb-item nk-tb-head"},[_c('div',{staticClass:"nk-tb-col"},[_c('span',[_vm._v("S/N")])]),_c('div',{staticClass:"nk-tb-col"},[_c('span',[_vm._v("Name")])]),_c('div',{staticClass:"nk-tb-col"},[_c('span',[_vm._v("Description")])]),_c('div',{staticClass:"nk-tb-col"},[_c('span',[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-toggle btn btn-icon btn-trigger",attrs:{"href":"javascript:void(0)","data-toggle":"dropdown"}},[_c('em',{staticClass:"icon ni ni-more-h"})])
}]

export { render, staticRenderFns }