<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between" v-if="isReport">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      <span class="h4" v-if="newFilter.customer_name"
                        >{{ newFilter.customer_name }} - Transactions</span
                      >
                      <span v-else-if="reportType == 'BRANCH_SALES'"
                        >Sales Report</span
                      >
                      <span v-else>Production Sales Report</span>
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            style="width: 180px"
                            v-if="
                              $helper.empty(loggedUser.branch) &&
                              reportType == 'BRANCH_SALES'
                            "
                          >
                            <multiselect
                              v-model="selectedBranch"
                              :options="branches"
                              placeholder="All branch"
                              label="name"
                              track-by="name"
                              @select="handleBranchFilter"
                            ></multiselect>
                          </li>
                          <li
                            style="width: 150px"
                            v-if="
                              $helper.empty(loggedUser.source) &&
                              reportType == 'PRODUCTION'
                            "
                          >
                            <multiselect
                              v-model="source"
                              :options="sources"
                              placeholder="All Productions"
                              label="name"
                              track-by="name"
                              @select="handleSourceFilter"
                            ></multiselect>
                          </li>
                          <template v-if="rows.length">
                            <li>
                              <button
                                type="button"
                                class="btn btn-icon btn-white btn-dim btn-outline-light"
                                @click="$helper.handlePrint('.nk-block')"
                              >
                                <em class="icon ni ni-printer"></em>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                class="btn btn-white btn-dim btn-outline-light"
                                @click="handleExcelExport"
                              >
                                <svg
                                  height="18px"
                                  viewBox="0 0 384 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path
                                    d="m369.9 97.9-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-204.1c-26.5.1-48 21.6-48 48.1v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-332.1c0-12.7-5.1-25-14.1-34zm-37.8 30.1h-76.1v-76.1zm-284.1 336v-416h160v104c0 13.3 10.7 24 24 24h104v288zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3h-28.9c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3h28.8c9.3 0 15-10 10.4-18l-46.4-78c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"
                                  ></path>
                                </svg>
                              </button>
                            </li>
                          </template>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-sm-12">
                                      <div class="form-group mb-2">
                                        <select
                                          v-model="filterUrl"
                                          class="form-control form-select"
                                        >
                                          <option value="all">
                                            All Invoices
                                          </option>
                                          <option value="shared/clients/search">
                                            By Client
                                          </option>
                                          <!-- <option value="production/items">
                                            By Item
                                          </option> -->
                                          <option value="shared/users/search">
                                            By Employee
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      class="col-lg-12"
                                      v-show="filterUrl && filterUrl != 'all'"
                                    >
                                      <div class="form-control-wrap mb-2">
                                        <type-head
                                          :url="filterUrl"
                                          label="name"
                                          tracker="name"
                                          :placeholder="`Search by ${filterEntity}`"
                                          @changed="handleInvoicesFilter"
                                          ref="invoiceSelector"
                                        ></type-head>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nk-block-between" v-else>
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      <span v-if="reportType == 'BRANCH_SALES'"
                        >Sales Report</span
                      >
                      <span v-else>Production Sales Report</span>
                      <small>
                        <a href="javascript:void(0)">({{ newFilter.from }})</a>
                      </small>
                    </h5>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} Invoices.</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li class="nk-block-tools-opt">
                            <router-link
                              :to="{ name: 'ProductionSales' }"
                              class="btn btn-primary d-md-inline-flex"
                            >
                              <em class="icon ni ni-plus"></em><span>New</span>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <print-header>
                  <div slot="title" class="text-center mb-1">
                    <h5 class="text-center">
                      Sales report /
                      <small style="font-size: 14px">{{ reportLabel }}</small>
                    </h5>
                  </div>
                </print-header>
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col" v-if="!newFilter.customer_name">
                      <span class="sub-text">Client</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Paid</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Remain</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Status</span>
                    </div>
                    <template v-if="isReport">
                      <div
                        class="nk-tb-col"
                        v-if="reportType == 'BRANCH_SALES'"
                      >
                        <span class="sub-text">Branch</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="sub-text">Created By</span>
                      </div>
                    </template>

                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>#{{ $helper.generateVoucherNo(row.id) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        $helper.formatDate(row.committed_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col" v-if="!newFilter.customer_name">
                      <div class="user-card">
                        <avatar
                          v-if="row.client_first_name"
                          :name="`${row.client_first_name} ${
                            row.client_last_name || ''
                          }`"
                          width="32px"
                          height="32px"
                        ></avatar>
                        <avatar
                          v-else
                          name="WI"
                          width="32px"
                          height="32px"
                        ></avatar>
                        <div class="user-name">
                          <span class="tb-lead">{{
                            row.client_name || "Walk-In Customer"
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub tb-amount">
                        {{ formatMoney(row.total_amount) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub tb-amount">
                        {{ formatMoney(row.amount_paid) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub tb-amount">
                        {{ formatMoney(row.amount_remain) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="badge badge-dot badge-dot-xs bg-success"
                        v-if="row.paid == 1"
                        >Paid</span
                      >
                      <span
                        class="badge badge-dot badge-dot-xs bg-warning"
                        v-else
                        >Due</span
                      >
                    </div>

                    <template v-if="isReport">
                      <div
                        class="nk-tb-col"
                        v-if="reportType == 'BRANCH_SALES'"
                      >
                        <span class="tb-lead">{{
                          row.branch.name || "N/A"
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-lead">{{
                          row.employee_name || "N/A"
                        }}</span>
                      </div>
                    </template>

                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="getSaleItems(row)"
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'SaleVoucherPrint',
                                      params: { reference: row.reference },
                                    }"
                                    ><em class="icon ni ni-printer-fill"></em
                                    ><span>Print</span></router-link
                                  >
                                </li>
                                <li
                                  v-if="
                                    row.paid != 1 && canEdit(permissionsPath)
                                  "
                                >
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="togglePaymentModal(row)"
                                    ><em class="icon ni ni-coins"></em
                                    ><span>Pay Remaining</span></a
                                  >
                                </li>
                                <li v-if="canEdit(permissionsPath)">
                                  <router-link
                                    :to="{
                                      name:
                                        reportType == 'PRODUCTION'
                                          ? 'ProductionSales'
                                          : 'BranchSalesInvoices',
                                      params: {
                                        reference: row.reference,
                                      },
                                    }"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit</span></router-link
                                  >
                                </li>
                                <li v-if="canDelete(permissionsPath)">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'sales',
                                        entityIdentifer: 'sales record',
                                        entity: row.reference,
                                        url: `sales/delete/${row.id}`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item" v-if="rows.length">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col">
                      <span class="fw-bolder text-dark">Grand Total</span>
                    </div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <span class="fw-bolder text-dark">
                        {{ `${formatMoney(totalAmount)}` }}
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="fw-bolder text-dark">{{
                        formatMoney(totalPaid)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="fw-bolder text-dark">{{
                        formatMoney(totalRemain)
                      }}</span>
                    </div>
                    <div class="nk-tb-col"></div>

                    <template v-if="isReport">
                      <div
                        class="nk-tb-col"
                        v-if="reportType == 'BRANCH_SALES'"
                      ></div>
                      <div class="nk-tb-col"></div>
                    </template>
                    <div class="nk-tb-col"></div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >RECEIPT <a href="javascript:void(0)">#{{ $helper.generateVoucherNo(record.id) }}</a></span
      >
      <div slot="body">
        <div
          class="invoice sheet"
          id="invoice-container"
          v-if="!$helper.empty(record)"
        >
          <div class="invoice-wrap">
            <div class="invoice-head">
              <div class="invoice-contact">
                <span class="overline-title">CLIENT</span>
                <div class="invoice-contact-info">
                  <h4 class="title">
                    {{
                      record.client ? record?.client?.name : "Walk-In Customer"
                    }}
                  </h4>
                  <ul class="list-plain">
                    <li class="my-0">
                      <em class="icon ni ni-call-fill"></em
                      ><span>{{
                        record.client ? record?.client?.phone : "N/A"
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="invoice-desc" style="width: 180px">
                <h3 class="title">RECEIPT</h3>
                <ul class="list-plain">
                  <li class="invoice-id">
                    <span>Voucher No:</span>:<span>{{
                      $helper.generateVoucherNo(record?.id)
                    }}</span>
                  </li>
                  <li class="invoice-date">
                    <span>Date</span>:<span>{{
                      $helper.formatDate(record?.committed_date)
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="invoice-bills">
              <div class="table-responsive">
                <table class="table table-striped-">
                  <thead>
                    <tr>
                      <th>$/N</th>
                      <th class="w-200px">Item Name</th>
                      <th v-if="reportType != 'BRANCH_SALES'">Price</th>
                      <th>Qty</th>
                      <th>Sub. Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in items" :key="'item' + i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.product.name }}</td>
                      <td v-if="reportType != 'BRANCH_SALES'">
                        <span class="tb-sub tb-amount">
                          {{ formatMoney(item.price) }}
                        </span>
                      </td>
                      <td>
                        {{
                          `${$helper.formatNumber(item.quantity)} ${
                            reportType != "BRANCH_SALES"
                              ? item.product.unit
                              : ""
                          }`
                        }}
                      </td>
                      <td>
                        <span class="tb-sub tb-amount">{{
                          formatMoney(item.amount)
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td :colspan="reportType != 'BRANCH_SALES' ? 2 : 1"></td>
                      <td :colspan="reportType != 'BRANCH_SALES' ? 2 : 1">
                        Subtotal
                      </td>
                      <td>
                        {{ formatMoney(record?.total_amount) }}
                      </td>
                    </tr>
                    <tr>
                      <td :colspan="reportType != 'BRANCH_SALES' ? 2 : 1"></td>
                      <td :colspan="reportType != 'BRANCH_SALES' ? 2 : 1">
                        Discount
                      </td>
                      <td>{{ record.discount_perc }}%</td>
                    </tr>
                    <tr>
                      <td :colspan="reportType != 'BRANCH_SALES' ? 2 : 1"></td>
                      <td :colspan="reportType != 'BRANCH_SALES' ? 2 : 1">
                        Grand Total
                      </td>
                      <td>
                        {{ formatMoney(record.discounted_total) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div class="nk-notes ff-italic fs-12px text-soft">
                  <!-- Invoice was created on a computer and is valid without
                        the signature and seal. -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
    <bootstrap-modal
      v-if="paymentModal"
      @close="togglePaymentModal"
      style="display: block"
      :modalSize="'modal-default'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >PAYMENT FOR VOUCHER
        <a href="javascript:void(0)"
          >#{{ $helper.generateVoucherNo(record.id) }}</a
        ></span
      >
      <div slot="body">
        <div class="row g-gs align-items-center">
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Total Amount</label>
              <div class="form-control-wrap">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text px-2">{{
                      appSettings.currency
                    }}</span>
                  </div>
                  <input
                    type="text"
                    :value="record.discounted_total"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Paid Amount</label>
              <div class="form-control-wrap">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text px-2">{{
                      appSettings.currency
                    }}</span>
                  </div>
                  <input
                    type="text"
                    :value="record.amount_paid"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Amount To Pay</label>
              <div class="form-control-wrap">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text px-2">{{
                      appSettings.currency
                    }}</span>
                  </div>
                  <input
                    type="number"
                    v-model="newPayment.amount_paid"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label required">Issued date</label>
              <div class="form-control-wrap">
                <input
                  type="date"
                  v-model="newPayment.committed_date"
                  class="form-control"
                  placeholder="Issued date"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label required">Payment Mode</label>
              <div class="form-control-wrap">
                <select
                  class="form-control"
                  v-model="newPayment.payment_method"
                >
                  <option value="null" selected hidden>Payment Method</option>
                  <option
                    v-for="row in paymentModes"
                    :key="row.id"
                    :value="row.id"
                  >
                    {{ row.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label required">Deposited To</label>
              <div class="form-control-wrap">
                <select class="form-control" v-model="newPayment.account_id">
                  <option value="null" selected hidden>Select option</option>
                  <option v-for="row in accounts" :key="row.id" :value="row.id">
                    {{ row.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group d-block">
              <label class="form-label w-100">&nbsp;&nbsp;</label>
              <wolf-button
                classes="btn btn-primary"
                @clicked="handlePartialPayment"
                :disabler="
                  !newPayment.amount_paid ||
                  !newPayment.account_id ||
                  !newPayment.payment_method
                "
                activator="saving_records"
              >
                <span slot="caption"
                  ><em class="icon ni ni-save"></em><span>Save</span></span
                >
              </wolf-button>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Avatar from "@/components/Avatar.vue";
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
import BootstrapModal from "@/components/BootstrapModal.vue";
import { getURIParams } from "@/_helpers/utils";
import PrintHeader from "@/components/PrintHeader.vue";
import { mapState } from "vuex";
export default {
  name: "SalesReport",
  mixins: [dashboardServices],
  components: { Avatar, TypeHead, BootstrapModal, Multiselect, PrintHeader },
  data: () => ({
    items: [],
    record: {},
    paymentModal: false,
    newPayment: {
      record_id: null,
      amount_paid: 0,
      amount_remain: 0,
      payment_method: null,
      account_id: null,
      committed_date: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
    },
    filterUrl: "all",
    filterEntity: null,
    selectedBranch: null,
    sources: [],
    source: null,
  }),
  computed: {
    ...mapState({
      branches: (state) => state.branches,
    }),
    isReport() {
      return (
        this.$route.name == "ProductionSalesReport" ||
        this.$route.name == "BranchesSalesReport"
      );
    },
    totalAmount() {
      return this.rows.reduce((a, b) => a + Number(b.total_amount), 0);
    },
    totalPaid() {
      return this.rows.reduce((a, b) => a + Number(b.amount_paid), 0);
    },
    totalRemain() {
      return this.rows.reduce((a, b) => a + Number(b.amount_remain), 0);
    },
    reportType() {
      return ["ProductionSalesReport", "ProductionInvoices"].includes(
        this.$route.name
      )
        ? "PRODUCTION"
        : "BRANCH_SALES";
    },
  },
  watch: {
    $route() {
      location.reload();
    },
    filterUrl() {
      if (this.filterUrl == "production/items") {
        this.filterEntity = "Item name";
        this.newFilter.filter = "item";
      } else if (this.filterUrl == "shared/clients/search") {
        this.newFilter.filter = "client";
        this.filterEntity = "Client name";
      } else if (this.filterUrl == "shared/users/search") {
        this.newFilter.filter = "employee";
        this.filterEntity = "Employee name";
      } else this.newFilter.filter = null;
      this.$refs.invoiceSelector.clearAll();
    },
  },
  created() {
    if (this.reportType == "PRODUCTION") {
      this.fetchUrl = "sales/PRODUCTION";
      this.permissionsPath = "production.submenus.invoices";
    } else {
      this.fetchUrl = "sales/BRANCH_SALE";
      this.permissionsPath = "branches.submenus.invoices";
    }
    const params = getURIParams();
    if (!Object.keys(params).length) {
      this.handlePaginationSearch();
    } else {
      this.newFilter = params;
      this.handlePaginationFilter();
    }
  },
  methods: {
    handleSourceFilter(source) {
      this.newFilter.source_id = source.id;
      this.handlePaginationFilter();
    },
    handleBranchFilter(record) {
      this.selectedBranch = record;
      if (record) {
        this.newFilter.branch_id = record.id;
        this.handlePaginationFilter();
      }
    },
    handleInvoicesFilter(record) {
      this.newFilter.record_id = record.id;
      this.handlePaginationFilter();
    },
    togglePaymentModal(record = null) {
      if (record) {
        this.record = record;
        this.newPayment.record_id = record.id;
        this.newPayment.amount_paid = record.amount_remain;
      }
      this.$helper.toggleModalOpen();
      this.paymentModal = !this.paymentModal;
    },
    getSaleItems(record) {
      this.items = [];
      this.$http.get(`sales/items/${record.reference}`).then((response) => {
        this.items = response.data.items;
        this.record = response.data.record;
        this.toggleModal();
      });
    },
    handlePartialPayment() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.newPayment.amount_remain =
            this.record.discounted_total -
            (Number(this.record.amount_paid) +
              Number(this.newPayment.amount_paid));

          this.$http
            .post(
              "sales/payments/partial",
              this.$helper.generateFormData(this.newPayment)
            )
            .then((response) => {
              if (response.data.status) {
                // const index = this.rows.find((row) => row.id == this.record.id);
                // this.rows[index].amount_paid += this.newPayment.amount_paid;
                // this.rows[index].amount_remain = this.newPayment.amount_remain;
                // this.rows[index].paid =
                //   this.newPayment.amount_remain == 0 ? 1 : 0;
                location.reload();
                this.$helper.resetObjectValues(this.newPayment);
                this.togglePaymentModal();
              }
            })
            
        }
      });
    },
  },
  mounted() {
    this.getPaymentsMode();
    this.getPaymentsAccounts();
    this.$nextTick(() => {
      if (
        this.$helper.empty(this.loggedUser.source) &&
        this.reportType == "PRODUCTION"
      ) {
        this.$http.get("production/sources").then((response) => {
          this.sources = response.data.sources;
        });
      }
    });
  },
};
</script>
