<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      <span v-if="isProductionItems"
                        >Raw materials receiving report</span
                      >
                      <span v-else>Inventory Receiving Report</span>
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li style="width: 150px">
                            <multiselect
                              v-model="supplier"
                              :options="suppliers"
                              placeholder="Filter By supplier"
                              label="name"
                              track-by="name"
                              @select="handleSupplierFilter"
                            ></multiselect>
                          </li>
                          <li style="width: 150px" v-if="isProductionItems">
                            <multiselect
                              v-model="source"
                              :options="sources"
                              placeholder="By Production"
                              label="name"
                              track-by="name"
                              @select="filterStock"
                            ></multiselect>
                          </li>
                          <template v-if="rows.length">
                            <li>
                              <button
                                type="button"
                                class="btn btn-icon btn-white btn-dim btn-outline-light"
                                @click="$helper.handlePrint('.nk-block')"
                              >
                                <em class="icon ni ni-printer"></em>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                class="btn btn-white btn-dim btn-outline-light"
                                @click="handleExcelExport"
                              >
                                <svg
                                  height="18px"
                                  viewBox="0 0 384 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path
                                    d="m369.9 97.9-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-204.1c-26.5.1-48 21.6-48 48.1v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-332.1c0-12.7-5.1-25-14.1-34zm-37.8 30.1h-76.1v-76.1zm-284.1 336v-416h160v104c0 13.3 10.7 24 24 24h104v288zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3h-28.9c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3h28.8c9.3 0 15-10 10.4-18l-46.4-78c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"
                                  ></path>
                                </svg>
                              </button>
                            </li>
                          </template>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <print-header>
                  <div slot="title" class="text-center mb-1">
                    <h5 class="text-center">
                      Receiving report /
                      <small style="font-size: 14px">{{ reportLabel }}</small>
                    </h5>
                  </div>
                </print-header>
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col" v-if="isProductionItems">
                      <span class="sub-text">Production</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Items</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Invoice</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">VAT</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Supplier</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        $helper.formatDate(row.received_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col" v-if="isProductionItems">
                      <span class="tb-lead">{{ row.source }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{
                        $helper.formatNumber(row.items || row.total_items)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{ formatMoney(row.total_amount) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="badge badge-dot bg-success"
                        v-if="row.has_invoice"
                        >Available</span
                      >
                      <span class="badge badge-dot bg-warning" v-else
                        >No Invoice</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="badge badge-sm badge-dim bg-outline-success d-md-inline-flex"
                        :title="row.vat"
                        v-if="row.vat"
                        >{{ row.vat }}</span
                      >
                      <span v-else>N/A</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-lead"
                        v-if="!$helper.empty(row.supplier)"
                        >{{ row.supplier }}</span
                      >
                      <span v-else>N/A</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-lead"
                        v-if="!$helper.empty(row.creator)"
                        >{{ row.creator }}</span
                      >
                      <span v-else>N/A</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      getItemVoucher(row.reference, row.type)
                                    "
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'ReceiveVoucher',
                                      params: { reference: row.reference },
                                      query: { type: row.type },
                                    }"
                                    ><em class="icon ni ni-file-text"></em
                                    ><span>View Voucher</span></router-link
                                  >
                                </li>
                                <template v-if="row.type != 'delivery'">
                                  <li v-if="canEdit(permissionsPath)">
                                    <router-link
                                      v-if="isProductionItems"
                                      :to="{
                                        name: 'ProductionReceive',
                                        query: {
                                          action: 'edit',
                                          reference: row.reference,
                                        },
                                      }"
                                      ><em class="icon ni ni-edit"></em
                                      ><span>Edit</span></router-link
                                    >
                                    <router-link
                                      v-else
                                      :to="{
                                        name: 'ProductReceive',
                                        query: {
                                          action: 'edit',
                                          reference: row.reference,
                                        },
                                      }"
                                      ><em class="icon ni ni-edit"></em
                                      ><span>Edit</span></router-link
                                    >
                                  </li>
                                  <li v-if="canDelete(permissionsPath)">
                                    <a
                                      href="javascript:void(0)"
                                      @click.prevent="
                                        handleDeleteAlertModal({
                                          tableName: 'receiving',
                                          entityIdentifer:
                                            'Receive record with vouncher No ',
                                          entity: `${row.code || ''}`,
                                          url: `stock/products/receive/delete/${row.id}`,
                                          callback: () => {
                                            rows.splice(i, 1);
                                          },
                                        })
                                      "
                                      ><em class="icon ni ni-trash"></em
                                      ><span>Delete</span></a
                                    >
                                  </li>
                                </template>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item" v-if="rows.length">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"><h6>Grand Total</h6></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>
                        {{ `${formatMoney(grandTotal)}` }}
                      </h6>
                    </div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >RECEIVING VOUCHER
        <a href="javascript:void(0)"
          >#{{ $helper.generateVoucherNo(record.id) }}</a
        ></span
      >
      <div slot="body">
        <div class="table-responsive">
          <div class="card card-full">
            <div class="nk-tb-list mt-n2 is-compact">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>S/N</span></div>
                <div class="nk-tb-col"><span>Name</span></div>
                <div class="nk-tb-col"><span>Type</span></div>
                <div class="nk-tb-col"><span>Cost Price</span></div>
                <div class="nk-tb-col"><span>Quantity</span></div>
                <div class="nk-tb-col"><span>Tot. Amount</span></div>
              </div>
              <div
                class="nk-tb-item"
                v-for="(item, i) in items"
                :key="'item' + i"
              >
                <div class="nk-tb-col">
                  <span>{{ i + 1 }}</span>
                </div>
                <div class="nk-tb-col">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">{{ item.product.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{ item.product.type }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub"> {{ formatMoney(item.price) }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{
                    $helper.formatNumber(item.quantity)
                  }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount">{{
                    formatMoney(item.quantity * item.price)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Multiselect from "vue-multiselect";
import BootstrapModal from "@/components/BootstrapModal.vue";
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "Receive",
  components: { Multiselect, BootstrapModal, PrintHeader },
  mixins: [dashboardServices],
  data: () => ({
    items: [],
    record: {},
    suppliers: [],
    sources: [],
    supplier: null,
    source: null,
  }),
  created() {
    this.permissionsPath = this.isProductionItems
      ? "production.submenus.receive_items"
      : "inventory.submenus.receive_items";
    this.fetchUrl = this.isProductionItems
      ? "reports/receive/production"
      : "reports/receive";
    const startDate = this.$route?.query?.start_date;
    const endDate = this.$route?.query?.end_date;
    if (startDate && endDate) {
      this.newFilter.from = startDate;
      this.newFilter.to = endDate;
      this.handlePaginationSearch(
        this.encodeQuery(this.fetchUrl, this.newFilter)
      );
    } else {
      this.handlePaginationSearch();
    }
  },
  mounted() {
    this.$http.get("settings/suppliers?nopagination=1").then((response) => {
      this.suppliers = response.data.rows.data;
    });
    if (this.isProductionItems) {
      this.$http.get("production/sources").then((response) => {
        this.sources = response.data.sources;
      });
    }
  },
  computed: {
    grandTotal() {
      return this.rows.reduce((a, b) => a + Number(b.total_amount), 0);
    },
    isProductionItems() {
      return this.$route.query["stock"] == "production";
    },
  },
  methods: {
    filterStock(source) {
      if (!this.$helper.empty(source)) {
        this.newFilter.source_id = source.id;
        this.handlePaginationFilter();
      }
    },
    handleSupplierFilter(supplier) {
      if (!this.$helper.empty(supplier)) {
        this.newFilter.supplier = supplier.id;
        this.handlePaginationFilter();
      }
    },
    getItemVoucher(reference, type) {
      this.items = [];
      this.record = {};
      this.$http
        .get(`/reports/receive/${reference}/voucher?type=${type}`)
        .then((response) => {
          this.items = response.data.items;
          this.record = response.data.record;
          this.toggleModal();
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
