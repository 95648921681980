<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Activity Logs
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li v-if="false">
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="search"
                                class="form-control"
                                id="default-04"
                                placeholder="Search by item name"
                                v-model="filterKey"
                                @keyup="handlePaginationSearch"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="
                                  dropdown-toggle
                                  btn
                                  btn-white
                                  btn-icon
                                  btn-dim
                                  btn-outline-light
                                "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="
                                            btn btn-primary
                                            d-md-inline-flex
                                          "
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-search"></em
                                          ><span>Search</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col">
                      <span class="sub-text">Date</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">ACTIVITY DETAILS</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">DESCRIPTION</span>
                    </div>
                    <div class="nk-tb-col"></div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        dayjs(row.created_at).format("MMM D, YYYY h:mm A")
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.notifier }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.description }}</span>
                      <span class="tb-odr-date">By {{ row.creator }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <div
                        class="tb-odr-btns d-none d-md-inline"
                        v-if="row.ref_url"
                      >
                        <a :href="row.ref_url" class="btn btn-sm btn-primary"
                          >View</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import dayjs from "dayjs";
export default {
  name: "ActivityLogs",
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "reports/system/activity-logs",
    dayjs: dayjs,
  }),
  methods: {},
};
</script>
