<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3
                      class="nk-block-title page-title"
                      v-if="isAutoRequisitions"
                    >
                      {{
                        isProductionRequisitions
                          ? "Auto Generated Branches' Requests"
                          : "Auto Generated Requisitions"
                      }}
                    </h3>
                    <h3 class="nk-block-title page-title" v-else>
                      {{
                        isProductionRequisitions
                          ? "Branches' Requests"
                          : isProductionMaterials
                          ? "Raw Materials Requisitions"
                          : "All Requisitions"
                      }}
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} requisitions.</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-control-wrap mb-2">
                                        <div class="form-icon form-icon-right">
                                          <em class="icon ni ni-search"></em>
                                        </div>
                                        <input
                                          type="search"
                                          class="form-control"
                                          id="default-04"
                                          placeholder="Search by Voucher No"
                                          v-model="filterKey"
                                          @keyup="handlePaginationSearch"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.destination"
                                          v-if="isProductionRequisitions"
                                        >
                                          <option value="all">
                                            All Productions
                                          </option>
                                          <option
                                            :value="item.id"
                                            v-for="item in sources"
                                            :key="item.id"
                                          >
                                            {{ item.name }}
                                          </option>
                                        </select>
                                        <select
                                          v-else
                                          class="form-control form-select"
                                          v-model="newFilter.type"
                                        >
                                          <option value="all">All Items</option>
                                          <option value="STOCK">
                                            MAIN STORABLE
                                          </option>
                                          <option value="PERISHABLES">
                                            PERISHABLES
                                          </option>
                                          <option value="PRODUCTION_STOCK">
                                            PRODUCTIONS
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.status"
                                        >
                                          <option value="all">All Items</option>
                                          <option>PENDING</option>
                                          <option>ACCEPTED</option>
                                          <option>DENIED</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            class="nk-block-tools-opt"
                            v-if="
                              !isProductionMaterials &&
                              (isAdministrator || canCreate(permissionsPath))
                            "
                          >
                            <router-link
                              :to="{ name: 'CreateRequest' }"
                              class="btn btn-primary d-md-inline-flex"
                              ><em class="icon ni ni-plus"></em
                              ><span>New</span></router-link
                            >
                          </li>
                          <li
                            v-if="
                              isProductionMaterials &&
                              canCreate(permissionsPath)
                            "
                          >
                            <router-link
                              :to="{ name: 'ProductionRequests' }"
                              class="btn btn-primary d-md-inline-flex"
                              ><em class="icon ni ni-plus"></em
                              ><span>New</span></router-link
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="custom-control custom-control-sm custom-checkbox notext"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid"
                          v-model="checkAll"
                        /><label class="custom-control-label" for="uid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Reference</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Date</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text" v-if="isProductionRequisitions"
                        >Branch</span
                      >
                      <span class="sub-text" v-else>Source/Branch</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Destination</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Items</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Total Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Status</span>
                    </div>
                    <div class="nk-tb-col" v-if="!isAutoRequisitions">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger mr-n1"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li
                                  v-if="
                                    checkedRows.length &&
                                    (isAdministrator ||
                                      canDelete(permissionsPath))
                                  "
                                >
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'items',
                                        entityIdentifer: 'requisitions',
                                        entity: `requisitions`,
                                        url: `requisitions/delete/${checkedRows.join(
                                          ','
                                        )}`,
                                        callback: () => {
                                          $store.commit(
                                            'REDUCE_NOTIFICATIONS',
                                            {
                                              key: 'requisitions',
                                              total: checkedRows.length,
                                            }
                                          );
                                          handlePaginationSearch();
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Selected</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'item' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="custom-control custom-control-sm custom-checkbox notext"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input row-checker"
                          :id="'uid' + i"
                          v-model="checkedRows"
                          :value="item.id"
                        /><label
                          class="custom-control-label"
                          :for="'uid' + i"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount"
                        >#{{ $helper.generateVoucherNo(item.id) }}</span
                      >
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        $helper.formatDate(item.committed_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <a href="javascript:void(0)">
                        <span v-if="!$helper.empty(item?.source)">{{
                          item?.source?.name
                        }}</span>
                        <span v-else>{{ item?.branch?.name }}</span>
                      </a>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        v-if="item.type == 'STOCK' || item.type == 'PRODUCTION'"
                        >MAIN STOCK</span
                      >
                      <a
                        href="javascript:void(0)"
                        v-else-if="item.type == 'PRODUCTION_STOCK'"
                      >
                        <span>{{ item.destination.name }}</span>
                      </a>
                      <span v-else>PERISHABLES</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount text-center">
                        {{
                          $helper.formatNumber(item.total_items) || "0"
                        }}</span
                      >
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">
                        {{ formatMoney(item.total_amount) || "0" }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-status text-warning"
                        v-if="item.status == 'PENDING'"
                        >PENDING</span
                      >
                      <span
                        class="tb-status text-success"
                        v-if="item.status == 'ACCEPTED'"
                        >ACCEPTED</span
                      >
                      <span
                        class="tb-status text-danger"
                        v-if="item.status == 'DENIED'"
                        >DENIED</span
                      >
                    </div>
                    <div class="nk-tb-col tb-col-mb" v-if="!isAutoRequisitions">
                      <span class="tb-amount">{{
                        item?.creator?.name || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      getRequisitionItems(item.reference)
                                    "
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <template
                                  v-if="
                                    item.status == 'PENDING' &&
                                    (isAdministrator ||
                                      canEdit(permissionsPath))
                                  "
                                >
                                  <li v-if="item.status == 'PENDING'">
                                    <router-link
                                      v-if="item.type == 'PRODUCTION_STOCK'"
                                      :to="{
                                        name: 'ProductionTransfer',
                                        params: { reference: item.reference },
                                      }"
                                      ><em class="icon ni ni-send"></em
                                      ><span>Deliver</span></router-link
                                    >
                                    <router-link
                                      v-else
                                      :to="{
                                        name: 'StockDelivery',
                                        params: { reference: item.reference },
                                      }"
                                      ><em class="icon ni ni-send"></em
                                      ><span>Deliver</span></router-link
                                    >
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0)"
                                      @click.prevent="
                                        $alert({
                                          title: 'Rejecting Requisition',
                                          content: `Are you sure you want to cancel this requisition (${item.reference})`,
                                          classes: 'btn btn-danger',
                                          actionText: 'Continue',
                                          closeText: 'Discard',
                                          action: () => {
                                            rejectRequisition(item);
                                          },
                                        })
                                      "
                                      ><em class="icon ni ni-na"></em
                                      ><span>Reject</span></a
                                    >
                                  </li>
                                </template>
                                <li
                                  v-if="
                                    item.status == 'PENDING' &&
                                    (isAdministrator ||
                                      canDelete(permissionsPath))
                                  "
                                >
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'items',
                                        entityIdentifer: `requisition item #${item.reference}`,
                                        entity: `${item.name || ''}`,
                                        url: `requisitions/delete/${item.id}`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                          $store.commit(
                                            'REDUCE_NOTIFICATIONS',
                                            { key: 'requisitions', total: 1 }
                                          );
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" class="d-flex align-items-center w-100">
        <span>Requested Items</span>
        <router-link
          class="btn btn-icon btn-sm btn-white btn-dim btn-outline-primary ml-auto"
          :to="{
            name: 'RequisitionPrint',
            params: { reference: requisition.reference },
          }"
          ><em class="icon ni ni-printer-fill"></em
        ></router-link>
      </span>
      <div slot="body">
        <div class="table-responsive">
          <div class="card card-full">
            <div class="nk-tb-list mt-n2 is-compact">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>$/N</span></div>
                <div class="nk-tb-col"><span>Name</span></div>
                <div class="nk-tb-col"><span>Cost Price</span></div>
                <div class="nk-tb-col"><span>Requested Qty</span></div>
                <div class="nk-tb-col"><span>Amount</span></div>
                <div class="nk-tb-col" v-if="false">
                  <span>Received Qty</span>
                </div>
              </div>
              <div
                class="nk-tb-item"
                v-for="(item, i) in items"
                :key="'item' + i"
              >
                <div class="nk-tb-col">
                  <span class="tb-lead"
                    ><a href="javascript:void(0)">{{ i + 1 }}</a></span
                  >
                </div>
                <div class="nk-tb-col">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">{{ item.product.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">
                    {{
                      formatMoney(item.product.cost_price || item.product.cost)
                    }}</span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub"
                    >{{ item.requested_quantity }}
                    {{ item.product?.unit }}</span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount">{{
                    formatMoney(
                      (item.product.cost_price || item.product.cost) *
                        item.requested_quantity
                    )
                  }}</span>
                </div>
                <div class="nk-tb-col" v-if="false">
                  <span>{{ item.received_quantity }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "Requests",
  mixins: [dashboardServices],
  components: { BootstrapModal },
  data: () => ({
    items: [],
    sources: [],
    newFilter: {
      from: null,
      to: null,
      type: "all",
      status: "all",
      destination: "all",
    },
    requisition: {},
  }),
  computed: {
    isAutoRequisitions() {
      return this.$route.query.category && this.$route.query.category == "auto";
    },
    isProductionRequisitions() {
      return this.$route.name == "ProductionRequisitions";
    },
    isProductionMaterials() {
      return this.$route.name == "RawMeterialsRequests";
    },
    isAdministrator() {
      return (
        this.$helper.empty(this.loggedUser.branch) &&
        this.$helper.empty(this.loggedUser.source)
      );
    },
  },
  created() {
    this.permissionsPath = "inventory.submenus.requisitions";
    if (this.isProductionRequisitions) {
      this.fetchUrl = "requisitions?type=PRODUCTION_STOCK";
      if (this.isAutoRequisitions) {
        this.fetchUrl += "&is_for_pos=1&confirmed=1";
      }
      this.newFilter.type = "PRODUCTION_STOCK";
      this.permissionsPath = "production.submenus.branches_requests";
    } else if (this.isProductionMaterials) {
      this.fetchUrl = "requisitions?is_production_materials=1";
      this.permissionsPath = "production.submenus.placed_requisitions";
    } else {
      this.fetchUrl = "requisitions";
      if (this.isAutoRequisitions) {
        this.fetchUrl += "?is_for_pos=1&confirmed=1";
      }
    }
    this.handlePaginationSearch();
  },
  beforeDestroy() {
    this.modalOpen = false;
    document.documentElement.classList.remove("modal-open");
    document.body.classList.remove("modal-open");
  },
  mounted() {
    this.$http.get("production/sources").then((response) => {
      this.sources = response.data.sources;
    });
  },
  watch: {
    $route() {
      location.reload();
    },
  },
  methods: {
    rejectRequisition(item) {
      this.$http
        .get(`requisitions/status/update/${item.id}`)
        .then((response) => {
          if (response.data.status) {
            item.status = "DENIED";
          }
        });
    },
    getRequisitionItems(reference) {
      this.items = [];
      this.$http.get(`requisitions/items/${reference}`).then((response) => {
        this.items = response.data.items;
        this.requisition = response.data.requisition;
        this.toggleModal();
      });
    },
  },
};
</script>
