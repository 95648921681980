<script>
export default {
  name: "PaymentHandler",
  props: {
    payment: {
      type: Object,
      required: false,
      default: () => {
        return Object.create(null);
      },
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    newPayment: {
      payment_method: null,
      payment_ref: null,
      total_amount: 0,
      discounted_total: 0,
      payment_date: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
      amount_paid: 0,
      discount_amount: 0,
      comment: null,
      amount_remain: 0,
      discount: 0,
    },
    payment_method: null,
    account: null,
  }),
  created() {
    if (this.payment && Object.keys(this.payment).length > 0) {
      Object.assign(this.newPayment, this.payment);
    }
  },
  mounted() {
    this.calculateDiscountedAmount();
    this.getPaymentsAccounts();
    this.getPaymentsMode();
  },
  watch: {
    total() {
      this.calculateDiscountedAmount();
    },
  },
  methods: {
    handlePaymentInfo() {
      this.newPayment.total_amount = this.total;
      return { ...this.newPayment };
    },
    calculateDiscountedAmount() {
      let discountAmount =
        (Number(this.total) * Number(this.newPayment.discount)) / 100;
      this.newPayment.discount_amount = discountAmount;
      this.newPayment.discounted_total =
        Number(this.total) - Number(discountAmount);
      this.newPayment.amount_paid = Number(this.total) - Number(discountAmount);
      this.newPayment.amount_remain =
        Number(this.newPayment.discounted_total) -
        Number(this.newPayment.amount_paid);
    },
    calculateAmountRemain() {
      this.newPayment.amount_remain = (
        Number(this.newPayment.discounted_total) -
        Number(this.newPayment.amount_paid)
      ).toFixed(3);
    },
  },
};
</script>
<template>
  <div class="total-section invoice-discount" id="total-section">
    <div class="ember-view">
      <div class="ember-view">
        <div class="ember-view collapse show" style="">
          <div class="total-row gross-total mt-0">
            <div class="total-label">Total</div>
            <div class="total-amount">{{ formatMoney(total) }}</div>
          </div>

          <div class="total-row sub-total mt-0">
            <div class="total-label">Paid Amount <br /></div>
            <div class="total-amount">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text px-2">{{
                    appSettings.currency
                  }}</span>
                </div>
                <input
                  type="number"
                  @keyup="calculateAmountRemain"
                  v-model="newPayment.amount_paid"
                  class="form-control"
                  placeholder="Directly paid"
                />
              </div>
            </div>
          </div>
          <div class="total-row sub-total mt-0">
            <div class="total-label">Amount Due<br /></div>
            <div class="total-amount">
              {{ formatMoney(newPayment.amount_remain) }}
            </div>
          </div>

          <div class="total-row sub-total mt-0">
            <div class="total-label">
              <span class="required">Payment Mode</span> <br />
            </div>
            <div class="total-amount">
              <div class="dropdown filter-dropdown w-100">
                <div
                  class="auto-select ac-selected dropdown-toggle w-100"
                  tabindex="-1"
                >
                  <input
                    autocomplete="off"
                    spellcheck="false"
                    placeholder="Select or type to add"
                    autocorrect="off"
                    autocapitalize="off"
                    class="form-control bg-white"
                    type="text"
                    v-model="payment_method"
                    readonly
                  />
                  <i class="zf-ac-toggler">
                    <em class="icon ni ni-caret-down-fill m-auto"></em>
                  </i>
                </div>

                <div class="dropdown-menu dropdown-menu-right w-100" style="">
                  <div class="p-2 position-relative" style="width: 100%">
                    <div class="options-select">
                      <div class="form-group">
                        <input
                          type="search"
                          class="form-control form-control-sm"
                          placeholder="Search for payment"
                        />
                      </div>
                      <div class="tame-vertical-container">
                        <div class="options-container mb-1">
                          <div
                            class="p-1 item"
                            v-for="row in paymentModes"
                            :key="row.id"
                          >
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                () => {
                                  newPayment.payment_method = row.id;
                                  payment_method = row.name;
                                }
                              "
                              class="w-100"
                            >
                              <span
                                ><em class="icon ni ni-property-alt"></em
                              ></span>
                              <span class="ml-1">{{ row.name }}</span></a
                            >
                          </div>
                          <span class="text-center" v-if="!paymentModes.length"
                            >No data available</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="total-row sub-total mt-0">
            <div class="total-label">
              <span class="required">Deposit To</span> <br />
            </div>
            <div class="total-amount">
              <div class="dropdown filter-dropdown w-100">
                <div
                  class="auto-select ac-selected dropdown-toggle w-100"
                  tabindex="-1"
                >
                  <input
                    autocomplete="off"
                    spellcheck="false"
                    placeholder="Select or type to add"
                    autocorrect="off"
                    autocapitalize="off"
                    class="form-control bg-white"
                    type="text"
                    v-model="account"
                    readonly
                  />
                  <i class="zf-ac-toggler">
                    <em class="icon ni ni-caret-down-fill m-auto"></em>
                  </i>
                </div>

                <div class="dropdown-menu dropdown-menu-right w-100" style="">
                  <div class="p-2 position-relative" style="width: 100%">
                    <div class="options-select">
                      <div class="form-group">
                        <input
                          type="search"
                          class="form-control form-control-sm"
                          placeholder="Select account"
                        />
                      </div>
                      <div class="tame-vertical-container">
                        <div class="options-container mb-1">
                          <div
                            class="p-1 item"
                            v-for="row in accounts"
                            :key="row.id"
                          >
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                () => {
                                  newPayment.account_id = row.id;
                                  account = row.name;
                                }
                              "
                              class="w-100"
                            >
                              <span
                                ><em class="icon ni ni-property-alt"></em
                              ></span>
                              <span class="ml-1">{{ row.name }}</span></a
                            >
                          </div>
                          <span class="text-center" v-if="!accounts.length"
                            >No data available</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="total-row sub-total mt-0">
            <div class="total-label">Payment Ref <br /></div>
            <div class="total-amount">
              <input
                type="text"
                class="form-control"
                v-model="newPayment.payment_ref"
                placeholder="Payment REF"
              />
            </div>
          </div>
          <div class="total-row sub-total mt-0">
            <textarea
              v-model="newPayment.comment"
              rows="3"
              class="form-control"
              style="height: auto !important"
              placeholder="Write some comment"
            ></textarea>
          </div>
          <div class="total-row sub-total mt-3">
            <div class="total-label"></div>
            <div class="total-amount">
              <slot name="foot"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
