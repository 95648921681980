<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block">
          <div class="card">
            <div class="card-aside-wrap">
              <router-view></router-view>
              <profile-sidebar></profile-sidebar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileSidebar from "./ProfileSidebar.vue";
export default {
  name: "User",
  components: {
    ProfileSidebar,
  },
  computed: {
  },
};
</script>
