<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Item Tracking Report
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> From: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.from"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> To: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.to"
                              />
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <button
                              type="button"
                              class="btn btn-primary d-md-inline-flex"
                              :disabled="$helper.empty(newFilter.from)"
                              @click="handlePaginationFilter"
                            >
                              <em class="icon ni ni-filter"></em
                              ><span>Filter</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <h6
                    class="text-right"
                    style="
                      width: 100%;
                      display: table-caption;
                      padding-right: 7rem;
                    "
                  >
                    Opening Qty:
                    {{ `${openQty} ${productUnit}` }}
                  </h6>
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Name</span>
                    </div>
                    <div class="nk-tb-col" v-if="!isProductionItem">
                      <span class="sub-text">From: / To: </span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">{{
                        isProductionItem ? "Produced" : "Received"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">{{
                        isProductionItem ? "Sold Out" : "Transfered"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Spoiled</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Closed</span>
                    </div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        $helper.formatDate(row.committed_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        fetchedData.product.name
                      }}</span>
                    </div>
                    <div class="nk-tb-col" v-if="!isProductionItem">
                      <span class="tb-lead">{{
                        row.branch || row.source
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-amount"
                        v-if="row.transaction_type == 'receive'"
                      >
                        {{
                          `${$helper.formatNumber(row.quantity)} ${productUnit}`
                        }}</span
                      >
                      <span v-else>{{ `0 ${productUnit}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-amount"
                        v-if="row.transaction_type == 'transfer'"
                      >
                        {{
                          `${$helper.formatNumber(row.quantity)} ${productUnit}`
                        }}</span
                      >
                      <span v-else>{{ `0 ${productUnit}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-amount"
                        v-if="row.transaction_type == 'spoiled'"
                      >
                        {{
                          `${$helper.formatNumber(row.quantity)} ${productUnit}`
                        }}</span
                      >
                      <span v-else>{{ `0 ${productUnit}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(
                          closeQuantities[i]
                        )}  ${productUnit}`
                      }}</span>
                    </div>
                  </div>
                  <div class="nk-tb-item" v-if="rows.length">
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col" v-if="!isProductionItem">
                      <span class="sub-text"></span>
                    </div>
                    <div class="nk-tb-col">
                      <h6>
                        {{
                          `${$helper.formatNumber(
                            fetchedData.received_qty
                          )} ${productUnit}`
                        }}
                      </h6>
                    </div>
                    <div class="nk-tb-col">
                      <h6>
                        {{
                          `${$helper.formatNumber(
                            Number(fetchedData.transfered_qty) +
                              Number(fetchedData.deliveries_qty)
                          )} ${productUnit}`
                        }}
                      </h6>
                    </div>
                    <div class="nk-tb-col">
                      <h6>
                        {{
                          `${$helper.formatNumber(
                            fetchedData.spoiled_qty
                          )} ${productUnit}`
                        }}
                      </h6>
                    </div>
                    <div class="nk-tb-col"></div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "ItemTracker",
  mixins: [dashboardServices],
  data: () => ({
    items: [],
    record: {},
    suppliers: [],
    supplier: null,
  }),
  computed: {
    isProductionItem() {
      return this.$route.name == "ProductionItemTracker";
    },
    grandTotal() {
      return this.rows.reduce((a, b) => a + Number(b.total_amount), 0);
    },
    productUnit() {
      return this.fetchedData.product ? this.fetchedData.product.unit : "";
    },
    stockQty() {
      return this.fetchedData.product ? this.fetchedData.product.stock_qty : 0;
    },
    openQty() {
      const a = Number(this.stockQty) - Number(this.fetchedData.received_qty);
      const result =
        a +
        Number(this.fetchedData.transfered_qty) +
        Number(this.fetchedData?.deliveries_qty || 0) +
        Number(this.fetchedData.spoiled_qty);
      if (result < 0) {
        return 0;
      }
      return result;
    },
    closeQuantities() {
      var openQty = this.openQty;
      /*if (!openQty) {
        return [];
      } */
      var results = [];
      this.rows.forEach((row) => {
        if (row.transaction_type == "receive") openQty += row.quantity;
        else openQty -= row.quantity;
        results.push(openQty);
      });
      return results;
    },
  },
  created() {
    this.fetchUrl = `${
      this.isProductionItem ? "production" : "reports"
    }/track-items/${this.$route.params.code}`;
    this.handlePaginationSearch();
  },
  methods: {},
};
</script>
<style scoped>
.colspan {
  max-width: 1px;
  overflow: visible;
}
.colspan2 {
  /* What to do here? */
  display: table-caption;
}
</style>
