<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title mb-0">
                      Production Stock Report
                    </h3>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <template v-if="rows.length">
                            <li>
                              <button
                                type="button"
                                class="btn btn-icon btn-white btn-dim btn-outline-light"
                                @click="$helper.handlePrint('.nk-block')"
                              >
                                <em class="icon ni ni-printer"></em>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                class="btn btn-white btn-dim btn-outline-light"
                                @click="handleExcelExport"
                              >
                                <svg
                                  height="18px"
                                  viewBox="0 0 384 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path
                                    d="m369.9 97.9-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-204.1c-26.5.1-48 21.6-48 48.1v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-332.1c0-12.7-5.1-25-14.1-34zm-37.8 30.1h-76.1v-76.1zm-284.1 336v-416h160v104c0 13.3 10.7 24 24 24h104v288zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3h-28.9c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3h28.8c9.3 0 15-10 10.4-18l-46.4-78c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"
                                  ></path>
                                </svg>
                              </button>
                            </li>
                          </template>
                          <li
                            style="width: 150px"
                            v-if="$helper.empty(loggedUser.source)"
                          >
                            <multiselect
                              v-model="source"
                              :options="sources"
                              placeholder="All Productions"
                              label="name"
                              track-by="name"
                              @select="handleSourceFilter"
                            ></multiselect>
                          </li>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-control-wrap mb-2">
                                        <div class="form-icon form-icon-right">
                                          <em class="icon ni ni-search"></em>
                                        </div>
                                        <input
                                          type="search"
                                          class="form-control"
                                          id="default-04"
                                          placeholder="Search by product name"
                                          v-model="filterKey"
                                          @keyup="handlePaginationSearch"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.type"
                                        >
                                          <option value="all">All Items</option>
                                          <option value="PRODUCTION">
                                            Production Items
                                          </option>
                                          <option value="STORABLE">
                                            Raw Materials
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.preference"
                                        >
                                          <option>FIGURES</option>
                                          <option>VALUES</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <print-header>
                  <div slot="title" class="text-center mb-1">
                    <h5 class="text-center">
                      Production stock report /
                      <small style="font-size: 14px">{{ reportLabel }}</small>
                    </h5>
                  </div>
                </print-header>
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Cost Price</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Open. {{ preferenceLabel }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text" v-if="newFilter.type == 'all'"
                        >Produced. {{ preferenceLabel }}</span
                      >
                      <span class="sub-text" v-else
                        >Received. {{ preferenceLabel }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text"
                        >Sold/Out {{ preferenceLabel }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text"
                        >Spoiled {{ preferenceLabel }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Close. {{ preferenceLabel }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger mr-n1"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Selected</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'item' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{ item.name }}</span>
                    </div>

                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(item.costPrice)}`
                      }}</span>
                    </div>
                    <template v-if="preferenceLabel == 'Qty'">
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${$helper.formatNumber(getOpenQty(item))} ${
                            item.unit ? item.unit.name : ""
                          }`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${$helper.formatNumber(item.receiveQty)} ${
                            item.unit ? item.unit.name : ""
                          }`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${$helper.formatNumber(
                            item.transferedQty + item.soldQty
                          )} ${item.unit ? item.unit.name : ""}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${$helper.formatNumber(item.spoiledQty)} ${
                            item.unit ? item.unit.name : ""
                          }`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${$helper.formatNumber(item.currentQty)} ${
                            item.unit ? item.unit.name : ""
                          }`
                        }}</span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${formatMoney(getOpenQty(item) * item.costPrice)}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${formatMoney(item.receiveQty * item.costPrice)}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${formatMoney(item.transferedQty * item.costPrice)}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${formatMoney(item.spoiledQty * item.costPrice)}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${formatMoney(item.currentQty * item.costPrice)}`
                        }}</span>
                      </div>
                    </template>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'ProductionItemTracker',
                                      params: { code: item.code },
                                    }"
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></router-link
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item stick-row">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">Total Amount:</span>
                    </div>
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(totalOpen)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(totalReceived)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(totalTransferred)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(totalSpoiled)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(totalClose)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Multiselect from "vue-multiselect";
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "ProductionStockStatus",
  mixins: [dashboardServices],
  components: { Multiselect, PrintHeader },
  data: () => ({
    fetchUrl: "production/items/track?type=PRODUCTION",
    newFilter: {
      preference: "FIGURES",
      type: "PRODUCTION",
    },
    sources: [],
    source: null,
  }),
  computed: {
    preferenceLabel() {
      return this.newFilter.preference == "FIGURES" ? "Qty" : "Amount";
    },
    totalOpen() {
      return this.rows.reduce(
        (a, b) => a + this.getOpenQty(b) * Number(b.costPrice),
        0
      );
    },
    totalClose() {
      return this.rows.reduce(
        (a, b) => a + Number(b.currentQty) * Number(b.costPrice),
        0
      );
    },
    totalReceived() {
      return this.rows.reduce(
        (a, b) => a + Number(b.receiveQty) * Number(b.costPrice),
        0
      );
    },
    totalTransferred() {
      return this.rows.reduce(
        (a, b) => a + Number(b.transferedQty) * Number(b.costPrice),
        0
      );
    },
    totalSpoiled() {
      return this.rows.reduce(
        (a, b) => a + Number(b.spoiledQty) * Number(b.costPrice),
        0
      );
    },
    isRawMaterials() {
      return this.newFilter.type != "all";
    },
  },
  created() {
    this.newFilter.type = "all";
    this.newFilter.preference = "FIGURES";
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$helper.empty(this.loggedUser.source)) {
        this.$http.get("production/sources").then((response) => {
          this.sources = response.data.sources;
        });
      }
    });
  },
  methods: {
    getOpenQty(row) {
      const a = Number(row.currentQty) - Number(row.receiveQty);
      const result =
        a +
        Number(row.transferedQty) +
        Number(row.soldQty) +
        Number(row.spoiledQty);
      if (result < 0) {
        return 0;
      }
      return result;
    },
    handleSourceFilter(source) {
      this.newFilter.source_id = source.id;
      this.handlePaginationFilter();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
