<template>
  <div class="container-fluid">
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
    >
      <span slot="head" style="padding-top: 10px">{{
        newMode.id ? "Edit payment mode" : "Create a new payment mode"
      }}</span>
      <div slot="body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="newMode.name"
                placeholder="name"
              />
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                style="height: auto !important"
                rows="4"
                v-model="newMode.description"
                placeholder="Description(Optional)"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div slot="foot">
        <wolf-button
          caption="Save and Close"
          @clicked="handleSubmit"
          :disabler="!newMode.name"
          activator="saving_user"
          classes="btn btn-primary"
        ></wolf-button>
      </div>
    </bootstrap-modal>

    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block nk-block-lg">
          <div class="row justify-content-center">
            <div class="col-xxl-4 col-xl-4 col-lg-5">
              <h4>Payment modes</h4>
              <div class="ant-transfer-list">
                <div class="ant-transfer-list-header">
                  <span class="ant-transfer-list-header-selected">
                    <span>{{ filteredModes.length }} payment modes in total</span>
                    <span class="ant-transfer-list-header-title">
                      <button
                        class="
                          btn btn-primary btn-sm btn-icon-only
                          rounded-circle
                        "
                        type="button"
                        @click="
                          $helper.resetObjectValues(newMode);
                          toggleModal();
                        "
                        title="Add new"
                      >
                        <span class="btn-inner--icon">
                          <i class="ni ni-plus"></i>
                        </span>
                      </button>
                    </span>
                  </span>
                </div>
                <div
                  class="
                    ant-transfer-list-body ant-transfer-list-body-with-search
                  "
                >
                  <div class="ant-transfer-list-body-search-wrapper">
                    <div>
                      <input
                        placeholder="Search here"
                        type="text"
                        v-model="searchKey"
                        class="ant-input ant-transfer-list-search"
                      />
                      <span class="ant-transfer-list-search-action">
                        <i class="anticon anticon-search">
                          <svg
                            viewBox="64 64 896 896"
                            data-icon="search"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class
                          >
                            <path
                              d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"
                            />
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                  <div class="ant-transfer-list-content mt-1">
                    <ul class="list-group list-group-flush my-1">
                      <li
                        class="list-group-item pl-2"
                        v-for="(row, index) in filteredModes"
                        :key="'table' + index"
                      >
                        <div class="row align-items-center">
                          <div class="col-8">
                            <span class="mb-0">
                              <a href="javascript:void(0)">{{ row.name }}</a>
                            </span>
                            <br />
                            <small>{{ row.description }}</small>
                          </div>
                          <div class="col-auto p-0 pr-1 text-right ml-auto">
                            <button
                              class="
                                btn btn-primary btn-sm btn-icon-only
                                rounded-circle
                              "
                              type="button"
                              @click="editMode(row)"
                          
                            >
                              <span class="btn-inner--icon">
                                <i class="ni ni-pen"></i>
                              </span>
                            </button>
                            <button
                              class="
                                btn btn-outline-danger btn-sm btn-icon-only
                                rounded-circle
                                ml-1
                              "
                              type="button"
                              @click="
                                handleDeleteAlertModal({
                                  tableName: 'payments_modes',
                                  entityIdentifer: 'Payment Mode',
                                  entity: `${row.name}`,
                                  url: `payments/modes/destroy/${row.id}`,
                                })
                              "
                              title="Delete Payment mode"
                            >
                              <span class="btn-inner--icon">
                                <i class="ni ni-trash"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/_helpers/ant-design.css";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "PaymentModes",
  components: { BootstrapModal },
  data: () => ({
    newMode: {
      name: null,
      description: null,
    },
    modes: [],
    searchKey: null,
  }),
  created() {
    this.$http.get(`payments/modes`).then((response) => {
      if (response.data.status) {
        this.modes = response.data.rows;
      }
    });
  },
  computed: {
    filteredModes: function () {
      let searchKey = this.searchKey && this.searchKey.toLowerCase();
      let modes = this.modes;
      if (searchKey) {
        modes = modes.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(searchKey) > -1;
          });
        });
      }
      return modes;
    },
  },
  methods: {
    keepUploadedImage(image) {
      this.newMode.image = image.data.file_path;
    },
    handleSubmit() {
      this.$http
        .post("payments/modes/store", this.$helper.generateFormData(this.newMode))
        .then((response) => {
          if (!response.data.error) {
            if (response.data.row) {
              this.modes.push(response.data.row);
            }
            this.toggleModal();
          }
        });
    },
    editMode(row) {
      this.newMode = {
        id: row.id,
        name: row.name,
        description: row.description,
      };
      this.toggleModal();
    },
  },
};
</script>
<style scoped>
body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 0.6em;
}
body::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--base-color);
  border-radius: 1px;
}
</style>
