<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Produced Items Report
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <template v-if="rows.length">
                            <li>
                              <button
                                type="button"
                                class="btn btn-icon btn-white btn-dim btn-outline-light"
                                @click="$helper.handlePrint('.nk-block')"
                              >
                                <em class="icon ni ni-printer"></em>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                class="btn btn-white btn-dim btn-outline-light"
                                @click="handleExcelExport"
                              >
                                <svg
                                  height="18px"
                                  viewBox="0 0 384 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path
                                    d="m369.9 97.9-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-204.1c-26.5.1-48 21.6-48 48.1v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-332.1c0-12.7-5.1-25-14.1-34zm-37.8 30.1h-76.1v-76.1zm-284.1 336v-416h160v104c0 13.3 10.7 24 24 24h104v288zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3h-28.9c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3h28.8c9.3 0 15-10 10.4-18l-46.4-78c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"
                                  ></path>
                                </svg>
                              </button>
                            </li>
                          </template>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group mb-2">
                                        <div class="form-control-wrap">
                                          <div
                                            class="form-icon form-icon-right"
                                          >
                                            <em class="icon ni ni-search"></em>
                                          </div>
                                          <input
                                            type="search"
                                            class="form-control"
                                            id="default-04"
                                            placeholder="Search by item name"
                                            v-model="filterKey"
                                            @keyup="handlePaginationSearch"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-search"></em
                                          ><span>Search</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <print-header>
                  <div slot="title" class="text-center mb-1">
                    <h5 class="text-center">
                      Produced Items report /
                      <small style="font-size: 14px">{{ reportLabel }}</small>
                    </h5>
                  </div>
                </print-header>
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col"><span>Production</span></div>
                    <div class="nk-tb-col"><span>Employee</span></div>
                    <div class="nk-tb-col"><span>Item Name</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Requested</span></div>
                    <div class="nk-tb-col"><span>Produced</span></div>
                    <div class="nk-tb-col"><span>Balance</span></div>
                    <div class="nk-tb-col"><span>Total Amount</span></div>
                    <div class="nk-tb-col"><span>Amount Due</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'production' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ $helper.formatDate(item.produce_date) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        ><a href="javascript:void(0)">{{
                          item.source.name
                        }}</a></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ item.employee }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ item.product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span> {{ formatMoney(item.product.cost_price) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.requested)} ${
                          item.product.unit
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.produced)} ${
                          item.product.unit
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.balance)} ${
                          item.product.unit
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(
                          item.produced * item.product.cost_price
                        )}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(item.balance * item.product.cost_price)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li v-if="canView(permissionsPath)">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      getReceivedIngredients(item)
                                    "
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <li v-if="canEdit(permissionsPath)">
                                  <router-link
                                    :to="{
                                      name: 'ProductionStatus',
                                      query: {
                                        action: 'edit',
                                        reference: item.reference,
                                      },
                                    }"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit</span></router-link
                                  >
                                </li>
                                <li v-if="canDelete(permissionsPath)">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="handleDelete(i)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item" v-if="rows.length">
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <span class="font-weight-bolder">Grand Total</span>
                    </div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(totalProducedAmount)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${formatMoney(totalAmountDue)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col"></div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" class="d-flex align-items-center w-100">
        <span>Used Ingredients for # {{ padNumber(selectedRow.id) }} </span>
        <router-link
          class="btn btn-icon btn-sm btn-white btn-dim btn-outline-primary ml-auto"
          :to="{
            name: '',
            params: {},
          }"
          ><em class="icon ni ni-printer-fill"></em
        ></router-link>
      </span>
      <div slot="body">
        <div class="table-responsive">
          <div class="card card-full">
            <div class="nk-tb-list is-compact mt-n2">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>$/N</span></div>
                <div class="nk-tb-col"><span>Name</span></div>
                <div class="nk-tb-col"><span>Cost Price</span></div>
                <div class="nk-tb-col"><span>Requested Qty</span></div>
                <div class="nk-tb-col"><span>Amount</span></div>
                <div class="nk-tb-col" v-if="false">
                  <span>Received Qty</span>
                </div>
              </div>
              <div
                class="nk-tb-item"
                v-for="(item, i) in items"
                :key="'item' + i"
              >
                <div class="nk-tb-col">
                  <span class="tb-lead"
                    ><a href="javascript:void(0)">{{ i + 1 }}</a></span
                  >
                </div>
                <div class="nk-tb-col">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">{{ item.product.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">
                    {{
                      formatMoney(item.product.cost_price || item.product.cost)
                    }}</span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub"
                    >{{ item.requested_quantity }} {{ item.product.unit }}
                  </span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount">{{
                    formatMoney(
                      (item.product.cost_price || item.product.cost) *
                        item.requested_quantity
                    )
                  }}</span>
                </div>
                <div class="nk-tb-col" v-if="false">
                  <span>{{ item.received_quantity }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import BootstrapModal from "@/components/BootstrapModal.vue";
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "ProducedItems",
  mixins: [dashboardServices],
  components: { BootstrapModal, PrintHeader },
  data: () => ({
    fetchUrl: "reports/production-status",
    items: [],
    selectedRow: {},
  }),
  computed: {
    totalProducedAmount() {
      return this.rows.reduce(
        (a, b) => a + b.produced * b.product.cost_price,
        0
      );
    },
    totalAmountDue() {
      return this.rows.reduce(
        (a, b) => a + b.balance * b.product.cost_price,
        0
      );
    },
  },
  created() {
    this.permissionsPath = "production.submenus.make_production";
  },
  methods: {
    getReceivedIngredients(row) {
      this.$http
        .get("production/used-ingredients/" + row.id)
        .then((response) => {
          this.selectedRow = row;
          this.items = response.data.items;
          this.toggleModal();
        });
    },
    handleDelete(i) {
      let row = this.rows[i];
      this.$alert({
        title: "Removing from production record",
        content: `Are you sure you want to delete this production record (${row.reference})?`,
        classes: "btn btn-danger",
        actionText: "Continue",
        closeText: "Discard",
        action: () => {
          this.$http
            .post(
              "production/status/delete",
              this.$helper.generateFormData({
                production_id: row.id,
                action: "DELETE",
              })
            )
            .then(() => {
              this.rows.splice(i, 1);
              this.$store.commit("SET_FLASH_MESSAGE", {
                type: "success",
                message: "Deleted successfully",
              });
            });
        },
      });
    },
  },
};
</script>
