<template>
  <div class="modal-open">
    <div class="modal-backdrop fade show">&nbsp;</div>
    <div class="modal fade d-block show">
      <div class="modal-dialog modal-xlg p-0">
        <div class="modal-content">
          <div class="modal-header ember-view">
            <h5 class="modal-title">Add Items in Bulk</h5>
            <span
              class="close no-outline cursor-pointer"
              @click="$emit('closed')"
            >
              ×
            </span>
          </div>
          <form>
            <div class="modal-body p-0 clearfix bulk-select-item">
              <div class="autocomplete-section">
                <div class="item-autocomplete ember-view">
                  <div class="dropdown show ac-dropdown ember-view d-block">
                    <div class="textarea-section">
                      <input
                        type="search"
                        class="form-control rounded"
                        placeholder="Search..."
                        v-model="searchKey"
                      />
                    </div>
                    <loading-dots v-if="showLoader" />
                    <div class="ac-dropdown-menu" v-else>
                      <ul class="ac-dropdown-results input-block-level">
                        <li
                          class="ac-option inactive"
                          v-for="item in filteredRows"
                          :key="$helper.generateRandomText(5) + item.id"
                          :class="{
                            selected: selectedItemIds.includes(item.id),
                          }"
                          @click="handleSelectedItem(item)"
                        >
                          <span tabindex="-1">
                            <div class="autocomplete-option" title="farine">
                              <div class="ac-name-rate-sku">
                                <div class="ac-name">{{ item.name }}</div>
                                <div class="grey-text"></div>
                              </div>
                              <div
                                class="ac-stock"
                                :class="{ invisible: !showQuantity }"
                              >
                                <div class="ac-stock-label">Stock on Hand</div>
                                <div class="stock-available">
                                  {{ $helper.formatNumber(item.quantity) }}
                                  {{ item.unit }}
                                </div>
                              </div>
                              <div class="svg-tick">
                                <div
                                  class="tick-filled"
                                  v-if="selectedItemIds.includes(item.id)"
                                >
                                  <svg
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    class="icon icon-xlg"
                                  >
                                    <path
                                      d="M256.36.21C115 .21.36 114.83.36 256.21s114.62 256 256 256 256-114.61 256-256-114.61-256-256-256zm134.32 225.48L254.37 362a22.5 22.5 0 01-31.82 0l-70.79-70.79a22.5 22.5 0 1131.82-31.82l54.88 54.88 120.4-120.4a22.5 22.5 0 0131.82 31.82z"
                                      transform="translate(-.36 -.21)"
                                    ></path>
                                    <path
                                      d="M358.86 193.87l-120.4 120.4-54.88-54.88a22.5 22.5 0 10-31.82 31.82L222.55 362a22.5 22.5 0 0031.82 0l136.31-136.31a22.5 22.5 0 00-31.82-31.82z"
                                      transform="translate(-.36 -.21)"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                </div>
                                <div class="hover-tick d-inline" v-else>
                                  <svg
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    class="icon icon-xlg"
                                  >
                                    <path
                                      d="M256.36.21C115 .21.36 114.83.36 256.21s114.62 256 256 256 256-114.61 256-256-114.61-256-256-256zm134.32 225.48L254.37 362a22.5 22.5 0 01-31.82 0l-70.79-70.79a22.5 22.5 0 1131.82-31.82l54.88 54.88 120.4-120.4a22.5 22.5 0 0131.82 31.82z"
                                      transform="translate(-.36 -.21)"
                                    ></path>
                                    <path
                                      d="M358.86 193.87l-120.4 120.4-54.88-54.88a22.5 22.5 0 10-31.82 31.82L222.55 362a22.5 22.5 0 0031.82 0l136.31-136.31a22.5 22.5 0 00-31.82-31.82z"
                                      transform="translate(-.36 -.21)"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="selected-items-section">
                <h5 class="items-heading text-regular">
                  Selected Items
                  <div class="d-inline-block">
                    <span class="badge badge-circle">{{ items.length }}</span>
                  </div>
                </h5>
                <div class="items-list">
                  <div
                    class="selected-item"
                    v-for="(row, i) in items"
                    :key="'items' + i"
                  >
                    <span class="item-name"> {{ row.item.name }}</span>
                    <span class="quantity-section" v-if="incrementQuantity"
                      ><div class="ember-view">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span
                              class="input-group-text cursor-pointer decrement-button"
                              @click="decrementQty(i)"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                class="icon icon-xsmall"
                              >
                                <path
                                  d="M483.7 228H28.3c-15.5 0-28 12.5-28 28s12.5 28 28 28h455.3c15.5 0 28-12.5 28-28s-12.5-28-27.9-28z"
                                ></path></svg
                            ></span>
                          </div>
                          <input
                            class="ember-text-field text-right ember-view form-control"
                            type="number"
                            v-model="row.quantity"
                          />
                          <div class="input-group-append">
                            <span
                              class="input-group-text cursor-pointer increment-button"
                              @click="incrementQty(i)"
                              ><svg
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 512 512"
                                xml:space="preserve"
                                class="icon icon-xsmall"
                              >
                                <path
                                  d="M484 227H284V28c0-15.5-12.5-28-28-28s-28 12.5-28 28v199H28c-15.5 0-28 12.5-28 28v1c0 15.5 12.5 28 28 28h200v200c0 15.5 12.5 28 28 28s28-12.5 28-28V284h200c15.5 0 28-12.5 28-28v-1c0-15.5-12.5-28-28-28z"
                                ></path></svg
                            ></span>
                          </div>
                        </div></div
                    ></span>
                    <span class="cancel-item-section" @click="removeItem(i)"
                      ><i class="action cancel-circled"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          class="icon align-middle"
                        >
                          <path
                            d="M256 32c30.3 0 59.6 5.9 87.2 17.6 26.7 11.3 50.6 27.4 71.2 48s36.7 44.5 48 71.2c11.7 27.6 17.6 56.9 17.6 87.2s-5.9 59.6-17.6 87.2c-11.3 26.7-27.4 50.6-48 71.2s-44.5 36.7-71.2 48C315.6 474.1 286.3 480 256 480s-59.6-5.9-87.2-17.6c-26.7-11.3-50.6-27.4-71.2-48s-36.7-44.5-48-71.2C37.9 315.6 32 286.3 32 256s5.9-59.6 17.6-87.2c11.3-26.7 27.4-50.6 48-71.2s44.5-36.7 71.2-48C196.4 37.9 225.7 32 256 32m0-32C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0z"
                          ></path>
                          <path
                            d="M284.3 250.3l62.3-62.3c6.2-6.2 6.2-16.2 0-22.4l-.2-.2c-6.2-6.2-16.2-6.2-22.4 0l-62.3 62.3c-3.1 3.1-8.2 3.1-11.3 0L188 165.4c-6.2-6.2-16.2-6.2-22.4 0l-.2.2c-6.2 6.2-6.2 16.2 0 22.4l62.3 62.3c3.1 3.1 3.1 8.2 0 11.3L165.4 324c-6.2 6.2-6.2 16.2 0 22.4l.2.2c6.2 6.2 16.2 6.2 22.4 0l62.3-62.3c3.1-3.1 8.2-3.1 11.3 0l62.3 62.3c6.2 6.2 16.2 6.2 22.4 0l.2-.2c6.2-6.2 6.2-16.2 0-22.4l-62.3-62.3c-3-3.2-3-8.2.1-11.4z"
                          ></path></svg></i
                    ></span>
                  </div>
                </div>
                <div class="items-footer">
                  <button
                    class="btn btn-primary ember-view"
                    type="button"
                    @click="$emit('confirmed', items)"
                    :disabled="!items.length"
                  >
                    Add Items
                  </button>
                  <button
                    class="btn btn-secondary ml-2"
                    @click="$emit('closed')"
                  >
                    Cancel
                  </button>
                  <div class="d-inline-block"></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingDots from "@/components/LoadingDots.vue";
export default {
  name: "BulkItemsSelect",
  props: {
    fetchUrl: { type: String, required: true },
    dataKey: { type: String, required: false },
    incrementQuantity: { type: Boolean, default: true },
    showQuantity: { type: Boolean, default: true },
  },
  components: { LoadingDots },
  data: () => ({
    items: [],
    selectedItemIds: [],
    rows: [],
    searchKey: null,
  }),
  computed: {
    loadedItems() {
      return this.rows.map((row) => row.id);
    },
    showLoader() {
      return (
        !this.rows.length &&
        this.$store.state.requestFlag == "GET_BULK_SELECT_ASSETS"
      );
    },
    selectedItems() {
      return this.items.map((item) => item.id);
    },
    filteredRows() {
      let rows = this.rows;
      const searchKey = this.searchKey && this.searchKey.toLowerCase();
      if (searchKey) {
        rows = rows.filter(
          (row) => String(row.name).toLowerCase().indexOf(searchKey) > -1
        );
        if (!rows.length || false) {
          if (searchKey.trim().length > 2) {
            this.$store.commit("SET_REQUEST_FLAG", "GET_BULK_SELECT_ASSETS");
            (async (searchKey) => {
              let response = await this.$http.get(
                `${this.fetchUrl}?query=${searchKey}`
              );
              const fetchedRows = response.data.rows || response.data || [];
              const newRows = fetchedRows.filter(
                (row) => !this.loadedItems.includes(row.id)
              );
              this.rows = [...newRows, ...this.rows];
            })(searchKey);
          }
        }
      }
      return rows;
    },
  },
  beforeMount() {
    this.$store.commit("SET_REQUEST_FLAG", "GET_BULK_SELECT_ASSETS");
    this.$http.get(this.fetchUrl).then((response) => {
      this.rows = this.dataKey ? response.data[this.dataKey] : response.data;
    });
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        this.items.push({ item: item, quantity: 1 });
        this.selectedItemIds.push(item.id);
      } else {
        let index = this.selectedItemIds.indexOf(item.id);
        this.removeItem(index);
      }
    },
    incrementQty(i, max = null) {
      if (max) {
        if (this.items[i].quantity < max)
          this.items[i].quantity = Number(this.items[i].quantity) + 1;
      } else this.items[i].quantity = Number(this.items[i].quantity) + 1;
    },
    decrementQty(i) {
      if (this.items[i].quantity > 1) {
        this.items[i].quantity = Number(this.items[i].quantity) - 1;
      }
    },
  },
};
</script>
<style scoped>
.h3,
h3 {
  font-size: 1.5rem;
}
.ac-dropdown .ac-dropdown-menu {
  position: absolute;
  width: 100%;
}
.ac-dropdown .ac-dropdown-menu .ac-dropdown-results {
  position: relative;
  display: block;
  width: 100%;
  min-height: 0;
  max-height: 200px;
  min-width: 0;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border-top: none;
  border-bottom: none;
}
.autocomplete-option {
  width: 100%;
  padding: 2px 0;
  line-height: 1.2;
  white-space: normal;
}
.ac-dropdown-results:after,
.autocomplete-option::after {
  content: "";
}
.item-autocomplete .autocomplete-option {
  display: flex;
}
.item-autocomplete .item-description {
  border: none;
  box-shadow: none;
  resize: none;
}
.item-autocomplete .item-description:focus {
  box-shadow: none;
}
.item-autocomplete .ac-dropdown {
  z-index: 1;
}
.item-autocomplete .ac-dropdown .ac-dropdown-menu {
  min-width: 600px;
}
.item-autocomplete .ac-dropdown .ac-dropdown-menu .ac-dropdown-results {
  max-height: 275px;
  word-wrap: break-word;
}
.item-autocomplete .ac-dropdown .ac-dropdown-menu .active .autocomplete-option,
.item-autocomplete .ac-dropdown .ac-dropdown-menu .active .grey-text,
.item-autocomplete .ac-dropdown .ac-dropdown-menu .active .stock-unavailable {
  color: #fff;
}
.item-autocomplete .grey-text {
  color: #777;
}
.item-autocomplete .stock-unavailable {
  color: #ff3e3e;
}
.item-autocomplete .stock-available {
  color: #029745;
}
.item-autocomplete .autocomplete-option {
  color: #101010;
}
.item-autocomplete .autocomplete-option .ac-name-rate-sku {
  width: 65%;
  padding-right: 10px;
}
.item-autocomplete .autocomplete-option .ac-name-rate-sku .ac-name {
  padding-bottom: 4px;
  line-height: 20px;
  font-size: 15px;
}
.item-autocomplete .autocomplete-option .ac-stock {
  width: 35%;
  line-height: 21px;
  text-align: right;
  font-size: 13px;
}
svg.icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
}
svg.icon.icon-xsmall {
  height: 10px;
  width: 10px;
}
svg.icon.icon-xlg {
  height: 20px;
  width: 20px;
}
.badge-pill {
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 10rem;
}
.inactive {
  color: #777;
}
.cursor-pointer {
  cursor: pointer;
}
.text-regular,
h3,
h4 {
  font-weight: 400;
}
.modal-xlg {
  max-width: 1030px;
}
.no-outline {
  outline: 0 !important;
}
.bulk-select-item .autocomplete-section {
  width: 45%;
  float: left;
  padding-top: 20px;
  background-color: #fafcff;
  border-right: 1px solid #eee;
}
.bulk-select-item .autocomplete-section .textarea-section {
  height: 50px;
  padding: 0 20px;
}
.bulk-select-item .autocomplete-section .textarea-section textarea {
  border: 1px solid #80b2f8;
  height: 30px;
  min-height: 34px;
}
.bulk-select-item .autocomplete-section .textarea-section textarea:focus {
  border-color: #0f7aff;
  box-shadow: 0 2px 4px 0 rgba(26, 42, 88, 0.11);
}
.bulk-select-item
  .autocomplete-section
  .item-autocomplete
  .ac-dropdown
  .ac-dropdown-menu {
  min-width: 100%;
}
.bulk-select-item
  .autocomplete-section
  .item-autocomplete
  .ac-dropdown
  .ac-dropdown-menu
  .active
  .autocomplete-option,
.bulk-select-item
  .autocomplete-section
  .item-autocomplete
  .ac-dropdown
  .ac-dropdown-menu
  .active
  .grey-text,
.bulk-select-item
  .autocomplete-section
  .item-autocomplete
  .ac-dropdown
  .ac-dropdown-menu
  .active
  .stock-unavailable {
  color: #4382ff;
}
.bulk-select-item
  .autocomplete-section
  .item-autocomplete
  .ac-dropdown
  .ac-dropdown-menu
  .autocomplete-option
  .ac-name-rate-sku {
  width: 60%;
}
.bulk-select-item
  .autocomplete-section
  .item-autocomplete
  .ac-dropdown
  .ac-dropdown-menu
  .autocomplete-option
  .ac-stock {
  width: 30%;
  font-size: 13px;
}
.bulk-select-item .autocomplete-section .item-autocomplete .svg-tick {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
}
.bulk-select-item .autocomplete-section .ac-dropdown-menu {
  background-color: #fafcff;
  min-height: 420px;
  border-right: 1px solid #eee;
}
.bulk-select-item .autocomplete-section .ac-dropdown-menu .ac-dropdown-results {
  list-style: none;
  max-height: 430px;
}
.bulk-select-item .autocomplete-section .ac-dropdown-menu .ac-option {
  padding: 7px 10px 7px 20px;
  color: #000;
  border-radius: 2px;
  cursor: pointer;
}
.bulk-select-item
  .autocomplete-section
  .ac-dropdown-menu
  .ac-option
  .hover-tick {
  display: none;
}
.bulk-select-item
  .autocomplete-section
  .ac-dropdown-menu
  .ac-option
  .tick-filled
  svg {
  vertical-align: middle;
  fill: #22dd2e;
}
.bulk-select-item .autocomplete-section .ac-dropdown-menu .ac-option.active,
.ac-option:hover {
  background-color: #f1f4f9 !important;
}
.bulk-select-item .autocomplete-section .selected {
  background-color: inherit;
}
.bulk-select-item .selected-items-section {
  width: 55%;
  float: left;
  height: 500px;
}
.bulk-select-item .selected-items-section .items-heading {
  padding: 10px 0 5px 10px;
  border-bottom: 1px solid #eee;
  margin: 0;
}
.bulk-select-item .selected-items-section .items-list {
  position: relative;
  padding: 10px 0 10px 15px;
  height: 370px;
  overflow-y: auto;
}
.bulk-select-item .selected-items-section .items-list .selected-item {
  padding: 7px 0;
}
.bulk-select-item
  .selected-items-section
  .items-list
  .selected-item:hover
  .cancel-item-section {
  display: inline-block;
}
.bulk-select-item
  .selected-items-section
  .items-list
  .selected-item
  .item-name {
  display: inline-block;
  width: 69%;
  vertical-align: middle;
}
.bulk-select-item
  .selected-items-section
  .items-list
  .selected-item
  .quantity-section {
  display: inline-block;
  width: 22%;
  vertical-align: middle;
}
.bulk-select-item
  .selected-items-section
  .items-list
  .selected-item
  .quantity-section
  .input-group-text {
  border-color: #ddd;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 9px;
  background-color: inherit;
}
.bulk-select-item
  .selected-items-section
  .items-list
  .selected-item
  .quantity-section
  .input-group-text:hover {
  background-color: #f5f5f5;
}
.bulk-select-item
  .selected-items-section
  .items-list
  .selected-item
  .quantity-section
  .decrement-button {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.bulk-select-item
  .selected-items-section
  .items-list
  .selected-item
  .quantity-section
  .increment-button {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.bulk-select-item
  .selected-items-section
  .items-list
  .selected-item
  .cancel-item-section {
  padding-left: 10px;
  width: 5%;
  vertical-align: middle;
  display: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bulk-select-item
  .selected-items-section
  .items-list
  .selected-item
  .cancel-item-section
  svg {
  fill: #ff641b;
}
.bulk-select-item .selected-items-section .items-footer {
  padding: 20px 0 10px 15px;
  border-top: 1px solid #eee;
}
.quantity-section input {
  height: 30px;
  border-color: #ddd;
}
.quantity-section input:focus {
  border-color: #80b2f8;
  box-shadow: unset;
}
.cancel-circled {
  color: #fab2b1;
  cursor: pointer;
}
.item-autocomplete .ac-dropdown {
  z-index: 5;
}
.selected {
  background-color: #fff;
}
.tick-filled svg {
  vertical-align: middle;
  fill: #00b050;
}

.hover-tick svg {
  vertical-align: middle;
  fill: #c2cbd6;
}
</style>
