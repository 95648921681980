<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">User Roles</h3>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <div class="toggle-expand-content" >
                  <ul class="nk-block-tools g-1 float-right">
                    <li>
                      <router-link
                        :to="{
                          name: 'RolesCreator',
                          params: { action: 'new' },
                        }"
                        class="btn btn-primary d-md-inline-flex"
                        ><em class="icon ni ni-plus"></em
                        ><span>New</span></router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="nk-tb-list is-compact mb-3">
            <div class="nk-tb-item nk-tb-head">
              <div class="nk-tb-col"><span>S/N</span></div>
              <div class="nk-tb-col"><span>Name</span></div>
              <div class="nk-tb-col"><span>Description</span></div>
              <div class="nk-tb-col"><span>Action</span></div>
            </div>
            <div
              class="nk-tb-item"
              v-for="(row, i) in rows"
              :key="'role' + i"
              loading="lazy"
            >
              <div class="nk-tb-col">
                <span> {{ i + 1 }} </span>
              </div>
              <div class="nk-tb-col">
                <span class="tb-lead"> {{ row.name }} </span>
              </div>
              <div class="nk-tb-col">
                <span> {{ row.description }} </span>
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1 my-n1">
                  <li class="mr-n1">
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger"
                        data-toggle="dropdown"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <router-link
                              :to="{
                                name: 'RolesCreator',
                                params: { action: 'edit', id: row.id },
                              }"
                              ><em class="icon ni ni-edit"></em
                              ><span>Edit</span></router-link
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="openBranchPermissionsModal(row)"
                            >
                              <em class="icon ni ni-lock"></em>
                              <span>Branch Permissions</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                handleDeleteAlertModal({
                                  tableName: 'permissions',
                                  entityIdentifer: 'permissions',
                                  entity: `${row.name || ''}`,
                                  url: `settings/users/permissions/delete/${row.id}`,
                                  callback: () => {
                                    rows.splice(i, 1);
                                  },
                                })
                              "
                              ><em class="icon ni ni-trash"></em
                              ><span>Delete</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BranchPermissions
      :role="selectedRole"
      @closed="
        toggleModal();
        selectedRole = null;
      "
      v-if="modalOpen && selectedRole"
      @saved="updateSavedRow"
    />
  </section>
</template>
<script>
import BranchPermissions from "./BranchPermissions.vue";
export default {
  name: "UserRole",
  components: { BranchPermissions },
  data: () => ({
    rows: [],
    selectedRole: null,
  }),
  beforeCreate() {
    this.$http.get("settings/users/permissions").then((response) => {
      this.rows = response.data.rows;
    });
  },
  methods: {
    openBranchPermissionsModal(role) {
      this.selectedRole = role;
      this.toggleModal();
    },
    updateSavedRow(permissions) {
      const index = this.rows.findIndex(
        (row) => row.id == this.selectedRole.id
      );
      if (index) {
        this.rows[index].branch_permissions = permissions;
      }
      this.selectedRole = null;
      this.toggleModal();
    },
  },
};
</script>
