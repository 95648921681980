<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Accounts Transfers Report
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <template v-if="rows.length">
                            <li>
                              <button
                                type="button"
                                class="btn btn-icon btn-white btn-dim btn-outline-light"
                                @click="$helper.handlePrint('.nk-block')"
                              >
                                <em class="icon ni ni-printer"></em>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                class="btn btn-white btn-dim btn-outline-light"
                                @click="handleExcelExport"
                              >
                                <svg
                                  height="18px"
                                  viewBox="0 0 384 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path
                                    d="m369.9 97.9-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-204.1c-26.5.1-48 21.6-48 48.1v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-332.1c0-12.7-5.1-25-14.1-34zm-37.8 30.1h-76.1v-76.1zm-284.1 336v-416h160v104c0 13.3 10.7 24 24 24h104v288zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3h-28.9c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3h28.8c9.3 0 15-10 10.4-18l-46.4-78c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"
                                  ></path>
                                </svg>
                              </button>
                            </li>
                          </template>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <print-header>
                  <div slot="title" class="text-center mb-1">
                    <h5 class="text-center">
                      Accounts Transfers Report /
                      <small style="font-size: 14px">{{ reportLabel }}</small>
                    </h5>
                  </div>
                </print-header>
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Sender</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Receiver</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Description</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="fw-bold">{{
                        $helper.formatDate(row.created_at)
                      }}</span>
                    </div>

                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.sender.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead"> {{ row.receiver.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        formatMoney(row.amount)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="">{{ row.description }} </span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.creator.name }} </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  components: { PrintHeader },
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "reports/accounts/transfers",
    newFilter: {},
    account: {},
  }),
  computed: {},
  methods: {},
};
</script>
