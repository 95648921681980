<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      Stock Adjustments
                      <!-- <small>
                        <a href="javascript:void(0)">({{ newFilter.from }})</a>
                      </small> -->
                    </h5>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} adjustments.</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            class="nk-block-tools-opt"
                            v-if="canCreate(permissionsPath)"
                          >
                            <router-link
                              :to="{
                                name: isProductionItems
                                  ? 'NewProductionAdjustment'
                                  : isBranchItems
                                  ? 'NewBranchAdjustment'
                                  : 'NewInventoryAdjustment',
                                params: { action: 'new' },
                              }"
                              class="btn btn-primary d-md-inline-flex"
                            >
                              <em class="icon ni ni-plus"></em><span>New</span>
                            </router-link>
                          </li>
                          <li
                            style="width: 200px"
                            v-if="
                              isProductionItems &&
                              $helper.empty(loggedUser.source)
                            "
                          >
                            <multiselect
                              v-model="source"
                              :options="sources"
                              placeholder="Select Production"
                              label="name"
                              track-by="name"
                              @select="filterStock"
                            ></multiselect>
                          </li>

                          <li
                            style="width: 200px"
                            v-if="
                              isBranchItems && $helper.empty(loggedUser.branch)
                            "
                          >
                            <multiselect
                              v-model="branch"
                              :options="branches"
                              placeholder="Select Branch"
                              label="name"
                              track-by="name"
                              @select="filterByBranch"
                            ></multiselect>
                          </li>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Reason</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Description</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Reference</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created Time</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Items</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <template v-if="rows.length">
                    <div
                      class="nk-tb-item"
                      v-for="(row, i) in rows"
                      :key="'row' + i"
                    >
                      <div class="nk-tb-col">
                        <span class="tb-lead">{{
                          $helper.formatDate(row.adjustment_date)
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-lead">{{ row.reason }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span>{{ row.description }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-lead"
                          >#{{ $helper.generateVoucherNo(row.id) }}</span
                        >
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-lead" v-if="row.creator">{{
                          row.creator.name
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span>{{ $helper.formatTime(row.created_at) }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <a
                          href="javascript:void(0)"
                          @click.prevent="getAdjustedItems(row.reference)"
                          >{{ $helper.formatNumber(row.items) }}</a
                        >
                      </div>
                      <div class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1">
                          <li>
                            <div class="dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-icon btn-trigger"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-more-h"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <ul class="link-list-opt no-bdr">
                                  <li>
                                    <a
                                      href="javascript:void(0)"
                                      @click.prevent="
                                        getAdjustedItems(row.reference)
                                      "
                                      ><em class="icon ni ni-eye"></em
                                      ><span>View Details</span></a
                                    >
                                  </li>
                                  <li></li>
                                  <li v-if="canEdit(permissionsPath)">
                                    <router-link
                                      :to="{
                                        name: isProductionItems
                                          ? 'NewProductionAdjustment'
                                          : isBranchItems
                                          ? 'NewBranchAdjustment'
                                          : 'NewInventoryAdjustment',
                                        params: {
                                          action: 'edit',
                                          reference: row.reference,
                                        },
                                      }"
                                      ><em class="icon ni ni-edit"></em
                                      ><span>Edit</span></router-link
                                    >
                                  </li>
                                  <li v-if="canDelete(permissionsPath)">
                                    <a
                                      href="javascript:void(0)"
                                      @click.prevent="
                                        handleDeleteAlertModal({
                                          tableName: 'adjustment',
                                          entityIdentifer: 'Stock Adjustment',
                                          entity: `#${row.reference}`,
                                          url: `stock/adjustments/${row.id}/delete`,
                                          callback: () => {
                                            rows.splice(i, 1);
                                          },
                                        })
                                      "
                                      ><em class="icon ni ni-trash"></em
                                      ><span>Delete</span></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-default'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >ADJUSTMENT
        <a href="javascript:void(0)">#{{ record.reference }}</a></span
      >
      <div slot="body">
        <div class="table-responsive">
          <table class="table table-striped-">
            <thead>
              <tr>
                <th>$/N</th>
                <th>Item Name</th>
                <th>Before</th>
                <th>Adjusted</th>
                <th>After</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in items" :key="'item' + i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.product.name }}</td>
                <td>
                  {{ $helper.formatNumber(item.details.current_qty) }}
                  <span>{{ item.product.unit }}</span>
                </td>
                <td>
                  {{ $helper.formatNumber(item.details.adjusted) }}
                  <span>{{ item.product.unit }}</span>
                </td>
                <td>
                  <span class="tb-sub tb-amount">
                    {{ $helper.formatNumber(item.details.quantity) }}
                    <span>{{ item.product.unit }}</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Multiselect from "vue-multiselect";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "SalesReport",
  mixins: [dashboardServices],
  components: { Multiselect, BootstrapModal },
  data: () => ({
    record: {},
    items: [],
    sources: [],
    source: null,
    branch: null,
  }),
  computed: {
    isProductionItems() {
      return this.$route.name == "ProductionAdjustments";
    },
    isBranchItems() {
      return this.$route.name == "BranchAdjustments";
    },
    branches() {
      return this.$store.state.branches || [];
    },
  },
  watch: {
    $route() {
      location.reload();
    },
  },
  created() {
    this.permissionsPath = "inventory.submenus.adjustments";
    this.fetchUrl = this.isProductionItems
      ? "stock/adjustments/PRODUCTION"
      : this.isBranchItems
      ? "stock/adjustments/BRANCH_ITEMS"
      : "stock/adjustments";
    this.handlePaginationSearch();
  },
  methods: {
    filterStock(source) {
      if (!this.$helper.empty(source)) {
        this.newFilter.source = source.id;
        this.handlePaginationFilter();
      }
    },
    filterByBranch(branch) {
      if (!this.$helper.empty(branch)) {
        this.newFilter.branch = branch.id;
        this.handlePaginationFilter();
      }
    },
    getAdjustedItems(reference) {
      this.items = [];
      this.$http
        .get(`stock/adjustments/${reference}/details`)
        .then((response) => {
          this.items = response.data.items;
          this.record = response.data.record;
          this.toggleModal();
        });
    },
  },
  mounted() {
    if (this.isProductionItems) {
      this.$http.get("production/sources").then((response) => {
        this.sources = response.data.sources;
      });
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
