<template>
  <div
    class="
      card-aside card-aside-left
      user-aside
      toggle-slide toggle-slide-left toggle-break-lg toggle-screen-lg
    "
  >
    <div class="card-inner-group">
      <div class="simplebar-wrapper" style="margin: 0px">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div class="simplebar-offset" style="right: 0px; bottom: 0px">
            <div
              class="simplebar-content-wrapper"
              tabindex="0"
              role="region"
              aria-label="scrollable content"
              style="height: auto; overflow: hidden"
            >
              <div class="simplebar-content" style="padding: 0px">
                <div class="card-inner">
                  <div class="user-card">
                    <avatar
                      :name="`${user.first_name} ${user.last_name || ''}`"
                    ></avatar>
                    <div class="user-info">
                      <span class="lead-text">{{ user.name }}</span
                      ><span class="sub-text">{{ user.email }}</span>
                    </div>
                    <div class="user-action">
                      <div class="dropdown">
                        <a
                          class="btn btn-icon btn-trigger me-n2"
                          data-bs-toggle="dropdown"
                          href="#"
                          aria-expanded="false"
                          ><em class="icon ni ni-more-v"></em
                        ></a>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <a href="javascript:void(0)"
                                ><em class="icon ni ni-camera-fill"></em
                                ><span>Change Photo</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><em class="icon ni ni-edit-fill"></em
                                ><span>Update Profile</span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-inner">
                  <div class="user-account-info py-0">
                    <h6 class="overline-title-alt">Last Login</h6>
                    <p>
                      {{ dayjs(login.login_time).format("MMM D, YYYY h:mm A") }}
                    </p>
                    <h6 class="overline-title-alt">Login IP</h6>
                    <p>{{ login.ip }}</p>
                  </div>
                </div>
                <div class="card-inner p-0">
                  <ul class="link-list-menu nav nav-tabs" role="tablist">
                    <li>
                      <router-link :to="{ name: 'AccountProfile' }"
                        ><em class="icon ni ni-user-fill-c"></em
                        ><span>Personal Infomation</span></router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'AccountSecurity' }"
                        ><em class="icon ni ni-lock-alt-fill"></em
                        ><span>Security Settings</span></router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'LoginHistory' }"
                        ><em class="icon ni ni-activity-round-fill"></em
                        ><span>Login History</span></router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="simplebar-placeholder"
          style="width: auto; height: 20rem"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Avatar from "@/components/Avatar.vue";
import dayjs from "dayjs";
//import { mapState } from "vuex";
export default {
  name: "ProfileSidebar",
  mixins: [dashboardServices],
  components: { Avatar },
  data: () => ({
    login: {},
    dayjs: dayjs,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {
    this.$http.get("auth/profile").then((response) => {
      this.login = response.data.login;
    });
  },
  methods: {
    setActiveMenu() {
      let el = this.$el.querySelector(
        ".router-link-exact-active.router-link-active"
      );
      if (el) el.classList.add("active");
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.setActiveMenu();
      });
    },
  },
  mounted() {
    this.setActiveMenu();
  },
};
</script>
