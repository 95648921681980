<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Users</h3>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} users.</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                id="default-04"
                                placeholder="Search by name"
                                v-model="filterKey"
                                @keyup="handlePaginationSearch"
                              />
                            </div>
                          </li>
                          <li v-if="false">
                            <div class="dropdown">
                              <a
                                href="javascript:void(0)"
                                class="
                                  dropdown-toggle dropdown-indicator
                                  btn btn-outline-light btn-white
                                "
                                data-toggle="dropdown"
                                >Status</a
                              >
                              <div class="dropdown-menu dropdown-menu-right">
                                <ul class="link-list-opt no-bdr">
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>Actived</span></a
                                    >
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>Disabled</span></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                () => {
                                  user = {};
                                  toggleModal();
                                }
                              "
                              class="btn btn-icon btn-primary d-md-none"
                              ><em class="icon ni ni-plus"></em></a
                            ><a
                              href="javascript:void(0)"
                              @click.prevent="
                                () => {
                                  user = {};
                                  toggleModal();
                                }
                              "
                              class="btn btn-primary d-md-inline-flex"
                              ><em class="icon ni ni-plus"></em
                              ><span>Add</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list nk-tb-ulist is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid"
                        /><label class="custom-control-label" for="uid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Employee Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Phone</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Gender</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Role</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Branch</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Address</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Status</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="
                                dropdown-toggle
                                btn btn-icon btn-trigger
                                mr-n1
                              "
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-na"></em
                                    ><span>Disable Selected</span></a
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Selected</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(user, i) in rows"
                    :key="'user' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid1"
                        /><label
                          class="custom-control-label"
                          for="uid1"
                        ></label>
                      </div>
                    </div>

                    <div class="nk-tb-col">
                      <div class="user-card">
                        <avatar
                          :name="`${user.first_name} ${user.last_name || ''}`"
                        ></avatar>
                        <div class="user-info">
                          <span class="lead-text">{{
                            `${user.first_name} ${user.last_name || ""}`
                          }}</span
                          ><span class="sub-text">{{
                            user.email || "N/A"
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{ user.phone || "N/A" }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{ user.gender || "N/A" }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        !$helper.empty(user.role) ? user.role.name : "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{
                        !$helper.empty(user.branch) ? user.branch.name : "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{ user.address || "N/A" }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        !$helper.empty(user.creator) ? user.creator.name : "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-status text-success"
                        v-if="$helper.empty(user.deleted_at)"
                        >Active</span
                      >
                      <span class="tb-status text-danger" v-else
                        >Suspended</span
                      >
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="handleEdit(user)"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit</span></a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="openPasswordEditor(user)"
                                    ><em class="icon ni ni-shield-star"></em
                                    ><span>Reset Pass</span></a
                                  >
                                </li>
                                <li v-if="$helper.empty(user.deleted_at)">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      $alert({
                                        title: 'Changing User Status',
                                        content: `Are you sure you want to suspend this user : ${user.name}`,
                                        classes: 'btn btn-danger',
                                        actionText: 'Continue',
                                        closeText: 'Discard',
                                        action: () => {
                                          changeUserStatus(user, i);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-na"></em
                                    ><span>Suspend</span></a
                                  >
                                </li>
                                <li v-else>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      $alert({
                                        title: 'Changing User Status',
                                        content: `Are you sure you want to activated this user : ${user.name}`,
                                        classes: 'btn btn-primary',
                                        actionText: 'Continue',
                                        closeText: 'Discard',
                                        action: () => {
                                          changeUserStatus(user, i);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-check-circle"></em
                                    ><span>Activate</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <user-creator
      @closed="toggleModal"
      @saved="handleNewRecord"
      v-if="modalOpen"
      :branches="branches"
      :roles="roles"
      :user="user"
    ></user-creator>
    <bootstrap-modal
      @close="passwordModal = false"
      style="display: block"
      :modalSize="'default-modal'"
      :showFooter="false"
      v-if="passwordModal"
    >
      <span slot="head" style="padding-top: 10px">
        Reset Password for user:
        <a href="javascript:void(0)">{{ user.name }}</a>
      </span>
      <div slot="body">
        <form class="forms-sample">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-3">
                <label class="form-label">New Password</label>
                <input
                  type="Password"
                  v-model="credentials.password"
                  name="Password"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('Password') }"
                  class="form-control"
                  placeholder="Password"
                />
                <div class="invalid-feedback">
                  {{ errors.first("Password") }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-3">
                <label class="form-label">Confirm Password</label>
                <input
                  type="Password"
                  v-model="credentials.new_password"
                  name="Confirm Password"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('Confirm Password') }"
                  class="form-control"
                  placeholder="Password"
                />
                <div class="invalid-feedback">
                  {{ errors.first("Confirm Password") }}
                </div>
              </div>
            </div>
          </div>

          <div class="my-3">
            <wolf-button
              classes="btn btn-primary"
              caption="Save and Close"
              @clicked="changePassword"
              :disabler="
                !credentials.password ||
                credentials.new_password != credentials.password
              "
              activator="saving_records"
            ></wolf-button>
          </div>
        </form>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import UserCreator from "@/index/Shared/UserCreator.vue";
import Avatar from "@/components/Avatar";
import BootstrapModal from "@/components/BootstrapModal.vue";
import { mapState } from "vuex";
export default {
  name: "users",
  mixins: [dashboardServices],
  components: {
    UserCreator,
    Avatar,
    BootstrapModal,
  },
  data: () => ({
    fetchUrl: "users",
    roles: [],
    user: {},
    credentials: {
      password: null,
      new_password: null,
      id: null,
    },
    passwordModal: false,
  }),
  computed: {
    ...mapState({
      branches: (state) => state.branches,
    }),
  },
  mounted() {
    this.$http.get("shared/assets/users").then((response) => {
      if (response.data.status) {
        this.roles = response.data.roles;
      }
    });
  },
  methods: {
    handleNewRecord(user) {
      if (this.$helper.empty(this.user)) {
        this.rows = [...[user], ...this.rows];
        this.toggleModal();
      } else location.reload();
    },
    handleEdit(user) {
      this.user = user;
      this.toggleModal();
    },
    openPasswordEditor(user) {
      this.user = user;
      this.credentials.id = user.id;
      this.passwordModal = true;
    },
    changePassword() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .post(
              "users/create",
              this.$helper.generateFormData(this.credentials)
            )
            .then((response) => {
              if (response.data.status) {
                this.$helper.resetObjectValues(this.credentials);
                this.user = {};
                this.passwordModal = false;
                this.$validator.reset();
              }
            });
        }
      });
    },
    changeUserStatus(user, i) {
      this.$http.get(`users/delete/${user.id}`).then((response) => {
        if (response.data.status) {
          let user = this.rows[i];
          if (user.deleted_at) this.rows[i].deleted_at = null;
          else this.rows[i].deleted_at = new Date(TODAY).toDateString();
        }
      });
    },
  },
};
</script>
