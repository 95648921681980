<script>
import ExpenseCategoryCreator from "@/index/Views/Finance/ExpenseCategoryCreator.vue";
export default {
  name: "ExpenseCategories",
  components: {
    ExpenseCategoryCreator,
  },
  data: () => ({
    categories: [],
    selectedCategory: null,
  }),
  beforeCreate() {
    this.$http.get("expenses/categories")
      .then((response) => {
        this.categories = response.data.rows;
      });
  },
  methods: {
    handleEdit(category) {
      this.selectedCategory = category;
      this.toggleModal();
    },
    handleCreatedCategory(category) {
      if (this.selectedCategory) {
        let index = this.categories.findIndex(
          (item) => item.id == this.selectedCategory.id
        );
        this.categories[index] = category;
      } else {
        this.categories.unshift(category);
      }
      this.selectedCategory = null;
      this.toggleModal();
    },
  },
};
</script>
<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Expense categories
                    </h5>
                    <div class="nk-block-des text-soft"></div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <button
                              @click="toggleModal"
                              class="btn btn-primary d-md-inline-flex"
                            >
                              <em class="icon ni ni-plus"></em><span>New</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Category Name</span>
                    </div>
                    <div class="nk-tb-col">Description</div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in categories"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="title">{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ row.description || "N/A" }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="handleEdit(row)"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit</span></a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'expenses',
                                        entityIdentifer: 'Category ',
                                        entity: `${row.name || ''}`,
                                        url: `expenses/categories/delete/${row.id}`,
                                        callback: () => {
                                          categories.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <expense-category-creator
      v-if="modalOpen"
      @closed="toggleModal"
      :category="selectedCategory"
      @saved="handleCreatedCategory"
    />
  </section>
</template>
