<script>
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "ExpenseCategoryCreator",
  props: ["category"],
  components: { BootstrapModal },
  data: () => ({
    newCategory: {
      name: null,
      description: null,
    },
  }),
  created() {
    if (this.category) {
      this.newCategory = { ...this.category };
    }
  },
  methods: {
    handleSubmit() {
      this.$http
        .post(
          "expenses/categories/store",
          this.$helper.generateFormData(this.newCategory)
        )
        .then((response) => {
          this.$store.commit("SET_FLASH_MESSAGE", {
            type: "success",
            message: "Record saved successfully.",
          });
          this.$emit("saved", response.data.category);
        });
    },
  },
};
</script>
<template>
  <bootstrap-modal
    @close="$emit('closed')"
    style="display: block"
    :modalSize="'default'"
    :showFooter="false"
  >
    <span slot="head">
      {{ !newCategory.id ? "New Category" : "Edit Category" }}
    </span>
    <div slot="body">
      <div class="form-group">
        <label class="mb-1" for="">Category Name</label>
        <input type="text" class="form-control" v-model="newCategory.name" />
      </div>
      <div class="form-group">
        <label class="mb-1" for="">Description</label>
        <textarea class="form-control" v-model="newCategory.description" />
      </div>
      <div class="form-group">
        <wolf-button
          caption="Save"
          @clicked="handleSubmit"
          :disabler="!newCategory.name"
          activator="SAVING"
          classes="btn btn-primary"
        ></wolf-button>
      </div>
    </div>
  </bootstrap-modal>
</template>
