<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Customers Balance
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-control-wrap mb-2">
                                        <type-head
                                          url="shared/clients/search"
                                          label="name"
                                          tracker="name"
                                          :loadDefaults="true"
                                          placeholder="Filter by client"
                                          @changed="handleClientFilter"
                                        ></type-head>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Customer</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Discount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Balance</span>
                    </div>
                    <div class="nk-tb-col">Status</div>
                    <div class="nk-tb-col"></div>
                  </div>
                  <template v-if="rows.length">
                    <div
                      class="nk-tb-item"
                      v-for="(row, i) in rows"
                      :key="'row' + i"
                    >
                      <div class="nk-tb-col">
                        <span>{{ i + 1 }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <div class="user-card">
                          <avatar
                            :name="`${row.client ? row.client.name : 'WI'}`"
                          ></avatar>
                          <div class="user-info">
                            <span class="lead-text">{{
                              `${
                                row.client
                                  ? row.client.name
                                  : "Walk-In Customer"
                              }`
                            }}</span
                            ><span class="sub-text"
                              >Tel:
                              {{ row.client ? row.client.phone : "N/A" }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="nk-tb-col">
                        <span
                          >{{
                            row.client
                              ? $helper.formatNumber(row.client.discount)
                              : "0"
                          }}%</span
                        >
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">
                          {{ formatMoney(row.balance) }}</span
                        >
                      </div>
                      <div class="nk-tb-col">
                        <span class="badge badge-dot bg-warning">Due</span>
                      </div>
                      <div class="nk-tb-col">
                        <router-link
                          :to="{
                            name: 'InvoicesBulkPayment',
                            query: { vouchers: row.vouchers },
                          }"
                          class="btn btn-primary d-md-inline-flex"
                        >
                          <em class="icon ni ni-coins"></em><span>Pay Now</span>
                        </router-link>
                      </div>
                    </div>
                    <div class="nk-tb-item">
                      <div class="nk-tb-col"><span></span></div>
                      <div class="nk-tb-col"><h6>Grand Total</h6></div>
                      <div class="nk-tb-col"></div>
                      <div class="nk-tb-col">
                        <h6>
                          {{ `${formatMoney(totalBalance)}` }}
                        </h6>
                      </div>
                      <div class="nk-tb-col"></div>
                      <div class="nk-tb-col"></div>
                    </div>
                  </template>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import TypeHead from "@/components/TypeHead.vue";
import Avatar from "@/components/Avatar.vue";
export default {
  name: "CustomerBalance",
  components: { TypeHead, Avatar },
  mixins: [dashboardServices],
  data: () => ({
    items: [],
    record: {},
    client: null,
    newFilter: {
      from: null,
      to: null,
    },

    rowIndex: null,
    payableAmount: 0,
  }),
  computed: {
    totalBalance() {
      return this.rows.reduce((a, b) => a + Number(b.balance), 0);
    },
    salesType() {
      return ["CustomerBalance"].includes(this.$route.name)
        ? "PRODUCTION"
        : "BRANCH_SALE";
    },
  },
  created() {
    this.fetchUrl = `sales/customers/balances?type=${this.salesType}`;
    const startDate = this.$route?.query?.start_date;
    const endDate = this.$route?.query?.end_date;
    if (startDate && endDate) {
      this.newFilter.from = startDate;
      this.newFilter.to = endDate;
      this.handlePaginationSearch(
        this.encodeQuery(this.fetchUrl, this.newFilter)
      );
    } else {
       console.log(this.newFilter, this.reportLabel);
      this.handlePaginationSearch();
    }
  },
  methods: {
    handleClientFilter(client) {
      if (!this.$helper.empty(client)) {
        this.newFilter.client = client.id;
        this.handlePaginationFilter();
      }
    },
  },
};
</script>
