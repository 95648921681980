<script>
import { dashboardServices } from "@/_helpers/services";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "CountersReportCreator",
  mixins: [dashboardServices],
  components: { BootstrapModal },
  data: () => ({
    selectedItem: null,
    comments: [],
    newComment: {
      qty: 0,
      reason: null,
      notes: null,
    },
  }),
  created() {
    this.fetchUrl = "branches/items/counted?per_page=2000000";
    delete this.newFilter.to;
    this.handlePaginationSearch();
  },
  computed: {
    totalOpen() {
      return this.rows.reduce((a, b) => a + Number(b.open) * Number(b.cost), 0);
    },
    totalClose() {
      return this.rows.reduce(
        (a, b) => a + Number(b.close) * Number(b.cost),
        0
      );
    },
    totalReceived() {
      return this.rows.reduce(
        (a, b) => a + Number(b.receivedItems) * Number(b.cost),
        0
      );
    },
    totalTransferred() {
      return this.rows.reduce(
        (a, b) => a + Number(b.transferredItems) * Number(b.cost),
        0
      );
    },
    totalSpoiled() {
      return this.rows.reduce(
        (a, b) => a + Number(b.spoiledItems) * Number(b.cost),
        0
      );
    },
    filteredRows() {
      let rows = this.rows;
      if (this.filterKey != null && this.filterKey.trim().length >= 2) {
        const keyword = this.filterKey.toLowerCase();
        rows = rows = this.rows.filter(
          (row) => String(row.name).toLowerCase().indexOf(keyword) !== -1
        );
      }
      return rows;
    },
    submitDisabled() {
      return false;
    },
  },
  methods: {
    toggleNotesModal(item) {
      this.selectedItem = item;
      if (item != null) {
        const balance = item.close - item.counted;
        this.selectedItem.balance = balance;
        this.newComment.qty = balance;
        if (item.comments) {
          this.comments = [...item.comments];
        }
      }
      this.toggleModal();
    },
    closeNotesModal() {
      const index = this.filteredRows.findIndex(
        (row) => row.id == this.selectedItem.id
      );
      this.rows[index].comments = [...this.comments];
      this.comments = [];
      this.selectedItem = null;
      this.toggleModal();
    },
    addComment() {
      this.comments.push({ ...this.newComment });
      Object.assign(this.newComment, { qty: 0, reason: null, notes: null });
    },
    removeComment(i) {
      this.comments.splice(i, 1);
    },
    handleSubmit() {
      this.$http
        .post(
          "branches/physical-count/store",
          this.$helper.generateFormData({
            rows: this.rows,
            system_date: this.newFilter.from,
          })
        )
        .then(() => {
          this.$store.commit("SET_FLASH_MESSAGE", {
            type: "success",
            message: "Physical count saved successfully",
          });
        });
    },
  },
};
</script>
<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title mb-0">
                      Create or edit Physical Count
                    </h3>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ newFilter.from }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li style="width: 180px">
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="search"
                                class="form-control"
                                placeholder="Search by name"
                                v-model="filterKey"
                              />
                            </div>
                          </li>
                          <li style="width: 150px">
                            <div class="form-group">
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.from"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="form-group text-right">
                              <button
                                type="button"
                                class="btn btn-primary d-md-inline-flex"
                                :disabled="$helper.empty(newFilter)"
                                @click="handlePaginationFilter"
                              >
                                <em class="icon ni ni-filter"></em
                                ><span>Filter</span>
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">OPEN.</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">REC. </span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">USED</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">CLOSE.</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">COUNT</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">BAL.</span>
                    </div>
                    <!-- <div class="nk-tb-col">
                      <span class="sub-text">SP&amp;DM</span>
                    </div> -->
                    <div class="nk-tb-col">
                      <span class="sub-text">Notes</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1"></ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in filteredRows"
                    :key="'item' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{ item.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.open)} ${item.unit}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.receivedItems)} ${
                          item.unit
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.transferredItems)} ${
                          item.unit
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.close)} ${item.unit}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col" style="width: 150px">
                      <div class="form-control-wrap">
                        <div class="input-group">
                          <input
                            type="number"
                            class="form-control"
                            v-model="item.counted"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">{{
                              item.unit
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${$helper.formatNumber(item.close - item.counted)} ${
                          item.unit
                        }`
                      }}</span>
                    </div>
                    <!-- <div class="nk-tb-col">
                      <span
                        class="tb-amount"
                        :class="{ 'text-danger': rows[i].spoiledItems > 0 }"
                        >{{
                          `${$helper.formatNumber(rows[i].spoiledItems)} ${
                            item.unit
                          }`
                        }}</span
                      >
                    </div> -->
                    <div class="nk-tb-col">
                      <template
                        v-if="
                          item.comments &&
                          Object.prototype.toString.call(item.comments) ===
                            '[object Array]'
                        "
                      >
                        <p
                          class="sub-text"
                          v-for="(row, k) in item.comments"
                          :key="k"
                        >
                          {{ row.reason }}:{{ row.qty }} {{ row.notes }}
                        </p>
                      </template>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <a
                            href="javascript:void(0)"
                            v-if="item.counted != item.close"
                            @click.prevent="toggleNotesModal(item)"
                            class="dropdown-toggle btn btn-icon btn-trigger"
                            ><em class="icon ni ni-pen"></em
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen && selectedItem !== null"
      @close="toggleNotesModal(null)"
      style="display: block"
      :modalSize="'modal-default'"
      :showFooter="true"
    >
      <span slot="head" class="d-flex align-items-center w-100">
        <span>Edit Physical Count notes for {{ selectedItem.name }}</span>
      </span>
      <div slot="body">
        <div class="table-responsive">
          <div class="card card-full">
            <div class="d-flex w-100 align-items-center gx-2">
              <div class="form-control-wrap w-100">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    placeholder="Write Some Notes"
                    v-model="newComment.notes"
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              class="d-flex w-100 align-items-center gx-2 my-2 justify-content-between"
            >
              <div class="form-control-wrap" style="width: 130px">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="newComment.qty"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">{{
                      selectedItem.unit
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="form-control-wrap" style="width: 200px">
                <select
                  class="form-control form-select"
                  v-model="newComment.reason"
                >
                  <option value="null" hidden disabled>Select Reason</option>
                  <option>SPOILED</option>
                  <option>DAMAGED</option>
                  <option>EXTRA</option>
                </select>
              </div>
              <div class="form-control-wrap text-right">
                <button
                  class="btn btn-secondary"
                  type="button"
                  @click="addComment"
                  :disabled="
                    !newComment.notes || !newComment.qty || !newComment.reason
                  "
                >
                  Add to List
                </button>
              </div>
            </div>

            <div class="nk-tb-list mt-n2 is-compact">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>$/N</span></div>
                <div class="nk-tb-col"><span>Qty #</span></div>
                <div class="nk-tb-col"><span>Reason</span></div>
                <div class="nk-tb-col"><span>Notes</span></div>
                <div class="nk-tb-col"><span></span></div>
              </div>
              <div class="nk-tb-item" v-for="(row, i) in comments" :key="i">
                <div class="nk-tb-col">
                  <span class="tb-lead"
                    ><a href="javascript:void(0)">{{ i + 1 }}</a></span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-lead"
                    ><a href="javascript:void(0)">{{
                      $helper.formatNumber(row.qty)
                    }}</a></span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-lead">{{ row.reason }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{ row.notes }}</span>
                </div>

                <div class="nk-tb-col">
                  <span class="tb-sub">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="removeComment(i)"
                      class="dropdown-toggle btn btn-icon btn-trigger"
                      ><em class="icon ni ni-trash"></em
                    ></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="foot" class="py-1">
        <button
          class="btn btn-primary btn-lg"
          type="button"
          @click="closeNotesModal"
          :disabled="!comments.length"
        >
          Confirm
        </button>
      </div>
    </bootstrap-modal>
    <div
      class="border-top shadow w-100 fixed-bottom p-2 main-content bg-white d-flex align-items-center"
      style="z-index: 999; left: 200px"
    >
      <wolf-button
        classes="btn btn-primary ml-2"
        @clicked="handleSubmit"
        :disabler="submitDisabled"
        activator="RECEIVING_ITEMS"
      >
        <span slot="caption"
          ><em class="icon ni ni-save"></em><span>Save</span></span
        >
      </wolf-button>

      <button class="btn btn-light ml-2" type="button" @click="$router.go(-1)">
        Cancel
      </button>
    </div>
  </section>
</template>
