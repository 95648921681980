<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-md mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title mb-0">
                      Sale produced products
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ rows.length }} products in stock</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            style="width: 180px"
                            v-if="this.$helper.empty(loggedUser.source)"
                          >
                            <multiselect
                              v-model="newSales.source"
                              :options="sources"
                              placeholder="Production Site"
                              label="name"
                              track-by="name"
                              @select="getProductionItems"
                            ></multiselect>
                          </li>
                          <template v-if="rows.length">
                            <li style="width: 180px">
                              <multiselect
                                v-model="newItem.product"
                                :options="rows"
                                placeholder="Select item"
                                label="name"
                                track-by="name"
                              ></multiselect>
                            </li>
                            <li style="width: 80px">
                              <input
                                type="number"
                                v-model="newItem.quantity"
                                class="form-control"
                                placeholder="Qty"
                              />
                            </li>
                            <li class="nk-block-tools-opt">
                              <div class="drodown">
                                <button
                                  type="button"
                                  class="dropdown-toggle btn btn-icon btn-primary"
                                  @click.prevent="handleSelectedItem"
                                  :disabled="
                                    !newItem.quantity ||
                                    $helper.empty(newItem.product)
                                  "
                                >
                                  <em class="icon ni ni-plus"></em>
                                </button>
                              </div>
                            </li>
                          </template>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Qty In</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Sale Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Sub Total</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        >{{ items[i].current_qty }}
                        {{ items[i].unit || " " }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ ` ${formatMoney(items[i].cost) || 0}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{
                            appSettings.currency
                          }}</span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          :name="'Sale Price' + i"
                          v-model="items[i].price"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required'"
                          @keyup="findTotalAmount(product)"
                        />
                      </div>
                      <span
                        class="text-danger"
                        v-if="errors.has('Sale Price' + i)"
                        >Sale Price is required
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="
                            `required|max_value: ${
                              items[i].current_qty + items[i].initial_qty
                            }`
                          "
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(product)"
                        />
                      </div>
                      <span
                        class="text-danger"
                        v-if="errors.has('Quantity' + i)"
                        >Quantity is required and <br />
                        must be less or equal to
                        {{ items[i].current_qty + items[i].initial_qty }}
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">
                          {{ formatMoney(items[i].total_amount) }}</span
                        ></span
                      >
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6
                      class="d-flex mb-3 align-items-center justify-content-end"
                    >
                      Grand Total:
                      <span class="ml-auto">
                        {{ formatMoney(grandTotal) }}</span
                      >
                    </h6>
                  </div>
                  <div class="w-100">
                    <div class="d-flex justify-content-end actions w-100">
                      <div class="col-2 px-1">
                        <!-- <label for="">Date</label> -->
                        <input
                          type="date"
                          v-model="newSales.committed_date"
                          class="form-control"
                        />
                      </div>
                      <div class="col-3 px-1">
                        <div class="d-block">
                          <type-head
                            url="shared/clients/search"
                            label="name"
                            tracker="name"
                            :loadDefaults="true"
                            placeholder="Search by client name"
                            @changed="handleSelectedClient"
                            v-show="$helper.empty(newSales.client)"
                          ></type-head>
                          <div
                            class="form-control-wrap"
                            v-if="!$helper.empty(newSales.client)"
                          >
                            <div
                              class="form-text-hint"
                              @click="resetClient"
                              style="cursor: pointer"
                            >
                              <span class="overline-title text-danger"
                                ><em class="icon ni ni-cross"></em
                              ></span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              :value="newSales.client.name"
                            />
                          </div>
                          <a
                            href="javascript:void(0)"
                            class="w-100"
                            style="text-decoration: underline; float:right; text-center"
                            @click.prevent="clientModalOpen = true"
                            >Add New Client?</a
                          >
                        </div>
                      </div>
                      <div class="col-2 px-1">
                        <button
                          type="button"
                          class="btn btn-primary btn-block"
                          @click="openPaymentModal"
                          :disabled="submitDisabled"
                        >
                          <span
                            ><span>Continue</span>&nbsp;<em
                              class="icon ni ni-arrow-right-circle"
                            ></em
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added items</h6>
                              <p>
                                No items found in sales list. Search for product
                                and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <client-creator
      @closed="clientModalOpen = false"
      @saved="handleSelectedClient"
      v-if="clientModalOpen"
    ></client-creator>
    <off-canvas
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
      position="end"
    >
      <span slot="head">Payment Information</span>
      <div slot="body">
        <form class>
          <PaymentHandler
            class="col-lg-10"
            :total="grandTotal"
            ref="paymentForm"
          >
            <div slot="foot">
              <wolf-button
                classes="btn btn-primary ml-2"
                @clicked="handleSubmit"
                :disabler="submitDisabled"
                activator="saving_records"
              >
                <span slot="caption"
                  ><em class="icon ni ni-save"></em><span>Save</span></span
                >
              </wolf-button>
            </div>
          </PaymentHandler>
        </form>
      </div>
    </off-canvas>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import TypeHead from "@/components/TypeHead.vue";
import ClientCreator from "@/index/Shared/ClientCreator.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import PaymentHandler from "@/index/Shared/PaymentHandler.vue";
export default {
  name: "Sales",
  components: {
    TypeHead,
    Multiselect,
    ClientCreator,
    OffCanvas,
    PaymentHandler,
  },
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    clientModalOpen: false,
    client: null,
    newItem: {
      product: null,
      quantity: 1,
    },
    selectedSouce: null,
    newSales: {
      record_id: null,
      payment_method: null,
      payment_ref: null,
      client: null,
      source: null,
      committed_date: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
      type: "PRODUCTION",
    },
    record: {},
    sources: [],
  }),
  computed: {
    submitDisabled() {
      return !this.newSales.client || !this.items.length;
    },
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
  },
  beforeCreate() {
    this.$http.get("production/sources").then((response) => {
      this.sources = response.data.sources;
    });
  },
  created() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$http.get(`sales/list/${reference}`).then((response) => {
        let items = response.data.items;
        items.forEach((item) => {
          this.items.push({
            id: item.product.id,
            name: item.product.name,
            source: item.product.source,
            quantity: item.quantity,
            initial_qty: item.quantity,
            cost: item.product.cost_price || 0,
            price: item.price,
            total_amount: item.amount,
            current_qty: item.product.stock_qty,
            unit: item.product.unit,
          });
          this.selectedItemIds.push(item.product.id);
        });

        this.record = response.data.record;
        this.newSales.record_id = this.record.id;
        this.newSales.client = this.record.client;
        this.newSales.source = this.record.source;
        this.newSales.discount = this.record.client.discount || 0;
        this.newSales.committed_date = new Date(this.record.committed_date)
          .toLocaleString("fr-CA", { timeZone: TIMEZONE })
          .slice(0, 10);
        this.newSales.payment_date = new Date(this.record.payment_date)
          .toLocaleString("fr-CA", { timeZone: TIMEZONE })
          .slice(0, 10);
      });
    }
  },
  mounted() {
    this.getPaymentsMode();
    this.$nextTick(() => {
      const source = this.loggedUser.source;
      if (source) {
        this.newSales.source = source;
        this.getProductionItems(source);
      }
    });
  },
  methods: {
    resetDefaults() {
      this.rows = [];
      this.items = [];
      this.$helper.resetObjectValues(this.newItem);
      this.selectedItemIds = [];
    },
    getProductionItems(source) {
      this.resetDefaults();
      this.$http
        .get(`production/items/configured/${source.id}`)
        .then((response) => {
          this.rows = response.data.rows;
          if (!this.rows.length)
            this.$notifier({
              type: "warning",
              message: "No configured items available for " + source.name,
            });
        });
    },
    resetClient() {
      this.newSales.client = null;
      this.newSales.discount = 0;
    },
    handleSelectedClient(client) {
      this.newSales.client = client;
      this.newSales.discount = client.discount || 0;
      this.clientModalOpen = false;
    },
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    handleSelectedItem() {
      var item = this.newItem.product;
      if (
        this.selectedItemIds.includes(item.id) ||
        this.$helper.empty(this.newItem.product)
      ) {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      } else {
        this.items.push({
          id: item.id,
          name: item.name,
          source: item.source,
          quantity: this.newItem.quantity,
          initial_qty: 0,
          cost: item.cost_price || 0,
          price: item.price || 0,
          total_amount: item.price * this.newItem.quantity,
          current_qty: item.stock_qty,
          unit: item.unit,
        });
        this.selectedItemIds.push(item.id);
      }
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    handleSubmit() {
      let paymentInfo = this.$refs.paymentForm.handlePaymentInfo();
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.newSales.total_amount = this.grandTotal;
          this.newSales.items = this.items;
          Object.assign(this.newSales, paymentInfo);
          this.selectedSouce = this.newSales.source;
          this.$http
            .post(
              this.$helper.empty(this.record) ? "sales/create" : "sales/edit",
              this.$helper.generateFormData(this.newSales)
            )
            .then((response) => {
              if (response.data.status) {
                if (!this.$helper.empty(this.record)) {
                  this.$router.push({ name: "ProductionSalesReport" });
                }
                this.$helper.resetObjectValues(this.newSales);
                (this.newSales = {
                  record_id: null,
                  payment_method: null,
                  payment_ref: null,
                  client: null,
                  committed_date: new Date(TODAY)
                    .toLocaleString("fr-CA", { timeZone: TIMEZONE })
                    .slice(0, 10),
                  type: "PRODUCTION",
                  source: this.selectedSouce,
                }),
                  this.toggleModal();
                this.items = [];
                this.selectedItemIds = [];
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: "Sales record added successfully",
                });
              }
            })
            
        }
      });
    },
    openPaymentModal() {
      this.toggleModal();
    },
  },
  beforeDestroy() {
    this.modalOpen = false;
    document.documentElement.classList.remove("modal-open");
    document.body.classList.remove("modal-open");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
a.cart-alt {
  color: #232323;
  display: block;
  padding: 21px 15px;
  position: relative;
}
a.cart-alt span.badge {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  right: 5px;
  font-size: 10px;
  padding: 2px 0 0 1px;
  width: 20px;
  height: 21px;
  color: #fff;
  border-radius: 18px;
  line-height: 15px;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  white-space: nowrap;
}
</style>
