<script>
import { encodeQuery } from "@/_helpers/utils.js";
import Multiselect from "vue-multiselect";
export default {
  name: "ProfitLossReport",
  components: { Multiselect },
  data: () => ({
    newFilter: {
      from: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
      to: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
    },
    totalPurchases: 0,
    sales: {},
    totalExpenses: 0,
    branchesData: [],
    fetchUrl: "reports/profit-and-loss",
    dateRanges: null,
    currentPeriod: "TODAY",
    currentBasis: "PURCHASES",
    storePurchases: 0,
    sources: [],
    selectedBranches: [],
  }),
  computed: {
    totalReceives() {
      return this.branchesData.reduce((a, b) => a + Number(b.receives), 0);
    },
    totalBranchPurchases() {
      return this.branchesData.reduce((a, b) => a + Number(b.purchases), 0);
    },
    branches() {
      return this.$store.state.branches;
    },
    destinations() {
      const result = [{ destination: "Branches", rows: this.branches }];
      result.push({ destination: "Productions", rows: this.sources });
      return result;
    },
  },
  mounted() {
    this.$http.get("production/sources").then((response) => {
      this.sources = response.data.sources;
    });
  },
  created() {
    this.getData(this.fetchUrl);
    this.$http.get("dates-range").then((response) => {
      this.dateRanges = response.data;
    });
  },
  methods: {
    handleBranchFilter() {
      const branches = [];
      const sources = [];
      delete this.newFilter.branches;
      delete this.newFilter.sources;
      this.selectedBranches.forEach((destination) => {
        const keys = Object.keys(destination);
        if (keys.includes("phone")) branches.push(destination.id);
        else sources.push(destination.id);
      });
      if (branches.length) {
        this.newFilter.branches = branches.join(",");
      }
      if (sources.length) {
        this.newFilter.sources = sources.join(",");
      }
      this.$store.commit("SET_REQUEST_FLAG", "PAGINATION_SEARCH");
      this.getData(encodeQuery(this.fetchUrl, this.newFilter));
    },
    handleFilter(period) {
      this.currentPeriod = period;
      this.newFilter.from = this.dateRanges[period][0];
      this.newFilter.to = this.dateRanges[period][1];
      this.$store.commit("SET_REQUEST_FLAG", "PAGINATION_SEARCH");
      this.getData(encodeQuery(this.fetchUrl, this.newFilter));
    },
    handleBasisFilter(basis) {
      this.currentBasis = basis;
    },
    getData(url) {
      this.$http.get(url).then((response) => {
        this.sales = response.data.general_sales;
        this.totalPurchases = response.data.total_purchases;
        this.totalExpenses = response.data.total_expenses;
        this.branchesData = response.data.branches_data;
        this.storePurchases = response.data.store_receiving;
      });
    },
    toggleCustomFilter() {
      this.currentPeriod = "CUSTOM";
      setTimeout(() => {
        document.querySelector("#data-filter-toggler").click();
      }, 2);
    },
    handleCustomFilter() {
      this.$store.commit("SET_REQUEST_FLAG", "PAGINATION_SEARCH");
      //this.toggleCustomFilter();
      this.getData(encodeQuery(this.fetchUrl, this.newFilter));
    },
  },
};
</script>
<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <div class="dropdown filter-dropdown date-filter">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle zf-daterange-picker d-flex align-items-center ember-view form-control cursor-pointer"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <em class="icon ni ni-growth-fill fs-4"></em>
                                <span class="range-text ml-2 text-capitalize">{{
                                  $helper.inputTitle(
                                    $helper.capitalizeFirstLetter(
                                      currentBasis.toLowerCase()
                                    )
                                  )
                                }}</span>

                                <em
                                  class="icon ni ni-caret-down-fill ml-auto"
                                ></em>
                              </a>

                              <div
                                class="daterangepicker dropdown-menu dropdown-menu-left"
                                style=""
                              >
                                <div class="ranges" v-if="dateRanges">
                                  <ul>
                                    <li
                                      :class="{
                                        active: currentBasis == 'PURCHASES',
                                      }"
                                      @click="handleBasisFilter('PURCHASES')"
                                    >
                                      Purchases
                                    </li>
                                    <li
                                      :class="{
                                        active: currentBasis == 'RECEIVING',
                                      }"
                                      @click="handleBasisFilter('RECEIVING')"
                                    >
                                      Receiving
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="dropdown filter-dropdown date-filter">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle zf-daterange-picker d-flex align-items-center ember-view form-control cursor-pointer"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                id="data-filter-toggler"
                              >
                                <em class="icon ni ni-calender-date fs-4"></em>
                                <span class="range-text ml-2 text-capitalize">{{
                                  $helper.inputTitle(
                                    $helper.capitalizeFirstLetter(
                                      currentPeriod.toLowerCase()
                                    )
                                  )
                                }}</span>

                                <em
                                  class="icon ni ni-caret-down-fill ml-auto"
                                ></em>
                              </a>

                              <div
                                class="daterangepicker dropdown-menu dropdown-menu-left"
                                style=""
                              >
                                <div
                                  class="ranges"
                                  :class="{
                                    'd-flex': currentPeriod == 'CUSTOM',
                                  }"
                                  v-if="dateRanges"
                                >
                                  <ul>
                                    <li
                                      :class="{
                                        active: currentPeriod == 'TODAY',
                                      }"
                                      @click="handleFilter('TODAY')"
                                    >
                                      Today
                                    </li>
                                    <li
                                      :class="{
                                        active: currentPeriod == 'THIS_WEEK',
                                      }"
                                      @click="handleFilter('THIS_WEEK')"
                                    >
                                      This Week
                                    </li>
                                    <li
                                      :class="{
                                        active:
                                          currentPeriod == 'CURRENT_MONTH',
                                      }"
                                      @click="handleFilter('CURRENT_MONTH')"
                                    >
                                      This Month
                                    </li>
                                    <li
                                      :class="{
                                        active: currentPeriod == 'THIS_YEAR',
                                      }"
                                      @click="handleFilter('THIS_YEAR')"
                                    >
                                      This Year
                                    </li>
                                    <li
                                      :class="{
                                        active: currentPeriod == 'YEAR_TO_DATE',
                                      }"
                                      @click="handleFilter('YEAR_TO_DATE')"
                                    >
                                      Year To Date
                                    </li>
                                    <li
                                      :class="{
                                        active: currentPeriod == 'YESTERDAY',
                                      }"
                                      @click="handleFilter('YESTERDAY')"
                                    >
                                      Yesterday
                                    </li>
                                    <li
                                      :class="{
                                        active: currentPeriod == 'LAST_WEEK',
                                      }"
                                      @click="handleFilter('LAST_WEEK')"
                                    >
                                      Previous Week
                                    </li>
                                    <li
                                      :class="{
                                        active:
                                          currentPeriod == 'PREVIOUS_MONTH',
                                      }"
                                      @click="handleFilter('PREVIOUS_MONTH')"
                                    >
                                      Previous Month
                                    </li>
                                    <li
                                      :class="{
                                        active: currentPeriod == 'LAST_YEAR',
                                      }"
                                      @click="handleFilter('LAST_YEAR')"
                                    >
                                      Previous Year
                                    </li>
                                    <li
                                      :class="{
                                        active: currentPeriod == 'CUSTOM',
                                      }"
                                      @click="toggleCustomFilter()"
                                    >
                                      Custom
                                    </li>
                                  </ul>
                                  <div
                                    class="custom-ranges border-left d-block px-2 ml-2"
                                    v-if="currentPeriod == 'CUSTOM'"
                                  >
                                    <div class="custom-ranger-wraper">
                                      <div class="date-picker">
                                        <div class="form-group mb-2">
                                          <label for="">From:</label>
                                          <input
                                            type="date"
                                            class="form-control"
                                            v-model="newFilter.from"
                                          />
                                        </div>
                                        <div class="form-group">
                                          <label for="">To:</label>
                                          <input
                                            type="date"
                                            class="form-control"
                                            v-model="newFilter.to"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        class="bottom-0 d-flex justify-content-between"
                                      >
                                        <button
                                          class="btn btn-sm btn-primary"
                                          type="button"
                                          @click="handleCustomFilter"
                                        >
                                          Apply
                                        </button>
                                        <button
                                          class="btn btn-sm btn-secondary ms-1"
                                          type="button"
                                          @click="currentPeriod = ''"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <button
                              type="button"
                              class="btn btn-icon btn-white btn-dim btn-outline-light"
                              @click="$helper.handlePrint('.nk-block')"
                            >
                              <em class="icon ni ni-printer"></em>
                            </button>
                          </li>
                          <li
                            style="min-width: 180px"
                            v-show="currentBasis != 'PURCHASES'"
                          >
                            <multiselect
                              v-model="selectedBranches"
                              :options="destinations"
                              group-values="rows"
                              group-label="destination"
                              placeholder="Select Branch"
                              tag-placeholder="Add this as new branch"
                              label="name"
                              track-by="name"
                              :multiple="true"
                              :taggable="true"
                            ></multiselect>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="btn btn-icon btn-primary"
                              @click="handleBranchFilter"
                            >
                              <em class="icon ni ni-search"></em>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="page-header notranslate text-center">
                  <h4>{{ appSettings?.site_name || "TAME APPS" }}</h4>
                  <h3 class="reports-headerspacing">Profit and Loss</h3>
                  <span
                    >Basis:
                    {{
                      $helper.inputTitle(
                        $helper.capitalizeFirstLetter(
                          currentBasis.toLowerCase()
                        )
                      )
                    }}</span
                  >
                  <h5>
                    <span>From</span>&nbsp;{{
                      $helper.formatDate(newFilter.from)
                    }}
                    <span>To</span>&nbsp;31
                    {{ $helper.formatDate(newFilter.to) }}
                  </h5>

                  <div class="tags"></div>
                </div>
                <div
                  class="reports-table-wrapper fill-container table-container"
                >
                  <table
                    class="table tb-comparison-table zi-table financial-comparison table-no-border"
                  >
                    <thead>
                      <tr>
                        <th
                          class="text-left sortable align-middle whitespace-nowrap"
                          style=""
                          rowspan="1"
                          colspan="1"
                        >
                          <div class="position-relative">
                            <div class="float-left" title="Account">
                              Account
                            </div>
                          </div>
                        </th>
                        <th
                          class="text-right sortable align-middle whitespace-nowrap"
                          style=""
                          rowspan="1"
                          colspan="1"
                        >
                          <div class="position-relative">
                            <div class=" " title="Total">Total</div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="font-weight-bold">
                        <td>Operating Income</td>
                        <td colspan="1">&nbsp;</td>
                      </tr>
                      <template v-for="row in branchesData">
                        <tr
                          class=" "
                          :key="row?.branch_id + $helper.generateRandomText(7)"
                          v-if="row.sales > 0"
                        >
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a class="ember-view" href="javascript:void(0)"
                              >{{ row.name }} Sales</a
                            >
                          </td>
                          <td class="text-right">
                            <a class="ember-view" href="javascript:void(0)">{{
                              formatMoney(row.sales)
                            }}</a>
                          </td>
                        </tr>
                      </template>

                      <tr class="highlight-subaccount-section">
                        <td><strong>Total for Operating Income</strong></td>
                        <td class="text-right">
                          {{ formatMoney(sales) }}
                        </td>
                      </tr>
                      <!-- Start for Purchases -->

                      <tr class="font-weight-bold">
                        <td>Purchases</td>
                        <td colspan="1">&nbsp;</td>
                      </tr>
                      <template v-if="currentBasis == 'PURCHASES'">
                        <tr class=" ">
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a class="ember-view" href="javascript:void(0)">
                              Main store purchases
                            </a>
                          </td>
                          <td class="text-right">
                            <a class="ember-view" href="javascript:void(0)">{{
                              formatMoney(storePurchases)
                            }}</a>
                          </td>
                        </tr>
                      </template>
                      <template v-for="row in branchesData">
                        <tr
                          class=" "
                          v-if="row.purchases > 0"
                          :key="row?.branch_id + $helper.generateRandomText(7)"
                        >
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a class="ember-view" href="javascript:void(0)">{{
                              row.name
                            }}</a>
                          </td>
                          <td class="text-right">
                            <a class="ember-view" href="javascript:void(0)">{{
                              formatMoney(row.purchases)
                            }}</a>
                          </td>
                        </tr>
                      </template>

                      <tr class="highlight-subaccount-section">
                        <td><strong>Total for Purchases</strong></td>
                        <td class="text-right">
                          <span v-if="currentBasis == 'PURCHASES'">{{
                            formatMoney(totalPurchases)
                          }}</span>
                          <span v-else>{{
                            formatMoney(totalBranchPurchases)
                          }}</span>
                        </td>
                      </tr>

                      <!-- End of purchases-->

                      <!-- Start of Receving -->
                      <template v-if="currentBasis != 'PURCHASES'">
                        <tr class="font-weight-bold">
                          <td>Stock Received</td>
                          <td colspan="1">&nbsp;</td>
                        </tr>
                        <template v-for="row in branchesData">
                          <tr
                            class=" "
                            v-if="row.receives > 0"
                            :key="
                              row?.branch_id + $helper.generateRandomText(7)
                            "
                          >
                            <td>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <a class="ember-view" href="javascript:void(0)">{{
                                row.name
                              }}</a>
                            </td>
                            <td class="text-right">
                              <a class="ember-view" href="javascript:void(0)">{{
                                formatMoney(row.receives)
                              }}</a>
                            </td>
                          </tr>
                        </template>
                        <tr class="highlight-subaccount-section">
                          <td><strong>Total for Stock Received</strong></td>
                          <td class="text-right">
                            {{ formatMoney(totalReceives) }}
                          </td>
                        </tr>
                      </template>
                      <!-- End of receives-->

                      <tr class="highlight-subaccount-section">
                        <td class="text-right">
                          <strong>Gross Profit</strong>
                        </td>
                        <td class="text-right">
                          <span v-if="currentBasis == 'PURCHASES'">
                            {{ formatMoney(sales - totalPurchases) }}
                          </span>
                          <span v-else>
                            {{
                              formatMoney(
                                sales - (totalReceives + totalBranchPurchases)
                              )
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr class="font-weight-bold">
                        <td>Operating Expense</td>
                        <td colspan="1">&nbsp;</td>
                      </tr>
                      <template v-for="row in branchesData">
                        <tr
                          class=" "
                          :key="row?.branch_id + $helper.generateRandomText(7)"
                          v-if="row.total_expenses > 0"
                        >
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a
                              class="ember-view fw-bolder text-dark"
                              href="javascript:void(0)"
                              >{{ row.name }} Expenses</a
                            >
                          </td>
                          <td class="text-right">
                            <a
                              class="ember-view fw-bolder text-dark"
                              href="javascript:void(0)"
                              >{{ formatMoney(row.total_expenses) }}</a
                            >
                          </td>
                        </tr>
                        <template v-for="expense in row.expenses">
                          <tr
                            class=" "
                            :key="expense.id + $helper.generateRandomText(7)"
                            v-if="expense.total"
                          >
                            <td>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <a class="ember-view" href="javascript:void(0)">{{
                                expense.name
                              }}</a>
                            </td>
                            <td class="text-right">
                              <a class="ember-view" href="javascript:void(0)">{{
                                formatMoney(expense.total)
                              }}</a>
                            </td>
                          </tr>
                        </template>
                      </template>

                      <tr class="highlight-subaccount-section">
                        <td><strong>Total for Operating Expense</strong></td>
                        <td class="text-right">
                          {{ formatMoney(totalExpenses) }}
                        </td>
                      </tr>

                      <tr
                        class="highlight-subaccount-section"
                        v-if="currentBasis == 'PURCHASES'"
                      >
                        <td class="text-right">
                          <strong>Operating Profit</strong>
                        </td>
                        <td class="text-right">
                          {{
                            formatMoney(
                              sales - (totalPurchases + totalExpenses)
                            )
                          }}
                        </td>
                      </tr>

                      <tr class="highlight-subaccount-section" v-else>
                        <td class="text-right">
                          <strong>Operating Profit</strong>
                        </td>
                        <td class="text-right">
                          {{
                            formatMoney(
                              sales -
                                (totalReceives +
                                  totalExpenses +
                                  totalBranchPurchases)
                            )
                          }}
                        </td>
                      </tr>
                      <!--
      <tr class="font-weight-bold">
        <td>Non Operating Income</td>
        <td colspan="1">&nbsp;</td>
      </tr>
      
      <tr class="highlight-subaccount-section">
        <td><strong>Total for Non Operating Income</strong></td>
        <td class="text-right">0</td>
      </tr>
      <tr class="font-weight-bold">
        <td>Non Operating Expense</td>
        <td colspan="1">&nbsp;</td>
      </tr>
      
      <tr class="highlight-subaccount-section">
        <td><strong>Total for Non Operating Expense</strong></td>
        <td class="text-right">0</td>
      </tr>
      <tr class="highlight-subaccount-section">
        <td class="text-right"><strong>Net Profit/Loss</strong></td>
        <td class="text-right">53,790</td>
      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 10px;
}
@media print {
  *,
  ::before,
  ::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  h3 {
    orphans: 3;
    widows: 3;
  }
  h3 {
    page-break-after: avoid;
  }
}
h3 {
  margin-top: 20px;
}
h4,
h5 {
  margin-top: 10px;
}
h3,
h4,
h5 {
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.1;
}
h3 {
  font-size: 1.7rem;
}
h4 {
  font-size: 1.3rem;
}
h5 {
  font-size: 1rem;
}
h3,
h4 {
  font-weight: 400;
}
.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}
.rep-container .page-header {
  border: 0;
}
.rep-container .tags {
  margin-top: 25px;
}
.reports-headerspacing {
  margin-top: -5px;
}
.custom-ranger-wraper {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
