<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Per Item Sales Report
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <template v-if="rows.length">
                            <li>
                              <button
                                type="button"
                                class="btn btn-icon btn-white btn-dim btn-outline-light"
                                @click="$helper.handlePrint('.nk-block')"
                              >
                                <em class="icon ni ni-printer"></em>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                class="btn btn-white btn-dim btn-outline-light"
                                @click="handleExcelExport"
                              >
                                <svg
                                  height="18px"
                                  viewBox="0 0 384 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path
                                    d="m369.9 97.9-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-204.1c-26.5.1-48 21.6-48 48.1v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-332.1c0-12.7-5.1-25-14.1-34zm-37.8 30.1h-76.1v-76.1zm-284.1 336v-416h160v104c0 13.3 10.7 24 24 24h104v288zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3h-28.9c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3h28.8c9.3 0 15-10 10.4-18l-46.4-78c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"
                                  ></path>
                                </svg>
                              </button>
                            </li>
                          </template>
                          <li>
                            <type-head
                              :url="
                                salesType == 'PRODUCTION'
                                  ? 'production/items'
                                  : 'branches/sales-group-items'
                              "
                              label="name"
                              tracker="name"
                              placeholder="Select item"
                              @changed="handleItemFilter"
                            ></type-head>
                          </li>
                          <li
                            style="width: 150px"
                            v-if="
                              $helper.empty(loggedUser.source) &&
                              salesType == 'PRODUCTION'
                            "
                          >
                            <multiselect
                              v-model="source"
                              :options="sources"
                              placeholder="All Productions"
                              label="name"
                              track-by="name"
                              @select="handleSourceFilter"
                            ></multiselect>
                          </li>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group mb-2">
                                        <type-head
                                          url="shared/clients/search"
                                          label="name"
                                          tracker="name"
                                          :loadDefaults="true"
                                          placeholder="Filter by client"
                                          @changed="handleClientFilter"
                                        ></type-head>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <print-header>
                  <div slot="title" class="text-center mb-1">
                    <h5 class="text-center">
                      Per Item Sales Report /
                      <small style="font-size: 14px">{{ reportLabel }}</small>
                    </h5>
                  </div>
                </print-header>
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Date</span>
                    </div>
                    <div class="nk-tb-col" v-if="!$helper.empty(client)">
                      <span class="sub-text">Client</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Cost Price</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Tot. Quantity</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Tot. Amount</span>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        $helper.formatDate(row.committed_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col" v-if="!$helper.empty(client)">
                      <div class="user-card">
                        <avatar
                          :name="`${client.first_name} ${
                            client.last_name || ''
                          }`"
                        ></avatar>
                        <div class="user-info">
                          <span class="lead-text">{{
                            `${client.first_name} ${client.last_name || ""}`
                          }}</span
                          ><span class="sub-text"
                            >Tel: {{ client.phone || "N/A" }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.product.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        formatMoney(row.product.cost_price)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead"
                        >{{ $helper.formatNumber(row.quantity) }}
                        {{ row.product ? row.product.unit : "" }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{ formatMoney(row.total_amount) }}</span
                      >
                    </div>
                  </div>
                  <div class="nk-tb-item" v-if="rows.length">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"><span class="tb-lead"></span></div>
                    <div class="nk-tb-col"><span class="tb-lead"></span></div>
                    <div class="nk-tb-col"><span class="tb-lead"></span></div>
                    <div class="nk-tb-col"><span class="tb-lead"></span></div>
                    <div class="nk-tb-col">
                      <h6 class="m-0 fw-bolder">
                        {{ formatMoney(grandTotal) }}
                      </h6>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
import Avatar from "@/components/Avatar.vue";
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "ItemSales",
  components: { TypeHead, Avatar, PrintHeader, Multiselect },
  mixins: [dashboardServices],
  data: () => ({
    client: null,
    sources: [],
    source: null,
  }),
  computed: {
    grandTotal() {
      return this.rows.reduce((a, b) => a + b.total_amount, 0);
    },
    salesType() {
      return ["ItemSalesReport"].includes(this.$route.name)
        ? "PRODUCTION"
        : "BRANCH_SALE";
    },
  },
  created() {
    this.fetchUrl = `reports/sales/items/history?reason=${this.salesType}`;
    this.handlePaginationSearch();
  },
  methods: {
    handleSourceFilter(source) {
      this.newFilter.source_id = source.id;
      this.handlePaginationFilter();
    },
    handleItemFilter(product) {
      this.newFilter.product = product.id;
      this.handlePaginationFilter();
    },
    handleClientFilter(client) {
      this.client = client;
      this.newFilter.client = client.id;
      this.handlePaginationFilter();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (
        this.$helper.empty(this.loggedUser.source) &&
        this.salesType == "PRODUCTION"
      ) {
        this.$http.get("production/sources").then((response) => {
          this.sources = response.data.sources;
        });
      }
    });
  },
};
</script>
