<template>
  <div class="container-fluid">
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
    >
      <span slot="head" style="padding-top: 10px">{{
        newCategory.id ? "Edit category" : "Create a new category"
      }}</span>
      <div slot="body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="newCategory.name"
                placeholder="Enter category name"
              />
            </div>
            <div class="form-group">
              <label class="form-label">Type</label>
              <br />
              <div
                class="custom-control custom-radio mr-3"
                :class="{ checked: newCategory.type == 'BAR' }"
              >
                <input
                  type="radio"
                  id="supplierType1"
                  name="supplierType"
                  :value="'BAR'"
                  v-model="newCategory.type"
                  class="custom-control-input"
                /><label class="custom-control-label" for="supplierType1"
                  >BAR</label
                >
              </div>
              <div
                class="custom-control custom-radio mr-3"
                :class="{ checked: newCategory.type == 'KITCHEN' }"
              >
                <input
                  type="radio"
                  id="supplierType2"
                  name="supplierType"
                  :value="'KITCHEN'"
                  v-model="newCategory.type"
                  class="custom-control-input"
                /><label class="custom-control-label" for="supplierType2"
                  >KITCHEN</label
                >
              </div>
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                style="height: auto !important"
                rows="4"
                v-model="newCategory.description"
                placeholder="Description(Optional)"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div slot="foot">
        <wolf-button
          caption="Save and Close"
          @clicked="setCategory"
          :disabler="!newCategory.name"
          activator="saving_user"
          classes="btn btn-primary"
        ></wolf-button>
      </div>
    </bootstrap-modal>

    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block nk-block-lg">
          <div class="row justify-content-center">
            <div class="col-xxl-5 col-xl-5 col-lg-6">
              <h4>Items Categories</h4>
              <div class="ant-transfer-list">
                <div class="ant-transfer-list-header">
                  <span class="ant-transfer-list-header-selected">
                    <span>{{ filteredCategories.length }} Item Types</span>
                    <span class="ant-transfer-list-header-title">
                      <button
                        class="
                          btn btn-primary btn-sm btn-icon-only
                          rounded-circle
                        "
                        type="button"
                        @click="
                          $helper.resetObjectValues(newCategory);
                          toggleModal();
                        "
                        title="Add new"
                      >
                        <span class="btn-inner--icon">
                          <i class="ni ni-plus"></i>
                        </span>
                      </button>
                    </span>
                  </span>
                </div>
                <div
                  class="
                    ant-transfer-list-body ant-transfer-list-body-with-search
                  "
                >
                  <div class="ant-transfer-list-body-search-wrapper">
                    <div>
                      <input
                        placeholder="Search here"
                        type="text"
                        v-model="searchKey"
                        class="ant-input ant-transfer-list-search"
                      />
                      <span class="ant-transfer-list-search-action">
                        <i class="anticon anticon-search">
                          <svg
                            viewBox="64 64 896 896"
                            data-icon="search"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class
                          >
                            <path
                              d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"
                            />
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                  <div class="ant-transfer-list-content mt-1">
                    <ul class="list-group list-group-flush my-1">
                      <li
                        class="list-group-item pl-2"
                        v-for="(category, index) in filteredCategories"
                        :key="'categoryTable' + index"
                      >
                        <div class="row align-items-center">
                          <div class="col-auto pr-1">
                            <!-- Avatar -->
                            <a
                              href="javascript:void(0)"
                              class="avatar rounded bg-white"
                              style="width: 38px; height: 38px"
                            >
                              <img
                                :alt="category.name"
                                class="img img-thumbnail rounded img-fluid"
                                :src="`${publicPath}img/upload-icon.png`"
                                style="width: 38px"
                              />
                            </a>
                          </div>
                          <div class="col-5">
                            <span class="mb-0">
                              {{ category.name }}
                            </span>
                            <small>{{ category.description }}</small>
                          </div>
                          <div class="col-2 text-center">
                            <span class="mb-0">
                              <a href="javascript:void(0)">{{
                                category.type
                              }}</a>
                            </span>
                          </div>
                          <div class="col-auto p-0 pr-1 text-right ml-auto">
                            <button
                              class="
                                btn btn-primary btn-sm btn-icon-only
                                rounded-circle
                              "
                              type="button"
                              @click="editCategory(category, index)"
                              title="Edit category"
                            >
                              <span class="btn-inner--icon">
                                <i class="ni ni-pen"></i>
                              </span>
                            </button>
                            <button
                              class="
                                btn btn-outline-danger btn-sm btn-icon-only
                                rounded-circle
                                ml-1
                              "
                              type="button"
                              @click="
                                handleDeleteAlertModal({
                                  tableName: 'category',
                                  entityIdentifer: 'category',
                                  entity: `${category.name}`,
                                  url: `stock/categories/delete/${category.id}`,
                                  callback: () => {
                                    categories.splice(i, 1);
                                  },
                                })
                              "
                              title="Delete category"
                            >
                              <span class="btn-inner--icon">
                                <i class="ni ni-trash"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/_helpers/ant-design.css";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "Categories",
  components: { BootstrapModal },
  data: () => ({
    newCategory: {
      parent_id: null,
      name: null,
      type: null,
      description: null,
      image: null,
    },
    categories: [],
    searchKey: null,
  }),
  created() {
    this.$http.get(`stock/categories`).then((response) => {
      if (response.data.status) {
        this.categories = response.data.rows;
      }
    });
  },
  computed: {
    filteredCategories: function () {
      let searchKey = this.searchKey && this.searchKey.toLowerCase();
      let categories = this.categories;
      if (searchKey) {
        categories = categories.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(searchKey) > -1;
          });
        });
      }
      return categories;
    },
  },
  methods: {
    setCategory() {
      var url = "stock/categories/create";
      if (this.newCategory.id) {
        url = "stock/categories/update";
      }
      this.$http.post(url,
          this.$helper.generateFormData(this.newCategory),
        )
        .then((response) => {
          if (this.newCategory.id) {
            let index = this.categories.findIndex(
              (item) => (item.id = this.newCategory.id)
            );
            this.categories[index] = response.data.category;
          } else this.categories.unshift(response.data.category);
          this.toggleModal();
        });
    },
    editCategory(category, i) {
      this.editIndex = i;
      this.newCategory = {
        id: category.id,
        name: category.name,
        description: category.description,
      };
      this.toggleModal();
    },
  },
};
</script>
<style scoped>
body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 0.6em;
}
body::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--base-color);
  border-radius: 1px;
}
</style>
