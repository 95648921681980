<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block"></div>
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <template v-if="$helper.empty(record)">
                        <span>New Expense</span>
                      </template>
                      <template v-else>
                        <span
                          >Edit Expense &nbsp;
                          <a href="javascript:void(0)"
                            >#{{ record.reference }}</a
                          ></span
                        >
                      </template>
                    </h3>
                    <div class="nk-block-des text-soft"></div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div id="bill-details">
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Category</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <div class="dropdown filter-dropdown w-100">
                          <div
                            class="auto-select ac-selected dropdown-toggle w-100"
                            tabindex="-1"
                          >
                            <input
                              autocomplete="off"
                              spellcheck="false"
                              placeholder="Select or type to add"
                              autocorrect="off"
                              autocapitalize="off"
                              class="form-control"
                              type="text"
                              v-model="selectedCategory"
                              readonly
                            />
                            <i class="zf-ac-toggler">
                              <em
                                class="icon ni ni-caret-down-fill m-auto"
                              ></em>
                            </i>
                          </div>

                          <div
                            class="dropdown-menu dropdown-menu-right w-100"
                            style=""
                          >
                            <div
                              class="p-2 position-relative"
                              style="width: 100%"
                            >
                              <div class="options-select">
                                <div class="form-group">
                                  <input
                                    type="search"
                                    class="form-control form-control-sm"
                                    placeholder="Search for category"
                                    v-model="categoryKey"
                                  />
                                </div>
                                <div class="tame-vertical-container">
                                  <div class="options-container mb-1">
                                    <div
                                      class="p-1 item"
                                      v-for="category in filteredCategories"
                                      :key="category.id"
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        @click.prevent="setCategory(category)"
                                        class="w-100"
                                      >
                                        <span
                                          ><em
                                            class="icon ni ni-property-alt"
                                          ></em
                                        ></span>
                                        <span class="ml-1">{{
                                          category.name
                                        }}</span></a
                                      >
                                    </div>
                                    <span
                                      class="text-center"
                                      v-if="!categories.length"
                                      >No categories available</span
                                    >
                                  </div>
                                  <div
                                    class="bottom-0 d-flex align-items-center w-100 mt-1"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      @click.prevent="toggleModal"
                                    >
                                      <em class="icon ni ni-plus-sm"></em>
                                      <span>New Category</span>
                                    </a>
                                    <span class="mx-2">|</span>
                                    <router-link
                                      :to="{ name: 'ExpenseCategories' }"
                                    >
                                      <span>Manage</span>
                                      <em class="icon ni ni-external"></em>
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row align-items-center"
                    v-if="$helper.empty(loggedUser.branch)"
                  >
                    <div class="col-lg-3 col-4">
                      <label class="required">Branch</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <select
                          v-model="newExpense.branch_id"
                          class="form-control form-select"
                        >
                          <option value="null" hidden disabled>
                            Select Branch
                          </option>
                          <option
                            :value="branch.id"
                            v-for="branch in branches"
                            :key="branch.id"
                          >
                            {{ branch.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label class="required">Expense Date</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <input
                          type="date"
                          v-model="newExpense.expense_date"
                          class="form-control"
                          placeholder="Expense Date"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label class="required">Amount</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <input
                          type="number"
                          v-model="newExpense.amount"
                          class="form-control"
                          placeholder="Amount"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label class="required">Paid Through</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <select
                          class="form-control"
                          v-model="newExpense.paid_through"
                        >
                          <option value="null" selected hidden>
                            Select Option
                          </option>
                          <option
                            v-for="row in accounts"
                            :key="row.id"
                            :value="row.id"
                          >
                            {{ row.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Invoice Given</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <div
                          class="custom-control custom-control-sm custom-radio"
                        >
                          <input
                            type="radio"
                            class="custom-control-input"
                            name="invoiceGiven"
                            id="customRadio7"
                            value="0"
                            v-model="newExpense.has_invoice"
                          /><label
                            class="custom-control-label"
                            for="customRadio7"
                            >No</label
                          >
                        </div>
                        <div
                          class="custom-control custom-control-sm custom-radio ml-3"
                        >
                          <input
                            type="radio"
                            class="custom-control-input"
                            name="invoiceGiven"
                            id="customRadio8"
                            value="1"
                            v-model="newExpense.has_invoice"
                          /><label
                            class="custom-control-label"
                            for="customRadio8"
                            >Yes</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row align-items-center"
                    v-if="newExpense.has_invoice == 1"
                  >
                    <div class="col-lg-3 col-4">
                      <label>Tax</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group">
                        <div
                          class="custom-control custom-control-sm custom-radio"
                        >
                          <input
                            type="radio"
                            class="custom-control-input"
                            name="invoiceGiven"
                            id="customRadio1"
                            value="1"
                            v-model="newExpense.tax_inclusive"
                          /><label
                            class="custom-control-label"
                            for="customRadio1"
                            >VAT Inclusive</label
                          >
                        </div>
                        <div
                          class="custom-control custom-control-sm custom-radio ml-3"
                        >
                          <input
                            type="radio"
                            class="custom-control-input"
                            name="invoiceGiven"
                            id="customRadio2"
                            value="0"
                            v-model="newExpense.tax_inclusive"
                          /><label
                            class="custom-control-label"
                            for="customRadio2"
                            >VAT Exclusive</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Reference #</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <input
                          type="text"
                          v-model="newExpense.reference"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Description</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <textarea
                          v-model="newExpense.description"
                          style="height: auto !important; min-height: unset"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4"></div>
                    <div class="col-lg-4 col-5">
                      <wolf-button
                        caption="Save"
                        @clicked="handleSubmit"
                        :disabler="submitDisabled"
                        activator="SAVING"
                        classes="btn btn-primary"
                      ></wolf-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <expense-category-creator
      v-if="modalOpen"
      @closed="toggleModal"
      @saved="handleCreatedCategory"
    />
  </div>
</template>
<script>
import ExpenseCategoryCreator from "./ExpenseCategoryCreator.vue";
export default {
  name: "ExpenseCreator",
  components: {
    ExpenseCategoryCreator,
  },
  data: () => ({
    record: {},
    newExpense: {
      receipts: [],
      expense_date: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
      merchant: null,
      category_id: null,
      amount: null,
      description: null,
      reference: null,
      tax_inclusive: 0,
      has_invoice: 0,
      branch_id: null,
      paid_through: null,
    },
    categories: [],
    selectedCategory: null,
    categoryKey: null,
  }),
  computed: {
    submitDisabled() {
      return (
        !this.newExpense.amount ||
        !this.newExpense.category_id ||
        !this.newExpense.expense_date ||
        !this.newExpense.paid_through ||
        !this.newExpense.branch_id
      );
    },
    branches() {
      return this.$store.state.branches || [];
    },
    filteredCategories() {
      let categories = this.categories;
      let key = this.categoryKey && this.categoryKey.toLowerCase();
      if (key) {
        categories = categories.filter((row) => {
          return row.name.toLowerCase().indexOf(key) > -1;
        });
      }
      return categories;
    },
  },
  beforeCreate() {
    const reference = this.$route.params.reference;
    if (reference) {
      this.$http.get(`/expenses/show/${reference}`).then((response) => {
        this.newExpense = { ...response.data.expense };
        this.newExpense.expense_date = new Date(this.newExpense.expense_date)
          .toLocaleString("fr-CA", { timeZone: TIMEZONE })
          .slice(0, 10);
      });
    }
  },
  mounted() {
    this.$http.get("expenses/categories").then((response) => {
      this.categories = response.data.rows;
      this.$nextTick(() => {
        if (!this.$helper.empty(this.loggedUser.branch)) {
          this.newExpense.branch_id = this.loggedUser.branch.id;
        }
        if (this.newExpense.id) {
          this.selectedCategory = this.categories.find(
            (item) => item.id == this.newExpense.category_id
          ).name;
        }
      });
    });
    this.getPaymentsAccounts();
  },
  methods: {
    setCategory(category) {
      this.newExpense.category_id = category.id;
      this.selectedCategory = category.name;
    },
    handleCreatedCategory(category) {
      this.categories.unshift(category);
      this.toggleModal();
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.$helper.empty(this.loggedUser.branch)) {
            this.newExpense.branch_id = this.loggedUser.branch.id;
          }
          this.$http
            .post(
              "expenses/store",
              this.$helper.generateFormData(this.newExpense)
            )
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: "Record saved successfully",
                });

                if (this.newExpense.id)
                  this.$router.replace({ name: "Expenses" });
                else
                  Object.assign(this.newExpense, {
                    receipts: [],
                    expense_date: new Date(TODAY)
                      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
                      .slice(0, 10),
                    merchant: null,
                    category_id: null,
                    amount: null,
                    description: null,
                    reference: null,
                    tax_inclusive: 0,
                    has_invoice: 0,
                    branch_id: null,
                    paid_through: null,
                  });
                this.selectedCategory = null;
                if (!this.$helper.empty(this.loggedUser.branch)) {
                  this.newExpense.branch_id = this.loggedUser.branch.id;
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
