<template>
  <div class="col-xxl-6">
    <div class="card card-full card-bordered">
      <div class="nk-ecwg nk-ecwg8 h-100">
        <div class="card-inner">
          <div class="card-title-group mb-3">
            <div class="card-title">
              <h6 class="title">Receives Vs Transfers</h6>
            </div>
          </div>
          <ul class="nk-ecwg8-legends">
            <li>
              <div class="title">
                <span
                  class="dot dot-lg sq"
                  style="background-color: #6576ff"
                ></span
                ><span>Total Receives</span>
              </div>
            </li>
            <li>
              <div class="title">
                <span
                  class="dot dot-lg sq"
                  style="background-color: #eb6459"
                ></span
                ><span>Total Transfers</span>
              </div>
            </li>
          </ul>
          <div class="nk-ecwg8-ck">
            <line-chart
              ref="receivesTransfersChart"
              :chart-data="chartData"
              :options="options"
            ></line-chart>
          </div>
          <div class="chart-label-group ps-5">
            <div class="chart-label">
              {{ $helper.formatDate(records.firstDay) }}
            </div>
            <div class="chart-label">
              {{ $helper.formatDate(records.lastDay) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/LineChart";
export default {
  name: "InOutChart",
  props: ["records"],
  components: { LineChart },
  data: () => ({
    options: {
      legend: {
        display: false,
        rtl: false,
        labels: { boxWidth: 12, padding: 20, fontColor: "#6783b8" },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        rtl: false,
        callbacks: {
          title: function (a, t) {
            return t.labels[a[0].index];
          },
          label: function (a, t) {
            return t.datasets[a.datasetIndex].data[a.index];
          },
        },
        backgroundColor: "#1c2b46",
        titleFontSize: 13,
        titleFontColor: "#fff",
        titleMarginBottom: 6,
        bodyFontColor: "#fff",
        bodyFontSize: 12,
        bodySpacing: 4,
        yPadding: 10,
        xPadding: 10,
        footerMarginTop: 0,
        displayColors: false,
      },
      scales: {
        yAxes: [
          {
            display: true,
            stacked: false,
            position: "left",
            ticks: {
              beginAtZero: true,
              fontSize: 11,
              fontColor: "#9eaecf",
              padding: 10,
              callback: function (e) {
                return 999 < Math.abs(e)
                  ? Math.sign(e) * (Math.abs(e) / 1e3).toFixed(1) + " k"
                  : Math.sign(e) * Math.abs(e);
              },
              stepSize: 3e3,
            },
            gridLines: {
              color: "rgba(82,100,132,0.2)",
              tickMarkLength: 0,
              zeroLineColor: "rgba(82,100,132,0.2)",
            },
          },
        ],
        xAxes: [
          {
            display: false,
            stacked: false,
            ticks: {
              fontSize: 9,
              fontColor: "#9eaecf",
              source: "auto",
              padding: 10,
              reverse: false,
            },
            gridLines: {
              color: "transparent",
              tickMarkLength: 0,
              zeroLineColor: "transparent",
            },
          },
        ],
      },
    },
  }),
  computed: {
    chartData() {
      return {
        labels: this.records.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.4,
        datasets: [
          {
            label: "Receives",
            borderColor: "#6576ff",
            dash: 0,
            backgroundColor: "rgba(157,114,255,0.15)",
            data: this.records.receives,
          },
          {
            label: "Transfers",
            borderColor: "#eb6459",
            dash: [5],
            backgroundColor: "transparent",
            data: this.records.transfers,
          },
        ],
      };
    },
  },
};
</script>
<style>
.nk-ecwg8-ck div {
  height: 100%;
  max-height: 100%;
}
</style>
