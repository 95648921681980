<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div
                class="alert alert-fill alert-warning alert-icon"
                v-if="!$helper.empty(record)"
              >
                <em class="icon ni ni-alert-circle"></em>
                <strong>Warning!</strong>. Make sure that items you are going to
                edit or delete are not involved in any transaction.
              </div>
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <template v-if="$helper.empty(record)">
                        <span>New Stock Adjustment</span>
                      </template>
                      <template v-else>
                        <span
                          >Edit Stock Adjustment &nbsp;
                          <a href="javascript:void(0)"
                            >#{{ record.code }}</a
                          ></span
                        >
                      </template>
                    </h3>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            style="width: 150px"
                            v-if="
                              isProductionItems &&
                              $helper.empty(loggedUser.source)
                            "
                          >
                            <multiselect
                              v-model="productionSource"
                              :options="sources"
                              placeholder="Production Site"
                              label="name"
                              track-by="name"
                              @select="getProductionItems"
                            ></multiselect>
                          </li>
                          <li
                            style="width: 150px"
                            v-if="
                              $helper.empty(loggedUser.branch) && isBranchItems
                            "
                          >
                            <multiselect
                              v-model="selectedBranch"
                              :options="branches"
                              placeholder="Select Branch"
                              label="name"
                              track-by="name"
                              @select="getBranchStockItems"
                            ></multiselect>
                          </li>
                          <li
                            v-show="
                              (!isProductionItems ||
                                !$helper.empty(productionSource)) &&
                              (!isBranchItems || !$helper.empty(selectedBranch))
                            "
                          >
                            <type-head
                              :url="
                                isProductionItems
                                  ? productionUrl
                                  : isBranchItems
                                  ? branchUrl
                                  : 'stock/products/search'
                              "
                              label="name"
                              tracker="name"
                              placeholder="Select an item"
                              @changed="handleSelectedItem"
                              ref="itemSelector"
                            ></type-head>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Item Name</span></div>
                    <div class="nk-tb-col"><span>Qty available</span></div>
                    <div class="nk-tb-col">
                      <span>New Qty on hand </span>
                    </div>
                    <div class="nk-tb-col">
                      <span>Qty Adjusted(<em>Eg. +10, -10</em>)</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      {{
                        `${$helper.formatNumber(product.current_qty)} ${
                          product.unit
                        }`
                      }}
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required|min:0'"
                          class="form-control"
                          placeholder="Quantity"
                          @input="findQtyAdjusted(product)"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Quantity' + i)"
                          >Quantity is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Adjusted' + i"
                          v-model="items[i].adjusted"
                          :class="{ 'is-invalid': errors.has('Adjusted' + i) }"
                          v-validate="'required'"
                          class="form-control"
                          placeholder="Eg. +10, -10"
                          @input="findQtyInHands(product)"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Adjusted' + i)"
                          >Adjusted is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        v-if="!$helper.empty(record)"
                        @click.prevent="
                          $alert({
                            title: 'Removing from adjustment record',
                            content: `Are you sure you want to remove this item (${product.name})?`,
                            classes: 'btn btn-danger',
                            actionText: 'Continue',
                            closeText: 'Discard',
                            action: () => {
                              handleItemDeletion(product, i);
                            },
                          })
                        "
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                      <a
                        v-else
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <bootstrap-modal
                    @close="toggleModal"
                    style="display: block"
                    :modalSize="'modal-default'"
                    :showFooter="false"
                    v-if="modalOpen"
                  >
                    <span slot="head" style="padding-top: 10px"
                      >Confirm Adjustment</span
                    >
                    <div slot="body">
                      <div class="form-group row align-items-center">
                        <label class="mb-0 col-sm-4 required">Date</label>
                        <div class="col-sm-8">
                          <input
                            type="date"
                            v-model="adjustment_date"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="mb-0 col-sm-4 required">Reason</label>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            v-model="reason"
                            class="form-control"
                            v-validate="'required'"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="mb-0 col-sm-4">Description</label>
                        <div class="col-sm-8">
                          <textarea
                            type="date"
                            v-model="description"
                            class="form-control"
                            placeholder="Max. 500 characters"
                          ></textarea>
                        </div>
                      </div>
                      <div class="form-group col-12">
                        <wolf-button
                          classes="btn btn-primary ml-2"
                          @clicked="handleSubmit"
                          :disabler="submitDisabled"
                          activator="saving_records"
                        >
                          <span slot="caption"
                            ><em class="icon ni ni-save"></em
                            ><span>Save</span></span
                          >
                        </wolf-button>
                      </div>
                    </div>
                  </bootstrap-modal>
                  <div class="w-100">
                    <div
                      class="d-flex justify-content-end align-items-center actions w-100"
                    >
                      <div>
                        <button
                          class="btn btn-primary"
                          @click="toggleModal"
                          :disabled="!items.length"
                          type="button"
                        >
                          <span slot="caption"
                            ><span>Continue</span
                            ><em class="icon ni ni-arrow-right"></em
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added items</h6>
                              <p>
                                No items found in adjustment list. Search for
                                product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "Adjustment",
  components: { Multiselect, TypeHead, BootstrapModal },
  data: () => ({
    sources: [],
    items: [],
    rows: [],
    selectedItemIds: [],
    description: null,
    reason: null,
    mode: null,
    adjustment_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
    isEditMode: false,
    record: {},
    stock: "MAIN_STOCK",
    productionSource: null,
    selectedBranch: null,
    productionUrl: "",
    branchUrl: "",
  }),
  computed: {
    submitDisabled() {
      return (
        !this.reason ||
        !this.items.length ||
        (this.isProductionItems && !this.productionSource)
      );
    },
    isProductionItems() {
      return this.$route.name == "NewProductionAdjustment";
    },
    isBranchItems() {
      return this.$route.name == "NewBranchAdjustment";
    },
    branches() {
      return this.$store.state.branches || [];
    },
  },
  beforeCreate() {
    const action = this.$route.params.action;
    const reference = this.$route.params.reference;
    if (typeof action == "string" && action == "edit") {
      localStorage.removeItem("adjustedItems");
      this.$http
        .get(`stock/adjustments/${reference}/details`)
        .then((response) => {
          const items = response.data.items;
          this.record = response.data.record;
          this.productionSource = this.record.source;
          this.selectedBranch = this.record.branch;
          this.adjustment_date = new Date(this.record.adjustment_date)
            .toLocaleString("fr-CA", { timeZone: TIMEZONE })
            .slice(0, 10);
          items.forEach((item) => {
            this.items.push({
              id: item.product.id,
              name: item.product.name,
              unit: item.product.unit,
              source: item.product.source,
              quantity: item.details.quantity - item.details.adjusted,
              adjusted: item.details.adjusted,
              current_qty: item.product.stock_qty || item.product.quantity,
            });
            this.selectedItemIds.push(item.product.id);
          });
        });
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$helper.empty(this.loggedUser.source)) {
        this.productionSource = this.loggedUser.source;
        this.getProductionItems(this.productionSource);
      }
      if (!this.$helper.empty(this.loggedUser.branch)) {
        this.selectedBranch = this.loggedUser.branch;
        this.getBranchStockItems(this.selectedBranch);
      }
    });
    if (this.isProductionItems && this.$helper.empty(this.loggedUser.source)) {
      this.$http.get("production/sources").then((response) => {
        this.sources = response.data.sources;
      });
    }
  },
  created() {
    const reference = this.$route.params.reference;
    if (!reference) {
      const cart = localStorage.getItem("adjustedItems");

      if (cart) {
        const result = JSON.parse(cart);
        /**
         * Check if saved items belongs to the current route
         */
        if (this.isProductionItems) {
          if (!result.isProduction) {
            localStorage.removeItem("adjustedItems");
          } else {
            this.items = result.items;
            this.selectedItemIds = result.selectedItemIds;
          }
        } else {
          if (result.isProduction) {
            localStorage.removeItem("adjustedItems");
          } else {
            this.items = result.items;
            this.selectedItemIds = result.selectedItemIds;
          }
        }
        //this.items = result.items;
        //this.selectedItemIds = result.selectedItemIds;
      }
    } else localStorage.removeItem("adjustedItems");
    const action = this.$route.query.action;
    this.isEditMode = typeof action == "string" && action == "edit";
  },
  beforeDestroy() {
    if (this.items.length) {
      if (!this.isEditMode)
        localStorage.setItem(
          "adjustedItems",
          JSON.stringify({
            items: this.items,
            selectedItemIds: this.selectedItemIds,
            isProduction: this.isProductionItems,
          })
        );
      else localStorage.removeItem("adjustedItems");
    }
  },
  watch: {
    items() {
      if (!this.items.length) {
        localStorage.removeItem("adjustedItems");
      }
    },
    $route() {
      location.reload();
    },
  },
  methods: {
    getProductionItems(source) {
      this.productionUrl = `production/items/configured/${source.id}`;
      //this.productionSource = source;
      this.items = [];
      this.selectedItemIds = [];
      this.$refs.itemSelector.clearAll();
      this.$refs.itemSelector.getDefaultData(
        `production/items/configured/${source.id}`
      );
      localStorage.removeItem("adjustedItems");
    },
    getBranchStockItems(branch) {
      this.branchUrl = `stock/products/purchasable-products?branch_id=${branch.id}`;
      this.items = [];
      this.selectedItemIds = [];
      this.$refs.itemSelector.clearAll();
      this.$refs.itemSelector.getDefaultData(this.branchUrl);
      localStorage.removeItem("adjustedItems");
    },
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
      localStorage.setItem(
        "adjustedItems",
        JSON.stringify({
          items: this.items,
          selectedItemIds: this.selectedItemIds,
          isProduction: this.isProductionItems,
        })
      );
    },
    handleItemDeletion(item, key) {
      this.$http
        .get(`orders/items/details/${item.item_id}/delete`)
        .then((response) => {
          if (response.data.status) {
            this.items.splice(key, 1);
            this.selectedItemIds.splice(key, 1);
          }
        });
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        const currentQty = item.stock_qty || item.quantity;
        this.items = this.$helper.handleArrayPush(this.items, {
          id: item.id,
          name: item.name,
          unit: item.unit,
          source: item.source,
          quantity: currentQty,
          adjusted: 0,
          current_qty: currentQty,
        });
        this.selectedItemIds = this.$helper.handleArrayPush(
          this.selectedItemIds,
          item.id
        );
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    findQtyAdjusted(item) {
      item.adjusted = -(Number(item.current_qty) - Number(item.quantity));
    },
    findQtyInHands(item) {
      item.quantity = Number(item.current_qty) + Number(item.adjusted);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.isProductionItems) {
            this.stock = "PRODUCTION";
          }
          if (this.isBranchItems) {
            this.stock = "BRANCH_ITEMS";
          }
          this.$http
            .post(
              "stock/adjustment",
              this.$helper.generateFormData({
                reason: this.reason,
                mode: this.mode,
                adjustment_date: this.adjustment_date,
                description: this.description,
                items: this.items,
                record: this.record.id || null,
                record_items: this.selectedItemIds,
                stock: this.stock,
                source: !this.$helper.empty(this.productionSource)
                  ? this.productionSource.id
                  : null,
                branch: !this.$helper.empty(this.selectedBranch)
                  ? this.selectedBranch.id
                  : null,
              })
            )
            .then((response) => {
              this.toggleModal();
              if (response.data.status) {
                localStorage.removeItem("adjustedItems");

                // this.adjustment_date = new Date(TODAY)
                //   .toLocaleString("fr-CA", { timeZone: TIMEZONE })()
                //   .slice(0, 10);

                this.items = [];
                this.selectedItemIds = [];

                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });

                if (!this.$helper.empty(this.record)) {
                  if (this.isBranchItems) {
                    this.$router.replace({ name: "BranchAdjustments" });
                  } else if (this.isProductionItems) {
                    this.$router.replace({ name: "ProductionAdjustments" });
                  } else {
                    this.$router.replace({ name: "InventoryAdjustments" });
                  }
                }
              }
            })
            .catch((err) => {
              this.toggleModal();
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
