<script>
import BulkItemsSelect from "@/components/BulkItemsSelect.vue";
import TypeHead from "@/components/TypeHead.vue";
import ClientCreator from "@/index/Shared/ClientCreator.vue";
import PaymentHandler from "@/index/Shared/PaymentHandler.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "SalesInvoicesCreator",
  components: {
    TypeHead,
    Multiselect,
    ClientCreator,
    BulkItemsSelect,
    PaymentHandler,
  },
  data: () => ({
    newSale: {
      reason: "BRANCH_SALE",
      type: "BRANCH_SALE",
      record_id: null,
      committed_date: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
      branch: null,
      client: null,
    },
    items: [],
    record: {},
    branches: [],
    clientModalOpen: false,
  }),
  created() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$http.get(`sales/list/${reference}`).then((response) => {
        let items = response.data.items;
        items.forEach((item) => {
          this.items.push({
            id: item.product.id,
            name: item.product.name,
            quantity: item.quantity,
            initial_qty: item.quantity,
            total_amount: item.amount,
          });
        });
        this.record = response.data.record;
        this.newSale.record_id = this.record.id;
        this.newSale.client = this.record.client;
        this.newSale.branch = this.record.branch;
        this.newSale.committed_date = new Date(this.record.committed_date)
          .toLocaleString("fr-CA", { timeZone: TIMEZONE })
          .slice(0, 10);
        this.newSale.payment_date = new Date(this.record.payment_date)
          .toLocaleString("fr-CA", { timeZone: TIMEZONE })
          .slice(0, 10);
      });
    }
  },
  mounted() {
    this.$http.get("branches").then((response) => {
      if (response.data.status) {
        this.branches = response.data.branches;
      }
    });
    if (!this.$helper.empty(this.loggedUser.branch)) {
      this.newSale.branch = this.loggedUser.branch;
    }
    this.getPaymentsMode();
  },
  computed: {
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    submitDisabled() {
      return (
        !this.newSale.branch ||
        !this.items.length ||
        this.items.some((item) => !item.total_amount || !item.quantity)
      );
    },
    selectedItems() {
      return this.items.map((item) => item.id);
    },
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
    },
    handleSelectedItem(item, qty = 1) {
      if (!this.selectedItems.includes(item.id)) {
        this.items = this.$helper.handleArrayPush(this.items, {
          id: item.id,
          name: item.name,
          quantity: qty,
          total_amount: 0,
        });
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    handleBulkItems(args) {
      args.forEach((row) => this.handleSelectedItem(row.item, row.quantity));
      this.toggleModal();
    },
    resetClient() {
      this.newSale.client = null;
      this.newSale.discount = 0;
    },
    handleSelectedClient(client) {
      this.newSale.client = client;
      this.newSale.discount = client.discount || 0;
      this.clientModalOpen = false;
    },
    handleSubmit() {
      let paymentInfo = this.$refs.paymentForm.handlePaymentInfo();
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.newSale.items = this.items;
          Object.assign(this.newSale, paymentInfo);
          this.$http
            .post(
              this.$helper.empty(this.record) ? "sales/create" : "sales/edit",
              this.$helper.generateFormData(this.newSale)
            )
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: "Invoice added successfully",
                });
                if (!this.$helper.empty(this.record)) {
                  this.$router.push({ name: "BranchesSalesReport" });
                }
                //this.branch = null;
                this.items = [];
                const usedBranch = this.newSale.branch;
                this.newSale = {
                  reason: "BRANCH_SALE",
                  type: "BRANCH_SALE",
                  record_id: null,
                  committed_date: new Date(TODAY)
                    .toLocaleString("fr-CA", { timeZone: TIMEZONE })
                    .slice(0, 10),
                  branch: usedBranch,
                  client: null,
                };
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    handleItemDeletion(item, i) {
      this.$http
        .get(`sales/delete/${this.record.id}/${item.id}`)
        .then((response) => {
          if (response.data.status) {
            this.items.splice(i, 1);
          }
        });
    },
  },
};
</script>
<template>
  <div class="container-fluid">
    <client-creator
      @closed="clientModalOpen = false"
      @saved="handleSelectedClient"
      v-if="clientModalOpen"
    ></client-creator>
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block"></div>
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <template v-if="$helper.empty(record)">
                        <span>New Sales Invoice</span>
                      </template>
                      <template v-else>
                        <span
                          >Edit Sales Invoice &nbsp;
                          <a href="javascript:void(0)"
                            >#{{ $helper.generateVoucherNo(record.id) }}</a
                          ></span
                        >
                      </template>
                    </h3>
                    <div class="nk-block-des text-soft"></div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div id="bill-details">
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-sm-4">
                      <label class="">Client</label>
                    </div>
                    <div class="col-lg-4 col-sm-8">
                      <div class="form-group mb-5">
                        <div class="d-block">
                          <type-head
                            url="shared/clients/search"
                            label="name"
                            tracker="name"
                            :loadDefaults="true"
                            placeholder="Client"
                            @changed="handleSelectedClient"
                            v-show="$helper.empty(newSale.client)"
                          ></type-head>
                          <div
                            class="form-control-wrap"
                            v-if="!$helper.empty(newSale.client)"
                          >
                            <div
                              class="form-text-hint"
                              @click="resetClient"
                              style="cursor: pointer"
                            >
                              <span class="overline-title text-danger"
                                ><em class="icon ni ni-cross"></em
                              ></span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              :value="newSale.client.name"
                            />
                          </div>
                          <a
                            href="javascript:void(0)"
                            class="w-100"
                            style="text-decoration: underline; float:right; text-center"
                            @click.prevent="clientModalOpen = true"
                            >Add New Client?</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row align-items-center"
                    v-if="$helper.empty(loggedUser.branch)"
                  >
                    <div class="col-lg-3 col-sm-4">
                      <label class="required">Branch</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <multiselect
                          v-model="newSale.branch"
                          :options="branches"
                          placeholder="Select Branch"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-sm-4">
                      <label class="required">Date</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <input
                          type="date"
                          v-model="newSale.committed_date"
                          class="form-control"
                          placeholder="Bill Date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="pt-3 w-100 d-flex align-content-center justify-content-between"
                >
                  <h6 class="m-0">Total Items Added({{ items.length }})</h6>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="toggleModal"
                  >
                    <em class="icon ni ni-plus"></em><span>Add Items</span>
                  </button>
                </div>
                <div class="nk-tb-list is-compact my-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Item Details</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Tot. Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-group">
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required|min:1'"
                          class="form-control"
                          placeholder="Quantity"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Quantity' + i)"
                          >Quantity is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-group">
                        <input
                          type="number"
                          :name="'Amount' + i"
                          v-model="items[i].total_amount"
                          :class="{ 'is-invalid': errors.has('Amount' + i) }"
                          v-validate="'required|min:1'"
                          class="form-control"
                          placeholder="Amount"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Amount' + i)"
                          >Amount is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        v-if="!$helper.empty(record)"
                        @click.prevent="
                          $alert({
                            title: 'Deleting Item',
                            content: `Are you sure you want to remove this item (${product.name})?`,
                            classes: 'btn btn-danger',
                            actionText: 'Continue',
                            closeText: 'Discard',
                            action: () => {
                              handleItemDeletion(product, i);
                            },
                          })
                        "
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                      <a
                        v-else
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="row justify-content-end">
                    <PaymentHandler
                      class="col-lg-6 ml-auto mr-4 rounded-xlg grey-bg"
                      :total="grandTotal"
                      ref="paymentForm"
                    >
                      <div slot="foot">
                        <wolf-button
                          classes="btn btn-primary ml-2"
                          @clicked="handleSubmit"
                          :disabler="submitDisabled"
                          activator="saving_records"
                        >
                          <span slot="caption"
                            ><em class="icon ni ni-save"></em
                            ><span>Save</span></span
                          >
                        </wolf-button>
                      </div>
                    </PaymentHandler>
                  </div>
                </div>
                <div class="d-block text-center" v-else>
                  <div>
                    <span>No Items added</span>
                  </div>
                </div>
                <bulk-items-select
                  fetch-url="branches/sales-group-items"
                  data-key="rows"
                  v-if="modalOpen"
                  @closed="toggleModal"
                  @confirmed="handleBulkItems"
                  :showQuantity="false"
                ></bulk-items-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
