<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      Invoices Bulk Payment
                    </h5>
                  </div>
                  <div class="nk-block-head-content"></div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Due Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Voucher No</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Client</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Total</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Paid</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Balance</span>
                    </div>
                    <div class="nk-tb-col">Amount To Pay</div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in items"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        $helper.formatDate(row.committed_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'SaleVoucherPrint',
                          params: {
                            reference: row.code,
                          },
                        }"
                        >#{{ $helper.generateVoucherNo(row.id) }}</router-link
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.client }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{ formatMoney(row.total) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{ formatMoney(row.amount_paid) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{ formatMoney(row.balance) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              {{ appSettings.currency }}
                            </span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Payment Amount"
                            v-model="items[i].amount"
                            v-validate="'required|max_value:' + items[i].amount"
                            :name="'Amount' + i"
                            :class="{
                              'is-invalid': errors.has('Amount' + i),
                            }"
                          />
                        </div>
                        <span class="text-danger">{{
                          errors.first("Amount" + i)
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="nk-tb-item">
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"><h6>Grand Total</h6></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>{{ formatMoney(totalBalance) }}</h6>
                    </div>
                    <div class="nk-tb-col">
                      <h6>{{ formatMoney(grandTotal) }}</h6>
                    </div>
                  </div>
                </div>
                <div class="w-100">
                  <div
                    class="
                      d-flex
                      justify-content-end
                      align-items-center
                      actions
                      w-100
                    "
                  >
                    <div class="col-2 px-1">
                      <div class="form-group">
                        <label class="form-label">Issued date</label>
                        <div class="form-control-wrap">
                          <input
                            type="date"
                            v-model="committed_date"
                            class="form-control"
                            placeholder="Issued date"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-2 px-1">
                      <div class="form-group">
                        <label class="form-label">Payment Mode</label>
                        <div class="form-control-select">
                          <select class="form-control" v-model="payment_type">
                            <option value="null" selected hidden>
                              Payment Method
                            </option>
                            <option
                              v-for="row in paymentModes"
                              :key="row.id"
                              :value="row.id"
                            >
                              {{ row.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-2 px-1">
                      <div class="form-group">
                        <label class="form-label">Deposited To:</label>
                        <div class="form-control-select">
                          <select class="form-control" v-model="account_id">
                            <option value="null" selected hidden>
                              Deposited To
                            </option>
                            <option
                              v-for="row in accounts"
                              :key="row.id"
                              :value="row.id"
                            >
                              {{ row.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-2 px-1">
                      <div class="form-group">
                        <label class="form-label">Reference</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="payment_ref"
                          placeholder="Payment REF"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label invisible d-block">btn</label>
                      <wolf-button
                        caption="Save"
                        @clicked="handleSubmit"
                        :disabler="
                          !items.length || !payment_type || !account_id
                        "
                        activator="handling_payment"
                        classes="btn btn-primary"
                      ></wolf-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "InvoicesBulkPayment",
  data: () => ({
    vouchers: [],
    items: [],
    payment_type: null,
    account_id: null,
    payment_ref: null,
    committed_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
  }),
  computed: {
    grandTotal() {
      return this.items.reduce((a, b) => a + Number(b.amount), 0).toFixed(3);
    },
    totalBalance() {
      return this.items.reduce((a, b) => a + Number(b.balance), 0).toFixed(3);
    },
  },
  created() {
    let vounchers = this.$route.query.vouchers;
    this.$http
      .get(`sales/customers/payment/vounchers/${vounchers}`)
      .then((response) => {
        this.vouchers = response.data.vouchers;
        this.vouchers.forEach((item) => {
          this.items.push({
            id: item.id,
            code: item.reference,
            client: item.client.name,
            committed_date: item.committed_date,
            amount: item.amount_remain,
            balance: item.amount_remain,
            total: item.discounted_total,
            amount_paid: item.amount_paid,
          });
        });
      });
  },
  methods: {
    handleSubmit() {
      this.$http
        .post(
          "sales/customers/payment/bulk",
          this.$helper.generateFormData({
            items: this.items,
            payment_type: this.payment_type,
            account_id: this.account_id,
            committed_date: this.committed_date,
            payment_ref: this.payment_ref,
          })
        )
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "success",
              message: response.data.message,
            });
            this.$router.go(-1);
          }
        })
        
    },
  },
  mounted() {
    this.getPaymentsMode();
    this.getPaymentsAccounts();
  },
};
</script>
