<template>
  <div class="w-100">
    <div class="card-inner card-inner-lg">
      <div class="nk-block-head nk-block-head-lg">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h4 class="nk-block-title">Your Login Activities</h4>
            <div class="nk-block-des">
              <p>
                Here is your last 20 login activities log.
                <span class="text-soft"><em class="icon ni ni-info"></em></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="nk-block card">
        <table class="table table-ulogs">
          <thead class="table-light">
            <tr>
              <th class="tb-col-os">
                <span class="overline-title"
                  >Browser <span class="d-sm-none">/ IP</span></span
                >
              </th>
              <th class="tb-col-ip"><span class="overline-title">IP</span></th>
              <th class="tb-col-time">
                <span class="overline-title">Time</span>
              </th>
              <th class="tb-col-action">
                <span class="overline-title">&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <td class="tb-col-os">{{ row.user_agent }}</td>
              <td class="tb-col-ip">
                <span class="sub-text">{{ row.ip }}</span>
              </td>
              <td class="tb-col-time">
                <span class="sub-text">
                  {{ dayjs(row.login_time).format("MMM D, YYYY h:mm A") }}</span
                >
              </td>
              <td class="tb-col-action"></td>
            </tr>
          </tbody>
        </table>
        <loading-dots v-if="showLoader" />
      </div>
    </div>
  </div>
</template>
<script>
import LoadingDots from "@/components/LoadingDots.vue";
import dayjs from "dayjs";
export default {
  components: { LoadingDots },
  name: "Logins",
  data: () => ({
    rows: [],
    dayjs: dayjs,
  }),
  computed: {
    showLoader() {
      return this.$store.state.requestFlag == "GET_LOGIN_HISTORY";
    },
  },
  created() {
    this.$store.commit("SET_REQUEST_FLAG", "GET_LOGIN_HISTORY");
    this.$http.get("auth/logins").then((response) => {
      this.rows = response.data.rows;
    });
  },
};
</script>
