<template>
  <div class="w-100">
    <div class="card-inner card-inner-lg">
      <div class="nk-block-head nk-block-head-lg">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h4 class="nk-block-title">Security Settings</h4>
            <div class="nk-block-des">
              <p>Change your password to keep your account secured.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="nk-block">
        <div class="card">
          <div class="card-inner-group">
            <div class="card-inner">
              <div class="row g-3 align-center">
                <div class="col-5">
                  <div class="form-group">
                    <label class="form-label">Current Password</label>
                    <span class="form-note">Your existing password here.</span>
                  </div>
                </div>
                <div class="col-7">
                  <div class="form-group">
                    <div class="form-control-wrap">
                      <input
                        :type="
                          visibles.includes('current') ? 'text' : 'password'
                        "
                        class="form-control form-control-lg"
                        placeholder="Your current password"
                        name="Current Password"
                        :class="{
                          'is-invalid': errors.has('Current Password'),
                        }"
                        v-validate="'required|min:6'"
                        v-model="newCredentials.current_password"
                      />
                      <a
                        href="javascript:void(0)"
                        @click.prevent="handleVisibility('current')"
                        class="form-icon form-icon-right passcode-switch"
                        ><em
                          class="passcode-icon icon-show icon ni ni-eye"
                          v-if="visibles.includes('current')"
                        ></em
                        ><em
                          class="
                            passcode-icon
                            icon-hide icon
                            ni ni-eye-off
                            d-block
                          "
                          v-else
                        ></em
                      ></a>
                    </div>
                    <span class="text-danger">{{
                      errors.first("Current Password")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row g-3 align-center">
                <div class="col-5">
                  <div class="form-group">
                    <label class="form-label">New Password</label>
                    <span class="form-note">Choose a new Password.</span>
                  </div>
                </div>
                <div class="col-7">
                  <div class="form-group">
                    <div class="form-control-wrap">
                      <input
                        :type="visibles.includes('new') ? 'text' : 'password'"
                        class="form-control form-control-lg"
                        placeholder="Type a new password"
                        name="New Password"
                        :class="{ 'is-invalid': errors.has('New Password') }"
                        v-validate="'required|min:6'"
                        v-model="newCredentials.new_password"
                        ref="password"
                      />
                      <a
                        href="javascript:void(0)"
                        @click.prevent="handleVisibility('new')"
                        class="form-icon form-icon-right passcode-switch"
                        ><em
                          class="passcode-icon icon-show icon ni ni-eye"
                          v-if="visibles.includes('new')"
                        ></em
                        ><em
                          class="
                            passcode-icon
                            icon-hide icon
                            ni ni-eye-off
                            d-block
                          "
                          v-else
                        ></em
                      ></a>
                    </div>
                    <span class="text-danger">{{
                      errors.first("New Password")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row g-3 align-center">
                <div class="col-5">
                  <div class="form-group">
                    <label class="form-label">Re-type new Password</label>
                    <span class="form-note">Confirm the new Password.</span>
                  </div>
                </div>
                <div class="col-7">
                  <div class="form-group">
                    <div class="form-control-wrap">
                      <input
                        :type="
                          visibles.includes('confirm') ? 'text' : 'password'
                        "
                        class="form-control form-control-lg"
                        placeholder="Confirm Password"
                        name="Confirm Password"
                        :class="{
                          'is-invalid': errors.has('Confirm Password'),
                        }"
                        v-validate="'required|confirmed:password|min:6'"
                      />
                      <a
                        href="javascript:void(0)"
                        @click.prevent="handleVisibility('confirm')"
                        class="form-icon form-icon-right passcode-switch"
                        ><em
                          class="passcode-icon icon-show icon ni ni-eye"
                          v-if="visibles.includes('confirm')"
                        ></em
                        ><em
                          class="
                            passcode-icon
                            icon-hide icon
                            ni ni-eye-off
                            d-block
                          "
                          v-else
                        ></em
                      ></a>
                    </div>
                    <span class="text-danger">{{
                      errors.first("Confirm Password")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row g-3" v-if="errorMessage">
                <div class="col-12 mb-2">
                  <div class="alert alert-danger">
                    <span>{{ errorMessage }}</span>
                  </div>
                </div>
              </div>
              <div class="row g-3 align-center">
                <div class="col-5"></div>
                <div class="col-7">
                  <div class="form-group">
                    <wolf-button
                      caption="Confirm and Save"
                      button-type="button"
                      @clicked="handleSubmit"
                      :disabler="
                        !newCredentials.new_password ||
                        !newCredentials.current_password
                      "
                      activator="signing"
                      classes="btn btn-primary btn-lg"
                      >Submit</wolf-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Security",
  data: () => ({
    visibles: [],
    newCredentials: {
      current_password: null,
      new_password: null,
    },
    errorMessage: null,
  }),
  methods: {
    handleVisibility(reference) {
      if (this.visibles.includes(reference))
        this.visibles.splice(this.visibles.indexOf(reference), 1);
      else this.visibles.push(reference);
    },
    handleSubmit() {
      this.errorMessage = null;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.post("auth/change-password",
              this.$helper.generateFormData(this.newCredentials),
             )
            .then((response) => {
              if (response.data.status) {
                this.$helper.resetObjectValues(this.newCredentials);
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
              } else this.errorMessage = response.data.error;
            });
        }
      });
    },
  },
};
</script>
