<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-lg mx-auto">
          <div
            class="invoice break-inside"
            id="report-container"
            v-if="!$helper.empty(record)"
          >
            <div class="invoice-action">
              <a
                class="btn btn-icon btn-white btn-dim btn-outline-primary d-print-none"
                href="javascript:void(0)"
                @click.prevent="$helper.handlePrint('.invoice-wrap')"
                ><em class="icon ni ni-printer-fill"></em
              ></a>
            </div>
            <div class="invoice-wrap">
              <print-header>
                <div slot="title" class="text-center mb-1">
                  <h4 class="text-center">
                    Transfer Voucher #
                    <small style="font-size: 14px">{{
                      $helper.generateVoucherNo(record.id)
                    }}</small>
                  </h4>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <div class="invoice-contact">
                      <span class="overline-title">Branch</span>
                      <div class="invoice-contact-info">
                        <h6 class="title">
                          {{ record.branch?.name || record.source?.name }}
                        </h6>
                      </div>
                    </div>
                    <div class="invoice-desc">
                      <ul class="list-plain">
                        <li class="invoice-id">
                          <span>Voucher No:</span>:<span>{{
                            $helper.generateVoucherNo(record.id)
                          }}</span>
                        </li>
                        <li class="invoice-date">
                          <span>Date</span>:<span>{{
                            $helper.formatDate(record.delivery_date)
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </print-header>
              <div class="invoice-head d-print-none">
                <div class="invoice-contact">
                  <span class="overline-title">Branch</span>
                  <div class="invoice-contact-info">
                    <h6 class="title">
                      {{ record.branch?.name || record.source?.name }}
                    </h6>
                    <ul class="list-plain">
                      <li>
                        <em class="icon ni ni-map-pin-fill"></em
                        ><span>{{ record.branch?.location }}</span>
                      </li>
                      <li>
                        <em class="icon ni ni-call-fill"></em
                        ><span>{{ record.branch?.phone }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="invoice-desc">
                  <h6 class="title">Transfer</h6>
                  <ul class="list-plain">
                    <li class="invoice-id">
                      <span>Voucher No:</span>:<span>{{
                        $helper.generateVoucherNo(record.id)
                      }}</span>
                    </li>
                    <li class="invoice-date">
                      <span>Date</span>:<span>{{
                        $helper.formatDate(record.delivery_date)
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="invoice-bills">
                <table class="w-100">
                  <thead>
                    <tr>
                      <th class="">$/N</th>
                      <th class="d-print-none">Item Type</th>
                      <th class="">Item Name</th>
                      <th>Price</th>
                      <th>Qty</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in items" :key="'item' + i">
                      <td class="">#{{ i+1 }}</td>
                      <td class="d-print-none">
                        {{ item.product.type }}
                      </td>
                      <td>{{ item.product.name }}</td>
                      <td>
                        {{ formatMoney(item.price) }}
                      </td>
                      <td>{{ $helper.formatNumber(item.quantity) }}</td>
                      <td>
                        <span class="tb-sub tb-amount">{{
                          formatMoney(item.quantity * item.price)
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3" class="d-print-none"></td>
                      <td class="d-none d-print"></td>
                      <td colspan="2">Subtotal</td>
                      <td>
                        {{ formatMoney(record.total_amount) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="d-print-none"></td>
                      <td class="d-none d-print"></td>
                      <td colspan="2">Shipping fee</td>
                      <td>{{ appSettings.currency }}0.00</td>
                    </tr>
                    <tr>
                      <td colspan="3" class="d-print-none"></td>
                      <td class="d-none d-print"></td>
                      <td colspan="2">TAX</td>
                      <td>{{ appSettings.currency }}0.00</td>
                    </tr>
                    <tr>
                      <td colspan="3" class="d-print-none"></td>
                      <td class="d-none d-print"></td>
                      <td colspan="2">Grand Total</td>
                      <td>
                        {{ formatMoney(record.total_amount) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div class="nk-notes mt-5 d-none d-print">
                  <div class="row">
                    <div class="col-12 pb-3">
                      <div class="d-flex w-100 flex-nowrap mb-5">
                        <h6 class="mb-0">
                          <b>Comment/Observation:</b>
                        </h6>
                        <div class="dots-after"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="d-flex w-100 flex-nowrap mb-5">
                        <h6 class="mb-0">
                          <b>Received by:</b>
                        </h6>
                        <div class="dots-after"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="d-flex w-100 flex-nowrap mb-5">
                        <h6 class="mb-0">
                          <b>Delivered by:</b>
                        </h6>
                        <div class="dots-after"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "TransferVoucher",
  components: { PrintHeader },
  data: () => ({
    items: [],
    record: {},
  }),
  computed: {},
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$http
        .get(`/reports/transfer/${reference}/voucher`)
        .then((response) => {
          this.items = response.data.items;
          this.record = response.data.record;
        });
    }
  },
  methods: {},
};
</script>
