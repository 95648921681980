<template>
  <div class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch" id="report-container">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Recipes Costing Report
                    </h3>
                    <div class="nk-block-des text-soft d-print-none">
                      <p>You have total {{ totalRows }} records</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-control-wrap mb-2">
                                        <div class="form-icon form-icon-right">
                                          <em class="icon ni ni-search"></em>
                                        </div>
                                        <input
                                          type="search"
                                          class="form-control"
                                          id="default-04"
                                          placeholder="Search by product name"
                                          v-model="filterKey"
                                          @keyup="handlePaginationSearch"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.type"
                                        >
                                          <option value="all">All Types</option>
                                          <option value="BAR">BAR</option>
                                          <option value="KITCHEN">
                                            KITCHEN
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          v-model="newFilter.category"
                                          class="form-control custom-select"
                                        >
                                          <option value="all">All Items</option>
                                          <option
                                            :value="item.id"
                                            v-for="(
                                              item, i
                                            ) in filteredCategories"
                                            :key="'category' + i"
                                          >
                                            {{ item.name }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>

                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-search"></em
                                          ><span>Search</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="btn btn-primary d-md-inline-flex"
                              :disabled="!totalRows"
                              @click="$helper.handlePrint('.nk-block')"
                            >
                              <em class="icon ni ni-printer"></em
                              ><span>Print</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <print-header>
                  <div slot="title" class="text-center mb-1">
                    <h5 class="text-center">
                      Recipes Costing report /
                      <small style="font-size: 14px">{{ reportLabel }}</small>
                    </h5>
                  </div>
                </print-header>
                <template v-for="(product, k) in rows">
                  <div
                    class="nk-tb-list is-compact mb-3 break-inside"
                    v-if="product.configurations.length"
                    :key="'product' + k"
                  >
                    <div class="nk-tb-item nk-tb-head tb-caption">
                      <h6 class="mb-0 w-100">{{ product.name }}</h6>
                    </div>
                    <div class="nk-tb-item nk-tb-head">
                      <div class="nk-tb-col"><span>#</span></div>
                      <div class="nk-tb-col"><span>Item Name</span></div>
                      <div class="nk-tb-col"><span>Price</span></div>
                      <div class="nk-tb-col"><span>Quantity</span></div>
                      <div class="nk-tb-col"><span>Tot. Price</span></div>
                      <div class="nk-tb-col"><span>In Stock</span></div>
                      <div class="nk-tb-col"><span>Sales</span></div>
                      <div class="nk-tb-col"><span>Cost (%)</span></div>
                    </div>
                    <div
                      class="nk-tb-item"
                      v-for="(item, i) in product.configurations"
                      :key="k + 'item' + i"
                      loading="lazy"
                    >
                      <div class="nk-tb-col">
                        <span>#{{ i + 1 }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <div class="user-card">
                          <div class="user-name">
                            <span class="tb-lead">{{ item.name }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-sub tb-amount">{{
                          formatMoney(itemCost(item))
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span>{{ item.quantity }} {{ item.unit }} </span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-sub tb-amount">{{
                          formatMoney(itemTotalAmount(item))
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span></span>
                      </div>
                      <div class="nk-tb-col">
                        <span></span>
                      </div>
                      <div class="nk-tb-col">
                        <span></span>
                      </div>
                    </div>

                    <div class="nk-tb-item">
                      <div class="nk-tb-col">
                        <span></span>
                      </div>
                      <div class="nk-tb-col">
                        <span></span>
                      </div>
                      <div class="nk-tb-col">
                        <span></span>
                      </div>

                      <div class="nk-tb-col">
                        <span>Total Amount</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount"
                          >{{ formatMoney(product.cost_price) || "N/A" }}
                        </span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          $helper.formatNumber(product.quantity)
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-sub"
                          ><span class="tb-amount">
                            {{ formatMoney(product.price) || "N/A" }}</span
                          ></span
                        >
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-sub"
                          >{{
                            calculateItemPercentage(product).toFixed(2)
                          }}%</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "RecipesCosting",
  mixins: [dashboardServices],
  components: { PrintHeader },
  data: () => ({
    fetchUrl: "branches/items/recipes-costing-report",
    categories: [],
  }),
  computed: {
    filteredCategories() {
      let key = this.newFilter.type;
      let categories = this.categories;
      if (key && key != "all") {
        categories = categories.filter((row) => {
          return row.type == key;
        });
      }
      return categories;
    },
  },
  created() {
    this.newFilter.type = "all";
    this.newFilter.category = "all";
    this.$http.get(`stock/categories`).then((response) => {
      if (response.data.status) {
        this.categories = response.data.rows;
      }
    });
  },
  methods: {
    itemCost(item) {
      if (item.tariff) return this.$helper.formatNumber(item.tariff);
      else return this.$helper.formatNumber(item.cost);
    },
    itemTotalAmount(item) {
      if (item.tariff)
        return this.$helper.formatNumber(item.quantity * item.tariff);
      else return this.$helper.formatNumber(item.total_amount);
    },
    calculateItemPercentage(item) {
      let costPrice = item.cost_price || 0;
      let salesPrice = item.price || 0;
      if (!salesPrice) return 0;
      return (costPrice * 100) / salesPrice;
    },
  },
};
</script>
