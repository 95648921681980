<template>
  <div class="upload-box text-center">
    <div class="d-block align-items-center justify-content-center">
      <div
        class="text-center py-1 ml-auto"
        v-if="file_path"
        style="height: auto !important"
      >
        <img
          :src="`${apiURL}/${file_path}`"
          class="img img-thumbails rounded img-fluid"
          style="width: 60px"
          @click.prevent="$helper.showFilePicker('settings-image' + label)"
        />
      </div>

      <div
        class="ml-auto"
        v-else
        @click="$helper.showFilePicker('settings-image' + label)"
      >
        <p class="text-center">
          <img
            :src="`${publicPath}img/upload-icon.png`"
            class="img img-fluid"
            style="width: 60px"
          />
        </p>
      </div>
      <label class="mb-0 mt-2" v-html="label"></label>
    </div>
    <file-upload
      :input-id="'settings-image' + label"
      :extentions="['png', 'jpg', 'jpeg', 'gif', 'webp', 'ico']"
      @after-uploading="keepUploadedImage"
      :post-data="{
        uploadPath: $store.state.productsMediaPath,
      }"
    ></file-upload>
  </div>
</template>
<script>
import FileUpload from "@/components/FileUpload.vue";
export default {
  name: "InputFile",
  components: { FileUpload },
  props: ["label", "currentFile"],
  data: () => ({
    file_path: null,
    apiURL:
      process.env.NODE_ENV === "production"
        ? "/api/uploads"
        : "http://127.0.0.1:8000/uploads",
  }),
  watch: {
    currentFile() {
      this.file_path = this.currentFile;
    },
  },
  methods: {
    keepUploadedImage(image) {
      this.file_path = image;
      this.$emit("uploaded", image);
    },
  },
};
</script>
