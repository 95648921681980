<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-lg mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title mb-0">
                      Make Production
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p>
                        You have total {{ rows.length }} configured products
                      </p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right m-0">
                          <li
                            style="width: 150px"
                            v-if="this.$helper.empty(loggedUser.source)"
                          >
                            <multiselect
                              v-model="source"
                              :options="sources"
                              placeholder="Production Site"
                              label="name"
                              track-by="name"
                              @select="getProductionItems"
                            ></multiselect>
                          </li>
                          <template v-if="rows.length">
                            <li style="width: 150px">
                              <multiselect
                                v-model="newItem.product"
                                :options="rows"
                                placeholder="Select item"
                                label="name"
                                track-by="name"
                              ></multiselect>
                            </li>
                            <li style="width: 90px">
                              <input
                                type="number"
                                v-model="newItem.quantity"
                                class="form-control"
                                placeholder="Qty"
                              />
                            </li>
                            <li class="nk-block-tools-opt">
                              <div class="drodown">
                                <button
                                  type="button"
                                  class="dropdown-toggle btn btn-icon btn-primary"
                                  @click.prevent="handleSelectedItem"
                                  :disabled="
                                    !newItem.quantity ||
                                    $helper.empty(newItem.product)
                                  "
                                >
                                  <em class="icon ni ni-plus"></em>
                                </button>
                              </div>
                            </li>
                          </template>
                          <li class="nk-block-tools-opt">
                            <a
                              href="javascript:void(0)"
                              class="cart-alt"
                              @click.prevent="showCartItem"
                            >
                              <em
                                class="icon ni ni-cart"
                                style="font-size: 32px"
                              ></em>
                              <span class="badge bg-primary">{{
                                totalIngredients
                              }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <!-- <div class="nk-tb-col"><span>Code</span></div> -->
                    <div class="nk-tb-col"><span>Qty In</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Sale Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <!-- <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">#{{ product.code }}</span></span
                      >
                    </div> -->
                    <div class="nk-tb-col">
                      <span
                        >{{ items[i].current_qty }}
                        {{ items[i].unit || "" }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ `${formatMoney(items[i].cost) || 0}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ `${formatMoney(items[i].price)}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title"
                        >{{ items[i].quantity }} {{ items[i].unit }}</span
                      >
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added items</h6>
                              <p>
                                No items found in the production list. Search
                                for product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Cart Modal -->
    <off-canvas
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
      position="end"
    >
      <span
        slot="head"
        class="d-flex align-items-center justify-content-between"
        ><span>Ingredients in production Cart</span>
        <button
          type="button"
          class="btn btn-icon btn-white btn-dim btn-outline-light"
          @click="$helper.handlePrint('#cart-container')"
        >
          <em class="icon ni ni-printer"></em>
        </button>
      </span>
      <div slot="body">
        <div class="card card-full" id="cart-container">
          <print-header>
            <div slot="title" class="text-center mb-1">
              <h5 class="text-center">Ingredients in production Cart</h5>
            </div>
          </print-header>
          <div class="nk-tb-list mt-n2 is-compact">
            <div class="nk-tb-item nk-tb-head">
              <div class="nk-tb-col"><span>#</span></div>
              <div class="nk-tb-col"><span>Ingredient</span></div>
              <div class="nk-tb-col"><span>Cost</span></div>
              <div class="nk-tb-col"><span>Qty</span></div>
              <div class="nk-tb-col"><span>Amount</span></div>
            </div>
            <div
              class="nk-tb-item"
              v-for="(item, i) in cartItems"
              :key="'item' + i"
            >
              <div class="nk-tb-col">
                <span class="tb-lead"
                  ><a href="javascript:void(0)">#{{ i + 1 }}</a></span
                >
              </div>
              <div class="nk-tb-col">
                <div class="user-card">
                  <div class="user-name">
                    <span class="tb-lead">{{ item.name }}</span>
                  </div>
                </div>
              </div>
              <div class="nk-tb-col">
                <span class="tb-sub tb-amount">{{
                  formatMoney(item.cost)
                }}</span>
              </div>
              <div class="nk-tb-col">
                <span>{{
                  `${$helper.formatNumber(item.quantity)} ${item.unit}`
                }}</span>
              </div>
              <div class="nk-tb-col">
                <span class="tb-sub tb-amount">{{
                  formatMoney(item.total_amount)
                }}</span>
              </div>
            </div>
          </div>
          <template v-if="items.length">
            <hr class="preview-hr my-2" />
            <div class="w-100 d-flex align-items-center">
              <h5 class="mb-0">Total Amount</h5>
              <h6 class="mb-0 ml-auto">
                {{ formatMoney(totalAmount) }}
              </h6>
            </div>
            <hr class="preview-hr my-2" />
            <div class="w-100 d-print-none">
              <div class="text-right mb-2">
                <div class="g">
                  <div
                    class="custom-control custom-control-sm custom-checkbox checked"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheck7"
                      v-model="enable_requisition"
                      value="1"
                    /><label
                      class="custom-control-label h6"
                      style="font-size: 15px"
                      for="customCheck7"
                      >Immediately place requisition of selected
                      ingredients</label
                    >
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-end align-items-center actions w-100"
              >
                <div class="form-group col-3 px-1 mb-0">
                  <input
                    type="text"
                    v-model="initiator"
                    class="form-control"
                    placeholder="Employee Name"
                  />
                </div>
                <div class="form-group col-3 px-1 mb-0">
                  <input
                    type="date"
                    v-model="produce_date"
                    class="form-control"
                    placeholder="Produce Date"
                  />
                </div>
                <wolf-button
                  classes="btn btn-primary ml-2"
                  @clicked="handleSubmit"
                  :disabler="submitDisabled"
                  activator="saving_records"
                >
                  <span slot="caption"
                    ><em class="icon ni ni-save"></em><span>Save</span></span
                  >
                </wolf-button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </off-canvas>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import OffCanvas from "@/components/OffCanvas.vue";
import { Cart } from "./cart.js";
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "Create",
  components: { Multiselect, OffCanvas, PrintHeader },
  mixins: [Cart],
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    sources: [],
    source: null,
    initiator: null,
    produce_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
    newItem: {
      product: null,
      quantity: 1,
    },
    enable_requisition: null,
  }),
  computed: {
    submitDisabled() {
      return !this.source || !this.items.length || !this.initiator;
    },
  },
  beforeCreate() {
    this.$http.get("production/sources").then((response) => {
      this.sources = response.data.sources;
    });
  },
  methods: {
    resetDefaults() {
      this.rows = [];
      this.items = [];
      this.$helper.resetObjectValues(this.newItem);
      this.selectedItemIds = [];
      this.cartItems = [];
      localStorage.removeItem("cartItems");
    },
    getProductionItems(source) {
      this.resetDefaults();
      this.$http
        .get(`production/items/configured/${source.id}`)
        .then((response) => {
          this.rows = response.data.rows;
          if (!this.rows.length)
            this.$notifier({
              type: "warning",
              message: "No configured items available for " + source.name,
            });
        });
    },
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    handleSelectedItem() {
      var item = this.newItem.product;
      if (
        this.selectedItemIds.includes(item.id) ||
        this.$helper.empty(this.newItem.product)
      ) {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      } else {
        this.items.push({
          id: item.id,
          name: item.name,
          code: item.code,
          quantity: this.newItem.quantity,
          cost: item.cost_price || 0,
          price: item.price || 0,
          current_qty: item.stock_qty || 0,
          unit: item.unit,
        });
        this.selectedItemIds.push(item.id);
        this.getItemIngredients(item);
      }
    },
    getItemIngredients(product) {
      this.$http
        .get(`production/item/configurations/${product.id}`)
        .then((response) => {
          let ingredients = response.data.rows;
          this.getCartItems();
          ingredients.forEach((item) => {
            this.addToCart({
              id: item.ingredient,
              name: item.name,
              code: item.code,
              cost: item.cost || 0,
              price: item.price || 0,
              quantity: item.quantity * this.newItem.quantity,
              unit: item.unit,
              product: product.id,
              total_amount:
                Number(item.total_amount) * Number(this.newItem.quantity),
            });
          });
          localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
          this.$helper.resetObjectValues(this.newItem);
        });
    },
    showCartItem() {
      if (this.totalIngredients) this.toggleModal();
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .post(
              "production/create",
              this.$helper.generateFormData({
                source: this.source.id,
                items: this.items,
                initiator: this.initiator,
                total_amount: this.totalAmount,
                produce_date: this.produce_date,
                enable_requisition: this.enable_requisition,
              })
            )
            .then((response) => {
              if (response.data.status) {
                this.toggleModal();
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                //this.$router.replace({ name: "Requests" });
                this.resetDefaults();
              }
            })
            
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      const source = this.loggedUser.source;
      if (source) {
        this.source = source;
        this.getProductionItems(source);
      }
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
a.cart-alt {
  color: #232323;
  display: block;
  padding: 0px 12px;
  position: relative;
  padding-left: 0;
}
a.cart-alt span.badge {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  right: 5px;
  font-size: 10px;
  padding: 2px 0 0 1px;
  width: 20px;
  height: 21px;
  color: #fff;
  border-radius: 18px;
  line-height: 15px;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  white-space: nowrap;
}
</style>
