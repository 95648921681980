<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Auto Generated Requisitions
                    </h3>

                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} requisitions.</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            style="width: 200px"
                            v-if="$helper.empty(loggedUser.branch)"
                          >
                            <multiselect
                              v-model="branch"
                              :options="branches"
                              placeholder="Filter By branch"
                              label="name"
                              track-by="name"
                              @select="handleBranchFilter"
                            ></multiselect>
                          </li>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-control-wrap mb-2">
                                        <div class="form-icon form-icon-right">
                                          <em class="icon ni ni-search"></em>
                                        </div>
                                        <input
                                          type="search"
                                          class="form-control"
                                          id="default-04"
                                          placeholder="Search by Voucher No"
                                          v-model="filterKey"
                                          @keyup="handlePaginationSearch"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.type"
                                        >
                                          <option value="all">All Items</option>
                                          <option value="STOCK">
                                            MAIN STORABLE
                                          </option>
                                          <option value="PERISHABLES">
                                            PERISHABLES
                                          </option>
                                          <option value="PRODUCTION_STOCK">
                                            PRODUCTIONS
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.status"
                                        >
                                          <option value="all">All Items</option>
                                          <option>PENDING</option>
                                          <option>ACCEPTED</option>
                                          <option>DENIED</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col">
                      <span class="sub-text">Reference</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Date</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Branch</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Destination</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Items</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Total Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Status</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'item' + i"
                  >
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount"
                        >#{{ $helper.generateVoucherNo(item.id) }}</span
                      >
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        $helper.formatDate(item.committed_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <a href="javascript:void(0)">
                        <span>{{ item?.branch?.name }}</span>
                      </a>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        v-if="item.type == 'STOCK' || item.type == 'PRODUCTION'"
                        >MAIN STOCK</span
                      >
                      <a
                        href="javascript:void(0)"
                        v-else-if="item.type == 'PRODUCTION_STOCK'"
                      >
                        <span>{{ item.destination.name }}</span>
                      </a>
                      <span v-else>PERISHABLES</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount text-center">
                        {{
                          $helper.formatNumber(item.total_items) || "0"
                        }}</span
                      >
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">
                        {{ formatMoney(item.total_amount) || "0" }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-status text-warning"
                        v-if="item.status == 'PENDING'"
                        >PENDING <em v-if="item.confirmed == 1">(Confirmed)</em>
                      </span>
                      <span
                        class="tb-status text-success"
                        v-if="item.status == 'ACCEPTED'"
                        >ACCEPTED</span
                      >
                      <span
                        class="tb-status text-danger"
                        v-if="item.status == 'DENIED'"
                        >DENIED</span
                      >
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      getRequisitionItems(item.reference)
                                    "
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <template
                                  v-if="
                                    item.status == 'PENDING' &&
                                    (isAdministrator || hasPermissionTo('U'))
                                  "
                                >
                                  <li>
                                    <a
                                      href="javascript:void(0)"
                                      @click.prevent="
                                        $alert({
                                          title: 'Rejecting Requisition',
                                          content: `Are you sure you want to cancel this requisition (${item.reference})`,
                                          classes: 'btn btn-danger',
                                          actionText: 'Continue',
                                          closeText: 'Discard',
                                          action: () => {
                                            rejectRequisition(item);
                                          },
                                        })
                                      "
                                      ><em class="icon ni ni-na"></em
                                      ><span>Reject</span></a
                                    >
                                  </li>
                                </template>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <off-canvas
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
      position="end"
    >
      <span slot="head" class="d-flex align-items-center w-100">
        <span>Requested Items</span>
        <div class="ml-auto">
          <wolf-button
            v-if="
              requisition.status == 'PENDING' &&
              (!requisition?.confirmed || requisition?.confirmed == 0)
            "
            classes="btn btn-primary mr-2"
            @clicked="handleConfirm"
            :disabler="confirmDisabled"
            activator="HANDLING_REQUISITION_CONFIRM"
          >
            <span slot="caption"
              ><em class="icon ni ni-check-circle"></em
              ><span>Confirm</span></span
            >
          </wolf-button>
          <router-link
            v-if="false"
            class="btn btn-icon btn-sm btn-white btn-dim btn-outline-primary"
            :to="{
              name: 'RequisitionPrint',
              params: { reference: requisition.reference },
            }"
            ><em class="icon ni ni-printer-fill"></em
          ></router-link>
        </div>
      </span>
      <div slot="body">
        <div class="table-responsive">
          <div class="card card-full">
            <div class="nk-tb-list mt-n2 is-compact">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>$/N</span></div>
                <div class="nk-tb-col"><span>Name</span></div>
                <div class="nk-tb-col"><span>Cost Price</span></div>
                <div class="nk-tb-col"><span>Requested Qty</span></div>
                <div class="nk-tb-col"><span>Amount</span></div>
                <div class="nk-tb-col"></div>
              </div>
              <div
                class="nk-tb-item"
                v-for="(item, i) in items"
                :key="'item' + i"
              >
                <div class="nk-tb-col">
                  <span class="tb-lead"
                    ><a href="javascript:void(0)">{{ i + 1 }}</a></span
                  >
                </div>
                <div class="nk-tb-col">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">{{ item?.product?.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">
                    {{
                      formatMoney(
                        item?.product?.cost_price || item?.product?.cost
                      )
                    }}</span
                  >
                </div>
                <div class="nk-tb-col">
                  <div
                    class="form-control-wrap"
                    v-if="requisition.status == 'PENDING'"
                  >
                    <div class="form-text-hint">
                      <span class="overline-title">{{
                        item.product?.unit
                      }}</span>
                    </div>
                    <input
                      type="number"
                      v-model="items[i].requested_quantity"
                      class="form-control"
                      placeholder="Qty"
                      :readonly="requisition?.confirmed == 1"
                    />
                  </div>
                  <span class="tb-sub" v-else
                    >{{ item.requested_quantity }}
                    {{ item.product?.unit }}</span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount">{{
                    formatMoney(
                      (item.product.cost_price || item.product.cost) *
                        item.requested_quantity
                    )
                  }}</span>
                </div>
                <div class="nk-tb-col">
                  <a
                    v-if="
                      requisition.status == 'PENDING' &&
                      (!requisition?.confirmed || requisition?.confirmed == 0)
                    "
                    href="javascript:void(0)"
                    class="text-danger"
                    @click.prevent="deleteItem(item)"
                  >
                    <i class="ni ni-trash fs-5"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </off-canvas>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import OffCanvas from "@/components/OffCanvas.vue";
import { permissionsHandler } from "@/_helpers/permissions-handler";
import Multiselect from "vue-multiselect";
export default {
  name: "Requests",
  mixins: [dashboardServices, permissionsHandler],
  components: { OffCanvas, Multiselect },
  data: () => ({
    items: [],
    fetchUrl: "requisitions?is_for_pos=1",
    newFilter: {
      from: null,
      to: null,
      type: "all",
      status: "all",
      destination: "all",
    },
    requisition: {},
    branch: null,
  }),
  computed: {
    branches() {
      return this.$store.state.branches || [];
    },
    isAdministrator() {
      return (
        this.$helper.empty(this.loggedUser.branch) &&
        this.$helper.empty(this.loggedUser.source)
      );
    },
    confirmDisabled() {
      return this.items.some(
        (item) => !item.requested_quantity || item.requested_quantity == 0
      );
    },
  },
  beforeDestroy() {
    this.modalOpen = false;
    document.documentElement.classList.remove("modal-open");
    document.body.classList.remove("modal-open");
  },
  watch: {
    $route() {
      location.reload();
    },
  },
  methods: {
    handleBranchFilter(branch) {
      this.newFilter.branch_id = branch.id;
      this.handlePaginationFilter();
    },
    deleteItem(item) {
      const amount =
        (item.product.cost_price || item.product.cost) *
        item.requested_quantity;
      this.$alert({
        title: "Removing requisition item",
        content: `Are you sure you want to removing this item (${item?.product?.name})`,
        classes: "btn btn-primary",
        actionText: "Yes, Continue",
        closeText: "Discard",
        action: () => {
          this.$http
            .get(
              `requisitions/destroy/items?requisition_id=${this.requisition?.id}&amount=${amount}&item_id=${item?.id}`
            )
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: "Item sucessfully",
                });
                const index = this.items.findIndex((row) => row.id == item.id);
                this.items.splice(index, 1);
              }
            });
        },
      });
    },
    handleConfirm() {
      this.$alert({
        title: "Confirming Auto Requisition",
        content: `Are you sure you want to confirm this requisition (${this.requisition.reference})`,
        classes: "btn btn-primary",
        actionText: "Yes, Continue",
        closeText: "Discard",
        action: () => {
          this.$http
            .post(
              "requisitions/confirm",
              this.$helper.generateFormData({
                requisition_id: this.requisition.id,
                items: this.items,
              })
            )
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: "Requisition confirmed sucessfully",
                });

                const index = this.rows.findIndex(
                  (row) => row.id == this.requisition.id
                );
                this.rows[index].confirmed == 1;
                this.toggleModal();
              }
            });
        },
      });
    },
    rejectRequisition(item) {
      this.$http
        .get(`requisitions/status/update/${item.id}`)
        .then((response) => {
          if (response.data.status) {
            item.status = "DENIED";
          }
        });
    },
    getRequisitionItems(reference) {
      this.items = [];
      this.$http.get(`requisitions/items/${reference}`).then((response) => {
        this.items = response.data.items;
        this.requisition = response.data.requisition;
        this.toggleModal();
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
