<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-lg mx-auto">
          <div
            class="invoice break-inside"
            id="report-container"
            v-if="!$helper.empty(requisition)"
          >
            <div class="invoice-action">
              <a
                class="btn btn-icon btn-white btn-dim btn-outline-primary d-print-none"
                href="javascript:void(0)"
                @click.prevent="$helper.handlePrint('.invoice-wrap')"
                ><em class="icon ni ni-printer-fill"></em
              ></a>
            </div>
            <div class="invoice-wrap">
              <print-header>
                <div slot="title" class="text-center mb-1">
                  <h4 class="text-center">
                    Requisition:#{{ $helper.generateVoucherNo(requisition.id) }}
                    /
                    <small style="font-size: 14px"
                      >From
                      {{
                        requisition?.source?.name || requisition?.branch?.name
                      }}
                      To
                      <span
                        v-if="
                          requisition.type == 'STOCK' ||
                          requisition.type == 'PRODUCTION'
                        "
                        >MAIN STOCK</span
                      >
                      <span v-else-if="requisition.type == 'PRODUCTION_STOCK'"
                        >PRODUCTION</span
                      >
                      <span v-else>PERISHABLES</span></small
                    >
                  </h4>
                </div>
              </print-header>
              <div class="invoice-head d-print-none">
                <div class="invoice-contact">
                  <span class="overline-title">Source/Branch</span>
                  <div class="invoice-contact-info">
                    <h6 class="title">
                      <span
                        class="tb-amount"
                        v-if="!$helper.empty(requisition.source)"
                        >{{ requisition.source.name || "N/A" }}</span
                      >
                      <span class="tb-amount" v-else>{{
                        requisition.branch.name || "N/A"
                      }}</span>
                    </h6>
                  </div>
                  <span class="overline-title">Destination</span>
                  <div class="invoice-contact-info">
                    <h6 class="title">
                      <span
                        v-if="
                          requisition.type == 'STOCK' ||
                          requisition.type == 'PRODUCTION'
                        "
                        >MAIN STOCK</span
                      >
                      <span v-else-if="requisition.type == 'PRODUCTION_STOCK'"
                        >PRODUCTION</span
                      >
                      <span v-else>PERISHABLES</span>
                    </h6>
                  </div>
                </div>
                <div class="invoice-desc">
                  <h6 class="title">REQUISITION</h6>
                  <ul class="list-plain">
                    <li class="invoice-id">
                      <span>Voucher No</span>:<span>{{
                        $helper.generateVoucherNo(requisition.id)
                      }}</span>
                    </li>
                    <li class="invoice-date">
                      <span>Date</span>:<span>{{
                        $helper.formatDate(requisition.committed_date)
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="invoice-bills">
                <table class="w-100">
                  <thead>
                    <tr>
                      <th>$/N</th>
                      <th class="w-150px d-print-none">Item Code</th>
                      <th class="w-350px">Item Name</th>
                      <th>Cost Price</th>
                      <th>Req. Qty</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in items" :key="'item' + i">
                      <td>{{ i + 1 }}</td>
                      <td class="d-print-none">#{{ item.product.code }}</td>
                      <td>{{ item.product.name }}</td>
                      <td>
                        <span>
                          {{
                            formatMoney(
                              item.product.cost_price || item.product.cost
                            )
                          }}</span
                        >
                      </td>
                      <td>
                        {{ $helper.formatNumber(item.requested_quantity) }}
                      </td>
                      <td>
                        <span class="tb-sub tb-amount">{{
                          formatMoney(
                            (item.product.cost_price || item.product.cost) *
                              item.requested_quantity
                          )
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3" class="d-print-none"></td>
                      <td class="d-none d-print"></td>
                      <td colspan="2">Grand Total</td>
                      <td>
                        {{ formatMoney(requisition.total_amount) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div class="nk-notes mt-5 d-none d-print">
                  <div class="row">
                    <div class="col-6">
                      <div class="d-flex w-100 flex-nowrap mb-5">
                        <h6 class="mb-0">
                          <b>Delivered by:</b>
                        </h6>
                        <div class="dots-after"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="d-flex w-100 flex-nowrap mb-5">
                        <h6 class="mb-0">
                          <b>Received by:</b>
                        </h6>
                        <div class="dots-after"></div>
                      </div>
                    </div>

                    <div class="col-6"></div>
                    <div class="col-6">
                      <div class="d-flex w-100 flex-nowrap mb-5">
                        <h6 class="mb-0">
                          <b>Approved By:</b>
                        </h6>
                        <div class="dots-after"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "RequisitionPrint",
  components: { PrintHeader },
  data: () => ({
    items: [],
    requisition: {},
  }),
  computed: {},
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$http.get(`requisitions/items/${reference}`).then((response) => {
        this.items = response.data.items;
        this.requisition = response.data.requisition;
      });
    }
  },
  methods: {},
};
</script>
<style></style>
