var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container-fluids"},[_c('div',{staticClass:"nk-content-inner"},[_c('div',{staticClass:"nk-content-body"},[_c('div',{staticClass:"mx-0"},[_c('div',{staticClass:"card card-stretch"},[_c('div',{staticClass:"card-inner-group"},[_c('div',{staticClass:"nk-block-head nk-block-head-sm"},[_c('div',{staticClass:"nk-block-between"},[_vm._m(0),_c('div',{staticClass:"nk-block-head-content"},[_c('div',{staticClass:"toggle-wrap nk-block-tools-toggle"},[_c('div',{staticClass:"toggle-expand-content"},[_c('ul',{staticClass:"nk-block-tools g-1 float-right"},[_c('li',[_c('button',{staticClass:"btn btn-primary d-md-inline-flex",on:{"click":_vm.toggleModal}},[_c('em',{staticClass:"icon ni ni-plus"}),_c('span',[_vm._v("New")])])])])])])])])]),_c('div',{staticClass:"nk-block"},[_c('div',{staticClass:"nk-tb-list is-compact mb-3"},[_vm._m(1),_vm._l((_vm.categories),function(row,i){return _c('div',{key:'row' + i,staticClass:"nk-tb-item"},[_c('div',{staticClass:"nk-tb-col"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(i + 1))])]),_c('div',{staticClass:"nk-tb-col"},[_c('span',{staticClass:"tb-lead"},[_vm._v(_vm._s(row.name))])]),_c('div',{staticClass:"nk-tb-col"},[_c('span',[_vm._v(_vm._s(row.description || "N/A"))])]),_c('div',{staticClass:"nk-tb-col nk-tb-col-tools"},[_c('ul',{staticClass:"nk-tb-actions gx-1"},[_c('li',[_c('div',{staticClass:"dropdown"},[_vm._m(2,true),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('ul',{staticClass:"link-list-opt no-bdr"},[_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.handleEdit(row)}}},[_c('em',{staticClass:"icon ni ni-edit"}),_c('span',[_vm._v("Edit")])])]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();_vm.handleDeleteAlertModal({
                                      tableName: 'expenses',
                                      entityIdentifer: 'Category ',
                                      entity: `${row.name || ''}`,
                                      url: `expenses/categories/delete/${row.id}`,
                                      callback: () => {
                                        _vm.categories.splice(i, 1);
                                      },
                                    })}}},[_c('em',{staticClass:"icon ni ni-trash"}),_c('span',[_vm._v("Delete")])])])])])])])])])])})],2)])])])])])]),(_vm.modalOpen)?_c('expense-category-creator',{attrs:{"category":_vm.selectedCategory},on:{"closed":_vm.toggleModal,"saved":_vm.handleCreatedCategory}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nk-block-head-content"},[_c('h5',{staticClass:"nk-block-title page-title mb-0"},[_vm._v(" Expense categories ")]),_c('div',{staticClass:"nk-block-des text-soft"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nk-tb-item nk-tb-head"},[_c('div',{staticClass:"nk-tb-col"},[_c('span',[_vm._v("$/N")])]),_c('div',{staticClass:"nk-tb-col"},[_c('span',{staticClass:"sub-text"},[_vm._v("Category Name")])]),_c('div',{staticClass:"nk-tb-col"},[_vm._v("Description")]),_c('div',{staticClass:"nk-tb-col nk-tb-col-tools"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-toggle btn btn-icon btn-trigger",attrs:{"href":"javascript:void(0)","data-toggle":"dropdown","aria-expanded":"false"}},[_c('em',{staticClass:"icon ni ni-more-h"})])
}]

export { render, staticRenderFns }