import { render, staticRenderFns } from "./OffCanvas.vue?vue&type=template&id=cf0c0694&scoped=true&"
import script from "./OffCanvas.vue?vue&type=script&lang=js&"
export * from "./OffCanvas.vue?vue&type=script&lang=js&"
import style0 from "./OffCanvas.vue?vue&type=style&index=0&id=cf0c0694&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf0c0694",
  null
  
)

export default component.exports