<template>
  <bootstrap-modal
    @close="$emit('closed')"
    style="display: block"
    :modalSize="'modal-lg'"
    :showFooter="false"
  >
    <span slot="head" style="padding-top: 10px">
      {{ newClient.id ? "Update Client" : "Add a new Client" }}
    </span>
    <div slot="body">
      <form class="forms-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">First Name</label>
              <input
                type="text"
                v-model="newClient.first_name"
                name="First Name"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('First Name') }"
                class="form-control"
                placeholder="First Name"
              />
              <div class="invalid-feedback">
                {{ errors.first("First Name") }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Last Name</label>
              <input
                type="text"
                v-model="newClient.last_name"
                name="Last Name"
                class="form-control"
                placeholder="Last Name"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Phone Number</label>
              <input
                type="text"
                v-model="newClient.phone"
                class="form-control"
                placeholder="Mobile number"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Email</label>
              <input
                type="text"
                v-model="newClient.email"
                class="form-control"
                placeholder="Email Address"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">TIN number</label>
              <input
                type="text"
                v-model="newClient.tin_number"
                class="form-control"
                placeholder="TIN NUMBER"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Address</label>
              <input
                type="text"
                v-model="newClient.address"
                class="form-control"
                placeholder="Address Line"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Discount(%)</label>
              <input
                type="text"
                v-model="newClient.discount"
                class="form-control"
                placeholder="Discout(%)"
              />
            </div>
          </div>
        </div>
        <div class="my-3">
          <wolf-button
            classes="btn btn-primary"
            caption="Save and Close"
            @clicked="handleSubmit"
            :disabler="!newClient.first_name"
            activator="saving_records"
          ></wolf-button>
        </div>
      </form>
    </div>
  </bootstrap-modal>
</template>
<script>
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "ClientCreator",
  props: ["client"],
  components: { BootstrapModal },
  data: () => ({
    newClient: {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      address: null,
      discount: 0,
      tin_number: null,
      receive: 0,
      store: 0,
      status: 1,
    },
  }),
  created() {
    if (!this.$helper.empty(this.client)) {
      this.newClient = this.client;
    }
  },
  methods: {
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = this.newClient.id
            ? "settings/clients/update"
            : "settings/clients/create";
          this.$http
            .post(url, this.$helper.generateFormData(this.newClient))
            .then((response) => {
              if (response.data.status) {
                this.$emit("saved", response.data.client);
                this.$helper.resetObjectValues(this.newClient);
                this.$validator.reset();
              }
            });
        }
      });
    },
  },
};
</script>
