<template>
  <div class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Production Status</h3>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} products</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li class="nk-block-tools-opt" v-if="false">
                            <button
                              type="button"
                              class="btn btn-icon btn-primary d-md-none"
                            >
                              <em class="icon ni ni-plus"></em>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col"><span>Employee</span></div>
                    <div class="nk-tb-col"><span>Item Name</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Sales Price</span></div>
                    <div class="nk-tb-col"><span>Requested</span></div>
                    <div class="nk-tb-col"><span>Produced</span></div>
                    <div class="nk-tb-col"><span>Balance</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'production' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span>{{
                          $helper.formatDate(item.produce_date)
                        }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ item.employee }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ item.product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span> {{ formatMoney(item.product.cost_price) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ `${formatMoney(item.product.price)}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{
                        `${$helper.formatNumber(item.requested)} ${
                          item.product.unit
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{
                            item.product.unit
                          }}</span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          v-model="rows[i].produced"
                          :name="'Produced' + i"
                          @keyup="computeBalance(item)"
                          :class="{ 'is-invalid': errors.has('Produced' + i) }"
                          v-validate="'required|min_value:0'"
                        />
                      </div>
                      <span
                        class="text-danger"
                        v-if="errors.has('Produced' + i)"
                        >Must be less or equal to
                        {{ rows[i].requested }}
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{
                            item.product.unit
                          }}</span>
                        </div>
                        <input
                          type="number"
                          v-model="rows[i].balance"
                          class="form-control"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="
                          $alert({
                            title: 'Removing from production record',
                            content: `Are you sure you want to remove this item (${item.product.name})? There is no way to undo this operation when it's done.`,
                            classes: 'btn btn-danger',
                            actionText: 'Continue',
                            closeText: 'Discard',
                            action: () => {
                              handleDelete(rows[i].id, i);
                            },
                          })
                        "
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="rows.length > 0">
                  <div class="float-right">
                    <div
                      class="d-flex justify-content-center align-items-center actions"
                    >
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @clicked="handleSubmit"
                        :disabler="submitDisabled"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-check-circle"></em
                          ><span>Confirm</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center w-100 cursor-pointer">
                  <h6 class="mb-0 text-muted">No data to display</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "Status",
  mixins: [dashboardServices],
  data: () => ({}),
  computed: {
    isEditRequest() {
      let reference = this.$route.query.reference;
      return typeof reference != "undefined";
    },
    submitDisabled() {
      return !this.rows.length || this.rows.every((item) => item.produced == 0);
    },
  },
  created() {
    let reference = this.$route.query.reference;
    this.fetchUrl = this.isEditRequest
      ? `production/status/${reference}`
      : "production/status";
    this.handlePaginationSearch();
  },
  methods: {
    removeItem(i) {
      this.rows.splice(i, 1);
    },
    computeBalance(item) {
      item.balance = Number(item.requested) - Number(item.produced);
    },
    handleDelete(id, key) {
      this.$http.get(`production/delete/${id}`).then((response) => {
        if (response.data.status) {
          this.rows.splice(key, 1);
        }
      });
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var data = {
            url: "production/status/update",
            fd: this.$helper.generateFormData({
              items: this.rows,
            }),
          };
          if (this.isEditRequest) {
            data = {
              url: "production/status/edit",
              fd: this.$helper.generateFormData({
                items: this.rows,
                action: "EDIT",
              }),
            };
          }
          this.$http
            .post(data.url, data.fd)
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: "Status updated successfully",
                });
                this.rows = response.data.rows.data;
                this.currentPage = response.data.rows.current_page;
                this.totalRows = response.data.rows.total;
                this.fetchedData = response.data;
                /*this.$store.state.requestFlag = "PERFORM_AUTO_TRACKING";
                this.$http.get("stock/perform-eod");*/
              }
            })
            
        }
      });
    },
  },
};
</script>
