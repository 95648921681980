<template>
  <div
    class="nk-block nk-block-middle nk-auth-body min-vh-100 d-flex justify-content-center align-items-center"
  >
    <div class="card w-100">
      <div class="card-inner card-inner-lgg">
        <div class="nk-block-head">
          <div class="brand-logo text-center">
            <router-link :to="{ name: '' }" class="logo-link"
              ><img
                class="logo-dark logo-img logo-img-lg"
                :src="`${publicPath}img/logo.jpg`"
                alt="logo"
            /></router-link>
          </div>
          <div class="nk-block-head-content mt-2">
            <h4 class="nk-block-title">{{ "Login to access your account" | trans }}</h4>
          </div>
        </div>
        <form action="" method="POST" @submit.prevent="authenticateUser">
          <div class="form-group">
            <div class="form-label-group">
              <label class="form-label" for="default-01">{{
                "Username" | trans
              }}</label>
            </div>
            <div class="form-control-wrap">
              <input
                type="text"
                class="form-control form-control-lg"
                id="default-01"
                :placeholder="'Enter your username' | trans"
                name="Username"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('Username') }"
                v-model="credentials.email"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-label-group">
              <label class="form-label" for="password">{{
                "Password" | trans
              }}</label>
            </div>
            <div class="form-control-wrap">
              <a
                href="javascript:void(0)"
                @click.prevent="handleVisibility"
                class="form-icon form-icon-right passcode-switch"
                ><em
                  class="passcode-icon icon-show icon ni ni-eye"
                  v-if="passwordType == 'password'"
                ></em
                ><em
                  class="passcode-icon icon-hide icon ni ni-eye-off d-block"
                  v-else
                ></em></a
              ><input
                :type="passwordType"
                class="form-control form-control-lg"
                id="password"
                :placeholder="'Enter your password' | trans"
                name="password"
                :class="{ 'is-invalid': errors.has('password') }"
                v-validate="'required'"
                v-model="credentials.password"
              />
            </div>
          </div>
          <template v-if="false">
            <div class="alert alert-danger my-2" v-if="invalidLogin">
              <span>Invalid password or Email addess</span>
            </div>
            <div
              class="alert alert-success fade show my-2"
              role="alert"
              v-if="loginSucceed"
            >
              <strong>Success!</strong>
              {{ "Login Success. Redirecting..." | trans }}
            </div>
          </template>
          <div class="form-group">
            <wolf-button
              :caption="'Login' | trans"
              button-type="submit"
              @clicked="authenticateUser"
              :disabler="!credentials.email || !credentials.password"
              activator="signing"
              classes="btn btn-primary btn-block btn-lg"
              >{{ "Login" | trans }}</wolf-button
            >
          </div>
          <div class="d-flex align-items-center justify-content-end" v-if="false">
            <div class="user-action">
              <div class="dropdown">
                <a
                  class="btn btn-icon dropdown-toggle fw-bolder"
                  data-bs-toggle="dropdown"
                  href="#"
                  aria-expanded="false"
                  ><em class="icon ni ni-globe fs-3"></em
                  >{{ country.label || "" }}</a
                >
                <div
                  class="dropdown-menu dropdown-menu-start"
                  style="left: auto; top: auto; right: 0; bottom: 100%"
                >
                  <ul class="link-list-opt no-bdr">
                    <li v-for="(item, i) in languages" :key="'lang' + i">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="setLanguage(item)"
                      >
                        <img
                          :src="`/img/flags/${item.flag}`"
                          class="img-fluid"
                          style="width: 20px"
                          :alt="item.label"
                        />
                        <span class="lang-label">{{ item.label }}</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { languages } from "@/lang/languages";
import { setLanguage, getCountry } from "@/lang/index";
export default {
  name: "login",
  data: () => ({
    credentials: {
      email: null,
      password: null,
    },
    invalidLogin: false,
    loginSucceed: false,
    passwordType: "password",
    languages,
    country: {},
  }),
  created() {
    if (this.$store.getters.isLoggedIn) {
      if (navigator.userAgent.indexOf("Firefox") > 0)
        setTimeout(() => {
          location.replace("/dashboard");
        }, 2000);
      else location.replace("/dashboard");
    }
  },
  mounted() {
    this.country = getCountry();
    console.log(this.$options.filters.trans("Close"));
  },
  methods: {
    setLanguage,
    handleVisibility() {
      if (this.passwordType == "password") this.passwordType = "text";
      else this.passwordType = "password";
    },
    authenticateUser() {
      this.$http
        .post("auth/login", this.$helper.generateFormData(this.credentials))
        .then((response) => {
          if (response.data.status) {
            const token = response.data.access_token;
            if (token) {
              localStorage.setItem("token", token);
              this.$http.defaults.headers.common["Authorization"] = token;
            }
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "success",
              message: "Login Succeed. Redirecting...",
            });
            if (navigator.userAgent.indexOf("Firefox") > 0)
              setTimeout(() => {
                location.replace("/dashboard");
              }, 3000);
            else location.replace("/dashboard");
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            /*this.invalidLogin = true;
            this.loginSucceed = false; */
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "danger",
              message: "Invalid Password Or Username. Try Again",
            });
          }
        });
    },
  },
};
</script>
<style>
.logo-img-lg {
  max-height: 50px;
}
</style>
