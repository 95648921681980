<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block"></div>
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <template v-if="$helper.empty(record)">
                        <span>New Bill</span>
                      </template>
                      <template v-else>
                        <span
                          >Edit Bill &nbsp;
                          <a href="javascript:void(0)"
                            >#{{ record.code }}</a
                          ></span
                        >
                      </template>
                    </h3>
                    <div class="nk-block-des text-soft"></div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div id="bill-details">
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Supplier</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <multiselect
                          v-model="supplier"
                          :options="suppliers"
                          placeholder="Select supplier"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Bill #</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <input
                          type="text"
                          v-model="reference"
                          class="form-control"
                          placeholder="Reference"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="row align-items-center"
                    v-if="$helper.empty(loggedUser.branch)"
                  >
                    <div class="col-lg-3 col-4">
                      <label class="required">Branch</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <multiselect
                          v-model="branch"
                          :options="branches"
                          placeholder="Select branch"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Invoice Given</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <ul class="custom-control-group m-0 pt-0">
                          <li>
                            <div
                              class="custom-control custom-control-sm custom-checkbox custom-control-pro"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                v-model="has_invoice"
                                id="btnRadio1"
                                value="1"
                              /><label
                                class="custom-control-label"
                                for="btnRadio1"
                                style="white-space: nowrap"
                                >Invoice Given</label
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Tax</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group" v-if="has_invoice == 1">
                        <select class="form-control" v-model="vat">
                          <option value="INCLUSIVE">VAT Inclusive</option>
                          <option value="EXCLUSIVE">VAT Exclusive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Bill Date</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <input
                          type="date"
                          v-model="received_date"
                          class="form-control"
                          placeholder="Bill Date"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-4">
                      <label>Due Date</label>
                    </div>
                    <div class="col-lg-4 col-5">
                      <div class="form-group mb-2">
                        <input
                          type="date"
                          v-model="due_date"
                          class="form-control"
                          placeholder="Due Date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-list is-compact my-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Item Details</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{
                              appSettings.currency
                            }}</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            v-model="items[i].price"
                            :placeholder="`Price in ${appSettings.currency}`"
                            :name="'Price' + i"
                            :class="{
                              'is-invalid': errors.has('Price' + i),
                            }"
                            v-validate="'required'"
                            @keyup="findTotalAmount(product)"
                          />
                        </div>
                        <span
                          class="text-danger"
                          v-if="errors.has('Cost Price' + i)"
                          >Price is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required'"
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(product)"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Quantity' + i)"
                          >Quantity is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">
                        {{ formatMoney(items[i].total_amount) }}
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6
                      class="d-flex mb-3 align-items-center justify-content-end"
                    >
                      Grand Total:
                      <span class="ml-auto">
                        {{ formatMoney(grandTotal) }}</span
                      >
                    </h6>
                  </div>

                  <div class="w-100">
                    <div
                      class="d-flex justify-content-end align-items-center actions w-100"
                    >
                      <div class="form-group mb-2">
                        <wolf-button
                          classes="btn btn-primary ml-2"
                          @clicked="handleSubmit"
                          :disabler="submitDisabled"
                          activator="saving_records"
                        >
                          <span slot="caption"
                            ><em class="icon ni ni-save"></em
                            ><span>Save</span></span
                          >
                        </wolf-button>
                      </div>
                    </div>
                  </div>
                </div>
                <empty-results v-else>
                  <div class="d-block" slot="description">
                    <div>
                      <span>No Items added</span>
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="toggleModal"
                    >
                      <em class="icon ni ni-plus"></em><span>Add Items</span>
                    </button>
                  </div>
                </empty-results>
                <bulk-items-select
                  fetch-url="finance/bills/service-items"
                  data-key="rows"
                  v-if="modalOpen"
                  @closed="toggleModal"
                  @confirmed="handleBulkItems"
                ></bulk-items-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import EmptyResults from "@/components/EmptyResults.vue";
import BulkItemsSelect from "@/components/BulkItemsSelect.vue";
export default {
  name: "BillCreator",
  components: { Multiselect, EmptyResults, BulkItemsSelect },
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    suppliers: [],
    supplier: null,
    branch: null,
    received_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
    due_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
    has_invoice: 0,
    vat: "EXCLUSIVE",
    objectType: "BILL",
    reference: null,
    isEditMode: false,
    record: {},
  }),
  computed: {
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    submitDisabled() {
      return (
        !this.supplier ||
        !this.branch ||
        !this.items.length ||
        (this.isProductionItems && !this.productionSource)
      );
    },
    branches() {
      return this.$store.state.branches || [];
    },
  },
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$http
        .get(`/reports/receive/${reference}/voucher`)
        .then((response) => {
          let items = response.data.items;
          this.record = response.data.record;
          this.supplier = this.record.vendor;
          this.vat = this.record.vat;
          this.reference = this.record.code;
          this.has_invoice = this.record.has_invoice;
          this.received_date = new Date(this.record.received_date)
            .toLocaleString("fr-CA", { timeZone: TIMEZONE })
            .slice(0, 10);
          items.forEach((item) => {
            this.items.push({
              id: item.product.id,
              name: item.product.name,
              unit: item.product.unit,
              source: item.product.source,
              quantity: item.quantity,
              total_amount: item.quantity * item.price,
              price: item.price,
            });
            this.selectedItemIds.push(item.product.id);
          });
        });
    }
  },
  mounted() {
    this.$http.get("settings/suppliers?nopagination=1").then((response) => {
      this.suppliers = response.data.rows.data;
    });
    this.$nextTick(() => {
      if (!this.$helper.empty(this.loggedUser.branch)) {
        this.branch = this.loggedUser.branch;
      }
    });
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    handleSelectedItem(item, qty = 1) {
      if (!this.selectedItemIds.includes(item.id)) {
        this.items = this.$helper.handleArrayPush(this.items, {
          id: item.id,
          name: item.name,
          unit: item.unit,
          source: item.source,
          quantity: qty,
          total_amount: item.cost_price || item.cost,
          price: item.cost_price || item.cost,
          current_qty: item.quantity,
        });
        this.selectedItemIds = this.$helper.handleArrayPush(
          this.selectedItemIds,
          item.id
        );
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    handleBulkItems(args) {
      args.forEach((row) => this.handleSelectedItem(row.item, row.quantity));
      this.toggleModal();
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.isProductionItems) {
            this.items_type = "PRODUCTION";
          }
          this.$http
            .post(
              "stock/products/receive",
              this.$helper.generateFormData({
                supplier: this.supplier.id,
                branch_id: this.branch.id,
                received_date: this.received_date,
                due_date: this.due_date,
                has_invoice: this.has_invoice,
                vat: this.vat,
                items: this.items,
                total_amount: this.grandTotal,
                record: this.record.id || null,
                record_items: this.selectedItemIds,
                object_type: this.objectType,
                reference: this.reference,
              })
            )
            .then((response) => {
              if (response.data.status) {
                this.supplier = null;
                this.has_invoice = 1;
                this.items = [];
                this.selectedItemIds = [];

                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                if (!this.$helper.empty(this.record)) {
                  this.$router.replace({ name: "BillsReport" });
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
