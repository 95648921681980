<template>
  <div class="row g-gs">
    <div class="col-xxl-3 col-lg-4 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Receiving Chart</h6>
              </div>
              <router-link
                :to="{ name: 'ReceiveReport', query: detailsQuery() }"
                class="ml-auto"
              >
                <span>Details</span> <em class="ni ni-arrow-long-right"></em>
              </router-link>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${formatMoney(receives.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="receivesChart"
                    :chart-data="receivesData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-lg-4 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Transfers Chart</h6>
              </div>
              <router-link
                :to="{ name: 'TransferReport', query: detailsQuery() }"
                class="ml-auto"
              >
                <span>Details</span> <em class="ni ni-arrow-long-right"></em>
              </router-link>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${formatMoney(transfers.total.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="transfersData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-lg-4 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Purchase Orders</h6>
              </div>
              <router-link
                :to="{ name: 'OrdersReports', query: detailsQuery() }"
                class="ml-auto"
              >
                <span>Details</span> <em class="ni ni-arrow-long-right"></em>
              </router-link>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${formatMoney(orders.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="ordersData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-lg-4 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Spoiled Items</h6>
              </div>
              <router-link
                :to="{ name: 'SpoiledItemsReport', query: detailsQuery() }"
                class="ml-auto"
              >
                <span>Details</span> <em class="ni ni-arrow-long-right"></em>
              </router-link>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${formatMoney(spoiled.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="spoiledData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-lg-4 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Production Spoiled</h6>
              </div>
              <router-link
                :to="{
                  name: 'SpoiledItemsReport',
                  query: {
                    ...detailsQuery(),
                    ...{ 'items-category': 'production' },
                  },
                }"
                class="ml-auto"
              >
                <span>Details</span> <em class="ni ni-arrow-long-right"></em>
              </router-link>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${formatMoney(productionSpoiled.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="productionSpoiledData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-lg-4 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">All Spoiled</h6>
              </div>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${formatMoney(allSpoiled.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="allSpoiledData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-lg-4 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Payments Received</h6>
              </div>
              <router-link
                :to="{ name: 'PaymentsReceived', query: detailsQuery() }"
                class="ml-auto"
              >
                <span>Details</span> <em class="ni ni-arrow-long-right"></em>
              </router-link>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${formatMoney(paymentsReceive.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="paymentsReceiveData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-lg-4 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Payments Made</h6>
              </div>
              <router-link
                :to="{ name: 'SupplierPaymentsMade', query: detailsQuery() }"
                class="ml-auto"
              >
                <span>Details</span> <em class="ni ni-arrow-long-right"></em>
              </router-link>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${formatMoney(paymentsMade.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="paymentsMadeData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-lg-4 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Expenses Paid</h6>
              </div>
              <router-link
                :to="{ name: 'Expenses', query: detailsQuery() }"
                class="ml-auto"
              >
                <span>Details</span> <em class="ni ni-arrow-long-right"></em>
              </router-link>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${formatMoney(expenses)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="paymentsMadeData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/LineChart";
export default {
  name: "InventoryCards",
  props: [
    "receives",
    "transfers",
    "orders",
    "spoiled",
    "productionSpoiled",
    "allSpoiled",
    "paymentsMade",
    "paymentsReceive",
    "expenses",
  ],
  components: { LineChart },
  data: () => ({
    option: {
      legend: {
        display: false,
        rtl: false,
        labels: { boxWidth: 12, padding: 20, fontColor: "#6783b8" },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        rtl: false,
        callbacks: {
          title: function () {
            return false;
          },
          label: function (a, t) {
            return `$ ${t.datasets[a.datasetIndex].data[a.index]}`;
          },
        },
        backgroundColor: "#1c2b46",
        titleFontSize: 8,
        titleFontColor: "#fff",
        titleMarginBottom: 4,
        bodyFontColor: "#fff",
        bodyFontSize: 8,
        bodySpacing: 4,
        yPadding: 6,
        xPadding: 6,
        footerMarginTop: 0,
        displayColors: false,
      },
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: false,
              fontSize: 12,
              fontColor: "#9eaecf",
              padding: 0,
            },
            gridLines: {
              color: "#526484",
              tickMarkLength: 0,
              zeroLineColor: "#526484",
            },
          },
        ],
        xAxes: [
          {
            display: false,
            ticks: {
              fontSize: 12,
              fontColor: "#9eaecf",
              source: "auto",
              padding: 0,
              reverse: false,
            },
            gridLines: {
              color: "transparent",
              tickMarkLength: 0,
              zeroLineColor: "#526484",
              offsetGridLines: true,
            },
          },
        ],
      },
    },
  }),
  computed: {
    receivesData() {
      return {
        labels: this.receives.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Receives",
            borderColor: "#854fff",
            backgroundColor: "transparent",
            data: this.receives.data,
          },
        ],
      };
    },
    transfersData() {
      return {
        labels: this.transfers.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Transfers",
            borderColor: "#33d895",
            backgroundColor: "transparent",
            data: this.transfers.data,
          },
        ],
      };
    },
    ordersData() {
      return {
        labels: this.orders.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Purchase Orders",
            borderColor: "#ff63a5",
            backgroundColor: "transparent",
            data: this.orders.data,
          },
        ],
      };
    },
    spoiledData() {
      return {
        labels: this.spoiled.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Spoiled",
            borderColor: "#559bfb",
            backgroundColor: "transparent",
            data: this.spoiled.data,
          },
        ],
      };
    },
    paymentsMadeData() {
      return {
        labels: this.paymentsMade.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Payments Made",
            borderColor: "#559bfb",
            backgroundColor: "transparent",
            data: this.paymentsMade.data,
          },
        ],
      };
    },
    paymentsReceiveData() {
      return {
        labels: this.paymentsReceive.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Payments Received",
            borderColor: "#559bfb",
            backgroundColor: "transparent",
            data: this.paymentsReceive.data,
          },
        ],
      };
    },
    productionSpoiledData() {
      return {
        labels: this.productionSpoiled.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Production Spoiled",
            borderColor: "#559bfb",
            backgroundColor: "transparent",
            data: this.spoiled.data,
          },
        ],
      };
    },
    allSpoiledData() {
      return {
        labels: this.allSpoiled.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "All Spoiled",
            borderColor: "#559bfb",
            backgroundColor: "transparent",
            data: this.spoiled.data,
          },
        ],
      };
    },
  },
  methods: {
    detailsQuery() {
      const dates = this.$helper.getMonthBoundaries(String(TODAY));
      return { start_date: dates[0], end_date: dates[1] };
    },
  },
};
</script>
<style>
.nk-ecwg6-ck.dash-cards div {
  height: 40px;
}
</style>
