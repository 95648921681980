<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Receiving Report
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> From: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.from"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> To: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.to"
                              />
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <button
                              type="button"
                              class="btn btn-primary d-md-inline-flex"
                              :disabled="$helper.empty(newFilter.from)"
                              @click="handlePaginationFilter"
                            >
                              <em class="icon ni ni-filter"></em
                              ><span>Filter</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Reference</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Source</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Items</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Taken By</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1" v-if="false">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger mr-n1"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Selected</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        $helper.formatDate(row.delivery_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead"
                        >#{{ $helper.generateVoucherNo(row.id) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">
                        {{
                          row.object_type != "PRODUCTION"
                            ? "MAIN STORE"
                            : "PRODUCTION"
                        }}
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ $helper.formatNumber(row.items) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{ formatMoney(row.total_amount) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        row.transporter || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-lead"
                        v-if="!$helper.empty(row.creator)"
                        >{{ row.creator.name }}</span
                      >
                      <span v-else>N/A</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      getItemVoucher(row.reference)
                                    "
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'TransferVoucher',
                                      params: { reference: row.reference },
                                    }"
                                    ><em class="icon ni ni-file-text"></em
                                    ><span>View Voucher</span></router-link
                                  >
                                </li>
                                <li v-if="false">
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item" v-if="rows.length">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"><h6>Grand Total</h6></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>
                        {{ `${formatMoney(grandTotal)}` }}
                      </h6>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >RECEIVING ITEMS LIST
        <a href="javascript:void(0)">{{ record.code }}</a></span
      >
      <div slot="body">
        <div class="table-responsive">
          <div class="card card-full">
            <div class="nk-tb-list mt-n2 is-compact">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>S/N</span></div>
                <div class="nk-tb-col"><span>Name</span></div>
                <div class="nk-tb-col"><span>Cost Price</span></div>
                <div class="nk-tb-col"><span>Quantity</span></div>
                <div class="nk-tb-col"><span>Tot. Amount</span></div>
              </div>
              <div
                class="nk-tb-item"
                v-for="(item, i) in items"
                :key="'item' + i"
              >
                <div class="nk-tb-col">
                  <span>{{ i + 1 }}</span>
                </div>
                <div class="nk-tb-col">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">{{ item.product.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub"> {{ formatMoney(item.price) }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{
                    $helper.formatNumber(item.quantity)
                  }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount">{{
                    formatMoney(item.quantity * item.price)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "ReceivingReport",
  mixins: [dashboardServices],
  components: { BootstrapModal },
  data: () => ({
    fetchUrl: "reports/transfer",
    items: [],
    record: {},
  }),
  computed: {
    grandTotal() {
      return this.rows.reduce((a, b) => a + Number(b.total_amount), 0);
    },
  },
  methods: {
    getItemVoucher(reference) {
      this.items = [];
      this.record = {};
      this.$http
        .get(`/reports/transfer/${reference}/voucher`)
        .then((response) => {
          this.items = response.data.items;
          this.record = response.data.record;
          this.toggleModal();
        });
    },
  },
};
</script>
