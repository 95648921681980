<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="components-preview wide-lg mx-auto">
          <div class="nk-block-head nk-block-head-lg wide-sm">
            <div class="nk-block-head-content">
              <h4 class="nk-block-title fw-normal">New Role</h4>
            </div>
          </div>
          <div class="nk-block nk-block-lg">
            <div class="preview-block has-multselect">
              <div class="row align-items-center mb-2">
                <div class="col-3">
                  <label>Role name</label>
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="newRole.name"
                    class="form-control"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div class="row align-items-center mb-2">
                <div class="col-3">
                  <label>Description</label>
                </div>
                <div class="col-6">
                  <textarea
                    v-model="newRole.description"
                    class="form-control"
                    placeholder="Description"
                  ></textarea>
                </div>
              </div>
              <div class="pb-0">
                <div
                  class="ember-view"
                  v-for="(menu, i) in rows"
                  :key="'menu' + i"
                >
                  <div class="card mb-5" v-if="$helper.empty(menu.category)">
                    <div class="card-header">
                      <div class="card-title font-large mb-0 text-capitalize">
                        <div class="form-check">
                          <input
                            :id="i"
                            class="ember-checkbox ember-view form-check-input"
                            type="checkbox"
                            v-model="menu.accessible"
                          />
                          <label class="form-check-label" for="a05c3054f">{{
                            $helper.inputTitle(i)
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <table class="table table-bordered role-table mb-0">
                      <colgroup>
                        <col />
                        <col style="background: #fcfcfc" />
                      </colgroup>
                      <thead>
                        <tr>
                          <td style="min-width: 220px"></td>
                          <td class="permission">Full Access</td>
                          <td class="permission text-capitalize">view</td>
                          <td class="permission">Create</td>
                          <td class="permission">Edit</td>
                          <td class="permission">Delete</td>
                          <td style="width: 100%"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, j) in menu.submenus"
                          :key="'menu' + i + '.' + 'item' + j"
                        >
                          <td class="text-capitalize">
                            {{ $helper.inputTitle(j) }}
                          </td>
                          <td class="permission">
                            <div class="form-check">
                              <input
                                :id="j + '-full'"
                                class="
                                  ember-checkbox ember-view
                                  form-check-input
                                  position-static
                                "
                                type="checkbox"
                                @change="grantFullAccess($event, j, item)"
                              />
                            </div>
                          </td>
                          <td class="permission">
                            <div class="form-check">
                              <input
                                :id="j + '-view'"
                                class="
                                  ember-checkbox ember-view
                                  form-check-input
                                  position-static
                                "
                                type="checkbox"
                                v-model="item[0]"
                              />
                            </div>
                          </td>
                          <td class="permission">
                            <div class="form-check">
                              <input
                                :id="j + '-create'"
                                class="
                                  ember-checkbox ember-view
                                  form-check-input
                                  position-static
                                "
                                type="checkbox"
                                v-model="item[1]"
                              />
                            </div>
                          </td>
                          <td class="permission">
                            <div class="form-check">
                              <input
                                :id="j + '-edit'"
                                class="
                                  ember-checkbox ember-view
                                  form-check-input
                                  position-static
                                "
                                type="checkbox"
                                v-model="item[2]"
                              />
                            </div>
                          </td>
                          <td class="permission">
                            <div class="form-check">
                              <input
                                :id="j + '-delete'"
                                class="
                                  ember-checkbox ember-view
                                  form-check-input
                                  position-static
                                "
                                type="checkbox"
                                v-model="item[3]"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="popovercontainer" v-if="false">
                              <a href="#">More Permissions</a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- For reports permissions -->
                <div class="card mb-5" v-if="!$helper.empty(rows.all_reports)">
                  <div class="alert alert-warning text-brown m-0" role="alert">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      class="
                        icon icon-md
                        text-muted
                        cursor-pointer
                        align-text-bottom
                      "
                    >
                      <path
                        fill="#FBA800"
                        d="M256 3C116.3 3 3 116.3 3 256s113.3 253 253 253 253-113.3 253-253S395.7 3 256 3zm0 411.1c-17.5 0-31.6-14.2-31.6-31.6 0-17.5 14.2-31.6 31.6-31.6 17.5 0 31.6 14.2 31.6 31.6 0 17.5-14.1 31.6-31.6 31.6zm31.6-126.5c0 17.3-14 31.3-31.3 31.3h-.6c-17.3 0-31.3-14-31.3-31.3V129.5c0-17.3 14-31.3 31.3-31.3h.6c17.3 0 31.3 14 31.3 31.3v158.1z"
                      ></path>
                    </svg>
                    When new reports are introduced, you will have to edit the
                    role and provide access to them.
                  </div>
                  <div class="container position-relative reports-permission">
                    <div class="row align-items-center fix-header">
                      <div class="col-lg-6 cell cell-border-right">
                        Report Groups
                      </div>
                      <div
                        class="
                          col-lg-2
                          text-center
                          bg-light
                          cell cell-border-right
                        "
                      >
                        Full Access
                      </div>
                      <div class="col-lg-1 text-center text-capitalize cell">
                        View
                      </div>
                      <div class="col-lg-1 text-center cell">Export</div>
                      <div class="col-lg-1 text-center cell">Print</div>
                      <div class="col-lg-1 text-center cell">Share</div>
                    </div>
                    <div class="ember-view">
                      <template v-for="(menu, i) in rows.all_reports.groups">
                        <div
                          :key="i"
                          class="
                            collapsed
                            ember-view
                            row
                            align-items-center
                            cell-border-top
                            reports-row
                          "
                          :id="i"
                        >
                          <div
                            class="
                              col-lg-6
                              text-left
                              cell
                              cursor-pointer
                              cell-border-right
                            "
                            @click="toggleReportGroup(i)"
                          >
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0"
                              y="0"
                              viewBox="0 0 512 512"
                              xml:space="preserve"
                              class="
                                icon icon-xs
                                align-middle
                                fill-draft
                                reports-arrow-down
                              "
                            >
                              <path
                                d="M175.8 447.8c-7.3 0-14.1-2.9-19.3-8.1-11-11.1-11.1-29.2-.2-40.4l129.4-131.8 11.5-11.7-11.5-11.7-129.4-131.6c-11-11.2-10.9-29.3.2-40.4 5.2-5.2 12-8.1 19.3-8.1 7.4 0 14.3 2.9 19.5 8.2L356 235.7c10.9 11.1 10.9 29.1 0 40.2L195.2 439.6c-5.2 5.3-12.1 8.2-19.4 8.2"
                              ></path>
                            </svg>
                            <span class="text-capitalize">
                              {{ $helper.inputTitle(i) }}
                            </span>
                          </div>
                          <div
                            class="
                              col-lg-2
                              text-center
                              cell
                              bg-light
                              cell-border-right
                              select-cell
                            "
                          >
                            <label
                              class="m-0 text-blue cursor-pointer"
                              @click="allowAccessAllReports(i)"
                              :id="'grant_all' + i"
                            >
                              Select All
                            </label>
                          </div>
                          <div
                            class="
                              col-lg-1
                              text-center text-capitalize
                              cell
                              select-cell
                            "
                          >
                            <label class="m-0 text-blue cursor-pointer">
                              Select All
                            </label>
                          </div>
                          <div class="col-lg-1 text-center cell select-cell">
                            <label class="m-0 text-blue cursor-pointer">
                              Select All
                            </label>
                          </div>
                          <div class="col-lg-1 text-center cell select-cell">
                            <label class="m-0 text-blue cursor-pointer">
                              Select All
                            </label>
                          </div>
                          <div class="col-lg-1 text-center cell select-cell">
                            <label class="m-0 text-blue cursor-pointer">
                              Select All
                            </label>
                          </div>
                        </div>
                        <div
                          :class="`ember-view active-border collapse ${i}`"
                          v-for="(item, j) in menu"
                          :key="i + '.' + j"
                          :id="i + '-' + j"
                        >
                          <div class="ember-view">
                            <div class="row align-items-center">
                              <div
                                class="
                                  col-lg-6
                                  text-left
                                  cell cell-border-right
                                "
                              >
                                <span class="text-capitalize">{{
                                  $helper.inputTitle(j)
                                }}</span>
                              </div>
                              <div
                                class="
                                  col-lg-2
                                  text-center
                                  bg-light
                                  cell cell-border-right
                                "
                              >
                                <input
                                  name="full_access"
                                  class="
                                    ember-checkbox ember-view
                                    form-check-input
                                    position-static
                                    cursor-pointer
                                  "
                                  type="checkbox"
                                  @click="
                                    grantFullReportAccess(
                                      $event,
                                      i + '-' + j,
                                      item
                                    )
                                  "
                                />
                              </div>
                              <div
                                class="
                                  col-lg-1
                                  text-center text-capitalize
                                  cell
                                "
                              >
                                <input
                                  name="can_access"
                                  class="
                                    ember-checkbox ember-view
                                    form-check-input
                                    position-static
                                    cursor-pointer
                                  "
                                  type="checkbox"
                                  v-model="item[0]"
                                />
                              </div>
                              <div class="col-lg-1 text-center cell">
                                <input
                                  name="can_export"
                                  class="
                                    ember-checkbox ember-view
                                    form-check-input
                                    position-static
                                    cursor-pointer
                                  "
                                  type="checkbox"
                                  v-model="item[1]"
                                />
                              </div>
                              <div class="col-lg-1 text-center cell">
                                <input
                                  name="can_schedule"
                                  class="
                                    ember-checkbox ember-view
                                    form-check-input
                                    position-static
                                    cursor-pointer
                                  "
                                  type="checkbox"
                                  v-model="item[2]"
                                />
                              </div>
                              <div class="col-lg-1 text-center cell">
                                <input
                                  name="can_share"
                                  class="
                                    ember-checkbox ember-view
                                    form-check-input
                                    position-static
                                    cursor-pointer
                                  "
                                  type="checkbox"
                                  v-model="item[3]"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <!-- End of reports permissions -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stick-action w-100">
        <div class="border shadow text-right px-4 py-2">
          <wolf-button
            classes="btn btn-primary"
            @clicked="handleSubmit"
            :disabler="!newRole.name"
            activator="saving_records"
          >
            <span slot="caption"
              ><em class="icon ni ni-save"></em
              ><span>Confirm and Save</span></span
            >
          </wolf-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import menus from "@/_helpers/menus.json";
export default {
  name: "RolesCreator",
  data: () => ({
    newRole: {
      name: null,
      description: null,
    },
    rows: {},
  }),
  created() {
    let id = this.$route.params.id;
    if (!this.$helper.empty(id)) {
      this.$http.get(`settings/users/permissions/${id}`).then((response) => {
        if (response.data.row) {
          this.newRole = { ...response.data.row };

          if (this.newRole.permissions)
            this.setDefaultPermissions(this.newRole.permissions, true);
          else this.setDefaultPermissions(menus);
        }
      });
    }
  },
  mounted() {
    let id = this.$route.params.id;
    if (this.$helper.empty(id)) {
      this.setDefaultPermissions(menus);
    }
  },
  methods: {
    grantFullAccess(e, menu, item) {
      if (e.target.checked) {
        document.getElementById(menu + "-view").checked = true;
        document.getElementById(menu + "-edit").checked = true;
        document.getElementById(menu + "-create").checked = true;
        document.getElementById(menu + "-delete").checked = true;
        for (let i = 0; i < 4; i++) item[i] = true;
      } else {
        document.getElementById(menu + "-view").checked = false;
        document.getElementById(menu + "-edit").checked = false;
        document.getElementById(menu + "-create").checked = false;
        document.getElementById(menu + "-delete").checked = false;
        for (let i = 0; i < 4; i++) item[i] = false;
      }
    },
    grantFullReportAccess(e, parentId, item) {
      var checkboxes = [
        ...document.querySelectorAll(`#${parentId} input[type='checkbox']`),
      ];
      if (e.target.checked) {
        checkboxes.forEach((elt) => (elt.checked = true));
        for (let i = 0; i < 4; i++) item[i] = true;
      } else {
        checkboxes.forEach((elt) => (elt.checked = false));
        for (let i = 0; i < 4; i++) item[i] = false;
      }
    },
    handleSubmit() {
      this.newRole.permissions = this.generatePermisions();
      this.$http
        .post(
          "settings/users/permissions/store",
          this.$helper.generateFormData(this.newRole)
        )
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "success",
              message: response.data.message,
            });
            this.$router.replace({ name: "UserRoles" });
          }
        });
    },
    setDefaultPermissions(rows, permissionsExist = false) {
      /**
       * Convert String to array so that checkboxes can be reactive
       */
      var existingMenus = null;
      if (permissionsExist) {
        existingMenus = JSON.parse(JSON.stringify(menus));
      }
      for (let menu in rows) {
        if (!this.$helper.empty(rows[menu].submenus)) {
          if (existingMenus) {
            rows[menu].submenus = {
              ...existingMenus[menu].submenus,
              ...rows[menu].submenus,
            };
          }
          for (let submenu in rows[menu].submenus) {
            let permissions = rows[menu].submenus[submenu];
            rows[menu].submenus[submenu] = [
              Boolean(Number(permissions[0])),
              Boolean(Number(permissions[1])),
              Boolean(Number(permissions[2])),
              Boolean(Number(permissions[3])),
            ];
          }
        } else {
          if (existingMenus) {
            /**
             * If there are new added items, delete those in database
             * and then merge two objects
             */
            if (existingMenus[menu].groups) {
              let localKeys = Object.keys(existingMenus[menu].groups);
              Object.keys(rows[menu].groups).forEach((item) => {
                if (!localKeys.includes(item)) {
                  delete rows[menu].groups[item];
                }
              });
            }
            /**
             * Merging object
             */
            rows[menu].groups = {
              ...existingMenus[menu].groups,
              ...rows[menu].groups,
            };
          }

          for (let group in rows[menu].groups) {
            if (existingMenus) {
              /**
               * Mergin inner items or submensus
               */
              rows[menu].groups[group] = {
                ...existingMenus[menu].groups[group],
                ...rows[menu].groups[group],
              };
            }
            for (let submenu in rows[menu].groups[group]) {
              let permissions = rows[menu].groups[group][submenu];
              rows[menu].groups[group][submenu] = [
                Boolean(Number(permissions[0])),
                Boolean(Number(permissions[1])),
                Boolean(Number(permissions[2])),
                Boolean(Number(permissions[3])),
              ];
            }
          }
        }
      }
      this.rows = rows;
    },
    generatePermisions() {
      let rows = JSON.parse(JSON.stringify(this.rows));
      for (let menu in rows) {
        for (let submenu in rows[menu].submenus) {
          let permissions = rows[menu].submenus[submenu]
            .map((x) => Number(x))
            .join("");
          rows[menu].submenus[submenu] = permissions;
        }
      }
      for (let group in rows.all_reports.groups) {
        for (let submenu in rows.all_reports.groups[group]) {
          let arr = rows.all_reports.groups[group][submenu]
            .map((x) => Number(x))
            .join("");
          rows.all_reports.groups[group][submenu] = arr;
        }
      }
      return rows;
    },
    toggleReportGroup(id) {
      document.getElementById(id).classList.toggle("active");
      [...document.querySelectorAll(`.${id}`)].forEach((elt) =>
        elt.classList.toggle("show")
      );
    },
    allowAccessAllReports(i) {
      let _this = document.querySelector("label#grant_all" + i);
      if (_this.textContent.trim() == "Select All")
        _this.textContent = "Unselect All";
      else _this.textContent = "Select All";
      [...document.querySelectorAll(`.${i} input[name='full_access']`)].forEach(
        (elt) => elt.click()
      );
    },
    handleAllReportsAccess(e) {
      for (let group in this.rows.all_reports.groups) {
        for (let submenu in this.rows.all_reports.groups[group]) {
          let arr = this.rows.all_reports.groups[group][submenu].map(
            (x) => x == e.target.checked
          );
          this.rows.all_reports.groups[group][submenu] = arr;
        }
      }
    },
  },
};
</script>
<style scoped>
label {
  margin-bottom: 0.5rem;
}
label {
  display: inline-block;
}
input {
  overflow: visible;
}
*,
::after,
::before {
  box-sizing: border-box;
}
a {
  color: var(--base-color);
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: var(--base-color);
  text-decoration: underline;
}
table {
  border-collapse: collapse;
}
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
@media print {
  tr {
    page-break-inside: avoid;
  }
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  thead {
    display: table-header-group;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td {
    background-color: #fff !important;
  }
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
}
label {
  font-weight: 400;
}
.table {
  width: 100%;
  margin-bottom: 10px;
  color: #212529;
}
.table td {
  padding: 8px;
  vertical-align: top;
  border-top: 1px solid #eee;
}
.table-bordered,
.table-bordered td {
  border: 1px solid #eee;
}
.table-bordered thead td {
  border-bottom-width: 2px;
}
.card > .table-bordered {
  border: 0;
}
.card > .table-bordered > tbody > tr > td:first-of-type,
.card > .table-bordered > thead > tr > td:first-of-type {
  border-left: 0;
}
.card > .table-bordered > tbody > tr > td:last-of-type,
.card > .table-bordered > thead > tr > td:last-of-type {
  border-right: 0;
}
.card > .table-bordered > tbody > tr:first-of-type > td,
.card > .table-bordered > tbody > tr:last-of-type > td,
.card > .table-bordered > thead > tr:first-of-type > td {
  border-bottom: 0;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #777;
}
.form-check-label {
  margin-bottom: 0;
}
input::-ms-clear {
  display: none;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-header {
  margin-bottom: 0;
}
.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.position-static {
  position: static !important;
}
.mb-5 {
  margin-bottom: 20px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-large {
  font-size: 16px;
}
.form-check-label {
  cursor: pointer;
}
.form-check-input:disabled ~ .form-check-label,
input[type="checkbox"]:disabled {
  cursor: not-allowed;
}
.popovercontainer {
  position: relative;
}
.popovercontainer:focus {
  outline: 0;
}
:focus::-webkit-input-placeholder {
  opacity: 0.5;
}
:focus:-moz-placeholder {
  opacity: 0.5;
}
:focus::-moz-placeholder {
  opacity: 0.5;
}
:focus:-ms-input-placeholder {
  opacity: 0.5;
}
.role-table td.permission {
  min-width: 70px;
  text-align: center;
}
.stick-action {
  position: sticky;
  bottom: 0;
  z-index: 90;
  background: #fff;
}

.collapse:not(.show) {
  display: none;
}
svg {
  vertical-align: baseline;
}
.alert {
  position: relative;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 2px;
}
.alert-warning {
  background-color: #fff4e7 !important;
  border: 0;
  color: #222;
}
.align-middle {
  vertical-align: middle !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
svg.icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
}
svg.icon.icon-xs {
  height: 12px;
  width: 12px;
}
svg.icon.fill-draft {
  fill: #999;
}
.cursor-pointer {
  cursor: pointer;
}
.font-large {
  font-size: 16px;
}
.reports-permission .reports-row .information {
  visibility: hidden;
}
.reports-permission .cell-border-right {
  border-right: 1px solid #eee;
}
.reports-permission .active,
.reports-permission .reports-row:hover {
  background-color: #fafafa;
}
.reports-permission .cell {
  padding: 0.9rem;
}
.reports-permission .cell-border-top {
  border-top: 1px solid #eee;
}
.reports-permission .active-border {
  margin-left: -15px;
  padding-left: 13px;
  border-left: 2px solid #54b4fa;
}
.reports-permission .reports-row:hover .information {
  visibility: visible;
}
.reports-row.active .reports-arrow-down {
  transform: rotate(90deg);
}
.reports-permission .fix-header {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  z-index: 1;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid #eee;
}
.text-brown {
  color: #8a6d3b;
}
.reports-row .select-cell {
  display: none;
}
.reports-row.active .select-cell {
  display: block;
}
</style>
