<script>
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "Accounts",
  components: {
    BootstrapModal,
  },
  data: () => ({
    rows: [],
    newAccount: {
      name: null,
      initial_balance: null,
      description: null,
    },
    selectedAccount: null,
    transferModalOpen: false,
    newTransfer: {
      sender_id: null,
      receiver_id: null,
      amount: null,
      description: null,
    },
  }),
  beforeCreate() {
    this.$http.get("accounts").then((response) => {
      this.rows = response.data.rows;
    });
  },
  methods: {
    openTransferModal(account) {
      this.newTransfer.sender_id = account.id;
      this.selectedAccount = account;
      this.transferModalOpen = true;
    },
    handleEdit(account) {
      this.newAccount = account;
      this.toggleModal();
    },
    handleTransfer() {
      this.$http
        .post(
          "accounts/transfer",
          this.$helper.generateFormData(this.newTransfer)
        )
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "success",
              message: "Transfered saved successfully.",
            });
            this.transferModalOpen = false;
            let receiver = this.rows.findIndex(
              (row) => row.id == this.newTransfer.receiver_id
            );
            let sender = this.rows.findIndex(
              (row) => row.id == this.newTransfer.sender_id
            );
            if (receiver) {
              this.rows[receiver].total_balance += Number(
                this.newTransfer.amount
              );
            }
            if (sender) {
              this.rows[sender].total_balance -= Number(
                this.newTransfer.amount
              );
            }
            this.newTransfer = {
              sender_id: null,
              receiver_id: null,
              amount: null,
              description: null,
            };
          }
        });
    },
    handleSubmit() {
      this.$http
        .post("accounts/store", this.$helper.generateFormData(this.newAccount))
        .then((response) => {
          this.$store.commit("SET_FLASH_MESSAGE", {
            type: "success",
            message: "Record saved successfully.",
          });
          if (this.newAccount.id) {
            let index = this.rows.findIndex(
              (item) => item.id == this.newAccount.id
            );
            this.rows[index] = response.data.account;
          } else {
            this.rows.unshift(response.data.account);
          }
          this.toggleModal();
          this.newAccount = {
            name: null,
            initial_balance: null,
            description: null,
          };
        });
    },
  },
};
</script>
<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">Accounts</h5>
                    <div class="nk-block-des text-soft"></div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <button
                              @click="toggleModal"
                              class="btn btn-primary d-md-inline-flex"
                            >
                              <em class="icon ni ni-plus"></em><span>New</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Account Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Opening Balance</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Current Balance</span>
                    </div>
                    <div class="nk-tb-col">Description</div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="title">{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        formatMoney(row.initial_balance)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        formatMoney(row.total_balance)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ row.description || "N/A" }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'AccountTransactions',
                                      query: {
                                        action: 'view-details',
                                        'account-id': row.id,
                                      },
                                    }"
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></router-link
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="openTransferModal(row)"
                                    ><em class="icon ni ni-reload"></em
                                    ><span>Transfer</span></a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="handleEdit(row)"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit</span></a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'accounts',
                                        entityIdentifer: 'Account ',
                                        entity: `${row.name || ''}`,
                                        url: `accounts/destroy/${row.id}`,
                                        callback: () => {
                                          accounts.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!rows.length"
                  class="text-center w-100 cursor-pointer"
                >
                  <h6 class="mb-0 text-muted">No data to display</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
      :showFooter="false"
      v-if="modalOpen"
    >
      <span slot="head">
        {{ !newAccount.id ? "New Account" : "Edit Account" }}
      </span>
      <div slot="body">
        <div class="form-group">
          <label class="mb-1" for="">Account Name</label>
          <input type="text" class="form-control" v-model="newAccount.name" />
        </div>
        <div class="form-group">
          <label class="mb-1" for="">Opening Balance</label>
          <input
            type="text"
            class="form-control"
            v-model="newAccount.initial_balance"
          />
        </div>
        <div class="form-group">
          <label class="mb-1" for="">Description</label>
          <textarea class="form-control" v-model="newAccount.description" />
        </div>
        <div class="form-group">
          <wolf-button
            caption="Save"
            @clicked="handleSubmit"
            :disabler="!newAccount.name || !newAccount.initial_balance"
            activator="SAVING"
            classes="btn btn-primary"
          ></wolf-button>
        </div>
      </div>
    </bootstrap-modal>
    <bootstrap-modal
      @close="transferModalOpen = false"
      style="display: block"
      :modalSize="'modal-sm'"
      :showFooter="false"
      v-if="transferModalOpen"
    >
      <span slot="head"> Transfer Amount From {{ selectedAccount.name }} </span>
      <div slot="body">
        <div class="form-group">
          <label class="mb-1" for="">Receiver Account</label>
          <select class="form-control" v-model="newTransfer.receiver_id">
            <template v-for="account in rows">
              <option
                :value="account.id"
                :key="account.id"
                v-if="account.id != selectedAccount.id"
              >
                {{ account.name }}
              </option>
            </template>
          </select>
        </div>
        <div class="form-group">
          <label class="mb-1" for="">Amount</label>
          <input
            type="number"
            class="form-control"
            v-model="newTransfer.amount"
          />
        </div>
        <div class="form-group">
          <label class="mb-1" for="">Description</label>
          <textarea class="form-control" v-model="newTransfer.description" />
        </div>
        <div class="form-group">
          <wolf-button
            caption="Confirm and Transfer"
            @clicked="handleTransfer"
            :disabler="!newTransfer.receiver_id || !newTransfer.amount"
            activator="SAVING"
            classes="btn btn-primary"
          ></wolf-button>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
