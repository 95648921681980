<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-lg mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <span v-if="isProuductionRequisitions"
                        >Request for raw materials</span
                      >
                      <span v-else>Create Requisition</span>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} products</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li class="w-100">
                            <div class="form-control-wrap w-100">
                              <type-head
                                :url="productsUrl"
                                label="name"
                                tracker="name"
                                placeholder="Search by item name"
                                @changed="handleSelectedItem"
                              ></type-head>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Type</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Total Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.type }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span> {{ formatMoney(product.price) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required'"
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(product)"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Quantity' + i)"
                          >Quantity is required
                        </span>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">
                        {{ formatMoney(items[i].total_amount) }}
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6 class="d-flex mb-3 align-items-center">
                      Grand Total:
                      <span class="ml-auto">
                        {{ formatMoney(grandTotal) }}</span
                      >
                    </h6>
                  </div>
                  <div class="w-100">
                    <div
                      class="d-flex justify-content-end align-items-center actions w-100"
                    >
                      <div
                        class="col-2 mb-0 px-1"
                        v-if="
                          $helper.empty(loggedUser.branch) &&
                          $helper.empty(loggedUser.source) &&
                          sources.length
                        "
                      >
                        <multiselect
                          v-if="isProuductionRequisitions"
                          v-model="source"
                          :options="sources"
                          :show-no-results="false"
                          :hide-selected="false"
                          placeholder="Select Production"
                          label="name"
                          track-by="name"
                        ></multiselect>
                        <multiselect
                          v-else
                          v-model="branch"
                          :options="sources"
                          :show-no-results="false"
                          :hide-selected="false"
                          placeholder="Select Branch"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>
                      <div class="col-2 mb-0 px-1">
                        <input
                          type="date"
                          v-model="committed_date"
                          class="form-control"
                          placeholder="Created Date"
                        />
                      </div>
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @clicked="handleSubmit"
                        :disabler="submitDisabled"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Save</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added items</h6>
                              <p>
                                No items found in Requisition list. Search for
                                product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import TypeHead from "@/components/TypeHead.vue";
import { mapState } from "vuex";
export default {
  name: "Requisitions",
  components: { Multiselect, TypeHead },
  data: () => ({
    items: [],
    productions: [],
    selectedItemIds: [],
    branch: null,
    source: null,
    destination: null,
    committed_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
  }),
  computed: {
    ...mapState({
      branches: (state) => state.branches,
      lockedBranch: (state) => state.branch,
    }),
    submitDisabled() {
      return (
        !this.items.length ||
        this.items.some((item) => !item.quantity) ||
        (!this.isProuductionRequisitions && !this.branch) ||
        (this.isProuductionRequisitions && !this.source)
      );
    },
    isProuductionRequisitions() {
      return this.$route.name == "ProductionRequests";
    },
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    sources() {
      if (this.isProuductionRequisitions) return this.productions;
      return this.branches;
    },
    productsUrl() {
      if (this.isProuductionRequisitions)
        return "shared/products?exclude=PRODUCTION";
      else return "shared/products";
    },
  },
  beforeCreate() {
    this.$http.get("production/sources").then((response) => {
      this.productions = response.data.sources;
    });
  },
  mounted() {
    this.$nextTick(() => {
      if (
        !this.$helper.empty(this.loggedUser.source) &&
        this.isProuductionRequisitions
      ) {
        this.source = this.loggedUser.source;
      }
      if (!this.$helper.empty(this.loggedUser.branch)) {
        this.branch = this.loggedUser.branch;
      }
    });
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        this.items.unshift({
          id: item.id,
          name: item.name,
          type: item.type,
          unit: item.unit,
          code: item.code,
          quantity: 1,
          total_amount: item.cost_price || item.cost,
          price: item.cost_price || item.cost,
          current_qty: item.quantity,
          items_count: 0,
          status: item.status,
          source: item.source,
        });
        this.selectedItemIds.unshift(item.id);
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          /**
           * Group Items according to their status(categories)
           */
          let productionItems = this.items.filter((item) => {
            return item.status == "PRODUCTION";
          });

          let stockItems = this.items.filter((item) => {
            return item.status == "STORABLE";
          });

          let perishableItems = this.items.filter((item) => {
            return item.status == "DIRECT_USE";
          });

          /**
           * Group requested production items into their destination sites
           */
          if (productionItems.length) {
            this.productions.forEach((production) => {
              let items = productionItems.filter((item) => {
                return item.source == production.id;
              });
              if (items.length) {
                let totalAmount = items.reduce(
                  (prev, cur) => prev + Number(cur.total_amount),
                  0
                );
                production.items = items;
                production.total_amount = totalAmount;
              }
            });
          }
          this.$http
            .post(
              "requisitions/create",
              this.$helper.generateFormData({
                branch_id: this.branch ? this.branch.id : null,
                source_id: this.source ? this.source.id : null,
                production_items: this.productions,
                stock_items: {
                  items: stockItems,
                  total_amount: stockItems.reduce(
                    (prev, cur) => prev + Number(cur.total_amount),
                    0
                  ),
                },
                perishable_items: {
                  items: perishableItems,
                  total_amount: perishableItems.reduce(
                    (prev, cur) => prev + Number(cur.total_amount),
                    0
                  ),
                },
                committed_date: this.committed_date,
              })
            )
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                this.$store.commit("REDUCE_NOTIFICATIONS", {
                  key: "requisitions",
                  total: -(
                    productionItems.length +
                    stockItems.length +
                    perishableItems.length
                  ),
                });
                if (this.loggedUser?.source_id) {
                  this.$router.push({ name: "RawMeterialsRequests" });
                } else if (this.isProuductionRequisitions) {
                  this.$router.push({ name: "ProductionRequisitions" });
                } else {
                  this.$router.push({ name: "Requests" });
                }
              }
            })
            
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
