<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Payments Received
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            style="width: 180px"
                            v-if="$helper.empty(loggedUser.branch)"
                          >
                            <multiselect
                              :options="branches"
                              placeholder="Select Branch"
                              label="name"
                              track-by="name"
                              @select="filterByBranch"
                            ></multiselect>
                          </li>
                          <li>
                            <type-head
                              url="payments/modes"
                              label="name"
                              tracker="name"
                              placeholder="Payment type"
                              @changed="handleTypeFilter"
                              ref="ModesSelector"
                              :load-defaults="true"
                            ></type-head>
                          </li>
                          <li style="width: 180px">
                            <multiselect
                              :options="users"
                              placeholder="Cashier or User"
                              label="name"
                              track-by="name"
                              @select="filterByUser"
                            ></multiselect>
                          </li>
                          <template v-if="rows.length">
                            <li>
                              <button
                                type="button"
                                class="btn btn-icon btn-white btn-dim btn-outline-light"
                                @click="$helper.handlePrint('.nk-block')"
                              >
                                <em class="icon ni ni-printer"></em>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                class="btn btn-white btn-dim btn-outline-light"
                                @click="handleExcelExport"
                              >
                                <svg
                                  height="18px"
                                  viewBox="0 0 384 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path
                                    d="m369.9 97.9-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-204.1c-26.5.1-48 21.6-48 48.1v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-332.1c0-12.7-5.1-25-14.1-34zm-37.8 30.1h-76.1v-76.1zm-284.1 336v-416h160v104c0 13.3 10.7 24 24 24h104v288zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3h-28.9c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3h28.8c9.3 0 15-10 10.4-18l-46.4-78c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"
                                  ></path>
                                </svg>
                              </button>
                            </li>
                          </template>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-toggle btn btn-white btn-icon btn-dim btn-outline-light"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-control-wrap mb-2">
                                        <type-head
                                          url="shared/clients/search"
                                          label="name"
                                          tracker="name"
                                          placeholder="Filter By client"
                                          @changed="handleClientFilter"
                                          ref="clientSelector"
                                        ></type-head>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="btn btn-primary d-md-inline-flex"
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <print-header>
                  <div slot="title" class="text-center mb-1">
                    <h5 class="text-center">
                      Payments Received /
                      <small style="font-size: 14px">{{ reportLabel }}</small>
                    </h5>
                  </div>
                </print-header>
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="custom-control custom-control-sm custom-checkbox notext"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid"
                          v-model="checkAll"
                        /><label class="custom-control-label" for="uid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col"><span>Payment Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Voucher No</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Client</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Payment type</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Reference</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger mr-n1"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li v-if="checkedRows.length">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'payments',
                                        entityIdentifer: 'payments',
                                        entity: `payments`,
                                        url: `sales/payments/${checkedRows.join(
                                          ','
                                        )}/delete`,
                                        callback: () => {
                                          handlePaginationSearch();
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete Selected</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="custom-control custom-control-sm custom-checkbox notext"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input row-checker"
                          :id="'uid' + i"
                          v-model="checkedRows"
                          :value="row.id"
                        /><label
                          class="custom-control-label"
                          :for="'uid' + i"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        $helper.formatDate(row.committed_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'SaleVoucherPrint',
                          params: {
                            reference: row.voucher,
                          },
                        }"
                      >
                        <span>#{{ row.voucher }}</span>
                      </router-link>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        row.client || "Walk-in Client"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>
                        <a href="javascript:void(0)">{{
                          row.payment_mode.name
                        }}</a>
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{ formatMoney(row.amount_paid) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="text-uppercase"
                        >{{ row.reference || "N/A" }}
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <a href="javascript:void(0)" v-if="row.creator">{{
                        row.creator
                      }}</a>
                      <span v-else>N/A</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <router-link
                                    target="_blank"
                                    :to="{
                                      name: 'SaleVoucherPrint',
                                      params: {
                                        reference: row.voucher,
                                      },
                                    }"
                                    ><em class="icon ni ni-file-text"></em
                                    ><span>View Voucher</span></router-link
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'payments',
                                        entityIdentifer: 'Client Payment',
                                        entity: `#${$helper.generateVoucherNo(
                                          row.id
                                        )}`,
                                        url: `sales/payments/${row.id}/delete`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item" v-if="rows.length">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"><h6>Grand Total</h6></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>
                        {{ `${formatMoney(grandTotal)}` }}
                      </h6>
                    </div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>

                    <div class="nk-tb-col"></div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
import PrintHeader from "@/components/PrintHeader.vue";
export default {
  name: "PaymentsReceived",
  components: { TypeHead, Multiselect, PrintHeader },
  mixins: [dashboardServices],
  data: () => ({
    excelFilename: "Payments-Received",
    users: [],
  }),
  computed: {
    branches() {
      return this.$store.state.branches || [];
    },
    grandTotal() {
      return this.rows.reduce((a, b) => a + Number(b.amount_paid), 0);
    },
    salesType() {
      let category = this.$route.query["category"];
      if (category && category == "branch-sales") {
        return "BRANCH_SALE";
      } else {
        return "PRODUCTION";
      }
    },
  },
  created() {
    //this.permissionsPath = "production.submenus.invoices";
    this.fetchUrl = `reports/sales/payments-received?sales_type=${this.salesType}`;
    const startDate = this.$route?.query?.start_date;
    const endDate = this.$route?.query?.end_date;
    if (startDate && endDate) {
      this.newFilter.from = startDate;
      this.newFilter.to = endDate;
      this.handlePaginationSearch(
        this.encodeQuery(this.fetchUrl, this.newFilter)
      );
    } else {
      this.handlePaginationSearch();
    }
    this.$http.get("shared/users/search").then((response) => {
      this.users = response.data;
    });
  },
  methods: {
    filterByBranch(branch) {
      if (branch) {
        this.newFilter.branch_id = branch.id;
        this.handlePaginationFilter();
      }
    },
    filterByUser(user) {
      this.newFilter.user = user.id;
      this.handlePaginationFilter();
    },
    handleTypeFilter(type) {
      if (type) {
        this.newFilter.type = type.id;
        this.handlePaginationFilter();
      }
    },
    handleClientFilter(client) {
      if (!this.$helper.empty(client)) {
        this.newFilter.client = client.id;
        this.handlePaginationFilter();
      }
    },
  },
};
</script>
