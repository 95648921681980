<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title mb-0">
                      Suppliers Payment
                    </h5>
                    <div class="nk-block-des text-soft">
                      <a href="javascript:void(0)"
                        ><small
                          ><b>{{ reportLabel }}</b></small
                        ></a
                      >
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            class="nk-block-tools-opt"
                            v-if="checkedRows.length > 1"
                          >
                            <router-link
                              :to="{
                                name: 'BulkPayment',
                                query: { vouchers: checkedRows.join(',') },
                              }"
                              class="btn btn-primary d-md-inline-flex"
                            >
                              <em class="icon ni ni-coins"></em
                              ><span>Pay Now</span>
                            </router-link>
                          </li>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="javascript:void(0)"
                                class="
                                  dropdown-toggle
                                  btn
                                  btn-white
                                  btn-icon
                                  btn-dim
                                  btn-outline-light
                                "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em class="icon ni ni-filter"></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span class="mb-2 font-weight-bolder"
                                        >Filter Results</span
                                      >
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-control-wrap mb-2">
                                        <multiselect
                                          v-model="supplier"
                                          :options="suppliers"
                                          placeholder="Filter By supplier"
                                          label="name"
                                          track-by="name"
                                          @select="handleSupplierFilter"
                                        ></multiselect>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="
                                            btn btn-primary
                                            d-md-inline-flex
                                          "
                                          :disabled="$helper.empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid"
                          v-model="checkAll"
                        /><label class="custom-control-label" for="uid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col"><span>Due Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Voucher No</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Supplier</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Items</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Paid</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Balance</span>
                    </div>
                    <div class="nk-tb-col">Status</div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input row-checker"
                          :id="'uid' + i"
                          v-model="checkedRows"
                          :value="row.id"
                          :disabled="row.total_amount == row.amount_paid"
                        /><label
                          class="custom-control-label"
                          :for="'uid' + i"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        $helper.formatDate(row.received_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'ReceiveVoucher',
                          params: { reference: row.reference },
                        }"
                        ><span>#{{ $helper.generateVoucherNo(row.id) }}</span></router-link
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead" v-if="!$helper.empty(row.vendor)">{{
                        row.vendor.name
                      }}</span>
                      <span v-else>N/A</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ $helper.formatNumber(row.items) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{ formatMoney(row.total_amount) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{ formatMoney(row.amount_paid) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">
                        {{
                          formatMoney(row.total_amount - row.amount_paid)
                        }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="badge badge-dot bg-success"
                        v-if="row.total_amount <= row.amount_paid"
                        >Paid</span
                      >
                      <span class="badge badge-dot bg-danger" v-else
                        >Unpaid</span
                      >
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <router-link
                                    target="_blank"
                                    :to="{
                                      name: 'ReceiveVoucher',
                                      params: { reference: row.reference },
                                    }"
                                    ><em class="icon ni ni-file-text"></em
                                    ><span>View Voucher</span></router-link
                                  >
                                </li>
                                <li v-if="row.total_amount > row.amount_paid">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleInstantPayment(row, i)
                                    "
                                    ><em class="icon ni ni-coins"></em
                                    ><span>Process Payment</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item" v-if="rows.length">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"><h6>Grand Total</h6></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>
                        {{ `${formatMoney(grandTotal)}` }}
                      </h6>
                    </div>
                    <div class="nk-tb-col">
                      <h6>
                        {{ `${formatMoney(totalPaid)}` }}
                      </h6>
                    </div>
                    <div class="nk-tb-col">
                      <h6>
                        {{ `${formatMoney(totalBalance)}` }}
                      </h6>
                    </div>

                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >PAY VOUCHER
        <a href="javascript:void(0)">
          &nbsp; #{{ newPayment.record.code }}</a
        ></span
      >
      <div slot="body">
        <form action="">
          <div class="form-group required">
            <label class="mb-1" for="">Payment Mode</label>
            <div class="form-control-select">
              <select class="form-control" v-model="newPayment.payment_type">
                <option value="null" selected hidden>Payment Method</option>
                <option
                  v-for="row in paymentModes"
                  :key="row.id"
                  :value="row.id"
                >
                  {{ row.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group required">
            <label class="mb-1 required" for="">Paid Through</label>
            <div class="form-control-select">
              <select class="form-control" v-model="newPayment.account_id">
                <option value="null" selected hidden>Select Option</option>
                <option v-for="row in accounts" :key="row.id" :value="row.id">
                  {{ row.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group required">
            <label class="mb-1">Amount</label>
            <div class="form-control-wrap">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    {{ appSettings.currency }}
                  </span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Payment Amount"
                  name="Paid Amount"
                  v-model="newPayment.amount_paid"
                  v-validate="'required|max_value:' + payableAmount"
                  :class="{ 'is-invalid': errors.has('Paid Amount') }"
                />
              </div>
              <span class="text-danger">{{ errors.first("Paid Amount") }}</span>
            </div>
          </div>
          <div class="form-group">
            <label class="mb-1" for="">Reference Number</label>
            <input
              type="text"
              class="form-control"
              v-model="newPayment.payment_ref"
              placeholder="Trx Id or Payment Ref"
            />
          </div>
          <div class="form-group">
            <label class="mb-1" for="">Comments</label>
            <textarea
              class="form-control"
              v-model="newPayment.comment"
              placeholder="Write comments (optional)"
            />
          </div>
          <div class="form-group">
            <wolf-button
              caption="Save"
              @clicked="handlePaymentSubmit"
              :disabler="
                !newPayment.payment_type ||
                !newPayment.amount_paid ||
                !newPayment.account_id
              "
              activator="handling_payment"
              classes="btn btn-primary"
            ></wolf-button>
          </div>
        </form>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Multiselect from "vue-multiselect";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "SupplierPayments",
  components: { Multiselect, BootstrapModal },
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "finance/suppliers/payments",
    items: [],
    record: {},
    suppliers: [],
    supplier: null,
    newFilter: {
      from: null,
      to: null,
    },
    newPayment: {
      voucher_id: null,
      amount_paid: null,
      payment_type: null,
      account_id: null,
      reference: null,
      record: {},
      payment_ref: null,
      comment: null,
    },
    rowIndex: null,
    payableAmount: 0,
  }),
  mounted() {
    this.$http.get("settings/suppliers?nopagination=1").then((response) => {
      this.suppliers = response.data.rows.data;
    });
    this.getPaymentsMode();
    this.getPaymentsAccounts();
  },
  computed: {
    grandTotal() {
      return this.rows.reduce((a, b) => a + Number(b.total_amount), 0);
    },
    totalPaid() {
      return this.rows.reduce((a, b) => a + Number(b.amount_paid), 0);
    },
    totalBalance() {
      return this.rows.reduce(
        (a, b) => a + (Number(b.total_amount) - Number(b.amount_paid)),
        0
      );
    },
  },
  methods: {
    handleSupplierFilter(supplier) {
      if (!this.$helper.empty(supplier)) {
        this.newFilter.supplier = supplier.id;
        this.handlePaginationFilter();
      }
    },
    getItemVoucher(reference) {
      this.items = [];
      this.record = {};
      this.$http
        .get(`/reports/receive/${reference}/voucher`)
        .then((response) => {
          this.items = response.data.items;
          this.record = response.data.record;
          this.toggleModal();
        });
    },
    handleInstantPayment(row, index) {
      this.newPayment.voucher_id = row.id;
      this.newPayment.amount_paid = (
        Number(row.total_amount) - Number(row.amount_paid)
      ).toFixed(2);
      this.payableAmount = this.newPayment.amount_paid;
      this.newPayment.record = row;
      this.rowIndex = index;
      this.toggleModal();
    },
    handlePaymentSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .post(
              "finance/suppliers/payment",
              this.$helper.generateFormData(this.newPayment)
            )
            .then((response) => {
              if (response.data.status) {
                // let product = ;
                this.rows[this.rowIndex] = response.data.voucher;
                this.rowIndex = null;
                this.toggleModal();
                this.$helper.resetObjectValues(this.newPayment);
              }
            })
            
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
