<template>
  <div class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Inventory Items</h3>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} items</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li>
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="search"
                                class="form-control"
                                id="default-04"
                                placeholder="Search by name"
                                v-model="filterKey"
                                @keyup="handlePaginationSearch"
                              />
                            </div>
                          </li>
                          <li v-if="false">
                            <div class="dropdown">
                              <a
                                href="javascript:void(0)"
                                class="
                                  dropdown-toggle dropdown-indicator
                                  btn btn-outline-light btn-white
                                "
                                data-toggle="dropdown"
                                aria-expanded="false"
                                >Status</a
                              >
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <ul class="link-list-opt no-bdr">
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>New Items</span></a
                                    >
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>Out of Stock</span></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li
                            class="nk-block-tools-opt"
                            v-if="canCreate(permissionsPath)"
                          >
                            <router-link
                              :to="{ name: 'ProductCreator' }"
                              class="toggle btn btn-icon btn-primary d-md-none"
                              ><em class="icon ni ni-plus"></em></router-link
                            ><router-link
                              :to="{ name: 'ProductCreator' }"
                              class="toggle btn btn-primary d-md-inline-flex"
                              ><em class="icon ni ni-plus"></em
                              ><span>New</span></router-link
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Item Type</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Sales Price</span></div>
                    <div class="nk-tb-col"><span>Count Unit</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col">
                      <span><em class="icon ni ni-setting"></em></span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in rows"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ product.type || "N/A" }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead"
                        >{{ formatMoney(product.cost) || "N/A" }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead"
                        >{{ formatMoney(product.price) || "N/A" }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">{{ product.unit }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">{{
                        `${$helper.formatNumber(product.quantity)} ${
                          product.unit
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span v-if="hasConfigured(product)"
                        ><em
                          class="icon ni ni-check-circle-cut text-primary"
                        ></em
                      ></span>
                      <span v-else
                        ><em class="icon ni ni-clock text-warning"></em
                      ></span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li class="mr-n1">
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <ul class="link-list-opt no-bdr">
                                <li v-if="canEdit(permissionsPath)">
                                  <router-link
                                    :to="{
                                      name: 'ProductCreator',
                                      params: { reference: product.code },
                                    }"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit Item</span></router-link
                                  >
                                </li>
                                <li v-if="canDelete(permissionsPath)">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'items',
                                        entityIdentifer: 'item',
                                        entity: `${product.name || ''}`,
                                        url: `stock/products/delete/${product.id}`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Item</span></a
                                  >
                                </li>
                                <li v-if="canEdit(permissionsPath)">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      openConfigurationModal(product, i)
                                    "
                                    ><em class="icon ni ni-setting"></em
                                    ><span>Configuration</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
      :showFooter="false"
    >
      <span slot="head">Edit product Configurations</span>
      <div slot="body">
        <div class="row">
          <div class="col-6">
            <div class="form-group mb-3">
              <label class="form-label" for="name">Product Name</label>
              <div class="form-control-wrap">
                <input
                  type="text"
                  v-model="newConfiguration.name"
                  class="form-control"
                  id="name"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Expiration alert before:</label>
              <div class="row no-gutters">
                <div class="col-6">
                  <input
                    type="number"
                    placeholder="Eg: 5"
                    class="form-control pr-0"
                    v-model="newConfiguration.expiry_notification.count"
                  />
                </div>
                <div class="col-6">
                  <select
                    class="form-control custom-select"
                    v-model="newConfiguration.expiry_notification.period"
                  >
                    <option value="days">Days</option>
                    <option value="weeks">Weeks</option>
                    <option value="months">Months</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <template>
              <span class="preview-title-lg overline-title"
                >Main Stock Configurations (optional)</span
              >
              <div class="row gy-4">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-1-01">Minimum</label
                    ><input
                      type="text"
                      class="form-control"
                      id="default-1-01"
                      placeholder="Minimum Quantity"
                      v-model="newConfiguration.store_min_qty"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-1-02">Purchase</label
                    ><input
                      type="text"
                      class="form-control"
                      id="default-1-02"
                      v-model="newConfiguration.store_purchase"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template>
              <hr class="preview-hr" />
              <span class="preview-title-lg overline-title"
                >Branches Configurations (optional)</span
              >
              <div class="row gy-4">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-1-01">Minimum</label
                    ><input
                      type="text"
                      class="form-control"
                      id="default-1-01"
                      placeholder="Minimum Quantity"
                      v-model="newConfiguration.branch_min_qty"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-1-02"
                      >Requisition</label
                    ><input
                      type="text"
                      class="form-control"
                      id="default-1-02"
                      v-model="newConfiguration.branch_purchase"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="col-12 mt-3">
            <div class="form-group mb-3">
              <wolf-button
                caption="Save and Close"
                @clicked="setProductConfiguration"
                :disabler="false"
                activator="saving_user"
                classes="btn btn-primary"
              ></wolf-button>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </div>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "Products",
  components: { BootstrapModal },
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "stock/products",
    newConfiguration: {
      id: null,
      store_min_qty: 0,
      store_purchase: 0,
      branch_min_qty: 0,
      branch_purchase: 0,
      expiry_notification: {
        count: null,
        period: "days",
      },
    },
  }),
  created() {
    this.permissionsPath = "inventory.submenus.items";
  },
  methods: {
    openConfigurationModal(product, index) {
      this.newConfiguration = {
        id: product.id,
        name: product.name,
        store_min_qty: product.store_min_qty,
        store_purchase: product.store_purchase,
        branch_min_qty: product.branch_min_qty,
        branch_purchase: product.branch_purchase,
        expiry_notification: {
          count: null,
          period: "days",
        },
      };
      if (!this.$helper.empty(product.expiry_notification)) {
        if (typeof product.expiry_notification == "string") {
          let data = product.expiry_notification.split(" ");
          this.newConfiguration.expiry_notification = {
            count: data[0],
            period: data[1] || "days",
          };
        }
      }
      this.editIndex = index;
      this.toggleModal();
    },
    hasConfigured(product) {
      let obj = {
        store_min_qty: product.store_min_qty,
        store_purchase: product.store_purchase,
        branch_min_qty: product.branch_min_qty,
        branch_purchase: product.branch_purchase,
      };
      return Object.values(obj).every((item) => {
        return item != 0 && item != null;
      });
    },
    setProductConfiguration() {
      this.$http
        .post(
          "stock/products/update",
          this.$helper.generateFormData(this.newConfiguration)
        )
        .then((response) => {
          if (response.data.status) {
            // let product = ;
            this.rows[this.editIndex] = {
              ...this.rows[this.editIndex],
              ...this.newConfiguration,
            };
            this.editIndex = null;
            this.toggleModal();
            this.$helper.resetObjectValues(this.newConfiguration);
          }
        })
        
    },
  },
};
</script>
<style scoped>
@media screen {
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    padding-top: 0.0625rem;
    font-size: 0.875rem;
    cursor: pointer;
  }
  .custom-option-label {
    position: relative;
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: 0;
    -webkit-transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    border: 1px solid #e7e7e7;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    line-height: 1.5rem;
    cursor: pointer;
  }
  .custom-option-label:hover {
    border-color: #0a0927;
    color: #0a0927;
  }
  .custom-option-color {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1rem;
    height: 1rem;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
  }
}
</style>
