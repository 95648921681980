var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"nk-content-inner"},[_c('div',{staticClass:"nk-content-body"},[_c('div',{staticClass:"wide-lg mx-auto"},[(!_vm.$helper.empty(_vm.requisition))?_c('div',{staticClass:"invoice break-inside",attrs:{"id":"report-container"}},[_c('div',{staticClass:"invoice-action"},[_c('a',{staticClass:"btn btn-icon btn-white btn-dim btn-outline-primary d-print-none",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.$helper.handlePrint('.invoice-wrap')}}},[_c('em',{staticClass:"icon ni ni-printer-fill"})])]),_c('div',{staticClass:"invoice-wrap"},[_c('print-header',[_c('div',{staticClass:"text-center mb-1",attrs:{"slot":"title"},slot:"title"},[_c('h4',{staticClass:"text-center"},[_vm._v(" Requisition:#"+_vm._s(_vm.$helper.generateVoucherNo(_vm.requisition.id))+" / "),_c('small',{staticStyle:{"font-size":"14px"}},[_vm._v("From "+_vm._s(_vm.requisition?.source?.name || _vm.requisition?.branch?.name)+" To "),(
                        _vm.requisition.type == 'STOCK' ||
                        _vm.requisition.type == 'PRODUCTION'
                      )?_c('span',[_vm._v("MAIN STOCK")]):(_vm.requisition.type == 'PRODUCTION_STOCK')?_c('span',[_vm._v("PRODUCTION")]):_c('span',[_vm._v("PERISHABLES")])])])])]),_c('div',{staticClass:"invoice-head d-print-none"},[_c('div',{staticClass:"invoice-contact"},[_c('span',{staticClass:"overline-title"},[_vm._v("Source/Branch")]),_c('div',{staticClass:"invoice-contact-info"},[_c('h6',{staticClass:"title"},[(!_vm.$helper.empty(_vm.requisition.source))?_c('span',{staticClass:"tb-amount"},[_vm._v(_vm._s(_vm.requisition.source.name || "N/A"))]):_c('span',{staticClass:"tb-amount"},[_vm._v(_vm._s(_vm.requisition.branch.name || "N/A"))])])]),_c('span',{staticClass:"overline-title"},[_vm._v("Destination")]),_c('div',{staticClass:"invoice-contact-info"},[_c('h6',{staticClass:"title"},[(
                        _vm.requisition.type == 'STOCK' ||
                        _vm.requisition.type == 'PRODUCTION'
                      )?_c('span',[_vm._v("MAIN STOCK")]):(_vm.requisition.type == 'PRODUCTION_STOCK')?_c('span',[_vm._v("PRODUCTION")]):_c('span',[_vm._v("PERISHABLES")])])])]),_c('div',{staticClass:"invoice-desc"},[_c('h6',{staticClass:"title"},[_vm._v("REQUISITION")]),_c('ul',{staticClass:"list-plain"},[_c('li',{staticClass:"invoice-id"},[_c('span',[_vm._v("Voucher No")]),_vm._v(":"),_c('span',[_vm._v(_vm._s(_vm.$helper.generateVoucherNo(_vm.requisition.id)))])]),_c('li',{staticClass:"invoice-date"},[_c('span',[_vm._v("Date")]),_vm._v(":"),_c('span',[_vm._v(_vm._s(_vm.$helper.formatDate(_vm.requisition.committed_date)))])])])])]),_c('div',{staticClass:"invoice-bills"},[_c('table',{staticClass:"w-100"},[_vm._m(0),_c('tbody',_vm._l((_vm.items),function(item,i){return _c('tr',{key:'item' + i},[_c('td',[_vm._v(_vm._s(i + 1))]),_c('td',{staticClass:"d-print-none"},[_vm._v("#"+_vm._s(item.product.code))]),_c('td',[_vm._v(_vm._s(item.product.name))]),_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.formatMoney( item.product.cost_price || item.product.cost )))])]),_c('td',[_vm._v(" "+_vm._s(_vm.$helper.formatNumber(item.requested_quantity))+" ")]),_c('td',[_c('span',{staticClass:"tb-sub tb-amount"},[_vm._v(_vm._s(_vm.formatMoney( (item.product.cost_price || item.product.cost) * item.requested_quantity )))])])])}),0),_c('tfoot',[_c('tr',[_c('td',{staticClass:"d-print-none",attrs:{"colspan":"3"}}),_c('td',{staticClass:"d-none d-print"}),_c('td',{attrs:{"colspan":"2"}},[_vm._v("Grand Total")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.requisition.total_amount))+" ")])])])]),_vm._m(1)])],1)]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("$/N")]),_c('th',{staticClass:"w-150px d-print-none"},[_vm._v("Item Code")]),_c('th',{staticClass:"w-350px"},[_vm._v("Item Name")]),_c('th',[_vm._v("Cost Price")]),_c('th',[_vm._v("Req. Qty")]),_c('th',[_vm._v("Amount")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nk-notes mt-5 d-none d-print"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex w-100 flex-nowrap mb-5"},[_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v("Delivered by:")])]),_c('div',{staticClass:"dots-after"})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex w-100 flex-nowrap mb-5"},[_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v("Received by:")])]),_c('div',{staticClass:"dots-after"})])]),_c('div',{staticClass:"col-6"}),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex w-100 flex-nowrap mb-5"},[_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v("Approved By:")])]),_c('div',{staticClass:"dots-after"})])])])])
}]

export { render, staticRenderFns }