<template>
  <div class="w-100">
    <div class="card-inner card-inner-lg">
      <div class="nk-block-head nk-block-head-lg">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h4 class="nk-block-title">Personal Information</h4>
            <div class="nk-block-des">
              <p>
                Basic info, like your name and address, that you use on Siga
                Platform.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="nk-block">
        <div class="nk-data data-list">
          <div class="data-head">
            <h6 class="overline-title">Basics</h6>
          </div>
          <div class="data-item">
            <div class="data-col">
              <span class="data-label">Full Name</span
              ><span class="data-value">{{ user.name }}</span>
            </div>
            <div class="data-col data-col-end">
              <span class="data-more"
                ><em class="icon ni ni-forward-ios"></em
              ></span>
            </div>
          </div>
          <div class="data-item">
            <div class="data-col">
              <span class="data-label">Display Name</span
              ><span class="data-value">{{ user.first_name }}</span>
            </div>
            <div class="data-col data-col-end">
              <span class="data-more"
                ><em class="icon ni ni-forward-ios"></em
              ></span>
            </div>
          </div>
          <div class="data-item">
            <div class="data-col">
              <span class="data-label">Email</span
              ><span class="data-value">{{
                user.email || "Not added yet"
              }}</span>
            </div>
            <div class="data-col data-col-end">
              <span class="data-more disable"
                ><em class="icon ni ni-lock-alt"></em
              ></span>
            </div>
          </div>
          <div class="data-item">
            <div class="data-col">
              <span class="data-label">Phone Number</span
              ><span class="data-value text-soft">{{
                user.phone || "Not added yet"
              }}</span>
            </div>
            <div class="data-col data-col-end">
              <span class="data-more"
                ><em class="icon ni ni-forward-ios"></em
              ></span>
            </div>
          </div>
          <div class="data-item">
            <div class="data-col">
              <span class="data-label">Gender</span
              ><span class="data-value">{{
                user.gender == "M" ? "Male" : "Female"
              }}</span>
            </div>
            <div class="data-col data-col-end">
              <span class="data-more"
                ><em class="icon ni ni-forward-ios"></em
              ></span>
            </div>
          </div>
          <div class="data-item">
            <div class="data-col">
              <span class="data-label">Address</span
              ><span class="data-value">{{ user.address || "N/A" }}</span>
            </div>
            <div class="data-col data-col-end">
              <span class="data-more"
                ><em class="icon ni ni-forward-ios"></em
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="modalOpen">
      <div
        class="modal fade show"
        id="profile-edit"
        style="display: block; padding-right: 15px"
        aria-modal="true"
        role="dialog"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <a
              href="javascript:void(0)"
              class="close"
              @click.prevent="toggleModal"
              ><em class="icon ni ni-cross-sm"></em
            ></a>
            <div class="modal-body modal-body-lg">
              <h5 class="title">Update Profile</h5>
              <ul class="nk-nav nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#personal"
                    >Personal</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#address"
                    >Address</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane active" id="personal">
                  <div class="row gy-4">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="full-name"
                          >First Name</label
                        ><input
                          type="text"
                          class="form-control form-control-lg"
                          id="full-name"
                          v-model="newInfo.first_name"
                          placeholder="First name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="display-name"
                          >Last Name</label
                        ><input
                          type="text"
                          class="form-control form-control-lg"
                          id="display-name"
                          v-model="newInfo.last_name"
                          placeholder="Last name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="phone-no"
                          >Phone Number</label
                        ><input
                          type="text"
                          class="form-control form-control-lg"
                          id="phone-no"
                          v-model="newInfo.phone"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="birth-day"
                          >Email address</label
                        ><input
                          type="text"
                          class="form-control form-control-lg date-picker"
                          id="birth-day"
                          v-model="newInfo.email"
                          placeholder="Your date of birth"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <ul
                        class="align-center flex-wrap flex-sm-nowrap gx-4 gy-2"
                      >
                        <li>
                          <a
                            href="javascript:void(0)"
                            class="btn btn-lg btn-primary"
                            >Update Profile</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            class="link link-light"
                            @click.prevent="toggleModal"
                            >Cancel</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="address" v-if="false">
                  <div class="row gy-4">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="address-l1"
                          >Address Line 1</label
                        ><input
                          type="text"
                          class="form-control form-control-lg"
                          id="address-l1"
                          v-model="address.address"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="address-l2"
                          >Address Line 2</label
                        ><input
                          type="text"
                          class="form-control form-control-lg"
                          id="address-l2"
                          v-model="address.address2"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="address-st"
                          >State / Province</label
                        ><input
                          type="text"
                          class="form-control form-control-lg"
                          id="address-st"
                          v-model="address.state"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="address-st"
                          >City / District</label
                        ><input
                          type="text"
                          class="form-control form-control-lg"
                          id="address-st"
                          v-model="address.city"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <ul
                        class="align-center flex-wrap flex-sm-nowrap gx-4 gy-2"
                      >
                        <li>
                          <a
                            href="javascript:void(0)"
                            class="btn btn-lg btn-primary"
                            >Update Address</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            data-dismiss="modal"
                            class="link link-light"
                            >Cancel</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show"></div>
    </template>
  </div>
</template>
<script>
export default {
  name: "Profile",
  data: () => ({
    newInfo: {},
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {},
  methods: {},
};
</script>
