<template>
  <div class="card card-full h-100 card-bordered">
    <div class="card-inner">
      <div class="card-title-group align-start gx-3 mb-3">
        <div class="card-title">
          <h6 class="title">Production Sales Overview</h6>
          <p>
            In 30 days sales of production items.
            <router-link
              :to="{ name: 'ProductionSalesReport', query: monthDatesQuery() }"
              >See Details</router-link
            >
          </p>
        </div>
      </div>
      <div class="nk-sale-data-group align-center justify-between gy-3 gx-5">
        <div class="nk-sale-data">
          <span class="amount">{{
            `${formatMoney(records.total_amount)}`
          }}</span>
        </div>
        <div class="nk-sale-data">
          <span class="amount sm"
            >{{ $helper.formatNumber(records.total_sales) }}
            <small>Sales</small></span
          >
        </div>
      </div>
      <div class="nk-sales-ck large pt-4">
        <line-chart
          ref="productionSalesOverview"
          :chart-data="chartData"
          :options="options"
        ></line-chart>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/LineChart";
export default {
  name: "ProductionSales",
  props: ["records"],
  components: { LineChart },
  data: () => ({
    options: {
      legend: {
        display: false,
        labels: { boxWidth: 30, padding: 20, fontColor: "#6783b8" },
      },
      responsive: true,
      maintainAspectRatio: !1,
      tooltips: {
        enabled: true,
        rtl: false,
        callbacks: {
          title: function (e, t) {
            return t.labels[e[0].index];
          },
          label: function (e, t) {
            return t.datasets[e.datasetIndex].data[e.index] + " $";
          },
        },
        backgroundColor: "#eff6ff",
        titleFontSize: 13,
        titleFontColor: "#6783b8",
        titleMarginBottom: 6,
        bodyFontColor: "#9eaecf",
        bodyFontSize: 12,
        bodySpacing: 4,
        yPadding: 10,
        xPadding: 10,
        footerMarginTop: 0,
        displayColors: !1,
      },
      scales: {
        yAxes: [
          {
            display: true,
            stacked: false,
            position: "left",
            ticks: {
              beginAtZero: !0,
              fontSize: 11,
              fontColor: "#9eaecf",
              padding: 10,
              callback: function (e) {
                return 999 < Math.abs(e)
                  ? Math.sign(e) * (Math.abs(e) / 1e3).toFixed(1) + " k"
                  : Math.sign(e) * Math.abs(e);
              },
              stepSize: 3e3,
            },
            gridLines: {
              color: "rgba(82,100,132,0.2)",
              tickMarkLength: 0,
              zeroLineColor: "rgba(82,100,132,0.2)",
            },
          },
        ],
        xAxes: [
          {
            display: true,
            stacked: false,
            ticks: {
              fontSize: 9,
              fontColor: "#9eaecf",
              source: "auto",
              padding: 10,
              reverse: false,
            },
            gridLines: {
              color: "transparent",
              tickMarkLength: 0,
              zeroLineColor: "transparent",
            },
          },
        ],
      },
    },
  }),
  computed: {
    chartData() {
      return {
        labels: this.records.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.1,
        datasets: [
          {
            label: "Production Sales Overview",
            borderColor: "#798bff",
            color: "#798bff",
            dash: 0,
            backgroundColor: "rgba(121,139,255,0.3)",
            data: this.records.data,
            borderWidth: 2,
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#798bff",
            pointBorderWidth: 2,
            pointHoverRadius: 3,
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 3,
          },
        ],
      };
    },
  },
};
</script>
<style>
.nk-sales-ck div {
  height: 100%;
  max-height: 100%;
}
</style>
