<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div
                class="alert alert-fill alert-warning alert-icon"
                v-if="!$helper.empty(record)"
              >
                <em class="icon ni ni-alert-circle"></em>
                <strong>Warning!</strong>. Make sure that items you are going to
                edit or delete are not involved in any transaction.
              </div>
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <template v-if="$helper.empty(record)">
                        <span v-if="isProductionItems"
                          >Receive Raw Materials</span
                        >
                        <span v-else>Receive Items</span>
                      </template>
                      <template v-else>
                        <span
                          >Edit Receiving &nbsp;
                          <a href="javascript:void(0)"
                            >#{{ record.code }}</a
                          ></span
                        >
                      </template>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p v-if="!$helper.empty(order)">
                        Receive Items from purchase order
                        <span class="text-primary">
                          #{{ $helper.generateVoucherNo(order.id) }}</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div
                        class="toggle-expand-content"
                        
                      >
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            v-if="
                              objectType == null ||
                              objectType == 'PURCHASE_ORDER'
                            "
                          >
                            <type-head
                              url="stock/products/search"
                              label="name"
                              tracker="name"
                              placeholder="Select Item"
                              @changed="handleSelectedItem"
                            ></type-head>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Total Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{
                              appSettings.currency
                            }}</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            v-model="items[i].price"
                            :placeholder="`Price in ${appSettings.currency}`"
                            :name="'Cost Price' + i"
                            :class="{
                              'is-invalid': errors.has('Cost Price' + i),
                            }"
                            v-validate="'required'"
                            @keyup="findTotalAmount(product)"
                          />
                        </div>
                        <span
                          class="text-danger"
                          v-if="errors.has('Cost Price' + i)"
                          >Cost Price is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required'"
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(product)"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Quantity' + i)"
                          >Quantity is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">
                        {{ formatMoney(items[i].total_amount) }}
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        v-if="!$helper.empty(order)"
                        @click.prevent="
                          $alert({
                            title: 'Removing from purchase order',
                            content: `Are you sure you want to remove this product (${product.name})?`,
                            classes: 'btn btn-danger',
                            actionText: 'Continue',
                            closeText: 'Discard',
                            action: () => {
                              handleOrderItemDeletion(product, i);
                            },
                          })
                        "
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                      <a
                        v-else
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6
                      class="d-flex mb-3 align-items-center justify-content-end"
                    >
                      Grand Total:
                      <span class="ml-auto">{{ formatMoney(grandTotal) }}</span>
                    </h6>
                  </div>
                  <bootstrap-modal
                    @close="toggleModal"
                    style="display: block"
                    :modalSize="'modal-default'"
                    :showFooter="false"
                    v-if="modalOpen"
                  >
                    <span slot="head" style="padding-top: 10px"
                      >Confirm Receive record</span
                    >
                    <div slot="body">
                      <div class="row">
                        <div
                          class="form-group col-12"
                          v-if="
                            isProductionItems &&
                            $helper.empty(loggedUser.source)
                          "
                        >
                          <label class="mb-0">Production Source</label>
                          <multiselect
                            v-model="productionSource"
                            :options="sources"
                            placeholder="Select Production"
                            label="name"
                            track-by="name"
                          ></multiselect>
                        </div>
                        <div class="form-group col-12">
                          <label class="mb-0">Supplier</label>
                          <type-head
                            url="shared/suppliers/search"
                            label="name"
                            tracker="name"
                            :loadDefaults="true"
                            placeholder="Select supplier"
                            @changed="setSupplier"
                          ></type-head>
                        </div>
                        <div class="form-group col-6">
                          <ul class="custom-control-group">
                            <li>
                              <div
                                class="custom-control custom-control-sm custom-checkbox custom-control-pro"
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  v-model="has_invoice"
                                  id="btnRadio1"
                                  value="1"
                                /><label
                                  class="custom-control-label"
                                  for="btnRadio1"
                                  style="white-space: nowrap"
                                  >Invoice Given</label
                                >
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="form-group col-6" v-if="has_invoice == 1">
                          <select class="form-control" v-model="vat">
                            <option value="INCLUSIVE">VAT Inclusive</option>
                            <option value="EXCLUSIVE">VAT Exclusive</option>
                          </select>
                        </div>
                        <div class="form-group col-12">
                          <label class="mb-0">Date</label>
                          <input
                            type="date"
                            v-model="received_date"
                            class="form-control"
                            placeholder="Receiving Date"
                          />
                        </div>
                        <div class="form-group col-12">
                          <wolf-button
                            classes="btn btn-primary ml-2"
                            @clicked="handleSubmit"
                            :disabler="submitDisabled"
                            activator="saving_records"
                          >
                            <span slot="caption"
                              ><em class="icon ni ni-save"></em
                              ><span>Save</span></span
                            >
                          </wolf-button>
                        </div>
                      </div>
                    </div>
                  </bootstrap-modal>
                  <div class="w-100" v-if="isProductionItems">
                    <div
                      class="d-flex justify-content-end align-items-center actions w-100"
                    >
                      <div>
                        <button
                          class="btn btn-primary"
                          @click="toggleModal"
                          :disabled="!items.length"
                          type="button"
                        >
                          <span slot="caption"
                            ><span>Continue</span
                            ><em class="icon ni ni-arrow-right"></em
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="w-100" v-else>
                    <div
                      class="d-flex justify-content-end align-items-center actions w-100"
                    >
                      <div class="col-3 px-1">
                        <type-head
                          url="shared/suppliers/search"
                          label="name"
                          tracker="name"
                          :loadDefaults="true"
                          placeholder="Select supplier"
                          @changed="setSupplier"
                        ></type-head>
                      </div>
                      <div class="form-group col-2 mb-0 px-1">
                        <ul class="custom-control-group m-0 pt-0">
                          <li>
                            <div
                              class="custom-control custom-control-sm custom-checkbox custom-control-pro"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                v-model="has_invoice"
                                id="btnRadio1"
                                value="1"
                              /><label
                                class="custom-control-label"
                                for="btnRadio1"
                                style="white-space: nowrap"
                                >Invoice Given</label
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="form-group col-2 mb-0 px-1"
                        v-if="has_invoice == 1"
                      >
                        <select class="form-control" v-model="vat">
                          <option value="INCLUSIVE">VAT Inclusive</option>
                          <option value="EXCLUSIVE">VAT Exclusive</option>
                        </select>
                      </div>
                      <div class="form-group col-2 mb-0 px-1">
                        <input
                          type="date"
                          v-model="received_date"
                          class="form-control"
                          placeholder="Receiving Date"
                        />
                      </div>
                      <div class="form-group">
                        <wolf-button
                          classes="btn btn-primary ml-2"
                          @clicked="handleSubmit"
                          :disabler="submitDisabled"
                          activator="saving_records"
                        >
                          <span slot="caption"
                            ><em class="icon ni ni-save"></em
                            ><span>Save</span></span
                          >
                        </wolf-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added items</h6>
                              <p>
                                No items found in receiving order. Search for
                                product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
import BootstrapModal from "@/components/BootstrapModal.vue";
export default {
  name: "StockReceive",
  components: { Multiselect, TypeHead, BootstrapModal },
  data: () => ({
    sources: [],
    items: [],
    rows: [],
    selectedItemIds: [],
    supplier: null,
    received_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
    has_invoice: 0,
    vat: "EXCLUSIVE",
    objectType: null,
    isEditMode: false,
    order: null,
    order_id: null,
    record: {},
    items_type: null,
    productionSource: null,
  }),
  computed: {
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    submitDisabled() {
      return (
        !this.supplier ||
        !this.items.length ||
        (this.isProductionItems && !this.productionSource)
      );
    },
    isProductionItems() {
      return this.$route.name == "ProductionReceive";
    },
  },
  beforeCreate() {
    const reference = this.$route.params.reference;
    if (reference) {
      localStorage.removeItem("receiveItems");
      this.$http.get(`orders/report/${reference}/details`).then((response) => {
        let items = response.data.items;
        this.objectType = "PURCHASE_ORDER";
        this.order = response.data.order;
        if (!this.$helper.empty(this.order)) {
          this.order_id = this.order.id;
          this.supplier = this.order.vendor;
        }
        items.forEach((item) => {
          this.items.push({
            item_id: item.id,
            id: item.product.id,
            name: item.product.name,
            unit: item.product.unit,
            source: item.product.source,
            quantity: item.quantity,
            total_amount: item.total_amount,
            price: item.price,
            current_qty: item.product.quantity,
          });
          this.selectedItemIds.push(item.product.id);
        });
      });
    } else {
      const action = this.$route.query.action;
      const reference = this.$route.query.reference;
      if (typeof action == "string" && action == "edit") {
        localStorage.removeItem("receiveItems");
        this.$http
          .get(`/reports/receive/${reference}/voucher`)
          .then((response) => {
            let items = response.data.items;
            this.record = response.data.record;
            this.supplier = this.record.vendor;
            this.productionSource = this.record.source;
            this.vat = this.record.vat;
            this.has_invoice = this.record.has_invoice;
            this.received_date = new Date(this.record.received_date)
              .toLocaleString("fr-CA", { timeZone: TIMEZONE })
              .slice(0, 10);
            items.forEach((item) => {
              this.items.push({
                id: item.product.id,
                name: item.product.name,
                unit: item.product.unit,
                source: item.product.source,
                quantity: item.quantity,
                total_amount: item.quantity * item.price,
                price: item.price,
              });
              this.selectedItemIds.push(item.product.id);
            });
          });
      } else if (typeof action == "string" && action == "confirm-dispatch") {
        //const itemIds = JSON.parse(decodeURIComponent(this.$route.query.items));
        //console.log(itemIds);
        const startDate = this.$route?.query?.start_date;
        const endDate = this.$route?.query?.end_date;
        this.$http
          .get(
            "stock/products/dispatchable-items?items=" +
              this.$route.query.items +
              "&from=" +
              startDate +
              "&to=" +
              endDate
          )
          .then((response) => {
            this.objectType = "ITEMS_DISPATCH";
            response.data.rows.forEach((item) => {
              this.items.push({
                id: item.id,
                name: item.name,
                unit: item.unit,
                source: item.source,
                quantity: item.quantity,
                total_amount: item.quantity * item.price,
                price: item.price,
              });
              this.selectedItemIds.push(item.id);
            });
          });
      }
    }
  },
  mounted() {
    if (this.isProductionItems) {
      this.$http.get("production/sources").then((response) => {
        this.sources = response.data.sources;
      });
      this.$nextTick(() => {
        const source = this.loggedUser.source;
        if (source) {
          this.productionSource = source;
        }
      });
    }
  },
  created() {
    let reference = this.$route.params.reference;
    if (!reference) {
      let cart = localStorage.getItem("receiveItems");

      if (cart) {
        let result = JSON.parse(cart);
        /**
         * Check if saved items belongs to the current route
         */
        if (this.isProductionItems) {
          if (!result.isProduction) {
            localStorage.removeItem("receiveItems");
          } else {
            this.items = result.items;
            this.selectedItemIds = result.selectedItemIds;
          }
        } else {
          if (result.isProduction) {
            localStorage.removeItem("receiveItems");
          } else {
            this.items = result.items;
            this.selectedItemIds = result.selectedItemIds;
          }
        }
        //this.items = result.items;
        //this.selectedItemIds = result.selectedItemIds;
      }
    } else localStorage.removeItem("receiveItems");
    let action = this.$route.query.action;
    this.isEditMode = typeof action == "string" && action == "edit";
  },
  beforeDestroy() {
    if (this.items.length) {
      if (!this.objectType && !this.isEditMode)
        localStorage.setItem(
          "receiveItems",
          JSON.stringify({
            items: this.items,
            selectedItemIds: this.selectedItemIds,
            isProduction: this.isProductionItems,
          })
        );
      else localStorage.removeItem("receiveItems");
    }
  },
  watch: {
    items() {
      if (!this.items.length) {
        localStorage.removeItem("receiveItems");
      }
    },
    $route() {
      location.reload();
    },
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
      localStorage.setItem(
        "receiveItems",
        JSON.stringify({
          items: this.items,
          selectedItemIds: this.selectedItemIds,
          isProduction: this.isProductionItems,
        })
      );
    },
    handleOrderItemDeletion(item, key) {
      this.$http
        .get(`orders/items/details/${item.item_id}/delete`)
        .then((response) => {
          if (response.data.status) {
            this.items.splice(key, 1);
            this.selectedItemIds.splice(key, 1);
          }
        });
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        this.items = this.$helper.handleArrayPush(this.items, {
          id: item.id,
          name: item.name,
          unit: item.unit,
          source: item.source,
          quantity: 1,
          total_amount: item.cost_price || item.cost,
          price: item.cost_price || item.cost,
          current_qty: item.quantity,
        });
        this.selectedItemIds = this.$helper.handleArrayPush(
          this.selectedItemIds,
          item.id
        );
      } else {
        this.$notifier({
          type: "warning",
          message: "Item already added to the list",
        });
      }
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    setSupplier(supplier) {
      this.supplier = supplier;
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.isProductionItems) {
            this.items_type = "PRODUCTION";
          }
          this.$http
            .post(
              "stock/products/receive",
              this.$helper.generateFormData({
                order_id: this.order_id,
                supplier: this.supplier.id,
                received_date: this.received_date,
                has_invoice: this.has_invoice,
                vat: this.vat,
                items: this.items,
                total_amount: this.grandTotal,
                record: this.record.id || null,
                record_items: this.selectedItemIds,
                object_type: this.objectType,
                items_type: this.items_type,
                source: !this.$helper.empty(this.productionSource)
                  ? this.productionSource.id
                  : null,
              })
            )
            .then((response) => {
              if (this.modalOpen) {
                this.toggleModal();
              }
              if (response.data.status) {
                localStorage.removeItem("receiveItems");
                this.supplier = null;
                // this.received_date = new Date(TODAY)
                //   .toLocaleString("fr-CA", { timeZone: TIMEZONE })()
                //   .slice(0, 10);
                this.has_invoice = 1;
                this.items = [];
                this.selectedItemIds = [];
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: "Items received successfully",
                });
                if (!this.$helper.empty(this.record)) {
                  this.$router.replace({ name: "ReceiveReport" });
                }
              }
            })
            .catch((err) => {
              if (this.modalOpen) {
                this.toggleModal();
              }
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
