<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="components-preview wide-md mx-auto">
          <div class="nk-block-head nk-block-head-lg wide-sm">
            <div class="nk-block-head-content">
              <div class="nk-block-head-sub">
                <router-link class="back-to" :to="{ name: 'Products' }"
                  ><em class="icon ni ni-arrow-left"></em
                  ><span>Inventory Items</span></router-link
                >
              </div>
              <h4 class="nk-block-title fw-normal">New Item</h4>
            </div>
          </div>
          <div class="nk-block nk-block-lg">
            <div class="card card-bordered card-preview">
              <div class="card-inner">
                <div class="preview-block has-multselect">
                  <span class="preview-title-lg overline-title"
                    >Item information</span
                  >
                  <div class="row gy-4">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="default-01"
                          >Item Name</label
                        >
                        <div class="form-control-wrap">
                          <input
                            type="text"
                            class="form-control"
                            id="default-01"
                            placeholder="Name"
                            v-model="newProduct.name"
                            name="Item Name"
                            :class="{ 'is-invalid': errors.has('Item Name') }"
                            v-validate="'required'"
                          />
                          <span
                            class="text-danger"
                            v-if="errors.has('Item Name')"
                            >{{ errors.first("Item Name") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label class="form-label" for="default-05"
                          >Cost Price</label
                        >
                        <div class="form-control-wrap">
                          <div class="form-text-hint">
                            <span class="overline-title">{{
                              appSettings.currency
                            }}</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            id="default-05"
                            :placeholder="`In ${appSettings.currency}`"
                            v-model="newProduct.cost"
                            name="Cost Price"
                            :class="{ 'is-invalid': errors.has('Cost Price') }"
                            v-validate="'required'"
                          />
                          <span
                            class="text-danger"
                            v-if="errors.has('Cost Price')"
                            >{{ errors.first("Cost Price") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label class="form-label" for="default-03"
                          >Sale Price</label
                        >
                        <div class="form-control-wrap">
                          <div class="form-text-hint">
                            <span class="overline-title">{{
                              appSettings.currency
                            }}</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            id="default-03"
                            :placeholder="`In ${appSettings.currency} (optional)`"
                            v-model="newProduct.price"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6" v-if="categories.length">
                      <div class="form-group">
                        <label class="form-label" for="default-96"
                          >Item Type</label
                        >
                        <multiselect
                          v-model="newProduct.type"
                          :options="categories"
                          placeholder="Select one"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>

                      <div class="form-group">
                        <label class="form-label" for="default-06"
                          >Item Unit</label
                        >
                        <div class="form-control-wrap">
                          <div class="form-control-select">
                            <select
                              class="form-control"
                              id="default-06"
                              v-model="newProduct.unit_id"
                              name="Item Unit"
                              :class="{ 'is-invalid': errors.has('Item Unit') }"
                              v-validate="'required'"
                            >
                              <option value="null" hidden disabled>
                                Select Unit
                              </option>
                              <option
                                :value="item.id"
                                v-for="(item, i) in units"
                                :key="'unit' + i"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                            <span
                              class="text-danger"
                              v-if="errors.has('Item Unit')"
                              >{{ errors.first("Item Unit") }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="default-textarea"
                          >Item Description</label
                        >
                        <div class="form-control-wrap">
                          <textarea
                            class="form-control no-resize"
                            id="default-textarea"
                            v-model="newProduct.description"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="form-group">
                        <label class="form-label" for="default-04"
                          >Daily Count</label
                        >
                        <div class="form-control-wrap">
                          <input
                            type="text"
                            class="form-control"
                            id="default-04"
                            placeholder="Daily Count"
                            v-model="newProduct.daily_count"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label class="form-label" for="default-04"
                          >Set Count</label
                        >
                        <div class="form-control-wrap">
                          <input
                            type="text"
                            class="form-control"
                            id="default-04"
                            v-model="newProduct.count_unit"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label d-block" for=""
                          >Item Status</label
                        >
                        <ul class="custom-control-group">
                          <li>
                            <div
                              class="custom-control custom-control-sm custom-radio custom-control-pro"
                            >
                              <input
                                type="radio"
                                class="custom-control-input"
                                name="btnRadio"
                                id="btnRadio1"
                                v-model="newProduct.status"
                                value="STORABLE"
                              /><label
                                class="custom-control-label"
                                for="btnRadio1"
                                >STORABLE</label
                              >
                            </div>
                          </li>
                          <li>
                            <div
                              class="custom-control custom-control-sm custom-radio custom-control-pro checked"
                            >
                              <input
                                type="radio"
                                class="custom-control-input"
                                name="btnRadio"
                                id="btnRadio2"
                                v-model="newProduct.status"
                                value="DIRECT_USE"
                              /><label
                                class="custom-control-label"
                                for="btnRadio2"
                                >PERISHABLES</label
                              >
                            </div>
                          </li>
                          <li>
                            <div
                              class="custom-control custom-control-sm custom-radio custom-control-pro checked"
                            >
                              <input
                                type="radio"
                                class="custom-control-input"
                                name="btnRadio"
                                id="btnRadio3"
                                v-model="newProduct.status"
                                value="SERVICE"
                              /><label
                                class="custom-control-label"
                                for="btnRadio3"
                                >SERVICE</label
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <template>
                    <hr class="preview-hr" />
                    <span class="preview-title-lg overline-title"
                      >Main Stock Configurations (optional)</span
                    >
                    <div class="row gy-4">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="form-label" for="default-1-01"
                            >Minimum</label
                          ><input
                            type="text"
                            class="form-control"
                            id="default-1-01"
                            placeholder="Minimum Quantity"
                            v-model="newProduct.store_min_qty"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="form-label" for="default-1-02"
                            >Purchase</label
                          ><input
                            type="text"
                            class="form-control"
                            id="default-1-02"
                            v-model="newProduct.store_purchase"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template>
                    <hr class="preview-hr" />
                    <span class="preview-title-lg overline-title"
                      >Branches Configurations (optional)</span
                    >
                    <div class="row gy-4">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="form-label" for="default-1-01"
                            >Minimum</label
                          ><input
                            type="text"
                            class="form-control"
                            id="default-1-01"
                            placeholder="Minimum Quantity"
                            v-model="newProduct.branch_min_qty"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="form-label" for="default-1-02"
                            >Requisition</label
                          ><input
                            type="text"
                            class="form-control"
                            id="default-1-02"
                            v-model="newProduct.branch_purchase"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="my-3 float-right">
                    <wolf-button
                      classes="btn btn-primary"
                      caption="Save"
                      @clicked="handleSubmit"
                      :disabler="!newProduct.name"
                      activator="saving_records"
                    ></wolf-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "ProductCreator",
  components: {
    Multiselect,
  },
  data: () => ({
    category: {},
    categories: {},
    suppliers: [],
    units: [],
    isProduct: true,
    newProduct: {
      name: null,
      description: null,
      cost: null,
      price: null,
      store: null,
      type_id: null,
      unit_id: null,
      quantity: 0,
      daily_count: 0,
      count_unit: 0,
      status: "STORABLE",
      category: null,
      supplier: null,
      store_min_qty: 0,
      store_purchase: 0,
      branch_min_qty: 0,
      branch_purchase: 0,
      type: null,
    },
    url: "stock/products/create",
    editingMode: false,
  }),
  computed: {
    filteredColors: function () {
      let searchKey = this.searchKey && this.searchKey.toLowerCase();
      let colors = this.colors;
      if (searchKey) {
        colors = colors.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(searchKey) > -1;
          });
        });
      }
      return colors;
    },
  },
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$http.get(`stock/products/single/${reference}`).then((response) => {
        this.newProduct = { ...this.newProduct, ...response.data.product };
        this.url = "stock/products/update";
        this.editingMode = true;
      });
    }
  },
  mounted() {
    this.$http.get(`stock/assets`).then((response) => {
      if (response.data.status) {
        this.categories = response.data.types;
        this.units = response.data.units;
      }
    });
  },
  methods: {
    customTypeName({ name }) {
      return `${name}`;
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.newProduct.type_id = this.newProduct.type.id;
          this.$http
            .post(this.url, this.$helper.generateFormData(this.newProduct))
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                if (this.editingMode) {
                  this.$router.push({ name: "Products" });
                }
                this.$helper.resetObjectValues(this.newProduct);
                this.newProduct.status = "STORABLE";
              }
            })
            
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="css"></style>
